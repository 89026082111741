import { Button, DataPoint } from '@/components/DesignSystem';
import { useGetRetailerById } from '@/hooks/useHierarchyOfRetailers';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomTypography } from './CustomStyledComponents';
import AddRetailer from './RetailerFormDialog';

const RetailerDetailsCard = () => {
  const { id } = useParams();

  const [isEditRetailer, setIsEditRetailer] = useState(false);

  const editRetailer = () => {
    setIsEditRetailer(true);
  };

  const { getRetailerById } = useGetRetailerById(id ?? '');

  return (
    <Card
      sx={{
        marginBottom: '20px',
        padding: '24px 24px 32px 24px',
      }}
    >
      <Stack direction='column'>
        <Stack spacing={0.7}>
          <CustomTypography
          color={'#C9C6BE'}
          fontSize={'11px'}
          fontWeight={'500'}
          letterSpacing={'1.25px'}
          textTransform={'uppercase'}
          >
            ID# {getRetailerById?.id}
          </CustomTypography>
          <Stack
            alignItems={'center'}
            direction='row'
            justifyContent={'space-between'}
          >
            <CustomTypography
              fontSize='28px'
              letterSpacing={'0.5px'}
              lineHeight={'10px'}
              textTransform={'capitalize'}
            >
              {getRetailerById?.name}
            </CustomTypography>
            <Button
              onClick={editRetailer}
              startIcon={<EditIcon />}
              testID='edit-details'
              variant='outlined'
            >
              Edit Details
            </Button>
          </Stack>
          <DataPoint
            fullWidth
            label="Stripe Corporate Account ID:"
            testID="stripe-account-datapoint"
          >
            {getRetailerById?.connectAccountId ?? '-'}
          </DataPoint>
        </Stack>
      </Stack>
      {isEditRetailer && (
        <AddRetailer
          initialValue={getRetailerById}
          isAddRetailerOpen={isEditRetailer}
          setIsAddRetailerOpen={setIsEditRetailer}
        />
      )}
    </Card>
  );
};

export default RetailerDetailsCard;
