import { ApiPricingRequest } from "@api/interfaces";
import {
  PricingRequestStatus,
  FulfillmentMethod,
  PaymentType,
  SeedProductUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  DryFormulationUnitOfMeasure,
  TalonStatus,
} from "@shared/enums";

export const testPricingRequest: ApiPricingRequest = {
  acceptedDate: null,
  acceptedRetailerIds: [],
  approvedDate: null,
  createdAt: new Date(),
  createdById: '',
  deletedAt: null,
  deliveryAddress1: null,
  deliveryAddress2:  null,
  deliveryCity: null,
  deliveryPostalCode: null,
  deliveryState: null,
  expiration: null,
  fulfillmentMethod: FulfillmentMethod.NO_PREFERENCE,
  id: "DI0PoxoghRDVxAm5QP2h",
  isExpired: false,
  isRead: true,
  paymentStatus: null,
  retailerPaymentStatus: '',
  isPaymentSubmitted: false,
  isReviewExpired: false,
  // eslint-disable-next-line max-len
  note: "FieldX has prepared a product request based on your last purchases. Feel free to use it, change it and then send it, in order to receive product offers for this request. Any doubts? Give us a call or send us an email (support@fieldx.com). Thank you!",
  offers: [],
  paymentType: PaymentType.Cash,
  products: [
    {
      accepted: false,
      allowSubstitutions: true,
      alternativeTo: null,
      companionToProductId: null,
      crop: null,
      daysToMaturity: null,
      id: "8s46zkS9VC1YH7yz6NYg",
      name: "Barley Seed",
      package: null,
      preferredManufacturer: null,
      quantity: 1,
      recommendedRateNote: null,
      seedTreatment: null,
      traitPackage: null,
      traitPackageDetails: null,
      type: null,
      uom: SeedProductUnitOfMeasure.BAG,
      updatedAt: new Date,
    },
    {
      accepted: false,
      allowSubstitutions: false,
      alternativeTo: null,
      companionToProductId: null,
      crop: null,
      daysToMaturity: null,
      id: "8s46zkS9VC1YH7yz6NYg",
      name: "Awesome Chemical 1000",
      package: null,
      preferredManufacturer: null,
      quantity: 100,
      recommendedRateNote: null,
      seedTreatment: null,
      traitPackage: null,
      traitPackageDetails: null,
      type: null,
      uom: LiquidFormulationUnitOfMeasure.GALLON,
      updatedAt: new Date,
    },
    {
      accepted: false,
      allowSubstitutions: true,
      alternativeTo: null,
      companionToProductId: null,
      crop: null,
      daysToMaturity: null,
      id: "8s46zkS9VC1YH7yz6NYg",
      name: "Fertlizer 4360",
      package: null,
      preferredManufacturer: null,
      quantity: 1,
      recommendedRateNote: null,
      seedTreatment: null,
      traitPackage: null,
      traitPackageDetails: null,
      type: null,
      uom: DryFormulationUnitOfMeasure.TON,
      updatedAt: new Date,
    },
  ],
  publicId: 52000,
  redeemedPoints: 0,
  referralAffiliate: null,
  referralNote: null,
  referralSender: "FieldX",
  rejectedDate: null,
  requestedExpirationDays: 3,
  salespersons: [],
  reviewExpiration: null,
  status: PricingRequestStatus.AwaitingApproval,
  storefrontId: null,
  submittedDate: new Date,
  systemDiscount: 0,
  talonStatus: TalonStatus.DEFAULT,
  updatedAt: new Date,
  userId: "5D5uIX2ii9xUC8jNDWld",
};
