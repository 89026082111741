import { Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { FulfillmentMethodPicker } from '@/components/shared/Picker/FulfillmentMethodPicker';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ApiPricingRequestPublic } from '@api/interfaces';
import Check from '@mui/icons-material/Check';
import { Stack } from "@mui/material";
import { FulfillmentMethod } from '@shared/enums';
import DeliveryAddress from './DeliveryAddress';

interface OfferFulfillmentMethodProps {
  offerMethod?: FulfillmentMethod,
  onEditFulfillmentMethod: (method: FulfillmentMethod) => void,
  pricingRequest: ApiPricingRequestPublic,
  readonly?: boolean,
}

const OfferFulfillmentMethod = ({
  offerMethod,
  onEditFulfillmentMethod,
  pricingRequest,
  readonly = false,
}: OfferFulfillmentMethodProps) => {

  const { isMobile } = useMediaQuery();

  return (
    <>
      <Stack direction="row" justifyContent={isMobile ? "center" : "space-between"}>
        <Text category="h3">Fulfillment Method</Text>
      </Stack>
      <VSpacer size="7" />
      <Text category="p1">
        The farmer is open to the following fulfillment method/methods:
      </Text>
      <VSpacer size="5" />
      <Card testID="offer-fufillment-method">
        <Stack>
          {
            pricingRequest.fulfillmentMethod === FulfillmentMethod.NO_PREFERENCE && (
              <Stack alignItems="center" direction="row">
                <Check />
                <HSpacer size="4" />
                <Text category="p2">
                  {FriendlyFulfillmentMethod[FulfillmentMethod.PICKUP]}
                </Text>
              </Stack>
            )
          }
          {
            pricingRequest.fulfillmentMethod === FulfillmentMethod.PICKUP ? (
              <Stack alignItems="center" direction="row">
                <Check />
                <HSpacer size="4" />
                <Text category="p2">
                  {FriendlyFulfillmentMethod[pricingRequest.fulfillmentMethod]}
                </Text>
              </Stack>
            ) : (
              <DeliveryAddress pricingRequest={pricingRequest} />
            )
          }
        </Stack>
      </Card>
      <VSpacer size="8" />
      <Text category="p1">How will this offer be fulfilled?</Text>
      <VSpacer size="5" />
      {
        readonly ? (
          <Card testID="offer-fulfillment-method-read-only">
            <Stack alignItems="center" direction="row">
              <Check />
              <HSpacer size="4" />
              <Text category="p2">
                {offerMethod ? FriendlyFulfillmentMethod[offerMethod] : '---'}
              </Text>
            </Stack>
          </Card>
        ) : (
          <FulfillmentMethodPicker
            hideNoPreferenceOption
            onChange={(fulfillmentMethod) => onEditFulfillmentMethod(fulfillmentMethod)}
            value={offerMethod}
          />
        )
      }
    </>
  );
}

export default OfferFulfillmentMethod;
