import { Button, DataPoint } from '@/components/DesignSystem';
import { OfferWindowPicker } from '@/components/shared/Picker/OfferWindowPicker';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const OfferWindowPickerDemo = () => {

  const [offerWindow, setOfferWindow] = useState<number | undefined>(3);
  const [openOffer, setOpenOffer] = useState(false);

  return (
    <DemoBlock label="Edit Offer Picker">
      <Stack spacing={2}>
        <Button onClick={() => setOpenOffer(true)} testID="test">
          Open Offer Picker
        </Button>
        <OfferWindowPicker
          offerWindow={offerWindow}
          onChange={(value) => setOfferWindow(value)}
          onClose={() => setOpenOffer(false)}
          open={openOffer}
          testID="offer-window-picker"
        />
        <DataPoint label="Offer window day(s):" size="large" testID="test">
          {offerWindow} day{offerWindow === 1 ? '' : 's'}
        </DataPoint>
      </Stack>
    </DemoBlock>
  );
}
