import { Button, Heading, IconButton, VSpacer } from '@/components/DesignSystem'
import { Box, Grid, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import { ApiProduct } from '@api/interfaces';
import KeyboardArrowLeftOutlined from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlined from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ProductCard } from '@/pages/Shop/ProductCard';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { DesktopOnly } from '@/components/shared/DesktopOnly';

const scrollAmount = 250;

export interface CarouselProps {
  maxProductsShown?: number,
  onQuickAdd?: (productId: string) => void,
  products?: ApiProduct[],
  testID: string,
  title: string,
}

export const Carousel = ({
  maxProductsShown = 8,
  onQuickAdd,
  products = [],
  testID,
  title,
}: CarouselProps) => {
  const { isMobile } = useMediaQuery();
  const [showViewAllProductsModal, setShowViewAllProductsModal] = useState(false);
  const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const scroll = (amount: number) => {
    containerRef.current?.scrollBy({ behavior: 'smooth', left: amount });
  };

  const updateScrollButtons = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowLeftScrollButton(scrollLeft !== 0);
      setShowRightScrollButton(scrollLeft !== scrollWidth - clientWidth);
    }
  };
  useEffect(updateScrollButtons, [products]);

  return (
    <>
      <Stack>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Heading
            level="2"
            testID={`${testID}-title`}
            title={title}
          />
          <Button
            onClick={() => setShowViewAllProductsModal(true)}
            testID={`${testID}-view-all-button`}
            variant="text"
          >
            View all
          </Button>
        </Stack>
        <VSpacer size="5" />
        <Stack position="relative">
          <Stack
            className="scrollable-container"
            direction="row"
            onScroll={updateScrollButtons}
            paddingY="2px"
            ref={containerRef}
            spacing="16px"
          >
            {products.slice(0, maxProductsShown).map((product, index) => (
              <Box id={`product-card-${index}`} key={product.id}>
                <ProductCard
                  onQuickAdd={() => onQuickAdd?.(product.id)}
                  product={product}
                  sx={{ minWidth: isMobile ? '140px' : '195px' }}
                  testID={`${testID}-product-card-${product.id}`}
                />
              </Box>
            ))}
          </Stack>
          <DesktopOnly>
            <IconButton
              onClick={() => scroll(-scrollAmount)}
              sx={{
                display: showLeftScrollButton ? 'block' : 'none',
                left: '2px',
                position: 'absolute',
                top: '50%',
              }}
              testID={`${testID}-scroll-left-button`}
              variant="filled"
            >
              <KeyboardArrowLeftOutlined />
            </IconButton>
            <IconButton
              onClick={() => scroll(scrollAmount)}
              sx={{
                display: showRightScrollButton ? 'block' : 'none',
                right: '2px',
                position: 'absolute',
                top: '50%',
              }}
              testID={`${testID}-scroll-right-button`}
              variant="filled"
            >
              <KeyboardArrowRightOutlined />
            </IconButton>
          </DesktopOnly>
        </Stack>
      </Stack>
      {showViewAllProductsModal && (
        <ModalBottomSheet
          onClose={() => setShowViewAllProductsModal(false)}
          open
          testID={`${testID}-view-all-products-modal`}
          title={`All ${title}`}
        >
          <Grid container justifyContent="flex-start" paddingY="2px" spacing="16px">
            {products.map((product) => (
              <Grid
                display="flex"
                item
                justifyContent="center"
                key={product.id}
                xs={isMobile ? 6 : 4}
              >
                <ProductCard
                  onQuickAdd={() => {
                    setShowViewAllProductsModal(false);
                    onQuickAdd?.(product.id);
                  }}
                  product={product}
                  sx={{ maxWidth: '240px' }}
                  testID={`${testID}-product-card-${product.id}`}
                />
              </Grid>
            ))}
          </Grid>
        </ModalBottomSheet>
      )}
    </>
  );
}
