import { Button, Chip, HSpacer, Text } from '@/components/DesignSystem';
import { GlobalBanner } from '@/components/DesignSystem/Banner/GlobalBanner';
import { FriendlyPaymentStatus } from '@/constants/FriendlyPaymentStatus';
import { Color } from '@/themes/MUITheme/palette';
import { BannerStatus } from '@/themes/variant-interfaces/Status';
import { Stack } from '@mui/material';
import { PaymentStatus, PaymentType } from '@shared/enums';
import { formatCurrency } from '@shared/utilities';

interface PaymentData {
  badge?: {
    color: Color,
    content: string,
  },
  banner?: {
    hideIcon?: boolean,
    message: string,
    status: BannerStatus,
  },
  button?: {
    outlined?: boolean,
    text: string,
  },
  note?: string,
}

const getPaymentData = (paymentStatus: PaymentStatus, paymentType: PaymentType): PaymentData => {
  switch (paymentStatus) {
    case PaymentStatus.FAILED:
      return {
        banner: {
          message: FriendlyPaymentStatus[paymentStatus],
          status: 'warning',
        },
        button: {
          text: 'Pay now',
        },
      };
    case PaymentStatus.PAID:
      return {
        badge: {
          color: 'success',
          content: FriendlyPaymentStatus[paymentStatus],
        },
      };
    case PaymentStatus.PROCESSING:
      return {
        badge: {
          color: 'error',
          content: FriendlyPaymentStatus[paymentStatus],
        },
        note: paymentType === PaymentType.ACH
          ? 'ACH payments may take 2-4 days to complete'
          : undefined,
      };
    case PaymentStatus.REFUNDED:
      return {
        badge: {
          color: 'info',
          content: FriendlyPaymentStatus[paymentStatus],
        },
      };
    case PaymentStatus.REQUIRES_ACTION:
      return {
        banner: {
          message: FriendlyPaymentStatus[paymentStatus],
          status: 'warning',
        },
        button: {
          outlined: true,
          text: 'Verify Trial Deposit',
        },
        note: 'Once verified, refresh this page to see updated status',
      };
    case PaymentStatus.UNPAID:
      return {
        banner: {
          hideIcon: true,
          message: 'Payment due',
          status: 'info',
        },
        button: {
          text: 'Pay now',
        },
      };
    default:
      return {};
  }
}

interface StripePaymentProps {
  feeAmountInCents: number,
  feePercent: number,
  paymentStatus: PaymentStatus,
  paymentType: PaymentType,
  testID: string,
}

export const StripePayment = ({
  feeAmountInCents,
  feePercent,
  paymentStatus,
  paymentType,
  testID,
}: StripePaymentProps) => {
  const {
    badge,
    banner,
    button,
    note,
  } = getPaymentData(paymentStatus, paymentType);

  return (
    <Stack
      data-testid={testID}
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      {!!banner && (
        <GlobalBanner {...banner} testID={`${testID}-global-banner`} />
      )}
      <Stack p="20px" spacing={2}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text
            category="title-medium"
            testID={`${testID}-fee-percent`}
          >
            {feePercent}% Retailer transaction fee
          </Text>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Text
              category="title-medium"
              testID={`${testID}-amount-due`}
            >
              Amount due: {formatCurrency(feeAmountInCents / 100)}
            </Text>
            {!!badge && (
              <>
                <HSpacer size="5" />
                <Chip
                  color={badge.color}
                  label={badge.content}
                  testID={`${testID}-color`}
                />
              </>
            )}
            {!!button && (
              <>
                <HSpacer size="6" />
                <Button
                  testID={`${testID}-button`}
                  variant={button.outlined ? 'outlined' : undefined}
                >
                  {button.text}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        {!!note && (
          <Text
            category="body-small"
            sx={{
              alignSelf: 'flex-end',
            }}
          >
            {note}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}
