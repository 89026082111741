import {
  Button,
  MenuItem,
  NumericInput,
  Select,
  Switch,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { useManufacturerList } from '@/hooks/useManufacturerList';
import { QueryUtil } from '@/utilities/Query';
import { ProductEventEndpoint } from '@api/endpoints/ProductEventEndpoint';
import AddIcon from '@mui/icons-material/Add';
import { Container, Stack } from '@mui/material';
import { useState } from 'react';

function clickLink (url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.click();
}

async function getCsv (options: ProductEventEndpoint.Export.Query) {
  if (!options.manufacturerId) {
    delete options.manufacturerId;
  }
  const queryString = QueryUtil.stringify(options);
  clickLink(`${AppConfig.biddingApiUrl}/product-events/export?${queryString}`);
}

export const ProductEvents = () => {
  const [days, setDays] = useState<number>(30);
  const [excludeInternal, setExcludeInternal] = useState<boolean>(false);
  const [manufacturerId, setManufacturerId] = useState<string>('');
  const { manufacturers } = useManufacturerList();

  const ExportButton = <Button
    onClick={() => getCsv({
      days,
      excludeInternal,
      manufacturerId,
    })}
    size="large"
    startIcon={<AddIcon />}
    testID="manage-manufacturers-create"
  >
    Export data
  </Button>;

  return (
    <Container maxWidth="xs">
      <Stack>
        <VSpacer size="8" />
        <Stack alignItems="left" spacing={2}>
          <Text category="h3">
            Product Events
          </Text>
        <NumericInput
          decimals={0}
          label="Days of Results to include"
          onChangeNumber={(value) => setDays(value ?? 30)}
          testID="export-events-days"
          value={days}
        />
        <Switch
          checked={excludeInternal}
          onChangeChecked={setExcludeInternal}
          testID="exclude-internal"
        >
          Exclude Internal
        </Switch>
          <Select
            label="Manufacturer"
            onChangeValue={setManufacturerId}
            testID="export-events-manufacturer"
            value={manufacturerId}
          >
            <MenuItem key={''} testID={`empty-menu-item`} value={''}>
              (all)
            </MenuItem>
            {manufacturers?.map((manufacturer) => (
              <MenuItem
                key={manufacturer.id}
                testID={`${manufacturer.id}-menu-item`}
                value={manufacturer.id}
              >
                {manufacturer.name}
              </MenuItem>
            ))}
          </Select>
        {ExportButton}
        </Stack>
      </Stack>
    </Container>
  );
}
