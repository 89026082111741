import {
  NumericInput,
} from '@/components/DesignSystem';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const NumericInputDemo = () => {

  const [number, setNumber] = useState<number | undefined>(0);

  return (
    <>
      <DemoBlock label="Basic">
        <NumericInput
          label="Label"
          onChangeNumber={setNumber}
          testID="numeric-input-demo-basic"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Required">
        <NumericInput
          label="Label"
          onChangeNumber={setNumber}
          required
          testID="numeric-input-demo-required"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Disabled">
        <NumericInput
          disabled
          label="Label"
          onChangeNumber={setNumber}
          testID="numeric-input-demo-disabled"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Error">
        <NumericInput
          error
          helperText="The specified number is invalid"
          label="Label"
          onChangeNumber={setNumber}
          testID="numeric-input-demo-error"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="With prefix">
        <NumericInput
          label="Label"
          onChangeNumber={setNumber}
          prefix="%"
          testID="numeric-input-demo-with-prefix"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="With prefix and suffix">
        <NumericInput
          label="Label"
          onChangeNumber={setNumber}
          prefix="$"
          suffix="*"
          testID="numeric-input-demo-with-prefix"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="5 decimals">
        <NumericInput
          decimals={5}
          label="Label"
          onChangeNumber={setNumber}
          testID="numeric-input-demo-with-decimals"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Min value = 0, max value = 250">
        <NumericInput
          label="Label"
          maxValue={250}
          minValue={0}
          onChangeNumber={setNumber}
          testID="numeric-input-demo-with-maxmin-values"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Min value = 100, max value = 250">
        <NumericInput
          label="Label"
          maxValue={250}
          minValue={100}
          onChangeNumber={setNumber}
          testID="numeric-input-demo-with-maxmin-values"
          value={number}
          width={300}
        />
      </DemoBlock>
      <DemoBlock label="Min value = -500, max value = 250">
        <NumericInput
          label="Label"
          maxValue={250}
          minValue={-500}
          onChangeNumber={setNumber}
          testID="numeric-input-demo-with-maxmin-values"
          value={number}
          width={300}
        />
      </DemoBlock>
    </>
  );
}
