import {
  Button,
  DataPoint,
  Dialog,
  Heading,
  HSpacer,
  NumericInput,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { ResponsiveDialog } from '@/components/shared/ResponsiveDialog/ResponsiveDialog';
import { ProductUomSelect } from '@/components/shared/Select/ProductUomSelect';
import { TextList } from '@/components/shared/TextList/TextList';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { useLogEvent, CreateOfferEventType } from '@/utilities/Analytics';
import { StringUtility } from '@/utilities/String';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import Notes from '@mui/icons-material/Notes';
import { Box, Divider, Stack } from '@mui/material';
import {
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  parseEnum,
  ProductUom,
  SeedProductUnitOfMeasure,
  ServiceUnitOfMeasure,
} from '@shared/enums';
import { ConversionUtility, formatCurrency, isEmptyText, roundToFixed } from '@shared/utilities';
import { useCallback, useEffect, useState } from 'react';

const styles = {
  closeIcon: {
    marginRight: '8px',
  },
  dataPoint: {
    py: 0,
  },
  inputSubline: {
    padding: '4px 0 0 16px',
  },
  lineBreaks: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  note: {
    border: '1px solid',
    borderRadius: 2,
    padding: '20px 20px 24px 20px',
  },
  swapIcon: {
    marginTop: '16px',
  },
} as const;

const maxValue = 9999999.99;

interface AddPriceModalProps {
  product: OfferProduct,
  onClose: () => void,
  onSave: (product: OfferProduct) => void,
}

export const AddPriceModal = ({
  product,
  onClose,
  onSave,
}: AddPriceModalProps) => {
  const [unitPrice, setUnitPrice] = useState<number | undefined>(
    (product.price && product.quantity)
      ? product.price / product.quantity
      : undefined,
  );
  const [
    totalPrice,
    setTotalPrice] = useState<number | undefined>(product.price ? product.price : undefined);
  const [unitPriceError, setUnitPriceError] = useState(false);
  const [totalPriceError, setTotalPriceError] = useState(false);
  const [showEditQuantity, setShowEditQuantity] = useState(
    !isEmptyText(product.recommendedRateNote)
    && (!product.quantity && !product.uom),
  );
  const [quantity, setQuantity] = useState<number | undefined>(product.quantity ?? undefined);
  const [uom, setUom] = useState<ProductUom | undefined>(product.uom ?? undefined);
  const [recommendedQuantity, setRecommendedQuantity]
    = useState<number | undefined>(product.quantity ?? undefined);
  const [recommendedUom, setRecommendedUom]
    = useState<ProductUom | undefined>(product.uom ?? undefined);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const logEvent = useLogEvent();

  const saveProduct = useCallback(() => {
    onSave({
      ...product,
      price: totalPrice ?? 0,
      quantity: quantity ?? null,
      uom: uom ?? null,
    });
  }, [onSave, product, quantity, totalPrice, uom]);

  const saveProductAndClose = useCallback(() => {
    saveProduct();
    onClose();
  }, [onClose, saveProduct]);

  const onChangeUnitPrice = useCallback((value?: number) => {
    setUnitPrice(value);
    if (value === undefined) {
      setTotalPrice(undefined);
    } else if (quantity) {
      setTotalPrice(value * quantity);
    }
  }, [quantity]);

  const onChangeTotalPrice = useCallback((value?: number) => {
    setTotalPrice(value);
    if (value === undefined) {
      setUnitPrice(undefined);
    } else if (quantity) {
      setUnitPrice(value / quantity);
    }
  }, [quantity]);

  const isUomDisabled = (listedUom: string) => {
    if (!uom) {
      const disallowedUoms: ProductUom[] = [
        SeedProductUnitOfMeasure.BUSHEL,
        SeedProductUnitOfMeasure.BAG,
        ServiceUnitOfMeasure.ACRE,
      ];
      return disallowedUoms.includes(listedUom as ProductUom);
    }
    if (parseEnum(LiquidFormulationUnitOfMeasure, uom)) {
      return !parseEnum(LiquidFormulationUnitOfMeasure, listedUom);
    } else if (parseEnum(DryFormulationUnitOfMeasure, uom)) {
      return !parseEnum(DryFormulationUnitOfMeasure, listedUom);
    } else {
      return listedUom !== uom;
    }
  }

  useEffect(() => {
    if (quantity && unitPrice) {
      onChangeUnitPrice(unitPrice);
    }
  }, [quantity, unitPrice, onChangeUnitPrice]);

  const onUomChange = useCallback((previousUom: ProductUom, newUom: ProductUom) => {
    if (unitPrice) {
      const conversionRate = ConversionUtility.convertProductUoM(1, previousUom, newUom)
      setUnitPrice(Number(roundToFixed(unitPrice / conversionRate, 2)));
    }
  }, [unitPrice])

  const isFormValid = !!unitPrice && !!totalPrice && !showEditQuantity;
  const isQuantityFormValid = !!recommendedQuantity && !!recommendedUom;
  const doesProductHaveRate = !!quantity && !!uom;
  const isRateRecommended = (
    doesProductHaveRate
    && (
      quantity !== product.initialQuantity
      || uom !== product.initialUom
    )
  );
  const doesProductHaveInitialRate = !!product.initialQuantity || !!product.initialUom;
  const showInitialQuantity = isRateRecommended && doesProductHaveInitialRate;
  const isPriceRemoved = !!product.price && !totalPrice;
  const isChanged = (
    product.price !== (totalPrice ?? 0)
    || quantity !== (product.quantity ?? undefined)
    || uom !== (product.uom ?? undefined)
  );

  const quantityText = doesProductHaveRate
    ? StringUtility.formatQuantityWithUom(quantity, uom)
    : 'Recommend';

  const ProductDetails = (
    <>
      <Divider />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <DataPoint
          containerStyle={{ py: '14px' }}
          label="Quantity:"
          spacing="fixed"
          testID="add-price-modal-quantity"
        >
          {quantityText}
        </DataPoint>
      </Stack>
      <Divider />
      {showInitialQuantity && (
        <Stack direction="row" justifyContent="space-between">
          <DataPoint
            childrenStyle={{ textDecoration: 'line-through' }}
            containerStyle={{ py: '14px' }}
            label="Initial quantity:"
            spacing="fixed"
            testID="add-price-modal-initial-quantity"
          >
            {StringUtility.formatQuantityWithUom(
              product?.initialQuantity ?? undefined,
              product?.initialUom ?? undefined,
            )}
          </DataPoint>
          <Divider />
        </Stack>
      )}
      <Divider />
    </>
  );

  return (
    <ResponsiveDialog
      acceptButton={() => (
        <Button
          disabled={!isFormValid}
          onClick={saveProductAndClose}
          testID="add-price-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={() => (
        <Button
          onClick={() => {
            if (isChanged) {
              setShowRemoveDialog(true);
            } else {
              onClose();
            }
            logEvent(CreateOfferEventType.ClickCancelPrice);
          }}
          testID="add-price-modal-cancel-button"
          variant="text"
        >
          Cancel
        </Button>
      )}
      onClose={() => {
        if (isChanged) {
          setShowRemoveDialog(true);
        } else {
          onClose();
        }
      }}
      testID="add-price-modal"
      title={product.price ? 'Edit price' : 'Add price'}
    >
      <VSpacer mobileSize="4" size="5" />
      {showEditQuantity ? (
        <Box padding="16px 16px 0">
          <Heading
            level="3"
            testID="add-price-modal-recommend-rate-heading"
            title="Enter quantity recommendation"
          />
          <VSpacer size="6" />
          <Stack alignItems="center" direction="row">
            <NumericInput
              label="Quantity"
              maxValue={9999999.99}
              onChangeNumber={setRecommendedQuantity}
              testID="add-price-modal-quantity-input"
              value={recommendedQuantity}
            />
            <HSpacer size="5" />
            <ProductUomSelect
              isUomDisabled={isUomDisabled}
              onChangeUoM={setRecommendedUom}
              testID="add-price-modal-uom-select"
              value={recommendedUom}
            />
          </Stack>
          <VSpacer size="8" />
          <Stack direction="row" justifyContent="center">
            <Button
              disabled={!isQuantityFormValid}
              onClick={() => {
                if (uom) {
                  onUomChange(uom, recommendedUom!);
                }
                setQuantity(recommendedQuantity);
                setUom(recommendedUom);
                setShowEditQuantity(false);
              }}
              size="medium"
              testID="add-price-modal-done"
            >
              Continue
            </Button>
          </Stack>
          {!isEmptyText(product.recommendedRateNote) && (
            <>
              <VSpacer size="9" />
              <Stack spacing={2} sx={styles.note}>
                <Heading
                  leftAccessory={<Notes fontSize="inherit" />}
                  level="4"
                  testID="add-price-modal-note-title"
                  title="FARMER'S NOTES"
                />
                <Text
                  sx={styles.lineBreaks}
                  testID="add-price-modal-note-text"
                >
                  {product.recommendedRateNote}
                </Text>
              </Stack>
            </>
          )}
        </Box>
      ) : (
        <Stack alignItems="center" px={getResponsiveValue('16px', '0')}>
          <VSpacer size="8" />
          <Text
            category="display-small"
            testID="add-price-modal-total-price-display-text"
          >
            {totalPrice ? formatCurrency(totalPrice) : '$0'}
          </Text>
          <VSpacer size="2" />
          <Text category="label-small" upperCase>
            Your Offer
          </Text>
          <VSpacer size="8" />
          <VSpacer size="2" />
          <Stack direction="row">
            <Stack>
              <NumericInput
                error={unitPriceError}
                label="Unit price"
                maxValue={maxValue}
                minValue={0}
                onBlur={() => {
                  setUnitPriceError(!unitPrice);
                  setTotalPriceError(!totalPrice);
                }}
                onBlurChanged={() => logEvent(CreateOfferEventType.ChangeUnitPrice)}
                onChangeNumber={onChangeUnitPrice}
                testID="add-price-modal-unit-price-input"
                value={unitPrice}
              />
              <Box sx={styles.inputSubline}>
                <Text category="body-small" testID="add-price-modal-per-uom">
                  per {uom ? FriendlyUoM[uom] : ''}
                </Text>
              </Box>
            </Stack>
            <HSpacer size="3" />
            <SwapHoriz sx={styles.swapIcon} />
            <HSpacer size="3" />
            <Stack>
              <NumericInput
                error={totalPriceError}
                label="Total price"
                maxValue={maxValue}
                minValue={0}
                onBlur={() => {
                  setTotalPriceError(!totalPrice);
                  setUnitPriceError(!unitPrice);
                }}
                onBlurChanged={() => logEvent(CreateOfferEventType.ChangeTotalPrice)}
                onChangeNumber={onChangeTotalPrice}
                testID="add-price-modal-total-price-input"
                value={totalPrice}
              />
              <Box sx={styles.inputSubline}>
                <Text category="body-small" testID="add-price-modal-for-quantity-uom">
                  for
                  {' '}
                  {StringUtility.formatQuantityWithUom(
                    quantity ?? undefined,
                    uom ?? undefined,
                  )}
                </Text>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
      <VSpacer size="9" />
      <Divider />
      <VSpacer size="7" />
      <Box px="16px">
        <Text category="body-xlarge">
          {product.name}
        </Text>
        <VSpacer size="7" />
        <VSpacer size="1" />
        {ProductDetails}
      </Box>
      <Dialog
        acceptButton={() => (
          <Button
            onClick={() => {
              if (isPriceRemoved) {
                onSave({
                  ...product,
                  price: 0,
                  quantity: product.initialQuantity,
                  uom: product.initialUom,
                });
              }
              onClose();
            }}
            testID="discard-add-price-modal-discard-button"
            variant="text"
          >
            Confirm
          </Button>
        )}
        cancelButton={() => (
          <Button
            onClick={() => setShowRemoveDialog(false)}
            testID="discard-add-price-modal-cancel-button"
            variant="text"
          >
            Cancel
          </Button>
        )}
        onClose={() => setShowRemoveDialog(false)}
        open={showRemoveDialog}
        showCloseButton={false}
        testID="discard-add-price-modal"
        title={isPriceRemoved ? 'Discard price?' : 'Discard changes?'}
      >
        {isPriceRemoved && (
          <TextList
            items={[
              'Price',
              'Quantity recommendation',
            ]}
            testID="discard-text-list-add-price-modal"
            title="This action will permanently delete:"
          />
        )}
      </Dialog>
    </ResponsiveDialog>
  );
}
