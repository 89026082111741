import { ApiProductRecommendation, ApiRetailerList } from '@api/interfaces';
import { CircularProgress, Container, Divider, Pagination, Stack, Tab } from '@mui/material';
import { FilterResult } from '@/components/DesignSystem/Toolbar/interfaces';
import { Filters } from './Interfaces';
import ProductRecommendationCard from './ProductRecommendationCard';
import { RetailerListing } from './Interfaces';
import { Search, Text, Tabs, VSpacer } from '@/components/DesignSystem';
import { useEffect, useMemo, useState } from 'react';
import { useProductRecommendations } from '@/hooks/useProductRecommendations';
import { useSearch } from '@/hooks/useSearch';
import { Toolbar } from '@/components/DesignSystem';
import { useGetRetailerList } from '@/hooks/useProductQuery';

const priceFilter = (selectedTab: number) => {
  switch (selectedTab) {
    case 1:
      return true;
    case 2:
      return false;
    default:
      return null;
  }
};

const ProductRecommendation = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [filters, setFilters] = useState<Filters>({});
  const [filterResult, setFilterResult] = useState<FilterResult>({});

  const { retailerList: partnerRetailerList } = useGetRetailerList();

  const listingoffilter: Record<string, string | boolean> = Array.from(
    filterResult?.selections?.entries() ?? [],
  )
    .map(([filterId, selection]) => {
      return {
        filterId,
        value: Array.from(selection).join(', '),
      };
    })
    .reduce((obj, filter) => {
      if (filter?.filterId === 'sortDesc') {
        obj['sortDesc'] = filter.value === 'z-a' ? true : false;
        return obj;
      } else {
        obj[filter.filterId] = filter.value;
        return obj;
      }
    }, {} as Record<string, string | boolean>);

  const { search, setSearch, debouncedSearch } = useSearch();

  const { productRecommendations, isLoading } = useProductRecommendations(
    { ...filters, ...listingoffilter },
    debouncedSearch,
  );

  const retailerListing = useMemo(
    () =>
      partnerRetailerList?.map(
        (retailer: ApiRetailerList) => ({
          id: retailer.id,
          label: retailer.name ?? '',
        }),
      ),
    [partnerRetailerList],
  ) as RetailerListing[] | undefined;

  useEffect(() => {
    if (debouncedSearch) {
      setFilters(prevFilters => ({ ...prevFilters, page: 0 }));
    }
  }, [debouncedSearch]);

  const generateCountLabel = () => {
    if (productRecommendations) {
      if (productRecommendations?.data?.length <= 1) {
        return `${productRecommendations?.data?.length} Product Recommendation`;
      }
      return `${productRecommendations?.data?.length} Product Recommendations`;
    }
    return `Product Recommendations`;
  }

  return (
    <Container maxWidth='lg'>
      <Stack>
        <Text
          category='h3'
          style={{ margin: '2rem 0' }}
          testID='product-recommendation-header'
        >
          Product Recommendations
        </Text>
        <Search
          onChangeText={setSearch}
          style={{ marginBottom: '2rem' }}
          testID='product-recommendation-search'
          value={search}
          width={300}
        />
        <Tabs
          onChange={(_, value) => {
            setSearch('');
            setSelectedTab(value);
            if (value !== 0)
              return setFilters({ ...filters, hasPrices: priceFilter(value) ?? undefined });
            setFilters((prev: Filters) => {
              delete prev.hasPrices;
              return prev;
            });
          }}
          testID='product-recommendation-tabs'
          value={selectedTab}
        >
          <Tab label='All' />
          <Tab label='With Price' />
          <Tab label='Without Price' />
        </Tabs>
        <Divider />
        <Stack
          alignItems={'center'}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ marginTop: '1rem' }}
        >
          <Text>
            {generateCountLabel()}
          </Text>
          <Stack direction={'row'} spacing='2'>
            <Toolbar
              filters={[
                {
                  hideClearButton: true,
                  id: 'sortDesc',
                  label: 'Sort by',
                  options: [
                    { default: true, id: 'z-a', label: 'Newest to Oldest' },
                    { id: 'a-z', label: 'Oldest to Newest' },
                  ],
                  selectionMethod: 'single-select',
                },
                {
                  hideClearButton: false,
                  id: 'retailerId',
                  label: 'Retailer',
                  options: retailerListing ?? [],
                  selectionMethod: 'single-select',
                },
              ]}
              hideAllFilter={false}
              hideSearch
              onChange={setFilterResult}
              testID='product-recommendation-toolbar'
            />
          </Stack>
        </Stack>
        {!productRecommendations?.data?.length && !isLoading && (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ marginTop: '10rem' }}
          >
            <Text
              category='p2'
              style={{
                color: '#A0A0A0',
              }}
            >
              No product recommendations available
            </Text>
          </Stack>
        )}
        {!isLoading ? (
          <>
            {productRecommendations?.data?.map(
              (
                productRecommendation: ApiProductRecommendation,
                index: number,
              ) => (
                <ProductRecommendationCard
                  key={index}
                  productRecommendation={productRecommendation}
                />
              ),
            )}
          </>
        ) : (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ marginTop: '10rem' }}
          >
            <CircularProgress />
          </Stack>
        )}
      </Stack>
      {productRecommendations && !!productRecommendations?.lastPage && (
        <Stack alignItems='center'>
          <VSpacer size='9' />
          <Pagination
            count={productRecommendations?.lastPage + 4}
            onChange={(_, page) => setFilters({ ...filters, page: page - 1 })}
            page={productRecommendations?.page + 1}
          />
        </Stack>
      )}
    </Container>
  );
};

export default ProductRecommendation;
