import { InlineTextList } from '@/components/shared/InlineTextList/InlineTextList';
import { DemoBlock } from '@/pages/UXSandbox/components/DemoBlock';

export const InlineTextListDemo = () => {
  return (
    <>
      <DemoBlock label="Items only">
        <InlineTextList
          items={[
            'Price',
            'Substitute product',
            'Quantity recommendation',
          ]}
          testID="inlint-text-list-demo"
        />
      </DemoBlock>
      <DemoBlock label="Items with title">
        <InlineTextList
          items={[
            'Price',
            'Substitute product',
            'Quantity recommendation',
          ]}
          testID="inlint-text-list-demo"
          title="title"
        />
      </DemoBlock>
    </>
  );
}
