import {VSpacer} from "@/components/DesignSystem";
import { Stack, Box, ListItemText } from "@mui/material";
import { Text } from "@/components/DesignSystem";
import { Upload } from "@mui/icons-material";
import { Button } from "@/components/DesignSystem";
import { SXStyles } from "@/themes/variant-interfaces/SXStyles";
import { AppConfig } from "../../../constants/AppConfig";
const styles: SXStyles = {
  imageContainer: {
    borderTop: "1px solid #3F3E38",
    borderBottom: "1px solid #3F3E38",
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  uploadImageButton:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItemText: {
    display: "inline-block",
    marginRight: "15px",  
  },
  removeImageButton:{
    marginLeft:'15px',
  },
} as const;

interface FileUploadProps {
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  value?: string;
  name?: string;
  imageName?: string;
  handleRemoveImage:()=>void
  productImg:string;
  isEdit:boolean;
}

export const FileUpload = ({
  handleOnChange,
  name,
  required = false,
  value,
  imageName,
  handleRemoveImage,
  productImg,
  // isEdit,
  ...rest
}: FileUploadProps) => {
  return (
    <Stack sx={styles.imageContainer}>
      {!value && (
        <Stack>
          <Box sx={styles.uploadImageButton}>
            <Text category="body-small" testID={`sdfs-description`}>
              Thumbnail link
            </Text>

            <Button
              startIcon={<Upload />}
              testID="button-demo-enabled-text"
              variant="text"
            >
              Upload Image
              <input
                accept=".png,"
                multiple
                name={name || "upload"}
                onChange={(e) => handleOnChange(e)}
                required={required}
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  height: "38px",
                  width: "100%",
                  opacity: "0",
                }}
                type="file"
                {...rest}
              />
            </Button>
          </Box>
        </Stack>
      )}
      {value && (
        <Stack
          sx={{
            border: "1px dashed #3F3E38",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "40px",
          }}
        >{

          !productImg.length ?
           <img alt="image" src={`${AppConfig.staticImageHost}/${value}`}width="220px"  />
            :
          <img alt="dsfs"  height="220px" src={`${productImg}`} width="220px"/>
        }

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Text category="body-small" testID={`thumbnail`}>
              {imageName}
            </Text>
            <Button
            onClick={handleRemoveImage}
            sx={styles.removeImageButton}
            testID="button-demo-enabled-text"
            variant="text">
              Remove
            </Button>
          </Box>
        </Stack>
      )}

      <Stack>
        <VSpacer size="4" />
        <Box>
          <ListItemText sx={styles.listItemText}>
            <Text category="body-small" testID={`description`}>
              File type: <strong>PNG</strong>
            </Text>
          </ListItemText>
          <ListItemText sx={styles.listItemText}>
            <Text category="body-small" testID={`description`}>
              Ratio: <strong>1:1</strong>
            </Text>
          </ListItemText>
          <ListItemText sx={styles.listItemText}>
            <Text category="body-small" testID={`description`}>
              Size: <strong>220 X 220 pixels</strong>
            </Text>
          </ListItemText>
          <ListItemText sx={styles.listItemText}>
            <Text category="body-small" testID={`description`}>
              Max File Size: <strong>1MB</strong>
            </Text>
          </ListItemText>
        </Box>
      </Stack>
    </Stack>
  );
};
