import Square from '@mui/icons-material/Square';
import {
  BottomNavigation,
  BottomNavigationAction,
  Stack,
} from '@mui/material';
import { DemoBlock } from '../../components/DemoBlock';
import { useState } from 'react';

export const NavigationBarDemo = () => {

  const [value, setValue] = useState("0");

  const SampleIcon = () => <Square sx={{
    width: "14px",
    height: "12px",
  }} />;

  return (
    <>
      <DemoBlock label="3 Items - Labels">
        <Stack sx={{ width: "412px" }}>
          <BottomNavigation
            showLabels
            value={value}
          >
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("0")}
              value="0"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("1")}
              value="1"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("2")}
              value="2"
            />
          </BottomNavigation>
        </Stack>
      </DemoBlock>
      <DemoBlock label="4 Items - Labels">
        <Stack sx={{ width: "412px" }}>
          <BottomNavigation
            showLabels
            value={value}
          >
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("0")}
              value="0"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("1")}
              value="1"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("2")}
              value="2"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("3")}
              value="3"
            />
          </BottomNavigation>
        </Stack>
      </DemoBlock>
      <DemoBlock label="5 Items - Labels">
        <Stack sx={{ width: "412px" }}>
          <BottomNavigation
            showLabels
            value={value}
          >
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("0")}
              value="0"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("1")}
              value="1"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("2")}
              value="2"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("3")}
              value="3"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("4")}
              value="4"
            />
          </BottomNavigation>
        </Stack>
      </DemoBlock>
      <DemoBlock label="3 Items - No Labels">
        <Stack sx={{ width: "412px" }}>
          <BottomNavigation
            value={value}
          >
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("0")}
              value="0"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("1")}
              value="1"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("2")}
              value="2"
            />
          </BottomNavigation>
        </Stack>
      </DemoBlock>
      <DemoBlock label="4 Items - No Labels">
        <Stack sx={{ width: "412px" }}>
          <BottomNavigation
            value={value}
          >
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("0")}
              value="0"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("1")}
              value="1"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("2")}
              value="2"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("3")}
              value="3"
            />
          </BottomNavigation>
        </Stack>
      </DemoBlock>
      <DemoBlock label="5 Items - No Labels">
        <Stack sx={{ width: "412px" }}>
          <BottomNavigation
            value={value}
          >
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("0")}
              value="0"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("1")}
              value="1"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("2")}
              value="2"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("3")}
              value="3"
            />
            <BottomNavigationAction
              icon={<SampleIcon />}
              label="Label"
              onClick={() => setValue("4")}
              value="4"
            />
          </BottomNavigation>
        </Stack>
      </DemoBlock>
    </>
  );
}
