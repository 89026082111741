import { Button, Card, Chip, HSpacer, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Color } from '@/themes/MUITheme/palette';
import { DateTime } from 'luxon';
import { formatDateOnly } from '@shared/utilities';

interface PromotionCardProps {
  promotion: ApiPromotion,
  onDelete: () => void,
  onEdit: () => void,
  onView: () => void,
  testID: string,
}

enum PromotionStatus {
  FUTURE = 'future',
  RUNNING = 'running',
  EXPIRED = 'expired',
}

const statusColors: { [key: string]: Color | undefined } = {
  [PromotionStatus.FUTURE]: 'info',
  [PromotionStatus.RUNNING]: 'success',
  [PromotionStatus.EXPIRED]: undefined,
};

function formatDate (date: string) {
  return DateTime.fromISO(date).toFormat('MMM d, yyyy');
}

function getStatuses (promotion: ApiPromotion) {
  const statuses: PromotionStatus[] = [];
  const currentDate = formatDateOnly(new Date());

  if (currentDate < promotion.startDate) {
    statuses.push(PromotionStatus.FUTURE)
  }

  if (currentDate > promotion.endDate) {
    statuses.push(PromotionStatus.EXPIRED)
  }

  if (currentDate >= promotion.startDate && currentDate <= promotion.endDate) {
    statuses.push(PromotionStatus.RUNNING)
  }

  return statuses;
}

export const PromotionCard = ({
  onDelete,
  onEdit,
  onView,
  promotion,
  testID,
}: PromotionCardProps) => {
  const statuses = getStatuses(promotion);
  const canBeEdited = statuses.includes(PromotionStatus.FUTURE);

  const Header = (
    <Stack
      alignItems='top'
      direction='row'
      justifyContent={'space-between'}
    >
      <Stack>
        <Text category="overline" sx={{ color: '#D8D8D8' }}>
          {promotion.type} promotion
        </Text>
        <VSpacer size="1" />
        <Text category="title-medium">
          {promotion.name}
        </Text>
      </Stack>
      <Stack alignItems='top' direction='row' spacing={2}>
        <Button
          onClick={onView}
          size="medium"
          sx={{ padding: '10px 24px 10px 24px' }}
          testID={`${testID}-view-button`}
          variant="outlined"
        >
          View
        </Button>
        <IconButton
          color="primary"
          disabled={!canBeEdited}
          onClick={onEdit}
          testID={`${testID}-edit-button`}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          disabled={!canBeEdited}
          onClick={onDelete}
          testID={`${testID}-delete-button`}
        >
          <DeleteOutline />
        </IconButton>
      </Stack>
    </Stack>
  );

  const StatusChips = (
    <Stack direction="row" gap="8px">
      {statuses.map((status) => (
        <Chip
          color={statusColors[status]}
          key={status}
          label={status.toUpperCase()}
          testID={`${testID}-status-${status}`}
        />
      ))}
    </Stack>
  );

  const Details = (
    <Stack>
      <Stack direction="row">
        <Text sx={{ color: '#D8D8D8' }}>
          Start date:
        </Text>
        <HSpacer size="3" />
        <Text>
          {formatDate(promotion.startDate)}
        </Text>
        <HSpacer size="6" />
        <Text sx={{ color: '#D8D8D8' }}>
          End date:
        </Text>
        <HSpacer size="3" />
        <Text>
          {formatDate(promotion.endDate)}
        </Text>
      </Stack>
      {!!promotion.description && (
        <>
          <VSpacer size="4" />
          <Text sx={{ color: '#D8D8D8' }}>
            Description
          </Text>
          <VSpacer size="2" />
          <Text category="label-medium">
            {promotion.description}
          </Text>
        </>
      )}
    </Stack>
  );

  return (
    <Card
      cardContentStyle={{ padding: '16px' }}
      testID={testID}
    >
      {Header}
      <VSpacer size="3" />
      {StatusChips}
      <VSpacer size="3" />
      {Details}
    </Card>
  );
};
