import { QueryUtil } from '../Query';
import { Client } from './Client';
import { ProductEndpoint } from '@api/endpoints';

export class ProductsApi {
  static getProducts (
    input: string,
  ): Promise<ProductEndpoint.GetCompletions.Response> {
    const query = QueryUtil.stringify({ input });
    return Client(`products/completions?${query}`);
  }

  static getProductRecommendations (
    product: string,
  ): Promise<ProductEndpoint.GetRecommendations.Response> {
    const query = QueryUtil.stringify({ product });
    return Client(`products/recommendations?${query}`);
  }

  static getProductList (
    search: string = '',
  ): Promise<ProductEndpoint.ProductList.Response> {
    if (search) {
      return Client(`product?search=${search}&isAllProductWithPagination=true`);
    }
    return Client(`product?isAllProductWithPagination=true`);
  }
}
