import { Dialog, FullscreenDialog } from '@/components/DesignSystem';
import { ButtonProps } from '@/components/DesignSystem/Button/Button';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

const styles = {
  mobileDialog: {
    px: 0,
  },
} as const;

interface ResponsiveDialogProps {
  acceptButton?: (props: ButtonProps) => ReactNode,
  cancelButton?: (props: ButtonProps) => ReactNode,
  children: ReactNode,
  onClose: () => void,
  px?: number | string,
  secondaryActionButton?: (props: ButtonProps) => ReactNode,
  showCloseButton?: boolean,
  testID: string,
  title?: string,
}

export const ResponsiveDialog = ({
  acceptButton,
  cancelButton,
  children,
  onClose,
  px,
  secondaryActionButton,
  showCloseButton = false,
  title,
  testID,
}: ResponsiveDialogProps) => {
  return (
    <>
      <DesktopOnly>
        <Dialog
          acceptButton={acceptButton}
          cancelButton={cancelButton}
          onClose={onClose}
          open
          px={px}
          secondaryActionButton={secondaryActionButton}
          showCloseButton={showCloseButton}
          testID={testID}
          title={title}
          titleProps={{ category: 'title-large' }}
        >
          {children}
        </Dialog>
      </DesktopOnly>
      <MobileOnly>
        <FullscreenDialog
          actionButton={(
            <Stack direction="row" spacing={2}>
              {secondaryActionButton?.({ testID: `${testID}-secondary-action-button` })}
              {!!acceptButton && acceptButton({ testID: `${testID}-action-button` })}
            </Stack>
          )}
          contentStyle={styles.mobileDialog}
          onClose={onClose}
          open
          testID={testID}
          title={title}
        >
          {children}
        </FullscreenDialog>
      </MobileOnly>
    </>
  );
}
