import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type FullPageLayoutProps = {
  children: ReactNode;
  footer?: JSX.Element,
  header?: JSX.Element,
}
export const FullPageLayout = (
  { children, footer, header }: FullPageLayoutProps,
) => {
  return (
    <Stack display='flex' flexDirection='column' minHeight='100vh'>
      {!!header && (
        <Stack flexShrink='0'>
          {header}
        </Stack>
      )}
      <Stack flex='1 0 auto'>
        {children}
      </Stack>
      {!!footer && (
        <Stack flexShrink='0'>
          {footer}
        </Stack>
      )}
    </Stack>
  );
}
