import { ProductEvents } from '@/pages/Admin/ProductEvents';
import ProductRecommendationView
  from '@/pages/Admin/ProductRecommendation/ProductRecommendationView';
import { ReactNode } from 'react';
import AdminList from '@/pages/Admin/AdminList/AdminList';
import PricingRequestList from '@/pages/Admin/PricingRequestList/PricingRequestList';
import FarmerList from '@/pages/Admin/FarmerList/FarmerList';
import { Navigate, Route, Routes as AppRoutes } from 'react-router-dom';
import { Routes } from '../constants/Routes';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { Text, VSpacer } from '@/components/DesignSystem';
import { UXSandbox } from '@/pages/UXSandbox/UXSandbox';
import { UIShellDemo } from '@/pages/UXSandbox';
import FeatureFlags from '@/pages/Admin/FeatureFlags';
import ManufacturerList from '@/pages/Admin/Manufacturers/ManufacturerList';
import ManufacturerDetails from '@/pages/Admin/Manufacturers/ManufacturerDetails';
import Product from '@/pages/Admin/Product/Product';
import AdminHomePage from '@/pages/Admin/HomePage/AdminHomePage';
import IndividualPricingRequest from '@/pages/Admin/PricingRequestList/IndividualPricingRequest';
import ViewDetails from '@/pages/Admin/PricingRequestList/ViewDetails';
import { AppHeader } from '@/components/shared/AppHeader';
import ProductRecommendation from '@/pages/Admin/ProductRecommendation/ProductRecommendation';
import {
  LocationDetailsOverviewPage,
  RetailerDetailsOverviewPage,
  RetailerListPage,
} from '@/pages/Admin/HierarchyOfRetailers';
import { Promotions } from '@/pages/Admin/Promotions/Promotions';

export const InternalView = () => {
  const createLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader />
        <DesktopOnly>{element}</DesktopOnly>
        <MobileOnly>
          <VSpacer mobileSize='6' />
          <Text>Please switch to desktop to use the admin console</Text>
        </MobileOnly>
      </>
    );
  };

  const createUXLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader />
        {element}
      </>
    );
  };

  return (
    <AppRoutes>
      <Route
        element={createLayout(<AdminList />)}
        path={Routes.ADMIN_LIST_ADMINS}
      />
      <Route
        element={createLayout(<AdminHomePage />)}
        path={Routes.ADMIN_HOME_PAGE}
      />
      <Route
        element={createLayout(<FarmerList />)}
        path={Routes.ADMIN_LIST_FARMERS}
      />
      <Route
        element={createLayout(<FeatureFlags />)}
        path={Routes.FEATURE_FLAGS}
      />
      <Route
        element={createLayout(<ManufacturerList />)}
        path={Routes.ADMIN_LIST_MANUFACTURERS}
      />
      <Route
        element={createLayout(<ManufacturerDetails />)}
        path={`${Routes.ADMIN_LIST_MANUFACTURERS}/:id`}
      />
      <Route
        element={createLayout(<PricingRequestList />)}
        path={Routes.ADMIN_LIST_REQUESTS}
      />
      <Route
        element={createLayout(<ProductEvents />)}
        path={Routes.ADMIN_PRODUCT_EVENTS}
      />
      <Route
        element={createLayout(<ProductRecommendation />)}
        path={Routes.ADMIN_PRODUCT_RECOMMENDATION}
      />
      <Route
        element={createLayout(<ProductRecommendationView />)}
        path={Routes.ADMIN_PRODUCT_RECOMMENDATION_VIEW}
      />
      <Route
        element={createLayout(<RetailerListPage />)}
        path={Routes.ADMIN_HIERARCHY_OF_RETAILERS}
      />
      <Route
        element={createLayout(
            <RetailerDetailsOverviewPage />,
        )}
        path={`${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/:id`}
      />
      <Route
        element={createLayout(
            <LocationDetailsOverviewPage />,
        )}
        path={`${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/:id/location/:locationId`}
      />
      <Route element={createUXLayout(<UXSandbox />)} path={Routes.UX_SANDBOX}>
        <Route element={createUXLayout(<UXSandbox />)} path=':componentName' />
      </Route>
      <Route element={<UIShellDemo showDemo />} path={Routes.UI_SHELL_DEMO} />
      <Route
        element={<Navigate to={Routes.ADMIN_LIST_REQUESTS} />}
        path={'*'}
      />
      <Route
        element={createUXLayout(<IndividualPricingRequest />)}
        path={`${Routes.INDIVIDUAL_PRICING_REQUEST}/:id`}
      />
      <Route
        element={createUXLayout(<ViewDetails />)}
        path={`${Routes.VIEW_DETAIL}/:id`}
      />
      <Route element={createLayout(<Product />)} path={Routes.PRODUCT} />
      <Route
        element={createLayout(<Promotions />)}
        path={Routes.ADMIN_LIST_PROMOTIONS}
      />
    </AppRoutes>
  );
};
