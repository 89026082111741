import { Button, Filter, Text, VSpacer } from "@/components/DesignSystem";
import { QueryKeys } from "@/constants/QueryKeys";
import { UserApi } from "@/utilities/api/UserApi";
import { UserEndpoint } from '@api/endpoints';
import { ApiUser } from "@api/interfaces/ApiUser";
import AddIcon from '@mui/icons-material/Add';
import { Alert, Container, Pagination, Stack } from "@mui/material";
import { UserType } from "@shared/enums";
import React, { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import AdminListItem from "./AdminListItem";
import CreateAdminModal from "./CreateAdminModal";

const AdminList = () => {
  const [results, setResults] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const queryClient = useQueryClient();
  const [selectedFilters, setSelectedFilters] = (
    useState<UserEndpoint.List.Query>({})
  );

  const { data } = useQuery(
    [QueryKeys.GET_ADMINS, selectedFilters],
    () => UserApi.list({ ...selectedFilters, userType: UserType.Internal }),
    {
      onSuccess: (data) => {
        setResults(data.total);
      },
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || 'An error has occurred');
      },
    },
  );
  return (
    <Container maxWidth="lg">
      <Stack>
        <VSpacer size="8" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="h3">
            Admins
          </Text>
          <Button
            onClick={() => setShowCreateModal(true)}
            size="giant"
            startIcon={<AddIcon />}
            testID="admin-list-create-button"
          >
            Create
          </Button>
        </Stack>
        <VSpacer size="9" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="p2">{results} results</Text>
        </Stack>
        <VSpacer size="5" />
        {!!errorMessage && (
          <Alert color="error" icon={false}>{errorMessage}</Alert>
        )}
        <Filter<UserEndpoint.List.Query>
          filters={[
            {
              id: 'daysCreated',
              label: 'Sign-up date',
              options: [
                { id: 1, label: 'Past day' },
                { id: 2, label: 'Past 2 days' },
                { id: 7, label: 'Past week' },
                { id: 30, label: 'Past month' },
              ],
              singleSelect: true,
            },
          ]}
          onChange={setSelectedFilters}
          recordName="Admin"
          testID="admin-list-filter"
          totalResults={data?.total}
        />
        {data?.data.map((user) => (
          <Fragment key={user.id}>
            <AdminListItem admin={user as ApiUser}/>
            <VSpacer size="3" />
          </Fragment>
        ))}
        {data && data.lastPage !== 0 &&
          <Stack alignItems="center">
            <VSpacer size="9" />
            <Pagination
              count={data.lastPage + 1}
              onChange={
                (event, page) => setSelectedFilters({ ...selectedFilters, page: page - 1 })
              }
              page={data.page + 1}
            />
          </Stack>
        }
        <VSpacer size="14" />
      </Stack>
      { showCreateModal && (
        <CreateAdminModal
          onClose={() => {
            setShowCreateModal(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            queryClient.invalidateQueries(QueryKeys.GET_ADMINS);
          }}
        />
      )}
    </Container>
  );
}

export default AdminList;
