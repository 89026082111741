import { Button, SideSheet } from '@/components/DesignSystem';
import { DemoBlock } from '@/pages/UXSandbox/components/DemoBlock';
import { Box } from '@mui/material';
import React, { useState } from 'react';

export const SideSheetDemo = () => {
  const [showSideSheet, setShowSideSheet] = useState(false);
  return (
    <>
      <DemoBlock>
        <Button onClick={() => setShowSideSheet(true)} testID="demo-button">
          Open Side Sheet
        </Button>
      </DemoBlock>
      <SideSheet
        actionButton={(props) => (
          <Button
            {...props}
            onClick={() => setShowSideSheet(false)}
            testID="demo-action-button"
          >
            Action
          </Button>
        )}
        onClose={() => setShowSideSheet(false)}
        onOpen={() => null}
        open={showSideSheet}
        secondaryActionButton={(props) => (
          <Button
            {...props}
            onClick={() => setShowSideSheet(false)}
            testID="demo-secondary-action-button"
          >
            Secondary Action
          </Button>
        )}
        testID="demo-side-sheet"
        title="Title"
      >
        <Box px="24px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </Box>
      </SideSheet>
    </>
  )
}
