import { InlineMessage } from '@/components/DesignSystem/InlineMessage/InlineMessage';
import Check from '@mui/icons-material/Check';
import { DemoBlock } from '../../components/DemoBlock';

export const InlineMessageDemo = () => {
  return (
    <>
      <DemoBlock label="Title only">
        <InlineMessage
          icon={<Check />}
          testID="demo1"
          title="Title"
        />
      </DemoBlock>
      <DemoBlock label="Title and secondary text">
        <InlineMessage
          icon={<Check />}
          secondaryText="Secondary text"
          testID="demo2"
          title="Title"
        />
      </DemoBlock>
      <DemoBlock label="Colored text">
        <InlineMessage
          icon={<Check color="info" />}
          secondaryText="Secondary text"
          secondaryTextColor="info"
          testID="demo3"
          title="Title"
          titleColor="info"
        />
      </DemoBlock>
    </>
  );
}
