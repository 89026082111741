import { AppConfig } from '@/constants/AppConfig';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { Input, InputProps } from './Input';
import { unformatPhoneNumber } from '@shared/utilities/StringUtils';

interface PhoneMaskInputProps {
  name: string,
  onChange: (event: { target: { name: string; value: string } }) => void,
}

const PhoneMaskInput = forwardRef<HTMLElement, PhoneMaskInputProps>(
  function TextMaskCustom (props, ref) {
    const { onChange, ...other } = props;
    const mask = AppConfig.env.test ? '(000) 000-0000[#*****]' : '(000) 000-0000';
    return (
      <IMaskInput
        {...other}
        definitions={{ '*': /\w/ }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputRef={ref as any}
        mask={mask}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        // To prevent the warning from showing up in the console
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        overwrite
      />
    );
  },
);

export const PhoneInput = ({
  label = 'Phone number',
  onChangeText,
  ...rest
}: InputProps) => {

  const handleOnChange = (value: string) => {
    onChangeText?.(unformatPhoneNumber(value));
  }

  return (
    <Input
      inputComponent={PhoneMaskInput}
      inputMode="tel"
      label={label}
      onChangeText={handleOnChange}
      placeholder="(___) ___-____"
      {...rest}
    />
  );
}
