import {
  Button,
  Checkbox,
  Input,
  MenuItem,
  PhoneInput,
  Select,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { useAddRetailerMember, useUpdateMember } from '@/hooks/useHierarchyOfRetailers';
import { SetErrorFunction } from '@/pages/Admin/HierarchyOfRetailers/Locations/Types';
import { RetailerLocationEndpoint } from '@api/endpoints';
import { Alert, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { UserType } from '@shared/enums';
import { EmailRegex, getObjectChanges, PhoneRegex } from '@shared/utilities';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Location } from '../Locations/Types';

type MemberType = {
  businessName?: string,
  email?: string,
  id?: string,
  isTest: boolean,
  role?: string,
  telephone?: string,
};

type AddMemberProps = {
  isAddLocation?: boolean,
  isLocation?: boolean,
  isLoading?: boolean,
  isEdit?: boolean,
  member?: MemberType,
  onClose: () => void,
  onSaveMember?: () => void,
  serverError?: string,
  setAddOrEditLocation?: React.Dispatch<React.SetStateAction<Location>>,
  setErrorMessage?: SetErrorFunction,
  show: boolean;
};

const MemberFormModal = ({
  isAddLocation = false,
  isLocation = false,
  isLoading = false,
  isEdit,
  member = {
    businessName: '',
    email: '',
    isTest: false,
    role: 'retailer-admin',
    telephone: '',
  },
  onClose,
  onSaveMember,
  serverError = '',
  setAddOrEditLocation,
  setErrorMessage,
  show,
}: AddMemberProps) => {

  const [memberDetails, setMemberDetails] = useState(member);
  const [error, setError] = useState('');

  const { id = '', locationId = '' } = useParams<{
    id: string;
    locationId: string;
  }>();

  const { addRetailerMember, isLoading: isAddMemberLoading } =
  useAddRetailerMember(onClose, setError);

  const { updateMember, isLoading: isUpdateMemberLoading } =
    useUpdateMember(member?.id ?? '', isLocation, onClose, setError);

  const onChange = (text: string = 'string', type: string) => {
    if (type === 'telephone' || type === 'email') {
      setError('');
      setErrorMessage && setErrorMessage((prev) => ({
        ...prev,
        serverError: '',
      }))
    }
    setMemberDetails({ ...memberDetails, [type]: text?.trimStart() });
    if (isAddLocation && setAddOrEditLocation) {
      if (text === undefined) {
        text = '';
      }
      setAddOrEditLocation((prevState: RetailerLocationEndpoint.Create.Request) => {
        const salesperson =
          prevState.salesperson ||
            { businessName: '', email: '', telephone: '', userType: UserType.SalesPerson };
        return {
          ...prevState,
          salesperson: {
            ...salesperson,
            [type]: text?.trimStart() || '',
          },
        };
      });
    }
  };

  const areAnyInputsEmpty = Object.entries(memberDetails).some(
    ([key, value]: [key: string, value: (string | boolean)]) => {
      if (key === 'telephone' || key === 'email') {
        return key === 'email'
          ? !EmailRegex.test(value as string)
          : !PhoneRegex.test(value as string);
      }
      if (typeof value === 'string') {
        return value?.trim() === '';
      }
    },
  );

  const isEditDisabled = Object.entries(memberDetails).every(
    ([key, value]: [string, (string | boolean)]) => member[key as keyof MemberType] === value,
  );

  const onSave = () => {
    if (isAddLocation && onSaveMember) {
      return onSaveMember();
    }
    const userType = (
      isLocation ? UserType.SalesPerson : UserType.RetailerAdmin
    ) as UserType.RetailerAdmin | UserType.SalesPerson;

    delete memberDetails.role;

    const updatedMember = {
      ...memberDetails,
      businessName: memberDetails?.businessName?.trim() || '',
      email: memberDetails?.email?.trim() || '',
      isTest: memberDetails?.isTest ?? false,
      locationId: locationId || '',
      retailerId: id,
      telephone: memberDetails?.telephone || '',
      userType: userType,
    };

    const changedMemberData = getObjectChanges(member, memberDetails);

    if (isEdit) {
      updateMember(changedMemberData);
    } else {
      addRetailerMember(updatedMember);
    }
  };

  return (
    <Dialog
      data-testid='add-edit-member'
      onClose={onClose}
      open={show}
      scroll='body'
    >
      <DialogContent sx={{
        width: '560px',
        background: '#191917',
      }}>
      <Stack>
        {!!error && (
        <Alert color='error' icon={false}>{error}</Alert>
        )}
        {
          !!serverError && (
            <Alert color='error' icon={false}>{serverError}</Alert>
          )
        }
        <VSpacer size='4' />
        <Stack spacing={4}>
          <Text category='h4'>{isEdit ? 'Edit' : 'Add'} Member</Text>
          <Input
            label='Member Name'
            onChangeText={(e) => onChange(e, 'businessName')}
            required
            testID='member-name'
            value={memberDetails.businessName}
          />
          <PhoneInput
            label='Phone Number'
            onChangeText={(e) => onChange(e, 'telephone')}
            required
            testID='salesPerson-telephone'
            value={memberDetails.telephone}
          />
          <Input
            label='Email'
            onChangeText={(e) => onChange(e, 'email')}
            required
            testID='member-email'
            value={memberDetails.email}
          />
          <Select
            disabled
            label='Role'
            required
            testID='select-role'
            value={
              isLocation || isAddLocation ? 'sales-person' : memberDetails.role
            }
          >
            <MenuItem testID={UserType.RetailerAdmin} value={'retailer-admin'}>
              Retailer Admin
            </MenuItem>
            <MenuItem testID={UserType.SalesPerson} value={'sales-person'}>
              Salesperson
            </MenuItem>
          </Select>
          <Checkbox
            checked={memberDetails?.isTest ?? false}
            onChangeChecked={
              (checked) => setMemberDetails({ ...memberDetails, isTest: checked })
            }
            testID="is-test-user-checkbox">
            <Text category="body-large">Test User</Text>
          </Checkbox>
        </Stack>
      </Stack>
      </DialogContent>
      <DialogActions sx={{ background: '#191917', pb: '2rem', pt: '2rem' }}>
        <Button disabled={isLoading || isAddMemberLoading || isUpdateMemberLoading}
        onClick={onClose} testID='cancel' variant='text'>
          Cancel
        </Button>
        <Button
          disabled={areAnyInputsEmpty || isEditDisabled || !!error || !!serverError}
          loading={isLoading || isAddMemberLoading || isUpdateMemberLoading}
          onClick={onSave}
          testID='save'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MemberFormModal;
