import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import PricingRequestStatusChip from '@/components/shared/PricingRequestStatusChip';
import { StorefrontChip } from '@/components/shared/StorefrontChip';
import { productRequest } from '@/constants/ProductRequest';
import { ApiStorefront } from '@api/interfaces';
import { Card } from '@mui/material';
import { PricingRequestPaymentStatus, PricingRequestStatus } from '@shared/enums';

interface ProductDetailsCardProps {
  productDetailsCard: {
    orderCompletedOn: Date | null | undefined,
    paymentStatus: PricingRequestPaymentStatus | null,
    publicId: number | undefined,
    status: PricingRequestStatus | undefined,
    storefront?: ApiStorefront | null,
  }
}
const ProductDetailsCard = ({ productDetailsCard }: ProductDetailsCardProps) => {
  return (
    <Card sx={{ padding: '20px' }}>
      <Text
        category='overline'
        testID='card-text'
      >
        {productRequest.productRequest}
      </Text>
      <VSpacer size='5' />
      <Text fontSize='28px' testID='card-text'>
        {`#${productDetailsCard?.publicId}`}
      </Text>
      <VSpacer size='5' />
      <PricingRequestStatusChip
        orderCompletedOn={productDetailsCard?.orderCompletedOn}
        paymentStatus={productDetailsCard?.paymentStatus}
        status={productDetailsCard?.status ?? PricingRequestStatus.Open}
      />
      {productDetailsCard.storefront !== undefined &&
        <>
          <HSpacer size="3" />
          <StorefrontChip subdomain={productDetailsCard.storefront?.subdomain} />
        </>
      }
    </Card>
  );
};

export default ProductDetailsCard;
