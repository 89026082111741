import { Text } from '@/components/DesignSystem';
import Circle from '@mui/icons-material/Circle';
import { Stack } from '@mui/material';

interface TextListProps {
  items: string[],
  testID: string,
  title?: string,
}

export const TextList = ({ items, testID, title }: TextListProps) => {
  return (
    <Stack data-testid={testID} spacing={1.5}>
      {!!title && (
        <Text category="body-medium">{title}</Text>
      )}
      <Stack pl={1} spacing={1}>
        {items.map((item, index) => (
          <Stack alignItems="center" direction="row" key={index} spacing={1}>
            <Circle fontSize="inherit" sx={{ fontSize: 5 }} />
            <Text category="body-medium">{item}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
