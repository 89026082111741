import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { SavedRetailersApi } from '@/utilities/api/SavedRetailersApi';
import { SavedRetailersEndpoint } from "@api/endpoints";
import { SharedConfig } from '@shared/SharedConfig';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useSavedRetailers = () => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: savedRetailers, isLoading } = useQuery(
    [QueryKeys.SAVED_RETAILERS],
    () => SavedRetailersApi.list({ limit: SharedConfig.maxPageLimit, sort: 'name' }),
    {
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'An error has occurred loading saved retailers');
      },
    },
  );

  const { mutate: createSavedRetailer } = useMutation(
    (retailer: SavedRetailersEndpoint.Create.Request) => SavedRetailersApi.create(retailer),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.SAVED_RETAILERS);
        openSnackbar('New Retailer saved')
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'Error creating retailer',
        );
      },
    },
  );

  type UpdateParams = {
    savedRetailerId: string,
    retailer: SavedRetailersEndpoint.Update.Request,
  }
  const { mutate: updateSavedRetailer } = useMutation(
    ({ savedRetailerId, retailer }: UpdateParams) => {
      return SavedRetailersApi.update(savedRetailerId, retailer);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.SAVED_RETAILERS);
        openSnackbar('Retailer updated successfully');
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'Error updating saved retailer',
        );
      },
    },
  );

  const { mutate: deleteSavedRetailer } = useMutation(
    (id: string) => SavedRetailersApi.delete(id),
    {
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'Error deleting retailer')
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.SAVED_RETAILERS);
        openSnackbar('Retailer removed');
      },
    },
  );

  return {
    createSavedRetailer,
    deleteSavedRetailer,
    loadingSavedRetailers: isLoading,
    savedRetailers,
    updateSavedRetailer,
  };
};
