import { Chip, HSpacer, TextElement, VSpacer } from "@/components/DesignSystem";
import { AcceptedMessage } from "@/components/shared/SharedCardComponents/AcceptedMessage";
import { getQuantityWithUom } from "@/components/shared/SharedCardComponents/helpers";
import {
  SubstituteProductMessage,
} from "@/components/shared/SharedCardComponents/SubstituteProductMessage";
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import { ApiOfferProduct } from "@api/interfaces";
import { Box, ButtonBase, Stack, StackProps } from "@mui/material";
import { formatCurrency } from "@shared/utilities";
import { ReactNode, useMemo } from "react";

interface ContainerProps {
  children: ReactNode,
  onClick?: () => void,
}

const Container = ({ children, onClick }: ContainerProps) => {
  const props: StackProps = {
    direction: 'row',
    spacing: 1,
    sx: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  };

  return !onClick ? (
    <Stack {...props}>
      {children}
    </Stack>
  ) : (
    <ButtonBase component={Stack} disableRipple onClick={onClick} {...props}>
      {children}
    </ButtonBase>
  );
}

export interface OfferProductCardProps {
  bottomLeftAccessory?: ReactNode,
  bottomRightAccessory?: ReactNode,
  isAccepted?: boolean,
  lineThrough?: boolean,
  product?: ApiOfferProduct,
  rightAccessory?: ReactNode,
  showAcreLabel?: boolean,
  testID: string,
}

export const OfferProductCard = ({
  bottomLeftAccessory,
  bottomRightAccessory,
  isAccepted = false,
  lineThrough = false,
  product,
  rightAccessory,
  showAcreLabel = false,
  testID,
}: OfferProductCardProps) => {
  const quantityWithUom = useMemo(() => {
    if (!product || !product) {
      return '';
    }

    return getQuantityWithUom({
      initialQuantity: product?.quantity,
      initialUom: product.uom,
      quantity: product?.quantity,
      uom: product?.uom,
    }, showAcreLabel);
  }, [product, showAcreLabel]);

  return (
    <>
      <Stack data-testid={`${testID}-retailer-card`} flex={1}>
        <Container data-testid={`${testID}-retailer-card-clickable-container`}>
          <Stack width="100%">
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <TextElement
                category="16"
                lineThrough={lineThrough}
                subline={quantityWithUom}
                testID={`${testID}-retailer-name-uom`}
                title={product?.substituteProduct
                  ? product?.substituteProduct
                  : product?.pricingRequestProduct?.name
                }
              />
              <HSpacer size="7"/>
              <Stack direction="row">
                {(!!product?.price && !!product.uom && !! product.quantity) && (
                  <TextElement
                    alignment="flex-end"
                    category="16"
                    subline={`Total: ${formatCurrency(product.price)}`}
                    testID={`${testID}-retailer-price-element`}
                    title={`${formatCurrency(product.price / product.quantity)} /${FriendlyUoM[product.uom]}`}
                  />
                )}
                {!!rightAccessory && (
                  <>
                    <HSpacer size="5" />
                    {rightAccessory}
                  </>
                )}
                {rightAccessory}
              </Stack>
            </Stack>
            {!!product?.substituteProduct && (
              <>
                <VSpacer size="4" />
                <SubstituteProductMessage
                  name={product?.pricingRequestProduct?.name ?? ''}
                  substituteProduct={product.substituteProduct}
                  testID={`${testID}-retailer-card`}
                />
              </>
            )}
            {!!product?.pricingRequestProduct?.companionToProductId && (
              <>
                <VSpacer size="4"/>
                <Chip
                  color="warning"
                  label="companion"
                  testID={`product-card-chip-${testID}`}
                />
              </>
            )}
            {isAccepted && (
              <>
                <VSpacer size="4" />
                <AcceptedMessage
                  testID={`${testID}-retailer-card`}
                />
              </>
            )}
          </Stack>
        </Container>
        <VSpacer size="4"/>

        <Box display="flex" justifyContent="space-between">
          {bottomLeftAccessory}
          {!!bottomRightAccessory && (
            <>
              <VSpacer size="4"/>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                {bottomRightAccessory}
              </Stack>
            </>
          )}
        </Box>
        <VSpacer size="2"/>
      </Stack>
    </>
  );
}
