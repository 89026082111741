import { Button } from "@/components/DesignSystem";
import { EmptyState } from "@/components/DesignSystem/EmptyState/EmptyState";
import { Divider, Stack } from '@mui/material';

export const EmptyStateDemo = () => {
  return (
    <Stack
      alignItems="top"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      <EmptyState
        headingMessage="Heading message text here"
        testID="empty-state-demo-1" />

      <EmptyState
        headingMessage="Heading message text here"
        subline="Subline text here"
        testID="empty-state-demo-2" />

      <EmptyState
        actionButton={(
          <Button
            testID="empty-state-demo-button"
            variant="outlined"
          >
            Enabled
          </Button>
        )}
        headingMessage="Heading message text here"
        subline="Subline text here"
        testID="empty-state-demo-3" />

    </Stack>
  );
}
