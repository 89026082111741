import { UserEndpoint } from '@api/endpoints';
import { QueryUtil } from '../Query';
import { Client } from './Client';

export class UserApi {
  static create (
    request: UserEndpoint.Create.Request,
  ): Promise<UserEndpoint.Create.Response> {
    return Client(`users`, { body: request, method: 'POST' });
  }

  static delete (id: string): Promise<void> {
    return Client(`users/${id}`, { method: 'DELETE' });
  }

  static async getAllowedCounties (
    partnerRetailerId: string,
  ): Promise<UserEndpoint.GetAllowedCounties.Response> {
    return Client(`users/${partnerRetailerId}/allowed-counties`);
  }

  static async getLocationsForUser (id: string): Promise<UserEndpoint.ListLocations.Response> {
    return Client(`users/${id}/locations`);
  }

  static async getPreferredRetailer (
    userId: string,
  ): Promise<UserEndpoint.GetPreferredRetailer.Response> {
    return Client(`users/${userId}/preferred-retailer`);
  }

  static list (
    filters: UserEndpoint.List.Query,
  ): Promise<UserEndpoint.List.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`users?${query}`);
  }

  static listEligiblePreferredRetailers ():
  Promise<UserEndpoint.ListEligiblePreferredRetailers.Response> {
    return Client('users/eligible-preferred-retailers');
  }

  static me (): Promise<UserEndpoint.Get.Response> {
    return Client(`users/me`);
  }

  static resendCreatePasswordEmail (id: string): Promise<void> {
    return Client(`users/${id}/resend-signup-email`, {
      method: 'POST',
    });
  }

  static resendVerifyEmail (id: string): Promise<void> {
    return Client(`users/${id}/resend-verify-email`, {
      method: 'POST',
    });
  }

  static switchAccount (
    token: string,
    storefrontId: string | null,
  ): Promise<UserEndpoint.SwitchAccount.Response> {
    const body: UserEndpoint.SwitchAccount.Request = { storefrontId, token };
    return Client(`switch-account`, { body });
  }

  static update (
    userId: string,
    data: UserEndpoint.Update.Request,
  ): Promise<UserEndpoint.Update.Response> {
    return Client(`users/${userId}`, {
      method: 'PATCH',
      body: { id: userId, ...data },
    });
  }

  static async updateConnectAccountId (
    partnerRetailerId: string,
    connectAccountId: string | null,
  ): Promise<void> {
    return Client(`users/${partnerRetailerId}/connect-account-id`, {
      body: { connectAccountId },
      method: 'POST',
    });
  }

  static verifyEmail (token: string): Promise<void> {
    const query = QueryUtil.stringify({ token });
    return Client(`verify-email?${query}`, { method: 'POST' });
  }

  static getVerifiedStatus (
    id: string,
  ): Promise<UserEndpoint.VerifiedStatus.Response> {
    return Client(`users/${id}/verified`);
  }

  static addSalesPerson (
    request: UserEndpoint.Create.Request,
  ): Promise<UserEndpoint.Create.Response> {
    return Client('sales-person', { body: request });
  }

  static getSalesPerson (
    id: string,
    filters?: UserEndpoint.List.Query,
  ): Promise<UserEndpoint.List.Response> {
    const query = QueryUtil.stringify(filters || {});
    return Client(`sales-person/${id}?${query}`);
  }

  static deleteSalesPerson (
    id: string,
    partnerRetailerId: string,
  ): Promise<void> {
    return Client(`sales-person/${id}?parentRetailerId=${partnerRetailerId}`, {
      method: 'DELETE',
    });
  }

  static EditSalesPerson (
    id: string,
    request: UserEndpoint.Update.SalesPersonRequest,
  ): Promise<UserEndpoint.Get.Response> {
    return Client(`sales-person/${id}`, { method: 'PATCH', body: request });
  }

  static RemoveLogo (
    id: string,
    request: UserEndpoint.Update.Request,
  ): Promise<UserEndpoint.Get.Response> {
    return Client(`users/${id}?deleteImage=true`, {
      method: 'PATCH',
      body: request,
    });
  }

  static deleteAllowedCounty (id: string, countyId: string): Promise<void> {
    return Client(`users/${id}/allowed-counties?countyId=${countyId}`, {
      method: 'DELETE',
    });
  }

  static getRetailerDetailsById (
    id: string,
  ): Promise<UserEndpoint.Get.Response> {
    return Client(`users/${id}/retailer`);
  }

  static getFarmerRewards (farmerId?: string): Promise<UserEndpoint.TalonLoyaltyBalance.Response> {
    const query = QueryUtil.stringify({ userId: farmerId });
    return Client(`users/rewards?${query}`);
  }

  static addFarmerRewardsById (
    farmerId: string,
    body: UserEndpoint.TalonLoyaltyBalance.AddLoyaltyPoints,
  ) {
    return Client(`users/${farmerId}/rewards/credit-points`, {
      method: 'POST',
      body,
    });
  }
}
