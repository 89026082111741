import { Button, Dialog } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
export const DialogDemo = () => {
  const [openBasicDialog, setOpenBasicDialog] = useState(false);
  const [openHeroDialog, setOpenHeroDialog] = useState(false);

  return (
    <Stack>
      <DemoBlock label="Basic Dialog">
        <Button
          onClick={() => setOpenBasicDialog(true)}
          testID="test"
        >
          Open Basic Dialog
        </Button>
        <Dialog
          acceptButton={(props) => (
            <Button
              onClick={() => setOpenBasicDialog(false)}
              {...props}
            >
              Save
            </Button>
          )}
          cancelButton={(props) => (
            <Button
              onClick={() => setOpenBasicDialog(false)}
              {...props}
            >
              Cancel
            </Button>
          )}
          onClose={() => setOpenBasicDialog(false)}
          open={openBasicDialog}
          testID="test"
          title="I'm a basic Dialog!"
        >
          Lorem ipsum dolor sit, amet consectetur adipisicing
          elit. In voluptate eum, sed dignissimos molestias,
          expedita maiores, laborum atque iusto sit voluptatem itaque cum tempore!
        </Dialog>
      </DemoBlock>
      <DemoBlock label="Hero Icon Dialog">
        <Button
          onClick={() => setOpenHeroDialog(true)}
          testID="test"
        >
          Open Dialog with Hero Icon
        </Button>
        <Dialog
          acceptButton={(props) => (
            <Button
              onClick={() => setOpenHeroDialog(false)}
              {...props}
            >
              Save
            </Button>
          )}
          cancelButton={(props) => (
            <Button
              onClick={() => setOpenHeroDialog(false)}
              {...props}
            >
              Cancel
            </Button>
          )}
          heroIcon={<WarningAmberIcon color="warning" />}
          onClose={() => setOpenHeroDialog(false)}
          open={openHeroDialog}
          testID="test"
          title="I'm a Dialog with a hero icon!"
        >
          Lorem ipsum dolor sit, amet consectetur adipisicing
          elit. In voluptate eum, sed dignissimos molestias,
          expedita maiores, laborum atque iusto sit voluptatem itaque cum tempore!
        </Dialog>
      </DemoBlock>
    </Stack>
  );
}
