import { Snackbar } from '@/components/DesignSystem';
import { Box } from '@mui/material';
import React, { ReactNode, useState } from 'react';

interface GlobalSnackbarContext {
  action: ReactNode,
  closeSnackbar: () => void,
  dismissible: boolean,
  duration: number,
  isOpen: boolean,
  longerAction?: boolean,
  message: string,
  openSnackbar: (
    message: string,
    duration?: number,
    dismissible?: boolean,
    action?: ReactNode,
    isLongerAction?: boolean,
  ) => void,
}

export const GlobalBannerContext = React.createContext<GlobalSnackbarContext>({
  action: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeSnackbar: () => { },
  dismissible: false,
  duration: 0,
  isOpen: false,
  longerAction: false,
  message: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSnackbar: () => { },
});

export const GlobalSnackbarProvider = (props: {
  children: ReactNode,
}) => {
  const [duration, setDuration] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [longerAction, setIsLongerAction] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [dismissible, setDismissible] = useState<boolean>(false);
  const [action, setAction] = useState<ReactNode>();

  const openSnackbar = (
    newMessage: string,
    snackbarDuration: number = 5000,
    isDismissible: boolean = false,
    snackbarAction: ReactNode = undefined,
    isLongerAction: boolean = false,
  ) => {
    closeSnackbar();
    setTimeout(() => {
      setMessage(newMessage);
      setIsOpen(true);
      setIsLongerAction(isLongerAction);
      setDuration(snackbarDuration);
      setDismissible(isDismissible);
      setAction(snackbarAction);
    }, 100);
  }

  const closeSnackbar = () => {
    setIsOpen(false);
  }

  return (
    <>
      <GlobalBannerContext.Provider
        value={{
          action,
          closeSnackbar,
          dismissible,
          duration,
          isOpen,
          longerAction,
          message,
          openSnackbar,
        }}
      >
        <Snackbar
          action={action}
          dismissible={dismissible}
          duration={duration}
          longerAction={longerAction}
          onClose={closeSnackbar}
          open={isOpen}
          testID="global-snackbar"
        >
          <Box dangerouslySetInnerHTML={{ __html: message }} />
        </Snackbar>
        {props.children}
      </GlobalBannerContext.Provider>
    </>
  );
};

export const useSnackbar = () => {
  const context = React.useContext(GlobalBannerContext);
  if (context === undefined) {
    throw new Error('useSnackbar can only be implemented in a child of GlobalSnackProvider');
  }

  return context;
};
