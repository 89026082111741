import { Filter, Text, VSpacer } from "@/components/DesignSystem";
import { QueryKeys } from "@/constants/QueryKeys";
import { UserApi } from "@/utilities/api/UserApi";
import { UserEndpoint } from '@api/endpoints';
import { ApiUser } from "@api/interfaces";
import { Alert, Container, Pagination, Stack } from "@mui/material";
import { UserType } from "@shared/enums";
import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import FarmerListItem from "./FarmerListItem";

const FarmerList = () => {
  const [results, setResults] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFilters, setSelectedFilters] = (
    useState<UserEndpoint.List.Query>({})
  );

  const { data } = useQuery(
    [QueryKeys.GET_FARMERS, selectedFilters],
    () => UserApi.list({ ...selectedFilters, userType: UserType.Farmer }),
    {
      onSuccess: (data) => {
        setResults(data.total);
      },
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || "An error has occurred");
      },
    },
  );

  const pricingRequestFilterMapping: {
    [key: string]: { maxPricingRequests?: number, minPricingRequests?: number },
  } = {
    'none': { maxPricingRequests: 0 },
    '1-4': { maxPricingRequests: 4, minPricingRequests: 1 },
    '5-9': { maxPricingRequests: 5, minPricingRequests: 9 },
    '10+': { minPricingRequests: 10 },
  }

  return (
    <Container maxWidth="lg">
      <Stack>
        <VSpacer size="8" />
        <Text category="h3">Farmers</Text>
        <VSpacer size="9" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="p2">{results} results</Text>
        </Stack>
        <VSpacer size="5" />
        {!!errorMessage && (
          <Alert color="error" icon={false}>{errorMessage}</Alert>
        )}
        <Filter<UserEndpoint.List.Query & { numPricingRequests: string }>
          filters={[
            {
              id: 'daysCreated',
              label: 'Sign-up date',
              options: [
                { id: 1, label: 'Past day' },
                { id: 2, label: 'Past 2 days' },
                { id: 7, label: 'Past week' },
                { id: 30, label: 'Past month' },
              ],
              singleSelect: true,
            },
            {
              id: 'numPricingRequests',
              label: 'Number of requests',
              options: [
                { id: 'none', label: 'None' },
                { id: '1-4', label: '1-4' },
                { id: '5-9', label: '5-9' },
                { id: '10+', label: '10+' },
              ],
              singleSelect: true,
            },
          ]}
          onChange={(filters) => {
            let maxPricingRequests = undefined;
            let minPricingRequests = undefined;
            if (filters.numPricingRequests) {
              ({
                maxPricingRequests,
                minPricingRequests,
              } = pricingRequestFilterMapping[filters.numPricingRequests]);
            }
            setSelectedFilters({ ...filters, maxPricingRequests, minPricingRequests });
          }}
          recordName="Farmer"
          testID="farmer-list-filter"
          totalResults={data?.total}
        />
        {data?.data.map((user) => (
          <Fragment key={user.id}>
            <FarmerListItem farmer={user as ApiUser}/>
            <VSpacer size="3" />
          </Fragment>
        ))}
        {data && data.lastPage !== 0 &&
          <Stack alignItems="center">
            <VSpacer size="9" />
            <Pagination
              count={data.lastPage + 1}
              onChange={
                (event, page) => setSelectedFilters({ ...selectedFilters, page: page - 1 })
              }
              page={data.page + 1}
            />
          </Stack>
        }
        <VSpacer size="14" />
      </Stack>
    </Container>
  );
}

export default FarmerList;

