import { Button, Chip, Fab, HSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Description from '@mui/icons-material/Description';
import { Box } from '@mui/material';

const styles: SXStyles = {
  fab: {
    bottom: '32px',
    position: 'fixed',
    right: '24px',
    zIndex: 1,
  },
};

interface MyOrderButtonProps {
  onViewOrder: () => void,
  orders: number,
  readonly?: boolean,
}

export const MyOrderButton = ({
  onViewOrder,
  orders,
  readonly = false,
}: MyOrderButtonProps) => {
  const OrderCountBadge = (
    <Chip
      color="primary"
      label={orders.toString()}
      testID="review-and-select-offers-my-order-fab-badge"
    />
  );

  if (readonly) {
    return (
      <Button
        color="primary"
        onClick={onViewOrder}
        startIcon={<Description />}
        testID="price-request-order-details"
        variant="outlined"
      >
        Order details
      </Button>
    );
  }

  return (
    <>
      <DesktopOnly>
        <Box sx={styles.fab}>
          <Fab
            color="primary"
            onClick={onViewOrder}
            testID="review-and-select-offers-my-order-fab"
            variant="extended"
          >
            My Order
            <HSpacer size="3" />
            {OrderCountBadge}
          </Fab>
        </Box>
      </DesktopOnly>
      <MobileOnly>
        <Button onClick={onViewOrder} testID="review-and-select-offers-my-order-button">
          My Order
          <HSpacer size="3" />
          {OrderCountBadge}
        </Button>
      </MobileOnly>
    </>
  );
}
