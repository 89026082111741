import { Card, HSpacer, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { FriendlyPaymentType } from '@/constants/FriendlyPaymentType';
import { ApiPricingRequestPublic, ApiUserPublic } from '@api/interfaces';
import { formatDate } from '@shared/utilities/DateUtils';
import { Stack } from "@mui/material";
import { FulfillmentMethod } from '@shared/enums';
import { useState } from 'react';
import ContactInformationModal from '../Modals/ContactInformationModal';
import DeliveryAddress from './DeliveryAddress';

interface OfferHeaderProps {
  pricingRequest: ApiPricingRequestPublic,
  user?: ApiUserPublic,
}

const OfferHeader = ({
  pricingRequest,
  user,
}: OfferHeaderProps) => {
  const [showContactInformationModal, setShowContactInformationModal] = useState(false);

  const ViewContactInformation = () => (
    <Stack alignItems="center" direction="row">
      <Text category="p1">
        Contact Information:
      </Text>
      <HSpacer size="2" />
      <TextLink
        category="p1"
        onClick={() => {
          setShowContactInformationModal(true);
        }}
        testID="offer-header-modal-info"
      >
        View
      </TextLink>
    </Stack>
  );

  return (
    <>
      <MobileOnly>
        <Card testID="offer-header-mobile-view">
          <Stack>
            {
              !!user && (
                <>
                  <Stack alignItems="center" direction="row">
                    <Text category="p1">
                      Farmer:
                    </Text>
                    <HSpacer size="2" />
                    <Text category="p1">{`${user.firstName} ${user?.lastName}`}</Text>
                  </Stack>
                  <VSpacer size="3" />
                  <Stack alignItems="center" direction="row">
                    <Text category="p1">
                      Farm name:
                    </Text>
                    <HSpacer size="2" />
                    <Text category="p1">{user.businessName}</Text>
                  </Stack>
                  <VSpacer size="3" />
                </>
              )
            }
            <Stack alignItems="center" direction="row">
              <Text category="p1">
                Financing:
              </Text>
              <HSpacer size="2" />
              <Text category="p1">{FriendlyPaymentType[pricingRequest.paymentType!]}</Text>
            </Stack>
            {
              !!user && (
                <>
                  <VSpacer size="3" />
                  <ViewContactInformation />
                </>
              )
            }
            {
              pricingRequest.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
                <>
                  <VSpacer size="3" />
                  <DeliveryAddress pricingRequest={pricingRequest} />
                </>
              )
            }
          </Stack>
        </Card>
      </MobileOnly>
      <DesktopOnly>
        <Stack>
          {
            !!user && (
              <>
                <Stack alignItems="flex-end" direction="row">
                  <Text category="p1">
                    Farmer:
                  </Text>
                  <HSpacer size="2" />
                  <Text category="p1">
                    {`${user.firstName} ${user.lastName}`}
                  </Text>
                  <HSpacer size="3" />
                  <Text category="p1">|</Text>
                  <HSpacer size="3" />
                  <Text category="p1">
                    Farm name:
                  </Text>
                  <HSpacer size="2" />
                  <Text category="p1">
                    {user.businessName}
                  </Text>
                </Stack>
                <VSpacer size="2" />
              </>
            )
          }
          <Stack alignItems="flex-end" direction="row">
            <Text category="p1">
              Expiration date:
            </Text>
            <HSpacer size="2" />
            <Text category="p1">
              {formatDate(pricingRequest.expiration as Date)}
            </Text>
            <HSpacer size="2" />
            <Text category="p1">|</Text>
            <HSpacer size="2" />
            <Text category="p1">
              Financing:
            </Text>
            <HSpacer size="2" />
            <Text category="p1">
              {FriendlyPaymentType[pricingRequest.paymentType!]}
            </Text>
            <HSpacer size="2" />
            <Text category="p1">|</Text>
            <HSpacer size="2" />
            <Text category="p1">
              Fulfillment method:
            </Text>
            <HSpacer size="2" />
            <Text category="p1">
              {FriendlyFulfillmentMethod[pricingRequest.fulfillmentMethod]}
            </Text>
          </Stack>
          {
            !!user && (
              <>
                <VSpacer size="2" />
                <ViewContactInformation />
              </>
            )
          }
          {
            pricingRequest.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
              <>
                <VSpacer size="3" />
                <DeliveryAddress pricingRequest={pricingRequest} />
              </>
            )
          }
        </Stack>
      </DesktopOnly>
      {
        !!user && (
          <ContactInformationModal
            contactInfo={{
              ...user,
              county: user?.countyData?.county,
            }}
            isVisible={showContactInformationModal}
            onClose={() => setShowContactInformationModal(false)}
          />
        )
      }
    </>
  );
}

export default OfferHeader;


