import {
  Button,
  Dialog,
  FullscreenDialog,
  VSpacer,
} from '@/components/DesignSystem';
import { useCallback, useEffect, useState } from 'react';
import { validateRetailer } from '@/utilities/validators';
import {
  defaultSavedRetailer,
  FormSavedRetailer,
  SavedRetailerForm,
} from './SavedRetailerForm';
import { ApiSavedRetailer } from '@api/interfaces';
import { useQuery } from 'react-query';
import { SavedRetailersApi } from '@/utilities/api/SavedRetailersApi';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { QueryKeys } from '@/constants/QueryKeys';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSavedRetailers } from '@/hooks/useSavedRetailers';

export interface SavedRetailerDialogProps {
  savedRetailerId?: ApiSavedRetailer['id'] | null,
  onClose: () => void,
  open: boolean,
  testID: string,
}

export const CreateEditSavedRetailerDialog = ({
  onClose,
  open,
  savedRetailerId,
  testID,
}: SavedRetailerDialogProps) => {
  const { isMobile } = useMediaQuery();
  const { openSnackbar } = useSnackbar();
  const [initialState, setInitialState] = useState<FormSavedRetailer>(defaultSavedRetailer)
  const [savedRetailer, setSavedRetailer] = useState<FormSavedRetailer>(defaultSavedRetailer);
  const { createSavedRetailer, updateSavedRetailer } = useSavedRetailers();

  useEffect(() => {
    if (!savedRetailerId) {
      setSavedRetailer(defaultSavedRetailer);
      setInitialState(defaultSavedRetailer);
    }
  }, [savedRetailerId]);

  const isValid = useCallback(() => {
    const isDirty = (
      initialState.name !== savedRetailer.name
      || initialState.email !== savedRetailer.email
      || initialState.telephone !== savedRetailer.telephone
    );
    return isDirty && validateRetailer(savedRetailer);
  }, [initialState, savedRetailer]);

  useQuery(
    [QueryKeys.GET_SAVED_RETAILER, savedRetailerId],
    () => SavedRetailersApi.get(savedRetailerId!),
    {
      enabled: !!savedRetailerId,
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'Error loading retailer');
      },
      onSuccess: (retailer) => {
        const formData = {
          name: retailer.name,
          email: retailer.email || '',
          telephone: retailer.telephone || '',
        }
        setSavedRetailer(formData);
        setInitialState(formData);
      },
    },
  );

  const handleSave = () => {
    const retailer = {
      name: savedRetailer.name,
      email: savedRetailer.email || null,
      telephone: savedRetailer.telephone || null,
    }
    if (savedRetailerId) {
      updateSavedRetailer({ savedRetailerId, retailer });
    } else {
      createSavedRetailer(retailer);
    }
    setSavedRetailer(defaultSavedRetailer);
    setInitialState(defaultSavedRetailer);
    onClose();
  }

  const title = `${savedRetailerId ? 'Edit' : 'Add'} Retailer`;

  if (isMobile) {
    // Fullscreen dialog affects the layout of other items on the page
    // when rendered and closed. Working around that by not rendering
    // in the closed state
    if (!open) {
      return null;
    }
    return (
      <FullscreenDialog
        actionButton={(
          <Button
            disabled={!isValid()}
            onClick={handleSave}
            testID={`${testID}-accept`}
            variant="text"
          >
            Save
          </Button>
        )}
        onClose={onClose}
        open
        testID={testID}
        title={title}
      >
        <VSpacer size="7" />
        <SavedRetailerForm
          onChange={setSavedRetailer}
          retailer={savedRetailer}
        />
      </FullscreenDialog>
    );
  }

  return (
    <>
      <Dialog
        acceptButton={() => (
          <Button
            disabled={!isValid()}
            onClick={handleSave}
            testID={`${testID}-accept`}
            variant="text"
          >
            Save
          </Button>
        )}
        cancelButton={() => (
          <Button
            onClick={onClose}
            testID={`${testID}-cancel`}
            variant="text"
          >
            Cancel
          </Button>
        )}
        onClose={onClose}
        open={open}
        testID={testID}
        title={title}
      >
        <VSpacer size="5" />
        <SavedRetailerForm
          onChange={setSavedRetailer}
          retailer={savedRetailer}
        />
        <VSpacer size="5" />
      </Dialog>
    </>
  )
};
