import {
  Select,
} from '@/components/DesignSystem';
import { Divider, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';

export const SelectDemo = () => {

  const [selectValue, setSelectValue] = useState<string>();

  return (
    <>
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Select
          label="Basic"
          onChangeValue={setSelectValue}
          testID="select-demo-basic"
          value={selectValue}
        >
          <MenuItem value="1">One</MenuItem>
          <MenuItem value="2">Two</MenuItem>
          <MenuItem value="3">Three</MenuItem>
          <MenuItem value="4">Four</MenuItem>
        </Select>
        <Select
          label="Required"
          onChangeValue={setSelectValue}
          required
          testID="select-demo-required"
          value={selectValue}>
          <MenuItem value="1">One</MenuItem>
          <MenuItem value="2">Two</MenuItem>
          <MenuItem value="3">Three</MenuItem>
          <MenuItem value="4">Four</MenuItem>
        </Select>
        <Select
          disabled
          label="Required"
          onChangeValue={setSelectValue}
          testID="select-demo-required-disabled"
          value={selectValue}>
          <MenuItem value="1">One</MenuItem>
          <MenuItem value="2">Two</MenuItem>
          <MenuItem value="3">Three</MenuItem>
          <MenuItem value="4">Four</MenuItem>
        </Select>
        <Select
          label="Clearable"
          onChangeValue={setSelectValue}
          onClear={() => setSelectValue('')}
          testID="select-demo-clearable"
          value={selectValue}
        >
          <MenuItem value="1">One</MenuItem>
          <MenuItem value="2">Two</MenuItem>
          <MenuItem value="3">Three</MenuItem>
          <MenuItem value="4">Four</MenuItem>
        </Select>
      </Stack>
      <Select
        label="Fixed width"
        onChangeValue={setSelectValue}
        testID="select-demo-fixed-width"
        value={selectValue}
        width={300}>
        <MenuItem value="1">One</MenuItem>
        <MenuItem value="2">Two</MenuItem>
        <MenuItem value="3">Three</MenuItem>
        <MenuItem value="4">Four</MenuItem>
      </Select>
    </>
  );
}
