import {
  Card,
  Checkbox,
  Chip,
  DataPoint,
  HSpacer,
  Input,
  NumericInput,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { Alert, Grid, Stack } from "@mui/material";
import { formatCurrency } from '@shared/utilities';
import { useState } from 'react';
import { LastLookData, Product } from '../interfaces';

type ProductCardProps = {
  disabled?: boolean,
  lastLookData?: LastLookData,
  onChange: (product: Product) => void,
  product: Product,
  readonly?: boolean,
}

const ProductCard = ({
  disabled = false,
  lastLookData,
  onChange,
  product,
  readonly = false,
}: ProductCardProps) => {
  const [
    isSubstituteProductAvailable,
    setIsSubstituteProductAvailable,
  ] = useState(!!product.substituteProduct);

  const handleUnitCostChange = (unitCost?: number) => {
    onChange({ ...product, price: (unitCost ?? 0) * (product.quantity ?? 0) });
  };

  const LastLookPrices = () => {
    if (!lastLookData?.lowestPrice) {
      return null;
    }

    const cases: [string, number | undefined][] = [];

    if (lastLookData?.lowestPrice) {
      Object.values(lastLookData.lowestPrice).forEach((products) => {
        const lowest = products.find((price) => (
          price.pricingRequestProductId === product.pricingRequestProductId
        ));

        cases.push(['Lowest Retail Price', lowest?.price]);
      })
    }

    if (lastLookData.sameOrganization?.prices) {
      Object.values(lastLookData.sameOrganization.prices).forEach((products) => {
        const lowest = products.find((price) => (
          price.pricingRequestProductId === product.pricingRequestProductId
        ));

        cases.push([
          `Lowest Retail Price for ${lastLookData?.sameOrganization?.organizationName}`,
          lowest?.price,
        ]);
      })
    }

    return <Grid container direction="row" justifyContent="space-between">
      {cases.map(([label, price]) => {
        if (price === undefined || !product.quantity || !product.uom) { return null; }

        return <Grid item key={label} md={6} xs={12}>
          <DataPoint
            label={label}
            size="large"
            spacing="flushed"
            stacked
            testID={`${product.pricingRequestProductId}-lowest-price-data-point`}
          >
            {formatCurrency(price / product.quantity)}/{FriendlyUoM[product.uom]}
            &nbsp;({formatCurrency(price)})
          </DataPoint>
        </Grid>
      })
    }
    </Grid>
  };

  return (
    <Card testID="product-card">
      <Stack>
        <Text category="h6">{product.name}</Text>
        <VSpacer size="3"/>
        <Stack direction="row">
          <Text
            category="body-large"
            flexShrink={0}
            testID="product-offer-package-label"
          >
            Package size:
          </Text>
          <HSpacer size="7"/>
          <Text
            breakWord
            category="body-large"
            testID="product-offer-package-value"
          >
            {!!product.package && `${product.package}`}
          </Text>
        </Stack>
        {!readonly && product.allowSubstitutions && (
          <>
            <VSpacer mobileSize="5" size="4"/>
            <Stack direction="row" justifyContent="flex-end">
              <Alert color="info" icon={false}>
                <Text category="label" color="info">
                  GENERIC PRODUCT OK
                </Text>
              </Alert>
            </Stack>
            <VSpacer mobileSize="6" size="3"/>
          </>
        )}
        {
          product.isAccepted ? (
            <>
              <VSpacer mobileSize="5" size="4"/>
              <Stack direction="row" justifyContent="flex-end">
                <Chip
                  color="success"
                  label="Accepted"
                  testID="product-card-accepted"
                />
              </Stack>
              <VSpacer mobileSize="6" size="3"/>
            </>
          ) : (
            <VSpacer size="5"/>
          )
        }
        <Stack alignItems="baseline" direction="row">
          <NumericInput
            disabled={disabled}
            label="Unit Price"
            onChangeNumber={(price) => handleUnitCostChange(price)}
            testID={`${product.pricingRequestProductId}-unit-price-input`}
            value={(product.price && product.quantity)
              ? (product.price / product.quantity)
              : undefined
            }
            width={112}
          />

          <DesktopOnly> {/* Not enough horizontal space on a mobile screen */}
            <HSpacer size="3"/>
            per
            <HSpacer size="3"/>
            {product.uom && FriendlyUoM[product.uom]}
          </DesktopOnly>

          <HSpacer size="3"/>
          x
          <HSpacer size="3"/>
          {(product.quantity && product.uom) && (
            `${formatCurrency(product.quantity, '')} ${FriendlyUoM[product.uom]}`
          )}
          <HSpacer size="3"/>
          =
          <HSpacer size="3"/>
          <NumericInput
            disabled={disabled}
            label="Total Price"
            onChangeNumber={(price) => {
              handleUnitCostChange(price && (price / (product.quantity ?? 0)));
            }}
            testID={`${product.pricingRequestProductId}-total-price-input`}
            value={product.price}
            width={145}
          />
        </Stack>
        <VSpacer size="4"/>
        <Checkbox
          checked={isSubstituteProductAvailable}
          disabled={disabled}
          onChangeChecked={(isChecked) => {
            setIsSubstituteProductAvailable(isChecked);
            if (!isChecked) {
              onChange({ ...product, substituteProduct: "" });
            }
          }}
          testID="product-card-checkbox"
        >
          <Text category="p2">Substitute product available</Text>
        </Checkbox>
        <DesktopOnly>
          <>
            <VSpacer size="4"/>
            {
              isSubstituteProductAvailable && (
                <Input
                  disabled={disabled}
                  label="Substitute product"
                  onChangeText={(substituteProduct) => onChange({ ...product, substituteProduct })}
                  sx={{ width: 316 }}
                  testID={`${product.pricingRequestProductId}-available-desktop-input`}
                  value={product.substituteProduct}
                />
              )
            }
          </>
        </DesktopOnly>
        <VSpacer size="6"/>
        <MobileOnly>
          {
            isSubstituteProductAvailable && (
              <Input
                disabled={disabled}
                label="Substitute product"
                onChangeText={(substituteProduct) => onChange({ ...product, substituteProduct })}
                testID={`${product.pricingRequestProductId}-available-mobile-input`}
                value={product.substituteProduct}
                width={316}
              />
            )
          }
        </MobileOnly>
        {LastLookPrices()}
      </Stack>
    </Card>
  );
}

export default ProductCard;
