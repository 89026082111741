import {
  Button,
  HSpacer,
  Input,
  Logo,
  Modal,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from "@/constants/Routes";
import { useAuthentication } from "@/contexts/dataSync/AuthenticationContext";
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useStorefront } from '@/hooks/useStorefront';
import OtpForm from '@/pages/Auth/OtpForm';
import { CreateFarmerAccountEventType, useLogEvent } from '@/utilities/Analytics';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Alert, AlertTitle, Divider, Stack } from "@mui/material";
import { ApiErrors, GrowersContactInfo } from '@shared/enums';
import { validateEmail, validatePhoneNumber } from '@shared/utilities';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from "react-router-dom";

const FarmerLoginForm = () => {
  const { isMobile } = useMediaQuery();
  const { state } = useLocation();
  const [username, setUsername] = useState(state?.username ?? "");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = (
    useState<{ title?: string, message: string } | undefined>(state?.errorMessage)
  );
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);

  const navigate = useNavigate();
  const { authenticate } = useAuthentication();
  const logEvent = useLogEvent();
  const { storefront } = useStorefront();
  const subdomain = getSubdomain();

  const { mutate: authenticateUser, isLoading } = useMutation(
    () => authenticate(username, password, storefront?.id),
    {
      onError: (err: { message: string }) => {
        if (err.message === ApiErrors.inactiveAccount) {
          setErrorMessage({ title: 'Inactive account',  message: err.message });
        } else {
          setErrorMessage({ message: "Incorrect username or password" });
        }
      },
      onSuccess: () => {
        navigate(Routes.HOMEPAGE, { replace: true });
      },
      retry: false,
    },
  );

  const handleInputBlur = () => {
    if (validatePhoneNumber(username, AppConfig.env.test)) {
      setErrorMessage(undefined);
      setShowPasswordField(false);
    } else if (validateEmail(username)) {
      setErrorMessage(undefined);
      setShowPasswordField(true);
    } else if (username) {
      setErrorMessage(
        { message: 'Invalid Email or Phone Number' },
      );
    } else {
      setShowPasswordField(false);
    }
  }

  const logInOrContinue = async () => {
    if (validatePhoneNumber(username, AppConfig.env.test)) {
      setErrorMessage(undefined);
      setShowOtpForm(true);
    } else if (validateEmail(username)) {
      setErrorMessage(undefined);
      if (password) {
        await authenticateUser();
      }
    } else {
      setErrorMessage(
        { message: 'Invalid Email or Phone Number' },
      );
    }
  }

  const Error = () => (
    <>
      <VSpacer size="9" />
      <Alert
        color="error"
        icon={<ErrorOutline fontSize="small" />}
        sx={{
          display: 'flex',
          padding: '14px 16px',
          textAlign: 'left',
        }}
      >
        {errorMessage?.title && <AlertTitle>{errorMessage?.title}</AlertTitle>}
        {errorMessage?.message}
      </Alert>
      <VSpacer size="9" />
    </>
  );

  return (
    <Stack
      direction="row"
      justifyContent="center"
      paddingTop={isMobile ? '0px' : '100px'}
    >
      <Stack display="flex" flex={1} maxWidth="512px">
        <Stack
          borderRadius="12px"
          justifyContent="center"
          paddingTop={isMobile ? '16px' : '48px'}
          paddingX={isMobile ? '16px' : '48px'}
        >
          <Stack direction='row' justifyContent='center'>
            <Logo subdomain={subdomain} />
          </Stack>
          <VSpacer size="9" />
          <Text category="h3" textAlign="center">Login to your account</Text>
          <VSpacer size="4" />
          <Text category="p1" textAlign="center">
            Please enter your phone number or email address to continue.
          </Text>
          <VSpacer size="9" />
          <Input
            error={!!errorMessage}
            label="Email or Phone Number"
            onBlur={handleInputBlur}
            onChangeText={setUsername}
            onEnterPressed={logInOrContinue}
            testID="farmer-login-form-username"
            value={username}
          />
          {showPasswordField &&
            <>
              <VSpacer size="8" />
              <Stack direction="row">
                <Input
                  error={!!errorMessage}
                  label="Password"
                  onChangeText={setPassword}
                  onEnterPressed={logInOrContinue}
                  testID="farmer-login-form-password"
                  type={showPassword ? undefined : 'password'}
                  value={password}
                />
                <HSpacer size="2" />
                <Stack>
                  <Button
                    onClick={() => setShowPassword(!showPassword)}
                    size="small"
                    square
                    sx={{
                      borderRadius: 1,
                      flex: 1,
                      width: 56,
                    }}
                    testID="show-password-button"
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </Stack>
              </Stack>
            </>
          }
          <VSpacer size="5" />
          <Text category="p2">
            By continuing you agree to the GROWERS&nbsp;
            <TextLink
              href={GrowersContactInfo.privacyPolicyLink}
              target="_blank"
              testID="privacy-policy"
            >
              Privacy policy
            </TextLink>
            &nbsp;and&nbsp;
            <TextLink
              href={GrowersContactInfo.termsOfUseLink}
              target="_blank"
              testID="tos-link"
            >
              Terms of service
            </TextLink>
          </Text>
          {showPasswordField &&
            <>
              <VSpacer size="5" />
              <TextLink
                category="p2"
                maxWidth="115px"
                onClick={() => navigate(Routes.FORGOT_PASSWORD)}
                testID="farmer-login-form-forgot-password"
              >
                Forgot password?
              </TextLink>
            </>
          }
          <Stack direction="row" justifyContent="center">
            {showPasswordField ? (
              <Button
                disabled={!validateEmail(username) || !password}
                loading={isLoading}
                onClick={logInOrContinue}
                size="giant"
                testID="farmer-login-form-button"
              >
                Login
              </Button>
            ) : (
              <Button
                disabled={
                  !validateEmail(username) && !validatePhoneNumber(username, AppConfig.env.test)
                }
                loading={isLoading}
                onClick={logInOrContinue}
                size="giant"
                testID="farmer-login-form-button"
              >
                Continue
              </Button>
            )}
          </Stack>
          {!!errorMessage && <Error />}
        </Stack>
        <VSpacer mobileSize="8" size="7" />
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Divider sx={{ height: '1px', width: '83%' }}/>
        </Stack>
        <VSpacer mobileSize="9" size="7" />
        <Stack alignItems="center">
          <Text testID="existing-user-text">
            Don't have an account?
          </Text>
          <TextLink
            category="p2"
            onClick={() => {
              logEvent(CreateFarmerAccountEventType.ClickCreateYourAccountHere);
              navigate(Routes.CREATE_ACCOUNT);
            }}
            testID="sign-up-link"
          >
            Sign up
          </TextLink>
        </Stack>
      </Stack>
      {showOtpForm && username &&
        <Modal
          onClose={() => setShowOtpForm(false)}
          open
          testID="create-farmer-otp-form-modal"
        >
          <OtpForm
            onCancel={() => setShowOtpForm(false)}
            telephone={username}
          />
        </Modal>
      }
    </Stack>
  );
}

export default FarmerLoginForm;
