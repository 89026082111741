import {
  Button,
  DatePickerDropdown,
  DatePickerModal,
  DateRangePickerModal,
  DatePickerInput,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const DatePickerDemo = () => {

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const tomorrow = new Date(+new Date().setHours(0, 0, 0, 0) + 86400000 * 2);

  const [date, setDate] = useState<Date | undefined>(today);
  const [startDate, setStartDate] = useState<Date | undefined>(today);
  const [endDate, setEndDate] = useState<Date | undefined>(tomorrow);
  const [showModal, setShowModal] = useState(false);
  const [showRangeModal, setShowRangeModal] = useState(false);
  const [showCalendarMode, setShowCalendarMode] = useState(false);

  const formatDate = (date: Date | undefined) => {
    if (!date) {
      return "---";
    }
    return DateTime.fromJSDate(date).toFormat('MM/dd/yyyy');
  }

  const handleShowModal = (isCalendarMode: boolean) => {
    setShowCalendarMode(isCalendarMode);
    setShowModal(true);
  }

  const handleShowRangeModal = (isCalendarMode: boolean) => {
    setShowCalendarMode(isCalendarMode);
    setShowRangeModal(true);
  }

  return (
    <>
      {showModal && (
        <DatePickerModal
          onChange={setDate}
          onClose={() => setShowModal(false)}
          open
          showCalendarMode={showCalendarMode}
          testID="date-picker"
          value={date}
        />
      )}
      {showRangeModal && (
        <DateRangePickerModal
          endDate={endDate}
          onChange={(start?: Date, end?: Date) => {
            setStartDate(start);
            setEndDate(end);
          }}
          onClose={() => setShowRangeModal(false)}
          open
          showCalendarMode={showCalendarMode}
          startDate={startDate}
          testID="date-range-picker"
          title="Depart - Return dates"
        />
      )}
      <DemoBlock label="Date picker dropdown">
        <Stack>
          <DatePickerDropdown
            label="Date"
            onChange={setDate}
            testID="date-picker-dropdown"
            value={date}
            width={312}
          />
          <VSpacer size="5" />
          <Text>Selected date: {formatDate(date)}</Text>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Date picker modal">
        <Stack>
          <Stack direction={getResponsiveValue("column", "row")} spacing={1}>
            <Button
              onClick={() => handleShowModal(true)}
              testID="date-picker-modal-demo-1"
            >
              Show calendar mode by default
            </Button>
            <Button
              onClick={() => handleShowModal(false)}
              testID="date-picker-modal-demo-2"
            >
              Show input mode by default
            </Button>
          </Stack>
          <VSpacer size="5" />
          <Text>Selected date: {formatDate(date)}</Text>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Date range picker modal">
        <Stack>
          <Stack direction={getResponsiveValue("column", "row")} spacing={1}>
            <Button
              onClick={() => handleShowRangeModal(true)}
              testID="date-range-picker-modal-demo-1"
            >
              Show calendar mode by default
            </Button>
            <Button
              onClick={() => handleShowRangeModal(false)}
              testID="date-range-picker-modal-demo-2"
            >
              Show input mode by default
            </Button>
          </Stack>
          <VSpacer size="3" />
          <Text>Selected dates: {formatDate(startDate)} - {formatDate(endDate)}</Text>
        </Stack>
      </DemoBlock>
      <DemoBlock label="Standalone date input (used inside the modals)">
        <Stack>
          <DatePickerInput
            label="Date"
            onChangeDate={setDate}
            testID="standalone-input"
            value={date}
            width={312}
          />
          <VSpacer size="3" />
          <Text>Selected date: {formatDate(date)}</Text>
        </Stack>
      </DemoBlock>
    </>
  );
}
