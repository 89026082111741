import { ButtonBase, Stack } from '@mui/material';
import { StackProps } from '@mui/material/Stack/Stack';
import { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode,
  onClick?: () => void,
}

export const Container = ({ children, onClick }: ContainerProps) => {
  const props: StackProps = {
    direction: 'row',
    spacing: 1,
    sx: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  };

  return !onClick ? (
    <Stack {...props}>
      {children}
    </Stack>
  ) : (
    <ButtonBase component={Stack} disableRipple onClick={onClick} {...props}>
      {children}
    </ButtonBase>
  );
}
