import { Button, Input, Modal } from '@/components/DesignSystem';
import { Stack } from "@mui/material";
import { useState } from 'react';

interface AddNoteToOfferModalProps {
  isVisible: boolean,
  onApplyNote: (note: string) => void,
  onClose: () => void,
  note?: string | null,
}

const AddNoteToOfferModal = ({
  isVisible,
  onApplyNote,
  onClose,
  note,
}: AddNoteToOfferModalProps) => {

  const [updatedNote, setUpdatedNote] = useState(note || "");
  return (
    <Modal
      acceptButton={(props) => (
        <Button onClick={() => onApplyNote(updatedNote)} {...props}>
          Add Note
        </Button>
      )}
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Cancel
        </Button>
      )}
      onClose={onClose}
      open={isVisible}
      testID="offer-add-note-modal"
      title="Note"
      titleSpacerSize="11">
      <Stack>
        <Input
          maxLength={2000}
          maxRows={6}
          multiline
          onChangeText={setUpdatedNote}
          testID="add-note-offer-modal-input"
          value={updatedNote}
        />
      </Stack>
    </Modal>
  );
}

export default AddNoteToOfferModal;
