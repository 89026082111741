import {
  BottomBar,
  Fab,
  HSpacer,
  IconButton,
  Text,
  TextAreaInput,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { IconBadge } from '@/components/DesignSystem/Badge/IconBadge';
import {
  CartProduct,
  CartProductCard,
} from '@/components/DesignSystem/ProductCard/CartProductCard';
import {
  FulfillmentMethodData,
  FulfillmentMethodForm,
} from '@/components/shared/CreatePriceRequest/FulfillmentMethodForm';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ToastMessages } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { VerifyYourEmail } from '@/pages/Auth/VerifyYourEmailModal';
import { ProductModal } from '@/pages/Shop/ProductModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { CreatePriceRequestEventType, useLogEvent } from '@/utilities/Analytics';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { UserApi } from '@/utilities/api/UserApi';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { PricingRequestEndpoint } from '@api/endpoints';
import { ApiPricingRequestCart } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { Box, Divider, Drawer, Stack, styled } from '@mui/material';
import {
  FulfillmentMethod,
  GrowersContactInfo,
  PricingRequestStatus,
  ProductUom,
} from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const defaultFulfillmentData: FulfillmentMethodData = {
  deliveryAddress1: null,
  deliveryAddress2: null,
  deliveryCity: null,
  deliveryPostalCode: null,
  deliveryState: null,
  fulfillmentMethod: FulfillmentMethod.PICKUP,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    boxSizing: 'border-box',
    height: '100%',
    maxWidth: '375px',
    padding: '0px 16px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    },
  },
  '& .MuiDrawer-slider': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const ShoppingCartHeader = styled(Box)(() => ({
  alignItems: 'center',
  padding: '24px 0px 0px 0px',
  height: '76px !important',
}));

export const ShoppingCartDrawer = () => {
  const {
    clearShoppingCart,
    deleteShoppingCart,
    isRequestView,
    setIsRequestView,
    setShowCart,
    shoppingCart,
    showCart,
  } = useShoppingCart();
  const { user } = useAuthentication();
  const { openSnackbar } = useSnackbar();
  const logEvent = useLogEvent();
  const queryClient = useQueryClient();
  const [cartProductIdToEdit, setCartProductIdToEdit] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showVerifyEmailDialog, setShowVerifyEmailDialog] = useState(false);
  const [fulfillmentMethodData, setFulfillmentMethodData]
    = useState<FulfillmentMethodData>(defaultFulfillmentData);
  const [note, setNote] = useState('');
  const navigate = useNavigate();

  const fetchDeliveryAddress = async () => {
    const address = await PricingRequestsApi.getMostRecentDeliveryAddress();
    if (address) {
      setFulfillmentMethodData({ ...fulfillmentMethodData, ...address });
    }
  }

  useEffect(() => {
    if (showCart && user) {
      void fetchDeliveryAddress();
    }
  }, [showCart, user]);

  useEffect(() => {
    if (!user && isRequestView) {
      setIsRequestView(false);
    }
  }, [user]);

  const productIdToEdit = shoppingCart.find(({ id }) => (
    id === cartProductIdToEdit
  ))?.productId;

  const isRequestValid = !!shoppingCart?.length;

  const convertCartProductsToRequestProducts = (
    products: ApiPricingRequestCart[],
  ): PricingRequestEndpoint.Save.Product[] => {
    const convertedProducts: PricingRequestEndpoint.Save.Product[] = [];
    products
      .filter((product) => (
        !product.isCompanion
        || !products.find(({ name }) => name === product.parentProductName)
      ))
      .forEach((product) => {
        const parentProduct = products.find(({ name }) => name === product.parentProductName);
        const companionProducts = products.filter(({ isCompanion, parentProductName }) => (
          isCompanion && parentProductName === product.name
        ));
        convertedProducts.push({
          alternativeTo: product.isAlternate && !!parentProduct
            ? product.parentProductName
            : null,
          companionProducts: companionProducts.map((companion) => ({
            package: companion.package,
            productId: companion.productId,
            name: companion.name,
            quantity: companion.quantity,
            uom: companion.uom as ProductUom,
          })) as PricingRequestEndpoint.Save.Product[],
          package: product.package,
          productId: product.productId,
          name: product.name,
          quantity: product.quantity,
          uom: product.uom as ProductUom,
        });
      });
    return convertedProducts;
  }

  const { mutateAsync: submitProductRequest } = useMutation(
    async () => {
      const products = convertCartProductsToRequestProducts(shoppingCart);

      const requestData = {
        deliveryAddress1: fulfillmentMethodData.deliveryAddress1 || undefined,
        deliveryAddress2: fulfillmentMethodData.deliveryAddress2 || undefined,
        deliveryCity: fulfillmentMethodData.deliveryCity || undefined,
        deliveryState: fulfillmentMethodData.deliveryState || undefined,
        deliveryPostalCode: fulfillmentMethodData.deliveryPostalCode || undefined,
        fulfillmentMethod: fulfillmentMethodData.fulfillmentMethod,
        note: note || undefined,
        products,
        requestedExpirationDays: SharedConfig.settings.pricingRequestStorefrontExpirationDays,
        status: PricingRequestStatus.Open,
      } as PricingRequestEndpoint.Update.Request;

      return PricingRequestsApi.createPricingRequest(requestData);
    },
    {
      onError: (error: { message: string, code: number }) => {
        openSnackbar(error.message || "An error has occurred");
      },
      onSuccess: async () => {
        setShowCart(false);
        setIsRequestView(false);
        setNote('');
        setFulfillmentMethodData(defaultFulfillmentData);
        openSnackbar(ToastMessages.requestSubmitted);
        await clearShoppingCart(user);
        await queryClient.invalidateQueries(QueryKeys.GET_SHOPPING_CART);
        await queryClient.invalidateQueries(QueryKeys.GET_PRICING_REQUESTS);
      },
    },
  );

  const handleSubmit = async () => {
    const verified = await UserApi.getVerifiedStatus(user!.id);
    if (!verified.emailVerified && !getSubdomain()) {
      setShowVerifyEmailDialog(true);
      return;
    }
    logEvent(CreatePriceRequestEventType.ClickSubmitPriceRequest);
    await submitProductRequest();
  }

  const title = isRequestView ? 'Request Products' : 'Cart';
  const shoppingCartHeader = (
    <>
      <MobileOnly>
        <Stack alignItems="center" direction="row" justifyContent="flex-start">
          {isRequestView ? (
            <IconButton
              onClick={() => setIsRequestView(false)}
              testID="back-to-cart-button"
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => setShowCart(false)}
              testID="close-shopping-cart-button"
            >
              <Close />
            </IconButton>
          )}
          <Text category="title-large" sx={{ pl: '8px' }}>
            {title}
          </Text>
        </Stack>
      </MobileOnly>
      <DesktopOnly>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            {isRequestView && (
              <IconButton
                onClick={() => setIsRequestView(false)}
                testID="back-to-cart-button"
              >
                <ArrowBack />
              </IconButton>
            )}
            <Text category="title-large" sx={{ pl: '8px' }}>
              {title}
            </Text>
          </Stack>
          <IconButton
            onClick={() => setShowCart(false)}
            testID="close-shopping-cart-button"
          >
            <Close />
          </IconButton>
        </Stack>
      </DesktopOnly>
      <VSpacer size="4" />
      {shoppingCart.length === 0 && <Divider />}
    </>
  )

  const shoppingCartCards = (
    shoppingCart?.map((cartItem) => (
      <Fragment key={cartItem.id}>
        <CartProductCard
          cartProduct={cartItem as CartProduct}
          onEdit={(id) => {
            setCartProductIdToEdit(id);
            setShowEditModal(true);
          }}
          onRemove={(id: string) => deleteShoppingCart({ id, user })}
        />
        <VSpacer size="4" />
      </Fragment>
    ))
  );

  const cartView = (
    <>
      {shoppingCart?.length
        ? shoppingCartCards
        : (
          <Stack alignItems="center">
            <VSpacer size="5" />
            <Text>Your shopping cart is empty.</Text>
          </Stack>
        )
      }
      <VSpacer size="13" />
      <BottomBar justifyContent="center" removeGutters width="inherit">
        <Fab
          color="primary"
          disabled={!shoppingCart?.length}
          onClick={() => {
            if (!user) {
              const route = getSubdomain() ? Routes.STOREFRONT_LOGIN : Routes.LOGIN;
              navigate(route);
            }
            setIsRequestView(true);
          }}
          sx={{ width: 'calc(100% - 32px)' }}
          testID="request-products-button"
          variant="extended"
        >
          Request Products
          {!!shoppingCart?.length && (
            <>
              <HSpacer size="4" />
              <IconBadge content={shoppingCart?.length}/>
            </>
          )}
        </Fab>
      </BottomBar>
    </>
  );

  const requestView = (
    <>
      <Stack height="100%" justifyContent="space-between">
        <Stack>
          <VSpacer size="6" />
          <Text category="label-large">Fulfillment Method</Text>
          <VSpacer size="6" />
          <FulfillmentMethodForm
            fulfillmentMethodData={fulfillmentMethodData}
            handleChange={(prop, value) => (
              setFulfillmentMethodData({
                ...fulfillmentMethodData,
                [prop]: value,
              })
            )}
            hideOptionalText
            testID="fulfillment-method-form"
          />
          <VSpacer size="8" />
          <Divider />
          <VSpacer size="6" />
          <Text category="label-large">Note</Text>
          <VSpacer size="6" />
          <TextAreaInput
            label="Note for retailer"
            maxLength={1000}
            maxRows={5}
            multiline
            onChangeText={setNote}
            showCharacterCountdown
            showCharacterLimitMessage
            testID="note-input"
            value={note}
          />
        </Stack>
        <Stack alignItems="center" pb="20px">
          <Text category="body-small">
            By clicking submit, you agree to Growers
            {' '}
            <TextLink
              category="body-small"
              href={GrowersContactInfo.privacyPolicyLink}
              rel="noopener noreferrer"
              sx={{ textDecoration: 'underline' }}
              target="_blank"
              testID="privacy-policy"
            >
              Privacy Policy
            </TextLink>
          </Text>
          <VSpacer size="12" />
        </Stack>
      </Stack>
      <BottomBar justifyContent="center" removeGutters width="inherit">
        <Fab
          color="primary"
          disabled={!isRequestValid}
          onClick={handleSubmit}
          sx={{ width: 'calc(100% - 32px)' }}
          testID="submit-request-button"
          variant="extended"
        >
          Submit Request
        </Fab>
      </BottomBar>
    </>
  );

  return (
    <>
      <StyledDrawer
        anchor="right"
        onClose={() => setShowCart(false)}
        open={showCart}
      >
        <ShoppingCartHeader>
          {shoppingCartHeader}
        </ShoppingCartHeader>
        {isRequestView ? requestView : cartView}
      </StyledDrawer>
      {showEditModal && !!productIdToEdit && (
        <ProductModal
          cartProductId={cartProductIdToEdit}
          onClose={() => setShowEditModal(false)}
          open
          productId={productIdToEdit}
        />
      )}
      {showVerifyEmailDialog && user?.email && (
        <VerifyYourEmail
          email={user.email}
          flow="product-request"
          onClose={() => setShowVerifyEmailDialog(false)}
          userId={user.id}
        />
      )}
    </>
  );
}
