import { useMediaQuery } from "@/hooks/useMediaQuery"
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { Button } from '../DesignSystem'
import { ContactFooter } from './ContactFooter'
import { DesktopOnly } from './DesktopOnly'
import { ModalBottomSheet } from './ModalBottomSheet/ModalBottomSheet'

export const HelpLinks = () => {
  const { isMobile } = useMediaQuery();
  const [showHelpLinks, setShowHelpLinks] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowHelpLinks(true)}
        square
        startIcon={<HelpOutlineOutlined fontSize={isMobile ? 'medium' : 'small'} />}
        testID='need-help-link'
        variant='text'
      >
        <DesktopOnly>
          Need Help?
        </DesktopOnly>
      </Button>
      <ModalBottomSheet
        onClose={() => setShowHelpLinks(false)}
        open={showHelpLinks}
        testID='help-link-modal'
        title='Need Help?'
      >
        <Stack padding='10px'>
          <ContactFooter prefix='' />
        </Stack>
      </ModalBottomSheet>
    </>
  );
}
