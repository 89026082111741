import { Checkbox, Text } from "@/components/DesignSystem";
import { Divider, Stack } from '@mui/material';
import { useState } from 'react';

export const CheckboxDemo = () => {

  const [checked, setChecked] = useState<boolean>(false);

  return (
    <Stack
      alignItems="top"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      <Checkbox
        checked={checked}
        onChangeChecked={setChecked}
        testID="checkbox-demo-large"
        >
        <Text category="body-large">Basic</Text>
      </Checkbox>
      <Checkbox
        checked={checked}
        disabled
        onChangeChecked={setChecked}
        testID="checkbox-demo-basic"
        >
        <Text category="body-large">Disabled</Text>
      </Checkbox>
    </Stack>
  );
}
