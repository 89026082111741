import { useCallback, useEffect } from 'react';

const playStoreURL = 'https://play.google.com/store/apps/details?id=com.growers_farmer&pcampaignid=web_share&pli=1';
const appStoreURL = 'https://itunes.apple.com/us/app/growers/id6462903105';

const RedirectToOs = () => {

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/i.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

  const getOs = getMobileOperatingSystem();

  const DetectAndServe = useCallback(() => {
    const os = getMobileOperatingSystem();
    if (os === 'Android') {
      return (window.location.href = playStoreURL);
    }
    if (os === 'iOS') {
      return (window.location.href = appStoreURL);
    }
  }, []);
  
  useEffect(() => {
    DetectAndServe();
  }, [DetectAndServe]);  

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>
        {getOs === 'unknown'
          ? 'Something went wrong'
          : `Redirecting to ${getOs}`}
      </p>
    </div>
  );
}

export default RedirectToOs;