import {
  Button,
  Heading,
  ProgressLoader,
  Text,
  Toolbar,
  VSpacer,
} from '@/components/DesignSystem';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import React, { Fragment, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import {
  Filter,
  FilterOption,
  FilterSelections,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { ProductApi } from '@/utilities/api/ProductApi';
import { ProductEndpoint } from '@api/endpoints';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ProductCard } from '@/pages/Shop/ProductCard';
import { ApiCategory } from '@api/interfaces';
import { Routes } from '@/constants/Routes';
import { ProductModal } from '@/pages/Shop/ProductModal';
import { useStorefront } from '@/hooks/useStorefront';

type CategoryInfo = {
  categoryId: string,
  primaryNutrientId?: string,
  subCategoryIds?: string[],
}

type CategoryLookup ={
  [label: string]: CategoryInfo,
};

const Shop = () => {
  const { isMobile } = useMediaQuery();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search') ?? undefined;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isLoading: isStorefrontLoading, storefront } = useStorefront();
  const [filterSelections, setFilterSelections]
    = useState<FilterSelections>(new Map());
  const [categoryLookups, setCategoryLookups] = useState<CategoryLookup>({});
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>();
  const [showQuickAddModal, setShowQuickAddModal] = useState(false);

  const selectedCategory = filterSelections.get('category')?.values().next().value;
  const selectedCategoryInfo = selectedCategory && categoryLookups[selectedCategory];
  const productListFilterArgs: ProductEndpoint.ProductList.Query = {
    categoryId: selectedCategoryInfo?.categoryId,
    cropId: filterSelections.get('crop')?.values().next().value,
    manufacturerId: filterSelections.get('manufacturer')?.values().next().value,
    practiceId: filterSelections.get('practice')?.values().next().value,
    primaryNutrientIds:
      selectedCategoryInfo?.primaryNutrientId
      && [selectedCategoryInfo?.primaryNutrientId],
    retailerId: storefront?.retailerId ? [storefront.retailerId] : undefined,
    subCategories: selectedCategoryInfo?.subCategoryIds,
  };

  const getFilterOptions = async () => {
    const [categories, crops, practices, manufacturers] = await Promise.all([
      ProductApi.getCategories(),
      ProductApi.getCrop(),
      ProductApi.getPractice(),
      ProductApi.getManufacturers({
        isActive: true,
        retailerId: storefront?.retailerId ?? undefined,
      }),
    ]);

    const getCategoryOptions = (categoryData: ApiCategory[]) => {
      const options: { id: string, label: string }[] = [];
      const lookups: CategoryLookup = {};
      for (const category of categoryData) {
        const label = `${category.name} - (All)`;
        options.push({
          id: label,
          label,
        });
        lookups[label] = { categoryId: category.id };
        for (const masterSubcategory of category.masterSubcategories ?? []) {
          const label = `${category.name} - ${masterSubcategory.name}`;
          options.push({
            id: label,
            label,
          });
          lookups[label] = { categoryId: category.id, subCategoryIds: [masterSubcategory.id] };
          for (const nutrient of masterSubcategory.nutrientsByCategory ?? []) {
            const label = `${category.name} - ${masterSubcategory.name} [${nutrient.name}]`;
            options.push({
              id: label,
              label,
            });
            lookups[label] = {
              categoryId: category.id,
              primaryNutrientId: nutrient.id,
              subCategoryIds: [masterSubcategory.id],
            };
          }
          for (const subcategory of masterSubcategory.subcategories ?? []) {
            const label = `${category.name} - ${masterSubcategory.name} [${subcategory.name}]`;
            options.push({
              id: label,
              label,
            });
            lookups[label] = {
              categoryId: category.id,
              subCategoryIds: [masterSubcategory.id, subcategory.id],
            };
          }
        }
      }
      setCategoryLookups(lookups);
      return options;
    }

    const getOptions = (data: { id: string, name: string | null }[]) => (
      (data
        .filter(({ name }) => !!name)
        .map(({ id, name }) => ({ id, label: name }))
      ) as FilterOption[]
    );

    return {
      categories: getCategoryOptions(categories.data),
      crops: getOptions(crops.data),
      practices: getOptions(practices.data),
      manufacturers: getOptions(manufacturers),
    };
  }

  const { data: filterOptions } = useQuery(
    [QueryKeys.GET_SHOP_FILTERS],
    getFilterOptions,
  );

  const sortDesc = filterSelections.get('sort')?.values().next().value === 'z-a';

  const {
    data: productData,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [QueryKeys.GET_PRODUCTS, productListFilterArgs, search, sortDesc],
    ({ pageParam }) => ProductApi.getProductList(
      {
        ...productListFilterArgs,
        page: pageParam,
        search,
        sortDesc,
      },
    ),
    {
      enabled: !isStorefrontLoading,
      getNextPageParam: (lastPage) => (
        lastPage.lastPage > lastPage.page
          ? lastPage.page + 1
          : undefined
      ),
    },
  );

  const totalResults = productData?.pages[0].total ?? 0;
  const hasResults = !!totalResults

  const filters: Filter[] = [
    {
      hideClearButton: true,
      id: 'sort',
      label: 'Sort by',
      options: [
        { id: 'a-z', label: 'A-Z', default: true },
        { id: 'z-a', label: 'Z-A'},
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'category',
      label: 'Product Category',
      options: filterOptions?.categories ?? [],
      selectionMethod: 'single-select',
    },
    {
      id: 'crop',
      label: 'Crop',
      options: filterOptions?.crops ?? [],
      selectionMethod: 'single-select',
    },
    {
      id: 'practice',
      label: 'Practice',
      options: filterOptions?.practices ?? [],
      selectionMethod: 'single-select',
    },
    {
      id: 'manufacturer',
      label: 'Manufacturer',
      options: filterOptions?.manufacturers ?? [],
      selectionMethod: 'single-select',
    },
  ];

  const ProductGrid = (
    <Grid
      container
      justifyContent="flex-start"
      spacing="16px"
    >
      {productData?.pages.map((group, i) => (
        <Fragment key={i}>
          {group.data.map((product) => (
            <Grid
              item
              key={product.id}
              xs={isMobile ? 6 : 3}
            >
              <ProductCard
                onClick={() => navigate(
                  Routes.PRODUCT_PAGE.replace(':id', product.id),
                  { state: { filterSelections } },
                )}
                onQuickAdd={() => {
                  setSelectedProductId(product.id);
                  setShowQuickAddModal(true);
                }}
                product={product}
                testID={`product-card-${product.id}`}
              />
            </Grid>
          ))}
        </Fragment>
      ))}
    </Grid>
  );

  let buttonText;
  if (hasResults) {
    buttonText = hasNextPage ? 'Load More Results' : 'No more results';
  } else {
    buttonText = isFetched ? 'No results' : '';
  }
  const LoadMoreButton = (
    <Stack alignItems="center">
      {isFetchingNextPage ? (
        <ProgressLoader type="circular" />
      ) : (
        <Button
          disabled={!hasNextPage || isFetching}
          onClick={() => fetchNextPage()}
          testID="shop-load-more-results-button"
          variant={hasResults ? 'outlined' : 'text'}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  );

  return (
    <>
      <Box display="flex" justifyContent={isMobile ? 'flex-start' : 'center'}>
        <Stack px="24px" width={isMobile ? '100%' : '1008px'}>
          <Heading level="2" testID="shop-heading" title="Shop" />
          <VSpacer size="5" />
          <Text category="body-large" testID="shop-page-subline">
            Add products to your cart and submit to create a product request.
            You will then receive a custom pricing offer to review and place an order.
          </Text>
          <VSpacer size="9" />
          <Toolbar
            filters={filters}
            hideSearch
            initialSelections={state?.filterSelections}
            onChange={async ({ selections }) => {
              await queryClient.invalidateQueries(QueryKeys.GET_PRODUCTS);
              setFilterSelections(selections ?? new Map());
            }}
            testID="shop-toolbar"
            totalItems={isFetched ? totalResults : undefined}
            totalUnit="product"
          />
          <VSpacer size="7" />
          <Stack alignItems="center">
            {(isFetching && !isFetchingNextPage)
              ? (
                <>
                  <VSpacer size="7" />
                  <ProgressLoader type="circular" />
                </>
              ) : ProductGrid}
          </Stack>
          <VSpacer size="6" />
          {LoadMoreButton}
        </Stack>
      </Box>
      {showQuickAddModal && selectedProductId && (
        <ProductModal
          onClose={() => {
            setSelectedProductId(undefined);
            setShowQuickAddModal(false);
          }}
          open
          productId={selectedProductId}
        />
      )}
    </>
  );
}

export default Shop;
