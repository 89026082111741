import { Chip, HSpacer, Text, TextElement, TextLink, VSpacer } from '@/components/DesignSystem';
import {
  CompanionProductList,
  ExpandButtonProps,
} from '@/components/DesignSystem/ProductCard/CompanionProductList';
import { OfferRetailersDialog } from '@/components/DesignSystem/ProductCard/OfferRetailersDialog';
import { ProductDetails } from '@/components/DesignSystem/ProductCard/ProductDetails';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { getQuantityWithUom } from '@/components/shared/SharedCardComponents/helpers';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ProductOfferRetailer } from '@/pages/CompareAcceptOffers/interfaces';
import { OfferCompanionProduct, OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { LastLookData } from '@/pages/ReceivePlaceOffers/interfaces';
import { LastLookPrices } from '@/pages/ViewPriceRequest/LastLookPrices';
import { ApiOfferProduct } from '@api/interfaces';
import { Add } from '@mui/icons-material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';
import { ProductUom } from '@shared/enums';
import { formatCurrency } from '@shared/utilities';
import { ReactNode, useState } from 'react';
import { AcceptedMessage } from '../../shared/SharedCardComponents/AcceptedMessage';
import { Container } from './Container';

interface ProductBaseProps {
  acceptMessage?: string,
  acceptedRetailer?: ProductOfferRetailer,
  bottomLeftAccessory?: ReactNode,
  bottomRightAccessory?: ReactNode,
  companionToProductName?: string,
  disableClick?: boolean,
  isAlternate?: boolean,
  isClosed?: boolean,
  isInOpenStatus?: boolean,
  lastLookData?: LastLookData,
  lineThrough?: boolean,
  offerProducts?: ApiOfferProduct[],
  product: OfferProduct,
  productOfferRetailerAcceptButton?: (onClick: () => void) => ReactNode,
  productOfferRetailerAccessory?: (productOfferRetailer: ProductOfferRetailer) => ReactNode,
  productOfferRetailers?: ProductOfferRetailer[],
  rightAccessory?: ReactNode,
  showAcreLabel?: boolean,
  substituteProduct?: string,
  testID: string,
  total?: number,
  truncateLines?: number,
}

interface ProductProps extends ProductBaseProps {
  companionRightAccessory?: (companionProduct: OfferCompanionProduct) => ReactNode,
  expandCompanion?: boolean,
  expandCompanionButton?: (props: ExpandButtonProps) => ReactNode,
  onExpandCompanion?: () => void,
  variant?: 'default',
}

interface CompanionProductProps extends ProductBaseProps {
  companionRightAccessory?: never,
  expandCompanion?: never,
  expandCompanionButton?: never,
  onExpandCompanion?: never,
  variant: 'companion',
}

export type ProductCardProps =  ProductProps | CompanionProductProps;

export const ProductCard = ({
  acceptMessage,
  acceptedRetailer,
  bottomLeftAccessory,
  bottomRightAccessory,
  companionRightAccessory,
  companionToProductName,
  disableClick,
  expandCompanion,
  expandCompanionButton,
  isAlternate,
  isClosed,
  isInOpenStatus,
  lastLookData,
  lineThrough = false,
  offerProducts,
  onExpandCompanion,
  product,
  productOfferRetailerAcceptButton,
  productOfferRetailerAccessory,
  productOfferRetailers,
  rightAccessory,
  showAcreLabel,
  substituteProduct,
  testID,
  total,
  truncateLines,
  variant = 'default',
}: ProductCardProps) => {
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [showOfferRetailers, setShowOfferRetailers] = useState(false);

  const originalProductName = product.alternativeTo || (product?.name ?? '');
  const productName = substituteProduct || (product?.name ?? '');
  const quantity = productOfferRetailers?.[0]?.offerProduct?.quantity ?? product.quantity;
  const uom = productOfferRetailers?.[0]?.offerProduct?.uom ?? product.uom;
  const showAlternateBadge = product.allowSubstitutions || !!substituteProduct || isAlternate;

  const handleClick = disableClick ? undefined : () => {
    if (!productOfferRetailers) {
      setShowProductDetails(true);
    } else {
      setShowOfferRetailers(true);
    }
  };

  const priceUom = `${formatCurrency(product.price)}/${FriendlyUoM[product.uom as ProductUom]}`;

  return (
    <>
      <Stack data-testid={testID} sx={{ flex: 1 }}>
        <Container onClick={!rightAccessory ? handleClick : undefined}>
          <Stack width="100%">
            <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
              <Stack>
                {(!!rightAccessory && !!product.price) && (
                  <>
                    <VSpacer size="2" />
                    <VSpacer size="1" />
                  </>
                )}
                <TextElement
                  category="16"
                  emphasis={variant === 'companion'}
                  lineThrough={lineThrough}
                  subline={getQuantityWithUom(
                    {
                      ...product,
                      quantity,
                      uom,
                    },
                    showAcreLabel,
                  )}
                  sublineAccessory={!!rightAccessory && (
                    <TextLink
                      accessoryLeft={<Add fontSize="inherit" />}
                      accessoryLeftSpacing="2"
                      category="body-medium"
                      onClick={handleClick}
                      testID={`product-card-more-text-link-${testID}`}
                    >
                      More
                    </TextLink>
                  )}
                  testID={`product-card-text-element-${testID}`}
                  title={productName}
                  truncateLines={truncateLines}
                />
              </Stack>
              <HSpacer size="7" />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ minWidth: product.price
                  ? `${103 + (12 * (product.price.toString().split(".")[0].length - 2))}px`
                  : undefined,
                }}
              >
                {(!!product.price || !!total) && (
                  <Stack direction="row">
                    <TextElement
                      alignment='flex-end'
                      category="16"
                      emphasis={variant === 'companion'}
                      subline={total !== undefined ? `Total: ${formatCurrency(total ?? 0)}` : undefined}
                      testID={`product-card-price-${testID}`}
                      title={priceUom}
                      truncateLines={1}
                    />
                    {!!offerProducts?.find(x => x.id === product.id)?.isAccepted && (
                      <>
                        <HSpacer size="6" />
                        <CheckCircle color="success" />
                      </>
                    )}
                  </Stack>
                )}
                {!isInOpenStatus && !!productOfferRetailers && (
                  <Stack alignItems="center" px="10px">
                    <Text category="body-xlarge" color="primary">
                      {productOfferRetailers.length}
                    </Text>
                    <Text category="overline" color="primary">Offers</Text>
                  </Stack>
                )}
                {rightAccessory}
              </Stack>
            </Stack>
            {showAlternateBadge && (
              <>
                <VSpacer size="3" />
                <Chip
                  color="primary"
                  label="Alternate"
                  testID={`product-card-chip-${testID}`}
                />
              </>
            )}
            {!!lastLookData?.lowestPrice && (
              <>
                <VSpacer size="6"/>
                <LastLookPrices
                  lastLookData={lastLookData}
                  product={product}
                  testID={testID}
                />
              </>
            )}
            {(!!product.alternativeTo || substituteProduct) && (
              <>
                <VSpacer size="3" />
                <TextElement
                  category="12"
                  testID={`${testID}-original-name`}
                  title={`Selected as an alternative to ${originalProductName}`}
                />
              </>
            )}
            {variant === "companion" && (
              <>
                <VSpacer size="3" />
                <Chip
                  color="warning"
                  label="Companion"
                  testID={`product-card-chip-${testID}`}
                />
              </>
            )}
            {!!companionToProductName && (
              <>
                <VSpacer size="3" />
                <TextElement
                  category="12"
                  testID={`${testID}-original-name`}
                  title={`Selected as a companion to ${companionToProductName}`}
                />
              </>
            )}
            {!!acceptMessage &&
              <>
                <VSpacer size="4" />
                <Stack direction="row">
                  {!!acceptMessage && (
                    <AcceptedMessage
                      testID={testID}
                      title={acceptMessage}
                    />
                  )}
                </Stack>
              </>
            }
          </Stack>
        </Container>
        {(!!bottomLeftAccessory || !!bottomRightAccessory) && (
          <>
            <VSpacer size="5" />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              {bottomLeftAccessory}
              {bottomRightAccessory}
            </Stack>
          </>
        )}
        {(variant === 'default' && !lineThrough && !!product.companionProducts?.length) && (
          <>
            <VSpacer size="6"/>
            <CompanionProductList
              companionProducts={product.companionProducts}
              expand={expandCompanion}
              expandButton={expandCompanionButton}
              lastLookData={lastLookData}
              offerProducts={offerProducts}
              onExpand={onExpandCompanion}
              rightAccessory={companionRightAccessory}
              testID={testID}
            />
          </>
        )}
      </Stack>

      {showOfferRetailers && (
        <OfferRetailersDialog
          acceptButton={productOfferRetailerAcceptButton?.(() => setShowOfferRetailers(false))}
          acceptedRetailer={acceptedRetailer}
          bottomRightAccessory={productOfferRetailerAccessory}
          companionToProductName={companionToProductName}
          isClosed={isClosed}
          onClose={() => setShowOfferRetailers(false)}
          product={product}
          productOfferRetailers={productOfferRetailers}
          showAcreLabel={showAcreLabel}
          testID={`product-card-offers-${testID}`}
        />
      )}

      <ModalBottomSheet
        hideBottomCloseButton
        onClose={() => setShowProductDetails(false)}
        onOpen={() => setShowProductDetails(true)}
        open={showProductDetails}
        testID={`product-card-details-modal-${testID}`}
        title={productName}
      >
        <ProductDetails
          product={product}
          showAcreLabel={showAcreLabel}
          testID={`product-card-details-${testID}`}
        />
      </ModalBottomSheet>
    </>
  );
}
