import { useState, useEffect } from 'react';
import AddRetailer from './RetailerFormDialog';
import { CircularProgress, Container, Stack, Pagination } from '@mui/material';
import {
  Button,
  Search,
  Text,
  Toolbar,
  VSpacer,
} from '@/components/DesignSystem';
import RetailerCard from './RetailerOverviewCard';
import { filters } from './filters';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import { ApiRetailer } from '@api/interfaces';
import { useSearch } from '@/hooks/useSearch';
import { FilterResult } from '@/components/DesignSystem/Toolbar/interfaces';
import AddIcon from '@mui/icons-material/Add';
import { getCountsText } from '@/constants/HierarchyOfRetailers';

type FilterKeys = 'isActive' | 'isInactive' | 'all';

const filterOptions = {
  all: 'all',
  isActive: true,
  isInactive: false,
} as const;

const RetailerListPage = () => {
  const [isAddRetailerOpen, setIsAddRetailerOpen] = useState(false);
  const { search, setSearch, debouncedSearch } = useSearch();
  const [currentPage, setPage] = useState(0);
  const [filterResult, setFilterResult] = useState<FilterResult>({});
  
  const selectedFilters: Record<string, string | boolean> = Array.from(
    filterResult?.selections?.entries() ?? [],
  )
    .map(([filterId, selection]) => {
      return {
        filterId,
        value: Array.from(selection).join(', '),
      };
    })
    .reduce((obj, filter) => {
      if (filter?.filterId === 'sortDesc') {
        obj['sortDesc'] = filter.value === 'desc' ? true : false;
        return obj;
      } else {
        obj[filter?.filterId] = filterOptions[filter.value as FilterKeys];
        return obj;
      }
    }, {} as Record<string, string | boolean>);
    
  useEffect(() => {
    setPage(0);
  }, [selectedFilters?.isActive, debouncedSearch]);

  const { getRetailers, isLoading } = useGetRetailers({
    ...selectedFilters,
    limit: 20,
    page: currentPage,
    search: debouncedSearch,
  });

  return (
    <Container maxWidth='lg'>
      <Stack>
        <VSpacer size='8' />
        <Stack
          alignItems='center'
          direction='row'
          justifyContent={'space-between'}
        >
          <Text category='h2'>Retailers</Text>
          <Button
            onClick={() => setIsAddRetailerOpen(true)}
            size='medium'
            startIcon={<AddIcon />}
            testID='add-retailer'
          >
            Add Retailer
          </Button>
        </Stack>
        <VSpacer size='8' />
        <Search
          onChangeText={setSearch}
          testID='search-retailer'
          value={search}
          width={350}
        />
        <Stack
          alignItems='center'
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            margin: '25px 0px',
          }}
        >
          <Toolbar
            filters={filters}
            hideAllFilter={false}
            hideSearch={true}
            onChange={setFilterResult}
            testID='filter-retailer'
          />
        </Stack>
        <Text category='body-medium'>
          {getCountsText({
            activeCount: getRetailers?.activeCount ?? 0,
            isActive: selectedFilters?.isActive,
            selectedTabText: 'retailer',
            total: getRetailers?.total ?? 0,
          })}
        </Text>
        <VSpacer size='8' />
        {!getRetailers?.data?.length && !isLoading && (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ marginTop: '10rem' }}
          >
            <Text
              category='p2'
              style={{
                color: '#A0A0A0',
              }}
            >
              No retailers found
            </Text>
          </Stack>
        )}
        {!isLoading ? (
          <>
            {getRetailers?.data?.map((retailer: ApiRetailer) => (
              <RetailerCard key={retailer.id} retailer={retailer} />
            ))}
            <VSpacer size='8' />
            {getRetailers && !!getRetailers?.lastPage && (
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='center'
              >
                <Pagination
                  count={getRetailers?.lastPage + 1}
                  onChange={(_, page) => {
                    setPage(page - 1);
                  }}
                  page={getRetailers?.page + 1}
                />
              </Stack>
            )}
          </>
        ) : (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ marginTop: '10rem' }}
          >
            <CircularProgress />
          </Stack>
        )}
      </Stack>
      {isAddRetailerOpen && (
        <AddRetailer
          isAddRetailerOpen={isAddRetailerOpen}
          setIsAddRetailerOpen={setIsAddRetailerOpen}
        />
      )}
    </Container>
  );
};

export default RetailerListPage;
