import { Text } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import moment from 'moment';
import { ApiProductRecommendation } from '@api/interfaces';

const Typography = styled(Text)`
  color: rgba(255, 255, 255, 0.8);
`;

const ProductRecommendationDetails = ({
  info,
  showDescription = false,
}: {
  info: ApiProductRecommendation | undefined;
  showDescription?: boolean;
}) => {
  return (
    <Stack spacing={1.2}>
      <Text category='h6' fontWeight={'medium'}>
        Product Recommendation #{info?.publicId}
      </Text>
      <Typography category='body-medium'>
        Salesperson Name :{' '}
        <span style={{ color: '#EDF354' }}>
          {info?.salespersonDetails?.businessName ?? '-'}
        </span>
      </Typography>
      <Typography category='body-medium'>
        Products : {info?.productRecommendationData?.length} &nbsp;&nbsp;
        Farmers : {info?.recommendationFarmer?.length}
      </Typography>
      <Typography category='body-medium'>
        <>
          Created On :{' '}
          <span style={{ color: '#8FD460' }}>
            {moment(info?.createdAt).format('MMM DD, YYYY')}
          </span> &nbsp;&nbsp;
          Expires On :{' '}
          <span style={{ color: '#FFA940' }}>
            {moment(info?.expiration).format('MMM DD, YYYY')}
          </span>
        </>
      </Typography>
      {showDescription && (
        <Typography category='body-medium'>
          <>
            Description :{' '}
            <span
              style={{
                color: 'white',
              }}
            >
              {info?.note}
            </span>
          </>
        </Typography>
      )}
    </Stack>
  );
};

export default ProductRecommendationDetails;
