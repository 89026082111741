import { Badge, VSpacer } from '@/components/DesignSystem';
import { Mail } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';

export const BadgeDemo = () => {
  return (
    <Stack
      alignItems="top"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      <Stack alignItems="center" spacing={4}>
        Primary
        <VSpacer size="3" />
        <Badge color="primary" content={1} testID="badge-demo-primary"><Mail /></Badge>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        Secondary
        <VSpacer size="3" />
        <Badge color="secondary" content={1} testID="badge-demo-secondary"><Mail /></Badge>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        Error
        <VSpacer size="3" />
        <Badge color="error" content={1} testID="badge-demo-error"><Mail /></Badge>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        Info
        <VSpacer size="3" />
        <Badge color="info" content={1} testID="badge-demo-info"><Mail /></Badge>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        Warning
        <VSpacer size="3" />
        <Badge color="warning" content={1} testID="badge-demo-warning"><Mail /></Badge>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        Default
        <VSpacer size="3" />
        <Badge color="default" content={1} testID="badge-demo-default"><Mail /></Badge>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        Success
        <VSpacer size="3" />
        <Badge color="success" content={1} testID="badge-demo-success"><Mail /></Badge>
      </Stack>
    </Stack>
  );
}
