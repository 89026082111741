import {
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { ShortDivider } from '@/components/DesignSystem/ShortDivider/ShortDivider';
import { Divider, Stack } from '@mui/material';

export const DividerDemo = () => {
  return (
    <Stack>
      <Text>Basic</Text>
      <Divider />
      <VSpacer size="5" />
      <Text>Light</Text>
      <Divider light />
      <Text>Short</Text>
      <ShortDivider />
    </Stack>
  );
}
