import { HSpacer, Text } from '@/components/DesignSystem';
import Check from '@mui/icons-material/Check';
import { Stack } from "@mui/material";
import { ApiPricingRequestPublic } from '@api/interfaces';
import { useCallback } from 'react';

interface DeliveryAddressProps {
  pricingRequest: ApiPricingRequestPublic,
}

const DeliveryAddress = ({
  pricingRequest,
}: DeliveryAddressProps) => {

  const formatAddress = useCallback(() => {
    const addressBuilder = [];
    addressBuilder.push(pricingRequest.deliveryAddress1 || pricingRequest.user?.address1);
    addressBuilder.push(pricingRequest.deliveryAddress2 || pricingRequest.user?.address2);
    addressBuilder.push(pricingRequest.deliveryCity || pricingRequest.user?.city);
    addressBuilder.push(pricingRequest.deliveryState || pricingRequest.user?.state);
    addressBuilder.push(pricingRequest.deliveryPostalCode || pricingRequest.user?.postal);

    if (addressBuilder.filter((i) => !!i).length === 0) {
      return "";
    }

    return "to " + addressBuilder.filter((i) => !!i).join(" ");

  }, [pricingRequest]);

  return (
    <Stack alignItems="center" direction="row">
      <Check />
      <HSpacer size="4" />
      <Text category="p2">
        {`Delivery ${formatAddress()}`}
      </Text>
    </Stack>
  );
}

export default DeliveryAddress;
