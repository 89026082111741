import { InlineMessage, Text, VSpacer } from '@/components/DesignSystem';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ResponsiveDialog } from '@/components/shared/ResponsiveDialog/ResponsiveDialog';
import { getQuantityWithUom } from '@/components/shared/SharedCardComponents/helpers';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { ProductOfferRetailer } from '@/pages/CompareAcceptOffers/interfaces';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { RetailerCard } from '@/pages/ReviewSelectOffers/RetailerCard';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { Divider, Stack } from '@mui/material';
import { Fragment, ReactNode } from 'react';
import SwapHorizontalCircle from '@mui/icons-material/SwapHorizontalCircle';
import StickyNote2Outlined from '@mui/icons-material/StickyNote2Outlined';

const styles: SXStyles = {
  emptyText: {
    pb: '160px',
    pt: '64px',
    px: '48px',
    textAlign: 'center',
  },
};

interface OfferRetailersDialogProps {
  acceptButton: ReactNode,
  acceptedRetailer?: ProductOfferRetailer,
  bottomRightAccessory?: (productOfferRetailer: ProductOfferRetailer) => ReactNode,
  companionToProductName?: string,
  isClosed?: boolean,
  onClose: () => void,
  product: OfferProduct,
  productOfferRetailers?: ProductOfferRetailer[],
  showAcreLabel?: boolean,
  testID: string,
  title?: string,
}

export const OfferRetailersDialog = ({
  acceptButton,
  acceptedRetailer,
  bottomRightAccessory,
  companionToProductName,
  isClosed,
  onClose,
  product,
  productOfferRetailers,
  showAcreLabel,
  testID,
}: OfferRetailersDialogProps) => {
  const quantityWithUom = getQuantityWithUom(product, showAcreLabel);
  const isStorefront = !!getSubdomain();
  const note = productOfferRetailers?.[0]?.offerProduct?.note;

  return (
    <ResponsiveDialog
      acceptButton={() => acceptButton}
      onClose={onClose}
      testID={testID}
    >
      <Stack px={getResponsiveValue(2, 0)}>
        <MobileOnly>
          <VSpacer size="5" />
        </MobileOnly>
        <Text category="h2">{product.name}</Text>
        <VSpacer size="3" />
        {!!quantityWithUom && (
          <Text
            testID={`product-card-text-quantity-${testID}`}
          >
            {`Quantity: ${quantityWithUom}`}
          </Text>
        )}
        {!!product.package && (
          <>
            {!!quantityWithUom && <VSpacer size="4" />}
            <Text
              testID={`product-card-text-quantity-${testID}`}
            >
              {`Package type: ${product.package}`}
            </Text>
          </>
        )}
        {!!product.alternativeTo && (
          <>
            <VSpacer size="4" />
            <InlineMessage
              icon={<SwapHorizontalCircle color="info" fontSize="small" />}
              testID={`product-card-alternative-to-${testID}`}
              title={`Alternative to ${product.alternativeTo}`}
              titleColor="primary"
              titleSpacing="3"
            />
          </>
        )}
        {!!companionToProductName && (
          <>
            <VSpacer size="4" />
            <InlineMessage
              icon={<SwapHorizontalCircle color="info" fontSize="small" />}
              testID={`product-card-companion-to-${testID}`}
              title={`Companion to ${companionToProductName}`}
              titleColor="primary"
              titleSpacing="3"
            />
          </>
        )}
        {!!note && (
          <>
            <VSpacer size="4" />
            <InlineMessage
              icon={<StickyNote2Outlined fontSize="small" />}
              testID={`product-card-note-${testID}`}
              title={note}
              titleSpacing="3"
            />
          </>
        )}
      </Stack>
      <Divider sx={{ my: 3 }} />
      {!productOfferRetailers?.length ? (
        <Text category="title-large" sx={styles.emptyText}>
          {`Retailer offer${isStorefront ? '' : 's'} for this product will appear here`}
        </Text>
      ) : productOfferRetailers.map((offerRetailer, index) => {
        if (!offerRetailer.offerProduct) {
          return null;
        }
        const isAccepted = acceptedRetailer?.offerProduct?.id === offerRetailer.offerProduct.id;
        return (
          <Fragment key={index}>
            <Stack px={getResponsiveValue(2, 0)}>
              <RetailerCard
                bottomRightAccessory={bottomRightAccessory?.(offerRetailer)}
                isAccepted={isClosed && isAccepted}
                product={product}
                productOfferRetailer={offerRetailer}
                testID={`retailer-card-${index}-${testID}`}
              />
            </Stack>
            <Divider sx={{ my: 3 }} />
          </Fragment>
        )

      })}
    </ResponsiveDialog>
  );
}
