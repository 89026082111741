import { DesktopOnly } from '@/components/shared/DesktopOnly'
import { MobileOnly } from '@/components/shared/MobileOnly'
import { Container, Stack } from '@mui/material'
import {
  Button,
  Heading,
  HSpacer,
  ProgressLoader,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { ArrowBack, CheckCircleOutline } from '@mui/icons-material';
import { Routes } from '@/constants/Routes';
import { EmailType } from '@/constants/EmailType';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { UserApi } from '@/utilities/api/UserApi';
import { AuthApi } from '@/utilities/api/AuthApi';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { InlineMessage } from '@/components/DesignSystem/InlineMessage/InlineMessage';
import { GrowersContactInfo } from '@shared/enums';
import { ApiError } from '@api/interfaces';

const Content = ({ testID }: { testID: string }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const emailType: EmailType = location.state.emailType;
  const emailSuccessMsg = "Email successfully resent";
  const errorMsg = "An error has occurred";

  const { mutate: resetPasswordEmail, isLoading: isLoadingPasswordReset } = useMutation(
    () => AuthApi.requestPasswordReset({ email: location.state.email }), {
      onError: (err: ApiError) => {
        setErrorMessage(err.message || errorMsg);
      },
      onSuccess: () => {
        openSnackbar(emailSuccessMsg);
      },
    });

  const { mutate: resendEmail, isLoading: isLoadingCreatePassword } = useMutation(
    () => UserApi.resendCreatePasswordEmail(id!), {
      onError: (err: ApiError) => {
        setErrorMessage(err.message || errorMsg);
      },
      onSuccess: () => {
        openSnackbar(emailSuccessMsg);
      },
    });

  const isLoading = isLoadingCreatePassword || isLoadingPasswordReset;

  useEffect(() => {
    if (!id || !location.state.email) {
      navigate(Routes.LANDING);
    }
  }, [location, id, navigate]);

  return (
    <Stack alignItems="center">
      {isLoading && <ProgressLoader type="linear" />}
      <CheckCircleOutline color="success" fontSize="large" />
      <VSpacer size="5" />
      <Heading level="1" testID={`${testID}-heading`} title="Check your email" />
      <VSpacer size="8" />
      <Text category="body-medium" testID={`${testID}-text-1`} textAlign="center">
        We've sent an email to {location.state.email} with a link to
        {emailType === EmailType.CREATE_PASSWORD
          ? " activate your account and set a "
          : " reset your "
        }
        password.
      </Text>
      <VSpacer size="8" />
      <Text category="body-medium" testID={`${testID}-text-1`} textAlign="center">
        Didn't receive an email? Check your spam folder or get in touch with us.
      </Text>
      <VSpacer size="5" />
      <VSpacer size="9" />
      <Button
        onClick={() => (
          emailType === EmailType.CREATE_PASSWORD && id ? resendEmail() : resetPasswordEmail()
        )}
        testID={`${testID}-send-email-button`}
        variant="outlined"
      >
        Send the email again
      </Button>
      <VSpacer size="7" />
      <TextLink
        accessoryLeft={<ArrowBack sx={{ width: '16px', height: '16px' }} />}
        category="body-small"
        onClick={() => navigate(Routes.LANDING)}
        testID={`${testID}-back-to-login-link`}
      >
        Back to login
      </TextLink>
      <VSpacer size="9" />
      <VSpacer size="10" />
      <Text>Need help?</Text>
      <Stack direction="row">
        <TextLink
          category="body-medium"
          href={`mailto:${GrowersContactInfo.supportEmail}`}
          testID={`${testID}-send-email-link`}
        >
          Send us an email
        </TextLink>
        <HSpacer size="3" />
        <Text>or</Text>
        <HSpacer size="3" />
        <TextLink
          category="body-medium"
          href={`tel:${GrowersContactInfo.supportPhone}`}
          testID={`${testID}-call-link`}
        >
          Give us a call
        </TextLink>
      </Stack>
      {!!errorMessage && (
        <>
          <VSpacer size="9" />
          <InlineMessage
            secondaryText={errorMessage}
            secondaryTextColor="error"
            testID={`${testID}-error`}
            title="Error:"
            titleColor="error"
          />
          <VSpacer mobileSize="5" size="8" />
        </>
      )}
    </Stack>
  )
};

export const CheckYourEmail = () => {
  return (
    <>
      <DesktopOnly>
        <Container
          maxWidth={false}
          sx={{
            maxWidth: '439px',
            border: "1px solid",
            borderRadius: '20px',
            padding: '32px',
          }}
        >
          <Content testID="check-your-email" />
        </Container>
      </DesktopOnly>
      <MobileOnly>
        <Container>
          <Content testID="check-your-email" />
        </Container>
      </MobileOnly>
    </>
  )
}
