import {
  Banner,
  BannerStatus,
  Button,
  Chip,
  HSpacer,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { FriendlyPaymentStatus } from '@/constants/FriendlyPaymentStatus';
import { PaymentSubmittedModal } from '@/pages/Payment/PaymentSubmittedModal';
import { RetailerPaymentModal } from '@/pages/Payment/RetailerPaymentModal';
import { Color } from '@/themes/MUITheme/palette';
import { ApiPayment } from '@api/interfaces';
import { Stack } from '@mui/material';
import { PaymentStatus } from '@shared/enums';
import { formatCurrency } from '@shared/utilities';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface PaymentDetailsProps {
  offerPublicId: string,
  payment: ApiPayment,
}

export const PaymentDetails = ({
  offerPublicId,
  payment,
}: PaymentDetailsProps) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentSubmittedModal, setShowPaymentSubmittedModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // need to fix url since stripe callback puts search before the hash route causing
    // react-router to not get the search params
    const hasWindowSearch = !!window.location.search;
    if (hasWindowSearch) {
      const [domain, search, route, inviteToken] = window.location.href.split(/[?#]/);
      let updatedHref = `${domain}#${route}?${search}`;
      if (inviteToken) {
        updatedHref += `&${inviteToken}`;
      }
      window.location.href = updatedHref;
    } else {
      const isSuccessfulRedirectFromStripe = searchParams.has('payment_intent')
        && searchParams.has('payment_intent_client_secret')
        && searchParams.get('redirect_status') === 'succeeded';
      if (isSuccessfulRedirectFromStripe && !showPaymentSubmittedModal) {
        setShowPaymentSubmittedModal(true);
      }
    }
  }, []);

  const navigateToVerifyDeposit = () => {
    if (payment.trialDepositUrl) {
      window.open(payment.trialDepositUrl);
    }
  }

  const StatusBanner = () => {
    let includeIcon = true;
    let message;
    let status: BannerStatus = 'warning';
    if (payment.isDelinquent && payment.status === PaymentStatus.REQUIRES_ACTION) {
      message = `${FriendlyPaymentStatus[PaymentStatus.REQUIRES_ACTION]} - Delinquent`;
    } else if (payment.isDelinquent) {
      message = 'Delinquent';
    } else if (payment.status === PaymentStatus.UNPAID) {
      includeIcon = false;
      message = FriendlyPaymentStatus[PaymentStatus.UNPAID];
      status = 'info';
    } else if (payment.status === PaymentStatus.REQUIRES_ACTION) {
      message = FriendlyPaymentStatus[PaymentStatus.REQUIRES_ACTION];
    } else if (payment.status === PaymentStatus.FAILED) {
      message = FriendlyPaymentStatus[PaymentStatus.FAILED];
    }

    if (message) {
      return <Banner includeIcon={includeIcon} message={message} status={status} />;
    }
    return null;
  }

  const isPayNow = (
    payment.status === PaymentStatus.UNPAID || payment.status === PaymentStatus.FAILED
  );
  const isVerifyTrialDeposit = (
    payment.status === PaymentStatus.REQUIRES_ACTION && !!payment.trialDepositUrl
  );
  const requiresPayment = isPayNow || isVerifyTrialDeposit;

  const StatusBadge = () => {
    let color: Color = 'success';
    let text = '';
    if (payment.status === PaymentStatus.PROCESSING) {
      color = 'warning';
      text = FriendlyPaymentStatus[PaymentStatus.PROCESSING];
    } else if (payment.status === PaymentStatus.PAID) {
      color = 'success';
      text = FriendlyPaymentStatus[PaymentStatus.PAID];
    } else if (payment.status === PaymentStatus.REFUNDED) {
      color = 'info';
      text = FriendlyPaymentStatus[PaymentStatus.REFUNDED];
    } else {
      return null;
    }

    return <Chip color={color} label={text} testID="payment-detail" />
  }

  const PaymentActionButton = () => {
    return isPayNow ? (
      <Button
        onClick={() => setShowPaymentModal(true)}
        testID="pay-now-button"
      >
        Pay Now
      </Button>
    ) : (
      <Button
        onClick={navigateToVerifyDeposit}
        testID="verify-trial-deposit-button"
        variant="outlined"
      >
        Verify Trial Deposit
      </Button>
    );
  }

  return (
    <Stack>
      <StatusBanner />
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          borderRadius: '4px',
          padding: '24px',
        }}
      >
        <Text category="headline-small">Payment</Text>
        <VSpacer size="2" />
        <Text category="body-medium">
          {!payment.feePercent
            ? 'Retailer transaction fee'
            : `${payment.feePercent}% Retailer transaction fee`}
        </Text>
        <VSpacer size="7" />
        <Stack
          alignItems={requiresPayment ? 'flex-end' : 'center'}
          direction="row"
          justifyContent={requiresPayment ? 'space-between' : 'flex-start'}
        >
          <Stack>
            {requiresPayment &&
              <Text category="overline">
                AMOUNT DUE
              </Text>
            }
            <Text category="headline-large">
              {formatCurrency(payment.feeAmountInCents / 100)}
            </Text>
          </Stack>
          {requiresPayment ? (
            <PaymentActionButton />
          ) : (
            <>
              <HSpacer size="7" />
              <StatusBadge />
            </>
          )}
        </Stack>
        {payment.status === PaymentStatus.PROCESSING &&
          <>
            <VSpacer size="5" />
            <Text align="center" category="body-small">
              ACH payments may take 2-4 days to complete
            </Text>
          </>
        }
        {isVerifyTrialDeposit &&
          <>
            <VSpacer size="5" />
            <Text align="center" category="body-small">
              Once verified, refresh this page to see updated status
            </Text>
          </>
        }
      </Stack>
      {showPaymentModal &&
        <RetailerPaymentModal
          offerPublicId={offerPublicId}
          onClose={() => setShowPaymentModal(false)}
          payment={payment}
        />
      }
      {showPaymentSubmittedModal &&
        <PaymentSubmittedModal
          offerPublicId={offerPublicId}
          onClose={() => {
            setShowPaymentSubmittedModal(false);
            const inviteToken = searchParams.get('token');
            if (inviteToken) {
              setSearchParams({ token: inviteToken });
            } else {
              setSearchParams({});
            }
          }}
        />
      }
    </Stack>
  )
}
