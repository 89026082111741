export class DetailedApiError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor (public code: string, message: string, public details: any) {
    super(message);
  }
}

export const errorIsDetailedApiError = (error: Error): error is DetailedApiError => (
  Object.hasOwnProperty.call(error, 'code')
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const detailsHasErrors = (details: any) : details is { errors: any[] } => (
  Object.hasOwnProperty.call(details, 'errors')
  && Array.isArray(details.errors)
);
