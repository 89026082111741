import {
  Button,
  Logo,
  Modal,
  PhoneInput,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from "@/constants/Routes";
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useStorefront } from '@/hooks/useStorefront';
import OtpForm from '@/pages/Auth/OtpForm';
import { CreateFarmerAccountEventType, useLogEvent } from '@/utilities/Analytics';
import { ApiStorefront } from '@api/interfaces';
import { CircularProgress, Stack, useTheme } from "@mui/material";
import { GrowersContactInfo } from '@shared/enums';
import { validatePhoneNumber } from '@shared/utilities';
import { Suspense, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

const StorefrontLoginForm = () => {
  const { isMobile } = useMediaQuery();
  const { state } = useLocation();
  const [username, setUsername] = useState(state?.username ?? "");
  const [errorMessage, setErrorMessage] = (
    useState<{ title?: string, message: string } | undefined>(state?.errorMessage)
  );
  const [showOtpForm, setShowOtpForm] = useState(false);

  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const { storefront } = useStorefront(true);
  const theme = useTheme();

  const handleInputBlur = () => {
    if (validatePhoneNumber(username, AppConfig.env.test)) {
      setErrorMessage(undefined);
    } else if (username) {
      setErrorMessage(
        { message: 'Invalid Phone Number' },
      );
    }
  }

  const sendVerificationCode = async () => {
    if (validatePhoneNumber(username, AppConfig.env.test)) {
      setErrorMessage(undefined);
      setShowOtpForm(true);
    } else {
      setErrorMessage(
        { message: 'Invalid Phone Number' },
      );
    }
  }

  const StorefrontLogo = ({ storefront }: { storefront: ApiStorefront }) => (
    <Stack direction='row' justifyContent='center'>
      <Logo size="giant" subdomain={storefront.subdomain} />
    </Stack>
  )

  return (
    <Stack
      direction="row"
      justifyContent="center"
      paddingTop={isMobile ? '0px' : '100px'}
    >
      <Stack display="flex" flex={1} maxWidth="512px">
        <Stack
          alignItems="center"
          borderRadius="12px"
          justifyContent="center"
          padding={isMobile ? '16px' : '48px'}
          sx={{ backgroundColor: isMobile ? undefined : theme.palette.background.paper }}
        >
          <Suspense fallback={<CircularProgress />}>
            <StorefrontLogo storefront={storefront!} />
          </Suspense>
          <VSpacer size="9" />
          <Text textAlign="center">
            Sign in with your phone number
          </Text>
          <VSpacer size="7" />
          <PhoneInput
            autoFocus
            error={!!errorMessage}
            fullWidth
            helperText={errorMessage?.message}
            onBlur={handleInputBlur}
            onChangeText={setUsername}
            onEnterPressed={sendVerificationCode}
            required
            testID="storefront-login-form-username"
            value={username}
          />
          <VSpacer size="7" />
          <Text category="p2">
            By continuing you agree to the GROWERS&nbsp;
            <TextLink
              href={GrowersContactInfo.privacyPolicyLink}
              target="_blank"
              testID="privacy-policy"
            >
              Privacy policy
            </TextLink>
            &nbsp;and&nbsp;
            <TextLink
              href={GrowersContactInfo.termsOfUseLink}
              target="_blank"
              testID="tos-link"
            >
              Terms of service
            </TextLink>
          </Text>
          <VSpacer size="7" />
          <Stack direction="row" justifyContent="center">
            <Button
              disabled={!validatePhoneNumber(username, AppConfig.env.test)}
              onClick={sendVerificationCode}
              testID="storefront-login-form-button"
              variant="outlined"
            >
              Send Verification Code
            </Button>
          </Stack>
        </Stack>
        <VSpacer size="7" />
        <Stack alignItems="center">
          <Text testID="existing-user-text">
            Don't have a {storefront?.name} Shop account?
          </Text>
          <TextLink
            category="p2"
            onClick={() => {
              logEvent(CreateFarmerAccountEventType.ClickCreateYourAccountHere);
              navigate(Routes.CREATE_ACCOUNT);
            }}
            testID="sign-up-link"
          >
            Sign up
          </TextLink>
        </Stack>
      </Stack>
      {showOtpForm && username &&
        <Modal
          onClose={() => setShowOtpForm(false)}
          open
          testID="create-farmer-otp-form-modal"
        >
          <OtpForm
            onCancel={() => setShowOtpForm(false)}
            telephone={username}
          />
        </Modal>
      }
    </Stack>
  );
}

export default StorefrontLoginForm;
