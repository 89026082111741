import { Button, InlineMessage, TextLink } from '@/components/DesignSystem';
import Add from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { Box } from '@mui/material';

interface ReviewActionProps {
  onClick?: () => void,
  testID: string,
  variant: 'compare' |
  'add-product' |
  'remove-product' |
  'product-already-in-order' |
  'replace-product',
}

export const ReviewAction = ({
  onClick,
  testID,
  variant,
}: ReviewActionProps) => {

  const actions = {
    'compare': (
      <TextLink
        category='body-medium'
        onClick={onClick}
        testID={testID}
      >
        Compare
      </TextLink>
    ),
    'add-product': (
      <Button
        color="primary"
        onClick={onClick}
        startIcon={(
          <Add fontSize="small" sx={{ paddingRight: "5px" }} />
        )}
        testID={testID}
        variant="outlined"
      >
        Add
      </Button>
    ),
    'remove-product': (
      <Button
        onClick={onClick}
        startIcon={(
          <Close fontSize="small" sx={{ paddingRight: "5px" }} />
        )}
        testID={testID}
        variant="outlined"
      >
        Remove
      </Button>
    ),
    'replace-product': (
      <Button
        onClick={onClick}
        startIcon={(
          <Close fontSize="small" sx={{ paddingRight: "5px" }} />
        )}
        testID={testID}
        variant="outlined"
      >
        Replace
      </Button>
    ),
    'product-already-in-order': (
      <Box py="10px">
        <InlineMessage
          icon={<Check fontSize="inherit" />}
          testID={testID}
          title="Product already in order"
          titleSpacing="3"
        />
      </Box>
    ),
  };

  return actions[variant];
}
