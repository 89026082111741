import { useUuid } from '@/hooks/useUuid';
import { useCallback, useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const key = 'MODAL_VISIBLE_KEY';
const initialData = '';

export const useHideBackdrop = (open = false) => {
  const currentId = useUuid();
  const mounted = useRef(false);
  const previousIdRef = useRef<string>('');
  const openRef = useRef(false);
  const queryClient = useQueryClient();

  const { data: activeId } = useQuery(key, () => initialData, {
    enabled: false,
    initialData,
  });

  useEffect(() => {
    if (openRef.current !== open) {
      previousIdRef.current = activeId ?? '';
    }

    openRef.current = open;
  }, [activeId, open]);

  const callback = useCallback(() => {
    if (!open) {
      return;
    }

    if (previousIdRef.current !== currentId) {
      queryClient.setQueryData(key, currentId);
    }

    return () => {
      queryClient.setQueryData(key, previousIdRef.current);
    };
  }, [queryClient, open]);

  useEffect(() => {
    if (!mounted.current) {
      return callback();
    }

    mounted.current = open;
  }, [mounted, open]);

  const modalElements = document.getElementsByClassName('MuiModal-root');

  return {
    transitionDuration: modalElements.length > 0 ? '0' : undefined,
    hide: currentId !== activeId,
  };
}
