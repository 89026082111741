import { TextLink } from '@/components/DesignSystem';
import { FilterSelector } from "@/components/DesignSystem/Toolbar/FilterSelector";
import { FilterSelection, NonBooleanFilter } from "@/components/DesignSystem/Toolbar/interfaces";
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { Box, Menu, MenuProps, Stack } from "@mui/material";
import { Modify } from "@shared/utilities/UtilityTypes";

const styles: SXStyles = {
  clearButton: {
    paddingRight: '10px',
    paddingY: '8px',
  },
} as const;

interface FilterMenuProps
  extends Modify<
    MenuProps,
    { onChange: (selection: FilterSelection) => void }
  > {
  filter: NonBooleanFilter,
  selection?: FilterSelection,
  testID: string,
}

export const FilterMenu = ({
  anchorEl,
  filter,
  onChange,
  onClose,
  selection = new Set(),
  testID,
}: FilterMenuProps) => {
  return (
    <Menu
      PaperProps={{
        style: {
          borderRadius: '12px',
          marginTop: '12px',
        },
      }}
      anchorEl={anchorEl}
      data-testid={testID}
      onClose={onClose}
      open
    >
      <Box p="0 16px 16px">
        {!filter.hideClearButton && (filter.selectionMethod !== 'multi-select') && (
          // TODO GA-1886 Figure out why this grabs focus when the user types "c" in the search box
          <Stack direction="row" justifyContent="flex-end">
            <TextLink
              category="label-medium"
              onClick={() => {
                onClose?.({}, 'backdropClick');
                onChange?.(new Set());
              }}
              sx={styles.clearButton}
              testID={`${testID}-clear-button`}
            >
              Clear
            </TextLink>
          </Stack>
        )}
        <FilterSelector
          filter={filter}
          onChange={onChange}
          selection={selection}
          testID={`${testID}-selector`}
        />
      </Box>
    </Menu>
  );
};
