import {
  BottomSheet,
  Button,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import Comment from '@mui/icons-material/Comment';
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';

export const BottomSheetDemo = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <DesktopOnly>
        <DemoBlock label="Mobile Only">
          <Text category="body-large">Toggle Device Toolbar to view the BottomSheetDemo</Text>
        </DemoBlock>
      </DesktopOnly>
      <MobileOnly>
        <DemoBlock>
          <Button
            onClick={() => setOpen(!open)}
            testID="bottom-sheet-demo-button"
          >
            Open Bottom Sheet
          </Button>
        </DemoBlock>
      </MobileOnly>
      <BottomSheet
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        testID="bottom-sheet-test"
      >
        <Stack>
          {[0, 1, 2, 3].map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <>
                <ListItem
                  disablePadding
                  key={value}
                  secondaryAction={
                    <IconButton aria-label="comments" edge="end">
                      <Comment />
                    </IconButton>
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Checkbox />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`List item... ${value + 1}`}
                      secondary="Supporting text..." />
                  </ListItemButton>
                </ListItem>
                <VSpacer size="5" />
              </>
            );
          })}
        </Stack>
      </BottomSheet>
    </>
  );
}
