import DetailSection from "@/components/shared/DetailsSections";
import { ApiUser } from "@api/interfaces";
import { Grid, Stack } from "@mui/material";

const AdminDetails = ({ admin }: { admin: ApiUser }) => {
  return (
    <Grid
      alignItems="start"
      container
      direction="row"
      spacing={4}
    >
      <Grid item md={4}>
        <Stack>
          <DetailSection
            details={`${admin.firstName} ${admin.lastName}`}
            label="Name"
            testID="admin-name"
          />
          <DetailSection
            details={admin.email}
            label="Email"
            testID="admin-email"
          />
          <DetailSection
            details={admin.telephone}
            label="Phone"
            testID="admin-phone"
          />
        </Stack>
      </Grid>
      <Grid item md={4}>
        <Stack>
          <DetailSection
            details={admin.address1}
            label="Address1"
            testID="admin-address1"
          />
          <DetailSection
            details={admin.address2}
            label="Address2"
            testID="admin-address2"
          />
          <DetailSection
            details={admin.city}
            label="City"
            testID="admin-city"
          />
        </Stack>
      </Grid>
      <Grid item md={4}>
        <Stack>
          <DetailSection
            details={admin.state}
            label="State"
            testID="admin-state"
          />
          <DetailSection
            details={admin.postal}
            label="Zip"
            testID="admin-zip"
          />
          <DetailSection
            details={new Date(admin.updatedAt).toLocaleDateString()}
            label="Updated"
            testID="admin-updated"
          />
        </Stack>
      </Grid>
    </Grid>
  )
};

export default AdminDetails;
