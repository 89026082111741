import { UserType } from '../enums';

export function userIsFarmer <T extends { userType: UserType }> (user?: T) {
  if (!user) {
    return false;
  }
  return user.userType === UserType.Farmer;
}

export function userIsInternal <T extends { userType: UserType }> (user?: T) {
  if (!user) {
    return false;
  }
  return user.userType === UserType.Internal;
}

export function userIsRetailer <T extends { userType: UserType }> (user?: T) {
  if (!user) {
    return false;
  }
  return user.userType === UserType.RetailerAdmin || user.userType === UserType.SalesPerson;
}
