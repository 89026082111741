import { Switch, Text, VSpacer } from "@/components/DesignSystem";
import { useFeatureFlags } from "@/hooks/useFeatureFlag";
import { Container, Stack } from "@mui/material";

const FeatureFlags = () => {
  const { flags, toggleFlag } = useFeatureFlags();
  return (
    <Container maxWidth='lg'>
      <VSpacer size='10' />
      <Text category="title-large">
        Feature Flagging
      </Text>
      <VSpacer size='5' />
      <Stack direction='column'>
        {flags.map((flag) => (
          <Switch
            checked={flag.enabled}
            key={flag.id}
            onChangeChecked={() => toggleFlag(flag.name)}
            testID={flag.name}
          >
            {flag.name}
          </Switch>
        ))}
      </Stack>
    </Container>
  )
}

export default FeatureFlags;
