import { Switch } from '@/components/DesignSystem';
import { ConfirmationDialog } from '@/constants/constant';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';
import { Routes } from '@/constants/Routes';
import { useEditRetailer } from '@/hooks/useHierarchyOfRetailers';
import { ApiRetailer } from '@api/interfaces';
import { Card, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';
import { IDTypography, LocationMemberTypography, NameTypography } from './CustomStyledComponents';

const RetailerOverviewCard = ({ retailer }: { retailer: ApiRetailer }) => {
  const navigate = useNavigate();
  const [changeIsActive, setChangeIsActive] = useState(false);
  const detailsNavigator = () => {
    navigate(`${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/${retailer?.id}`, {
      state: { retailer },
    });
  };

  const { editRetailer, isLoading } = useEditRetailer(retailer?.id, setChangeIsActive);

  return (
    <Card
      onClick={detailsNavigator}
      sx={{
        cursor: 'pointer',
        marginBottom: '20px',
        padding: '20px',
      }}
    >
      <Stack
        alignItems={'center'}
        direction='row'
        justifyContent={'space-between'}
      >
        <Stack spacing={0.5}>
        <IDTypography>ID: {retailer?.id}</IDTypography>
        <NameTypography>{retailer?.name}</NameTypography>
        <LocationMemberTypography>
          {stringifyCount(retailer?.locationDetails?.length, 'location', 'locations')} •{' '}
          {stringifyCount(retailer?.memberDetails?.length, 'member', 'members')}
        </LocationMemberTypography>
        </Stack>
        <Switch
          checked={retailer?.isActive}
          label={retailer?.isActive ? 'Active' : 'Inactive'}
          labelPlacement='start'
          onClick={(e) => {
            e.stopPropagation();
            setChangeIsActive(true);
          }}
          sx={{ marginRight: '0px' }}
          testID='active-toggle'
        />
      </Stack>
      {changeIsActive && (
        <ConfirmDialog
          isLoading={isLoading}
          isOpen={changeIsActive}
          message={
            retailer?.isActive
              ? ConfirmationDialog.inActivateRetailerMessage
              : ConfirmationDialog.activateRetailerMessage
          }
          onClose={() => setChangeIsActive(false)}
          onConfirm={() => {
            editRetailer({ isActive: !retailer?.isActive });
          }}
          title={
            retailer?.isActive
              ? ConfirmationDialog.inActivateRetailerTitle
              : ConfirmationDialog.activateRetailerTitle
          }
        />
      )}
    </Card>
  );
};

export default RetailerOverviewCard;
