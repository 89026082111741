import { Button, Input, Text, VSpacer } from '@/components/DesignSystem';
import { useAddRetailer, useEditRetailer } from '@/hooks/useHierarchyOfRetailers';
import { ApiRetailer } from '@api/interfaces';
import { Alert, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { getObjectChanges, trimValue } from '@shared/utilities';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

type InitialValue = Omit<
  ApiRetailer,
  'id' | 'connectAccountId' | 'createdAt' | 'updatedAt' | 'locationDetails' | 'memberDetails'
> & {
  connectAccountId?: string | null;
};

type RetailerFormDialogProps = {
  isAddRetailerOpen: boolean;
  setIsAddRetailerOpen: (isAddRetailerOpen: boolean) => void;
  initialValue?: InitialValue;
};
const RetailerFormDialog = ({
  isAddRetailerOpen,
  initialValue = {
    connectAccountId: '',
    isActive: true,
    isFeatured: false,
    name: '',
  },
  setIsAddRetailerOpen,
}: RetailerFormDialogProps) => {

  const [addOrEditRetailer, setAddOrEditRetailer] = useState(initialValue);
  const [error, setError] = useState('');

  const { id } = useParams();

  const isEditDisabled = Object.entries(addOrEditRetailer).every(
    ([key, value]: [string, string | boolean | Date | undefined | null]) =>
      initialValue[key as keyof InitialValue] === value,
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'name') {
      setError('');
    }
    setAddOrEditRetailer({
      ...addOrEditRetailer,
      [e.target.name]: e.target.value.trimStart(),
    });
  };

  const onClose = () => {
    setIsAddRetailerOpen(false);
  };

  const { addRetailer, isLoading } = useAddRetailer(setIsAddRetailerOpen, setError);
  const { editRetailer, isLoading: isEditLoading } = useEditRetailer(
    id ?? '',
    setIsAddRetailerOpen,
    setError,
  );

  const onSave = () => {

    const trimmedRetailerData = Object.fromEntries(
      Object.entries(addOrEditRetailer).map(([key, nestedValue]) =>
        [key, trimValue(nestedValue)]),
    )

    const changedRetailerData = getObjectChanges(initialValue, trimmedRetailerData);

    if (id) {
      return editRetailer(changedRetailerData);
    }

    addRetailer((
      {
        ...trimmedRetailerData,
        name: trimmedRetailerData?.name?.toString() ?? '',
      }
    ));
  };

  return (
    <Dialog fullWidth onClose={onClose} open={isAddRetailerOpen}>
      <DialogContent>
        <Stack spacing={3}>
      {!!error && (
        <Alert color='error' icon={false}>{error}</Alert>
      )}
          <Text category='h6'>Add Retailer</Text>
          <Input
            label='Retailer Name'
            name='name'
            onChange={onInputChange}
            required
            testID='hierarchy-retailer-name'
            value={addOrEditRetailer.name}
          />
          <Stack spacing={3}>
            <Text category='h6'>Payment</Text>
            <Input
              label='Stripe Connect Account ID'
              name='connectAccountId'
              onChange={onInputChange}
              testID='hierarchy-retailer-connect-id'
              value={addOrEditRetailer?.connectAccountId}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <VSpacer size='8' />
      <DialogActions>
        <Button
          disabled={isLoading || isEditLoading}
          onClick={onClose}
          style={{
            color: 'white',
          }}
          testID='hierarchy-retailer-cancel'
          variant='text'
        >
          Cancel
        </Button>
        <Button
          disabled={!addOrEditRetailer?.name
            || isEditDisabled || isLoading || isEditLoading || !!error}
          loading={isLoading || isEditLoading}
          onClick={onSave}
          testID='hierarchy-retailer-save'
        >
          Save
        </Button>
      </DialogActions>
      <VSpacer size='5' />
    </Dialog>
  );
};

export default RetailerFormDialog;
