import { Routes } from '@/constants/Routes';
import FarmerForgotPasswordForm from '@/pages/Auth/FarmerForgotPasswordForm';
import { LoginPage } from '@/pages/Auth/LoginPage';
import SetPasswordForm from '@/pages/Auth/SetPasswordForm';
import { GetAppPage } from '@/views/GetAppPage';
import { LoginLayout, PasswordLayout } from '@/views/Layouts';
import { UnderConstruction } from '@/views/UnderConstruction';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";

export const UnderConstructionView = () => {
  return (
    <AppRoutes>
      <Route element={<LoginPage />} path={Routes.ADMIN_LOGIN} />
      <Route
        element={<PasswordLayout><SetPasswordForm /></PasswordLayout>}
        path={Routes.CREATE_PASSWORD}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm isResetPasswordForm /></PasswordLayout>}
        path={Routes.RESET_PASSWORD}
      />
      <Route
        element={<LoginLayout><FarmerForgotPasswordForm /></LoginLayout>}
        path={Routes.FORGOT_PASSWORD}
      />
      <Route element={<UnderConstruction />} path={Routes.UNDER_CONSTRUCTION} />
      <Route element={<GetAppPage />} path={Routes.GET_APP} />
      <Route element={<Navigate to={Routes.UNDER_CONSTRUCTION} />} path={"*"} />
    </AppRoutes>
  );
}
