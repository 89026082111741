import { Button, FullscreenDialog, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { StripeBadge } from '@/components/shared/StripeBadge';
import { AppConfig } from '@/constants/AppConfig';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import PaymentForm from '@/pages/Payment/PaymentForm';
import SubmitFarmerPaymentButton from '@/pages/Payment/SubmitFarmerPaymentButton';
import { Totals } from '@/pages/ReviewSelectOffers/ReviewSelectOffers';
import { ApiPricingRequest } from '@api/interfaces';
import { Box, CircularProgress, Divider, Stack, Theme, useTheme } from '@mui/material';
import { AllowedPaymentMethodType, PricingRequestPaymentStatus } from '@shared/enums';
import { formatCurrency, validateEmail } from '@shared/utilities';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React, { useState } from "react";

const stripePromise = loadStripe(AppConfig.stripePublishableKey);

interface FarmerPaymentModalProps {
  onClose: () => void,
  onSuccess: (status: PricingRequestPaymentStatus) => void,
  paymentMethod: AllowedPaymentMethodType,
  productRequest: ApiPricingRequest,
  totals: Totals,
}

export const getStripeAppearanceOptions = (theme: Theme): StripeElementsOptions['appearance'] => ({
  rules: {
    '.Error': {
      marginBottom: '10px',
    },
    '.Input': {
      marginBottom: '10px',
    },
    '.Label': {
      paddingBottom: '6px',
    },
    '.Tab': {
      marginBottom: '10px',
    },
    '.Tab--selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '.Tab--selected:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  theme: theme.palette.mode === 'dark' ? 'night' : undefined,
  variables: {
    borderRadius: '6px',
    colorDanger: 'error',
    colorIconTab: theme.palette.text.primary,
    colorIconTabSelected: theme.palette.primary.contrastText,
    colorPrimary: theme.palette.primary.main,
    colorText: theme.palette.text.primary,
    colorTextSecondary: theme.palette.text.primary,
    fontFamily: "'Roboto', sans-serif;",
    fontWeightNormal: '400',
    spacingGridRow: '0px',
  },
});

export const FarmerPaymentModal = ({
  onClose,
  onSuccess,
  paymentMethod,
  productRequest,
  totals,
}: FarmerPaymentModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const { isMobile } = useMediaQuery();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const isFormComplete = (
    validateEmail(email)
    && isAddressComplete
    && isPaymentComplete
  );
  const totalToChargeCents = Math.round(
    (totals.subtotal + totals.shippingCost + totals.stripeFee) * 100,
  );
  const options: StripeElementsOptions = {
    appearance: getStripeAppearanceOptions(theme),
    amount: totalToChargeCents,
    currency: 'usd',
    loader: 'never',
    mode: 'payment',
    paymentMethodCreation: 'manual',
    paymentMethodTypes: paymentMethod === AllowedPaymentMethodType.CreditCard
      ? [paymentMethod, AllowedPaymentMethodType.Link] : [paymentMethod],
  };

  const renderBody = () => {
    return (
      <Box
        sx={{
          maxHeight: isMobile ? undefined : '490px',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Divider />
        <VSpacer size="4" />
        <Stack flexDirection="row" justifyContent="space-between">
          <Text category="body-medium">Product Request number {productRequest.publicId}</Text>
          <Text category="body-medium">
            Amount Due: {formatCurrency(totalToChargeCents / 100)}
          </Text>
        </Stack>
        <VSpacer size="4" />
        <Divider />
        <VSpacer size="8" />
        {isLoading &&
          <Box textAlign="center">
            <VSpacer size="12" />
            <CircularProgress size="100px" />
            <VSpacer size="12" />
          </Box>
        }
        <Box
          sx={{
            display: isLoading ? 'none' : undefined,
          }}
        >
          <PaymentForm
            onAddressUpdate={setIsAddressComplete}
            onEmailUpdate={(email) => setEmail(email)}
            onLoaded={() => setIsLoading(false)}
            onPaymentUpdate={setIsPaymentComplete}
            paymentType={paymentMethod}
            totals={totals}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Elements options={options} stripe={stripePromise}>
      <DesktopOnly>
        <Modal
          acceptButton={(props) => (
            <SubmitFarmerPaymentButton
              email={email}
              isFormComplete={isFormComplete}
              onSuccess={onSuccess}
              productRequest={productRequest}
              selectedPaymentMethod={paymentMethod}
              {...props}
            />
          )}
          cancelButton={(props) => (
            <Button onClick={onClose} {...props}>
              Cancel
            </Button>
          )}
          headerAccessoryRight={<StripeBadge />}
          loading={isLoading}
          onClose={onClose}
          open
          testID="payment-modal"
          title="Payment"
        >
          {renderBody()}
        </Modal>
      </DesktopOnly>
      <MobileOnly>
        <FullscreenDialog
          actionButton={
            <SubmitFarmerPaymentButton
              email={email}
              isFormComplete={isFormComplete}
              onSuccess={onSuccess}
              productRequest={productRequest}
              selectedPaymentMethod={paymentMethod}
              testID="payment-modal-submit-button"
            />
          }
          onClose={onClose}
          open
          testID="payment-fullscreen-dialog"
          title="Payment"
        >
          {renderBody()}
        </FullscreenDialog>
      </MobileOnly>
    </Elements>
  );
}
