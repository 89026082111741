import {
  Button,
  Input,
  NumericInput,
  Modal,
  Radio,
  VSpacer,
} from '@/components/DesignSystem';
import { FriendlyDiscountType } from '@/constants/FriendlyDiscountType';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Percent from '@mui/icons-material/Percent';
import { RadioGroup, Stack } from "@mui/material";
import { DiscountType } from '@shared/enums';
import { useState } from 'react';

interface AddDiscountToOfferModalProps {
  isVisible: boolean,
  onApplyDiscount: (name: string, amount: number, type: DiscountType) => void,
  onClose: () => void,
}

const AddDiscountToOfferModal = ({
  isVisible,
  onApplyDiscount,
  onClose,
}: AddDiscountToOfferModalProps) => {

  const [discountName, setDiscountName] = useState("");
  const [discountAmount, setDiscountAmount] = useState<number | undefined>();
  const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.Dollar);

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          disabled={!discountAmount}
          onClick={() => onApplyDiscount(discountName, discountAmount || 0, discountType)}
          {...props}
        >
          Apply Discount
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          onClick={onClose}
          {...props}
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open={isVisible}
      subline="The farmer will receive this discount if they select this offer in full."
      testID="offer-add-discount-modal"
      title="Add Discount to Offer">
      <Stack>
        <Input
          label="Discount name"
          onChangeText={setDiscountName}
          testID="add-discount-offer-modal-name"
          value={discountName}
        />
        <VSpacer size="8" />
        <RadioGroup
          name="discount-type"
          onChange={(e) => setDiscountType(e.target.value as DiscountType)}
          row
          value={discountType}>
          <Radio
            testID="add-discount-offer-modal-type-radio"
            value={DiscountType.Dollar}
          >
            {FriendlyDiscountType[DiscountType.Dollar]}
          </Radio>
          <Radio
            testID="add-discount-offer-modal-percentage"
            value={DiscountType.Percentage}
          >
            {FriendlyDiscountType[DiscountType.Percentage]}
          </Radio>
        </RadioGroup>
        <VSpacer size="8" />
        <NumericInput
          label="Discount amount"
          leadingIcon={(props) => (
            discountType === DiscountType.Dollar
              ? (<AttachMoney {...props} />)
              : (<Percent {...props} />)
          )}
          onChangeNumber={setDiscountAmount}
          required
          testID="add-discount-offer-modal-amount"
          value={discountAmount} />
      </Stack>
    </Modal>
  );
}

export default AddDiscountToOfferModal;
