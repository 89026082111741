import { useRef, useState } from 'react';
import LocationForm from './LocationForm';
import { useParams } from 'react-router-dom';
import { useUpdateLocation } from '@/hooks/useRetailerLocations';
import { getObjectChanges, sanitizedFileName, trimValue } from '@shared/utilities';
import { UpdateRetailerLocation } from '@api/interfaces';
import { validateImage } from './AddLocation';
import { errorMessages } from '@/constants/constant';

const EditLocation = ({
  location,
  onClose,
  show,
}: {
  location: UpdateRetailerLocation & { id?: string } | undefined;
  onClose: () => void;
  show: boolean;
}) => {
  const [addOrEditLocation, setAddOrEditLocation] = useState(location);
  const [error, setError] = useState({
    imageError: '',
    serverError: '',
  });
  const [selectedImage, setSelectedImage] = useState<Blob | MediaSource>();
  const [image, setImage] = useState({
    name: '',
    base64: '',
  });

  const { locationId } = useParams<{ id: string; locationId: string }>();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const areAnyInputsEmpty = (addOrEditLocation && Object.entries(addOrEditLocation).some(
    ([key, value]: [key: string, value: string | boolean | null | undefined]) => {
      if (
        key === 'address2' ||
        key === 'connectAccountId' ||
        key === 'image' ||
        key === 'retailerId'
      )
        return false;
      if (typeof value === 'string') {
        return value?.trim() === '';
      }
    },
  )) || false;

  const isEditDisabled = () => {
    if (image?.name) return false;
    if (addOrEditLocation?.postal && addOrEditLocation?.postal?.length < 5) return true
    if (!location) return true;

    const trimmedLocationData = Object.fromEntries(
      Object.entries(addOrEditLocation ?? {}).map(([key, nestedValue]) => 
        [key, trimValue(nestedValue)]),
    );
    return JSON.stringify(trimmedLocationData) === JSON.stringify(location);
  }

  const onUploadAreaClick = () => {
    if (fileUploadRef.current) {
      (fileUploadRef.current as HTMLInputElement).click();
    }
  };

  const onInputChange = (value: string, name: string) => {
    if (name === 'name') {
      setError({
        ...error,
        serverError: '',
      });
    }
    setAddOrEditLocation((prevState) => ({
      ...prevState,
      id: locationId ?? '',
      [name]: value?.trimStart() || '',
      ...(name === 'connectAccountId' && value.trim() === '' 
        ? { connectAccountId: null } : {}),
    }));
  };

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError({
      ...error,
      imageError: '',
    });
    setImage({
      name: '',
      base64: '',
    });
    const imageFile = event.target.files?.[0];

    if (imageFile) {
      if (!imageFile?.type || imageFile?.type !== 'image/png') {
        return setError({
          ...error,
          imageError: errorMessages?.invalidImageType ?? '',
        }); 
      }
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const imageError = validateImage(image, imageFile);
          if (imageError) {
            return setError({
              ...error,
              imageError: imageError ?? '',
            });
          }
          setError({
            ...error,
            imageError: '',
          });
          setImage({
            name: sanitizedFileName(imageFile.name),
            base64: reader.result as string,
          });
          setSelectedImage(imageFile as Blob);

        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const { updateLocation, isLoading } = useUpdateLocation(locationId ?? '', onClose, setError);

  const onSave = () => {
    const trimmedLocationData = Object.fromEntries(
      Object.entries(addOrEditLocation ?? {}).map(([key, nestedValue]) => 
        [key, trimValue(nestedValue)]),
    );

    const changedLocationData = getObjectChanges(location, trimmedLocationData);

    updateLocation({
      ...changedLocationData,
      ...(image.name ? { image: `${sanitizedFileName(image.name)}+${image.base64}` } : {}),
    });
  };

  const onRemove = () => {
    setAddOrEditLocation((prevState) => ({
      ...prevState,
      image: '',
      deleteImage: true,
    }));
    setSelectedImage(undefined);
    setImage({
      name: '',
      base64: '',
    });
    if (fileUploadRef.current) {
      (fileUploadRef.current as HTMLInputElement).value = '';
    }
  };

  return (
    <>
      <LocationForm
        addOrEditLocation={{
          address1: addOrEditLocation?.address1 ?? '',
          address2: addOrEditLocation?.address2 ?? '',
          city: addOrEditLocation?.city ?? '',
          connectAccountId: addOrEditLocation?.connectAccountId ?? '',
          image: addOrEditLocation?.image ?? '',
          name: addOrEditLocation?.name ?? '',
          postal: addOrEditLocation?.postal ?? '',
        }}
        areAnyInputsEmpty={areAnyInputsEmpty}
        error={error}
        fileUploadRef={fileUploadRef}
        image={image}
        isAddLocationLoading={isLoading}
        isEdit={true}
        isEditDisabled={isEditDisabled()}
        onClose={onClose}
        onImageChange={onImageChange}
        onInputChange={onInputChange}
        onRemove={onRemove}
        onSave={onSave}
        onUploadAreaClick={onUploadAreaClick}
        selectedImage={selectedImage}
        show={show}
        showMemberForm={false}
      />
    </>
  );
};

export default EditLocation;
