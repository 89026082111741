import { DataPoint, Dialog, Heading, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { CostSummary } from '@/components/shared/PriceRequestOrder/CostSummary';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { StringUtility } from '@/utilities/String';
import { ApiOffer, ApiPricingRequest, ApiPricingRequestRetailer } from '@api/interfaces';
import { Mail } from '@mui/icons-material';
import { Box, Divider, Stack } from '@mui/material';
import { FulfillmentMethod } from '@shared/enums';
import { formatPhoneNumber, pluralize } from '@shared/utilities';

const styles: SXStyles = {
  dataPointContainer: {
    paddingY: 0,
  },
  lineBreaks: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  note: {
    border: '1px solid',
    borderRadius: 2,
    padding: '16px 16px 16px 20px',
  },
  mobilePadding: {
    paddingX: '16px',
  },
};

interface OfferDetailsProps {
  offer: ApiOffer,
  onClose: () => void,
  priceRequest: ApiPricingRequest,
  retailer: ApiPricingRequestRetailer,
  testID: string,
}

export const OfferDetails = ({
  offer,
  onClose,
  priceRequest,
  retailer,
  testID,
}: OfferDetailsProps) => {
  const { isMobile } = useMediaQuery();

  const formattedDeliveryAddress = StringUtility.formatAddress(
    priceRequest.deliveryAddress1,
    priceRequest.deliveryAddress2,
    priceRequest.deliveryCity,
    priceRequest.deliveryState,
    priceRequest.deliveryPostalCode,
  );
  const retailerContactInfo = [
    retailer.name,
    ...(retailer.retailerSalesperson?.email ? [retailer.retailerSalesperson?.email] : []),
    ...(retailer.retailerSalesperson?.telephone
      ? [formatPhoneNumber(retailer.retailerSalesperson?.telephone, 'paren')]
      : []),
  ].join('\n');

  const RetailerNote = (
    <Box sx={isMobile ? styles.mobilePadding : undefined}>
      <Stack sx={styles.note}>
        <Heading
          leftAccessory={<Mail color="info" fontSize="inherit" />}
          level="4"
          testID={`${testID}-note-from-retailer-header`}
          title="NOTE FROM RETAILER"
        />
        <VSpacer size="4" />
        <Text sx={styles.lineBreaks} testID={`${testID}-note-from-retailer-text`}>
          {offer.note}
        </Text>
      </Stack>
    </Box>
  );

  const ContactDetails = (
    <Box sx={isMobile ? styles.mobilePadding : undefined}>
      <DataPoint
        containerStyle={styles.dataPointContainer}
        label="Fulfillment"
        spacing="none"
        stacked
        testID={`${testID}-fulfillment`}
      >
        {FriendlyFulfillmentMethod[offer.fulfillmentMethod]}
      </DataPoint>
      {offer.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
        <>
          <VSpacer size="7" />
          <DataPoint
            containerStyle={styles.dataPointContainer}
            label="Delivery address"
            multiline
            spacing="none"
            stacked
            testID={`${testID}-delivery-address`}
          >
            {formattedDeliveryAddress || '-'}
          </DataPoint>
        </>
      )}
      <VSpacer size="7" />
      <DataPoint
        containerStyle={styles.dataPointContainer}
        label="Retailer contact information"
        multiline
        spacing="none"
        stacked
        testID={`${testID}-retailer-contact-info`}
      >
        {retailerContactInfo}
      </DataPoint>
    </Box>
  );

  const Content = (
    <>
      <Text sx={isMobile ? styles.mobilePadding : undefined}>
        {pluralize(offer.products?.length ?? 0, 'product')}
      </Text>
      <VSpacer size="5" />
      <Divider />
      <VSpacer size="6" />
      <CostSummary offer={offer} priceRequest={priceRequest} />
      <VSpacer size="7" />
      <Divider />
      {!!offer.note && (
        <>
          <VSpacer size="7" />
          {RetailerNote}
        </>
      )}
      <VSpacer size="7" />
      {ContactDetails}
    </>
  );

  return (
    <>
      <DesktopOnly>
        <Dialog
          onClose={onClose}
          open
          testID={testID}
          title="Offer details"
        >
          {Content}
        </Dialog>
      </DesktopOnly>
      <MobileOnly>
        <ModalBottomSheet
          onClose={onClose}
          onOpen={() => null}
          open
          showPuller={false}
          testID={testID}
          title="Offer details"
        >
          {Content}
        </ModalBottomSheet>
      </MobileOnly>
    </>
  );
}
