import {
  Button,
  Checkbox,
  Chip,
  HSpacer,
  Input,
  MenuItem,
  Radio,
  Text,
} from "@/components/DesignSystem";
import { Filter, FilterSelection } from '@/components/DesignSystem/Toolbar/interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Check from '@mui/icons-material/Check';
import { Box, ButtonGroup, RadioGroup, Stack } from '@mui/material';
import { Fragment, memo, useCallback, useState } from "react";

const styles: SXStyles = {
  radio: {
    py: '4px',
  },
  checkbox: {
    p: '13px 16px 13px 0',
  },
} as const;

interface RawFilterSelectorProps {
  filter: Filter,
  onChange?: (selection: FilterSelection) => void,
  selection?: FilterSelection,
  testID: string,
}

const RawFilterSelector = ({
  filter,
  onChange,
  selection = new Set(),
  testID,
}: RawFilterSelectorProps) => {
  const maxItems = 20;
  const [search, setSearch] = useState('');

  const handleChange = useCallback((newSelection: FilterSelection) => {
    onChange?.(newSelection);
  }, [onChange]);

  switch (filter.selectionMethod) {
    case 'boolean':
    case 'chips':
      return (
        <Stack direction="row" flexWrap="wrap" pr="24px" rowGap="16px">
          {filter.options.map((option) => (
            <Fragment key={option.id}>
              <Chip
                icon={selection.has(option.id) ? <Check color="primary" /> : undefined}
                key={option.id}
                label={
                  <Text
                    category="label-large"
                  >
                    {option.label}
                  </Text>
                }
                onClick={() => {
                  const newSelection = new Set(selection);
                  if (newSelection.has(option.id)) {
                    newSelection.delete(option.id);
                  } else {
                    newSelection.add(option.id);
                  }
                  handleChange(newSelection);
                }}
                testID={`${testID}-${option.id}`}
                variant={selection.has(option.id) ? 'filled' : 'outlined'}
              />
              <HSpacer size="3"/>
            </Fragment>
          ))}
        </Stack>
      )
    case 'single-select':
      return (
        <Box pr="24px">
          <RadioGroup
            onChange={(event, value) => handleChange(new Set([value]))}
            value={selection.values().next().value ?? ''}
          >
            {filter.options.map((option) => (
              <Radio key={option.id} sx={styles.radio} testID={`${testID}-${option.id}`} value={option.id}>
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </Box>
      )
    case 'multi-select':
      return (
        <Stack>
          {filter.options.length > 20 && (
            <Input
              label="Search"
              onChangeText={setSearch}
              testID={`${testID}-search`}
              value={search}
            />
          )}
          {filter.options
            .filter(({ label }) => label.toLowerCase().includes(search.toLowerCase()))
            .slice(0, maxItems)
            .map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => {
                  const newSelection = new Set(selection);
                  if (selection.has(option.id)) {
                    newSelection.delete(option.id);
                  } else {
                    newSelection.add(option.id);
                  }
                  handleChange(newSelection);
                }}
                paddingLeft={0}
                sx={styles.checkbox}
                testID={`${testID}-${option.id}`}
                value={option.id}
              >
                <Checkbox
                  checked={selection.has(option.id)}
                  testID={`${testID}-${option.id}`}
                >
                  {option.label}
                </Checkbox>
              </MenuItem>
            ))}
        </Stack>
      )
    case 'segmented-button':
      return (
        <ButtonGroup>
          {filter.options.map((option) => (
            <Button
              aria-selected={selection.has(option.id)}
              key={option.id}
              onClick={() => handleChange(new Set([option.id]))}
              testID={`${testID}-${option.id}`}
            >
              {selection.has(option.id) && (
                <>
                  <Check/>
                  <HSpacer size="2"/>
                </>
              )}
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
      )
  }
}

export const FilterSelector = memo(RawFilterSelector);
