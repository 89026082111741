import { Button, MenuItem } from '@/components/DesignSystem';
import { Autocomplete, Menu, Stack, TextField } from '@mui/material';
import { State } from '@shared/enums';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import HighlightOff from '@mui/icons-material/HighlightOff';

export const MenuDemo = () => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [density, setDensity] = useState<0 | 2 | 4>(0);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>, 
    densityValue: 0 | 2 | 4 = 0,
  ) => {
    setDensity(densityValue);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        data-testid="test-menu"
        onClose={handleClose}
        open={open}
      >
        <MenuItem density={density} testID="list-item-1">
          List Item ...
        </MenuItem>
        <MenuItem density={density} testID="list-item-2">
          List Item ...
        </MenuItem>
        <MenuItem density={density} testID="list-item-3">
          List Item ...
        </MenuItem>
      </Menu>
      <DemoBlock label="Autocomplete">
        <Autocomplete
          clearIcon={<HighlightOff />}
          disablePortal
          options={Object.entries(State).map((s) => s[0])}
          renderInput={(params) => <TextField {...params} label="Label" />}
          sx={{ width: 300 }}
        />
      </DemoBlock>
      <DemoBlock label="No density">
        <Stack direction="row">
          <Button 
            onClick={(e) => handleClick(e)}
            testID="menu-1">
            Open Menu
          </Button>
        </Stack>
      </DemoBlock>
      <DemoBlock label="-2 Density">
        <Stack direction="row">
          <Button 
            onClick={(e) => handleClick(e, 2)}
            testID="menu-1">
            Open Menu
          </Button>
        </Stack>
      </DemoBlock>
      <DemoBlock label="-4 Density">
        <Stack direction="row">
          <Button 
            onClick={(e) => handleClick(e, 4)}
            testID="menu-1">
            Open Menu
          </Button>
        </Stack>
      </DemoBlock>
    </>
  );
}
