import { Stack, StackProps } from '@mui/material';

export const SpaceBetweenRow = (props: StackProps) => {
  const { children, ...stackProps } = props;
  return (
    <Stack direction='row' justifyContent='space-between' {...stackProps}>
      {children}
    </Stack>
  );
}
