import {
  BottomSheet,
  Button,
  Heading,
  IconButton,
  SideSheet,
  SideSheetProps,
  Text,
  TopAppBar,
  VSpacer,
} from "@/components/DesignSystem";

import { FilterSelector } from "@/components/DesignSystem/Toolbar/FilterSelector";
import {
  Filter,
  FilterSelection,
  FilterSelections,
} from "@/components/DesignSystem/Toolbar/interfaces";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Close from "@mui/icons-material/Close";
import { Box, Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";

const styles: SXStyles = {
  bottomSheetButtons: {
    height: '79px',
    width: '100%',
  },
}

interface FilterSheetProps extends Omit<SideSheetProps, 'onOpen' | 'onClose'> {
  filters: Filter[],
  onApply?: (selections: FilterSelections) => void,
  onClose: () => void,
  selections: FilterSelections,
}

export const FilterSheet = ({
  filters,
  onApply,
  onClose,
  open,
  selections,
  testID,
}: FilterSheetProps) => {

  const [
    currentSelections,
    setCurrentSelections,
  ] = useState<FilterSelections>(selections);

  useEffect(() => {
    setCurrentSelections(selections);
  }, [selections]);

  const handleSelectionChange = useCallback((filterId: string, selection: FilterSelection) => {
    const newSelections = _.cloneDeep(currentSelections);
    newSelections.set(filterId, selection);
    setCurrentSelections(newSelections);
  }, [currentSelections]);

  const handleClose = useCallback(() => {
    onClose?.();
    setCurrentSelections(selections);
  }, [onClose, selections]);

  const clearSelections = useCallback(() => {
    setCurrentSelections(new Map());
  }, []);

  const renderContent = () => (
    <Stack overflow="auto">
      {filters.map((filter, index) => (
        <Fragment key={filter.id}>
          {index > 0 && (
            <>
              <VSpacer size="8" />
              <Divider/>
              <VSpacer size="4" />
            </>
          )}
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box p="8px 24px">
                <Heading
                  level="3"
                  testID={`${testID}-section-header`}
                  title={filter.label}
                />
              </Box>
              {!filter.hideClearButton && (
                <Box pr="16px">
                  <Button
                    onClick={() => handleSelectionChange(filter.id, new Set())}
                    size="medium"
                    testID={`${testID}-${filter.id}-clear-button`}
                    variant="text"
                  >
                    Clear
                  </Button>
                </Box>
              )}
            </Stack>
            <VSpacer size="5"/>
            <Box px="24px">
              <FilterSelector
                filter={filter}
                onChange={(selection) => handleSelectionChange(filter.id, selection)}
                selection={currentSelections.get(filter.id)}
                testID={`${testID}-${filter.id}-selector`}
              />
            </Box>
          </Stack>
        </Fragment>
      ))}
      <VSpacer size="8" />
    </Stack>
  );

  const MobileFooter = () => (
    <>
      <Divider />
      <Stack
        alignItems="center"
        boxShadow="0 -4px 8px 3px rgba(0, 0, 0, 0.15), 0px -1px 3px rgba(0, 0, 0, 0.3)"
        direction="row"
        justifyContent="space-evenly"
        minHeight="79px"
      >
        <Button
          onClick={clearSelections}
          square
          sx={styles.bottomSheetButtons}
          testID={`${testID}-clear-button`}
          variant="text"
        >
          Clear
        </Button>
        <Divider flexItem orientation="vertical" />
        <Button
          onClick={() => {
            onApply?.(currentSelections);
            handleClose();
          }}
          square
          sx={styles.bottomSheetButtons}
          testID={`${testID}-apply-button`}
          variant="text"
        >
          Apply
        </Button>
      </Stack>
    </>
  );

  return (
    <>
      <DesktopOnly>
        <SideSheet
          actionButton={(props) => (
            <Button
              {...props}
              onClick={() => {
                onApply?.(currentSelections);
                handleClose();
              }}
              testID={`${testID}-apply-button`}
            >
              Apply
            </Button>
          )}
          hideScrollbar
          onClose={handleClose}
          onOpen={() => setCurrentSelections(selections)}
          open={open}
          secondaryActionButton={(props) => (
            <Button
              {...props}
              onClick={clearSelections}
              testID={`${testID}-clear-button`}
            >
              Clear
            </Button>
          )}
          testID={testID}
          title="Filters"
        >
          {renderContent()}
        </SideSheet>
      </DesktopOnly>
      <MobileOnly>
        <BottomSheet
          footer={<MobileFooter />}
          onClose={handleClose}
          onOpen={() => setCurrentSelections(selections)}
          open={open}
          showPuller={false}
          testID={testID}
        >
          <TopAppBar
            leftAccessory={
              <IconButton onClick={handleClose} testID={`${testID}-close-button`}>
                <Close />
              </IconButton>
            }
            testID={`${testID}-top-bar`}
          >
            <Stack direction="row" justifyContent="center" pr="44px">
              <Text category="title-large">
                Filters
              </Text>
            </Stack>
          </TopAppBar>
          <VSpacer size="11" />
          {renderContent()}
        </BottomSheet>
      </MobileOnly>
    </>
  );
}
