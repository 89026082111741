import {
  Button,
  CalculateQuantityModal,
  HSpacer,
  NumericInput,
  ProgressLoader,
  Text,
  VSpacer,
} from '@/components/DesignSystem'
import { MobileOnly } from '@/components/shared/MobileOnly';
import { QueryKeys } from '@/constants/QueryKeys';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { ProductApi } from '@/utilities/api/ProductApi';
import Calculate from '@mui/icons-material/Calculate';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Stack,
} from '@mui/material'
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import React, { useEffect, useState } from 'react';
import { ProductUomSelect } from '@/components/shared/Select/ProductUomSelect';
import { PackageType, ProductUom, ProductUomEnums, parseEnums } from '@shared/enums';
import { PackageTypeSelect } from '@/components/shared/Select/PackageTypeSelect';
import { ExpandMore } from '@mui/icons-material';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { PricingRequestCartEndpoint } from '@api/endpoints';
import { getProductImageUrl } from '@/utilities/Product';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Routes } from '@/constants/Routes';
import { ProductModal } from '@/pages/Shop/ProductModal';
import { Carousel } from '@/components/DesignSystem/Carousel/Carousel';
import Check from '@mui/icons-material/Check';
import { useStorefront } from '@/hooks/useStorefront';
import { ApiProduct } from '@api/interfaces';

export interface SavedProduct {
  alternateTo?: string | null,
  companionToProductId?: string | null,
  image?: string | null,
  name: string,
  package?: PackageType | null,
  parentProductName?: string | null,
  productId: string,
  quantity?: number,
  uom?: ProductUom,
}

const styles: SXStyles = {
  container: {
    paddingTop: '18px',
    paddingLeft: { sm: '24px' },
    paddingRight: { sm: '24px' },
  },
  image: {
    borderRadius: '12px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: { xs: '343px', sm: '360px', md: '390px' },
    minWidth: { xs: '343px', sm: '360px', md: '390px' },
    width: 'auto',
  },
} as const;

export const ProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { createShoppingCart, isCartLoading, shoppingCart } = useShoppingCart();
  const { storefront } = useStorefront();
  const [expanded, setExpanded] = useState(false);
  const [showProductAddedModal, setShowProductAddedModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ApiProduct | null>(null);
  const { isMobile, isTablet } = useMediaQuery();
  const [showCalculateQuantityModal, setShowCalculateQuantityModal] = useState(false);
  const { user } = useAuthentication();

  const { data: product, isFetching } = useQuery([QueryKeys.GET_PRODUCT], async () => {
    return (await ProductApi.get(params.id as string));
  });

  const { data: alternativeProducts } = useQuery(
    [QueryKeys.GET_PRODUCT_ALTERNATIVES, params.id],
    async () => (
      ProductApi.getAlternatives(
        params.id as string,
        {
          retailerId: storefront?.retailerId ? [storefront.retailerId] : undefined,
          sort: 'rank',
        },
      )
    ),
    { enabled: !!storefront?.retailerId },
  );

  const { data: companionProducts } = useQuery(
    [QueryKeys.GET_PRODUCT_COMPANIONS, params.id],
    async () => (
      ProductApi.getCompanions(
        params.id as string,
        {
          retailerId: storefront?.retailerId ? [storefront.retailerId] : undefined,
          sort: 'rank',
        },
      )
    ),
    { enabled: !!storefront?.retailerId },
  );

  const alternatives = alternativeProducts?.data.map(({ productDetails }) => productDetails);
  const companions = companionProducts?.data.map(({ productDetails }) => productDetails);

  const imageUrl = getProductImageUrl(product?.image);

  const [orderedProduct, setOrderedProduct] = useState<SavedProduct>({
    image: product?.image,
    name: product?.name || '',
    productId: product?.id || '',
    quantity: undefined,
    uom: undefined,
  });

  useEffect(() => {
    if (product) {
      setOrderedProduct({
        ...orderedProduct,
        image: product.image,
        name: product.name ?? '',
        productId: product.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const isValid = [orderedProduct.quantity, orderedProduct.uom].every(Boolean);
  const isProductAdded = shoppingCart?.some(({ productId }) => (
    productId === orderedProduct.productId
  ));
  const selectedProductIsAlternate = !!alternatives?.some(({ id }) => (
    id === selectedProduct?.id
  ));
  const selectedProductIsCompanion = !!companions?.some(({ id }) => (
    id === selectedProduct?.id
  ));

  const handleCreateShoppingCart = async () => {
    if (isValid) {
      await createShoppingCart({
        createRequest: orderedProduct as PricingRequestCartEndpoint.Create.Request,
        image: orderedProduct.image,
        user,
      });
      setSelectedProduct(product ?? null);
      setShowProductAddedModal(true);
    }
  }

  const getAllowedUoms = () => {
    return (product?.purchaseUom ?? []).map((uom: string) => (
      parseEnums(ProductUomEnums, uom)
    ));
  };

  const backButton = (
    <Button
      onClick={() => navigate(Routes.SHOP, { state })}
      sx={{ display: 'flex', align: 'center' }}
      testID="back-to-shop-button"
      variant="text"
    >
      <KeyboardBackspaceIcon sx={{ marginRight: '5px' }} />
      Back to shop
    </Button>
  );

  const productTitle = (
    <Stack alignItems="flex-start" spacing={1}>
      <Text category="title-large">{product?.name}</Text>
      {!!product?.ingredientNames?.length && (
        <Text breakWord category="body-small">
          Active ingredients: {product?.ingredientNames.map((name) => name).join(', ')}
        </Text>
      )}
    </Stack>
  );

  const productDetails = (
    <Accordion
      disableGutters
      expanded={expanded}
      sx={{
        borderRadius: '12px',
        '&::before': {
          display: 'none',
        },
      }}
      variant="outlined"
    >
      <AccordionSummary expandIcon={<ExpandMore />} onClick={() => setExpanded(!expanded)}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
        >
          <Text category="body-large">
            Product Details
          </Text>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Text category="body-medium">
          {product?.sellSheet || 'No product details available'}
        </Text>
      </AccordionDetails>
    </Accordion>
  );

  const productInfo = (
    <Stack flex={1} mt={0}>
      <DesktopOnly>
        {productTitle}
      </DesktopOnly>
      {!isMobile && <VSpacer size="7" />}
      <Stack direction="row" justifyContent="space-between">
        <NumericInput
          label="Quantity"
          maxValue={9999999.99}
          onChangeNumber={(quantity) =>
            setOrderedProduct({ ...orderedProduct, quantity })
          }
          testID="product-quantity-input"
          value={orderedProduct?.quantity ?? undefined}
          variant="outlined"
        />
        <HSpacer size="5" />
        <ProductUomSelect
          allowedUoms={getAllowedUoms()}
          onChangeUoM={(uom) => {
            setOrderedProduct({ ...orderedProduct, uom });
          }}
          testID="product-uom-input"
          value={orderedProduct.uom ?? undefined}
          variant="outlined"
        />
      </Stack>
      <VSpacer size="7" />
      <PackageTypeSelect
        allowedPackageTypes={product?.packageSize || []}
        onChangePackageType={(val) => setOrderedProduct({ ...orderedProduct, package: val })}
        packageType={orderedProduct.package || ''}
        testID="package-type-select"
        variant="outlined"
      />
      <Stack alignItems="flex-start">
        <Button
          color="secondary"
          onClick={() => setShowCalculateQuantityModal(true)}
          startIcon={<Calculate />}
          testID="calculate-quantity-button"
          variant="text"
        >
          Calculate quantity
        </Button>
      </Stack>
      <VSpacer size="7" />
      <Stack direction="row" justifyContent="center">
        <Button
          disabled={!isValid && !isProductAdded}
          onClick={isProductAdded ? undefined : handleCreateShoppingCart}
          sx={{ pointerEvents: isProductAdded ? 'none' : 'auto' }}
          testID="add-product-button"
          variant={isProductAdded ? 'outlined' : 'contained'}
        >
          {isProductAdded ? (
            <>
              <Check sx={{ width: '18px', height: '18px' }} />
              <HSpacer size="3" />
              In cart
            </>
          ) : 'Add to cart'}
        </Button>
      </Stack>
      <VSpacer size="7" />
      <Text category="body-small">
        Add the product to your cart and submit the product request to receive pricing offer.
      </Text>
      <VSpacer size="7" />
      {productDetails}
      {isMobile && <VSpacer size="5" />}
    </Stack>
  );

  const productImage = (
    <Stack alignItems="center" flex={1} justifyContent="flex-start" width="100%">
      <Box
        alt={`Image of ${product?.name}`}
        component="img"
        src={imageUrl}
        sx={styles.image}
      />
    </Stack>
  );

  const relatedProductCarousels = (
    <>
      {!!alternatives?.length && (
        <>
          <Carousel
            onQuickAdd={(productId) => {
              const matchedProduct = alternatives?.find(({ id }) => id === productId);
              if (matchedProduct) {
                setSelectedProduct(matchedProduct);
                setShowProductAddedModal(true);
              }
            }}
            products={alternatives}
            testID="alternative-products-carousel"
            title="Alternative products"
          />
          <VSpacer size="9" />
        </>
      )}
      {!!companions?.length && (
        <Carousel
          onQuickAdd={(productId) => {
            const matchedProduct = companions?.find(({ id }) => id === productId);
            if (matchedProduct) {
              setSelectedProduct(matchedProduct);
              setShowProductAddedModal(true);
            }
          }}
          products={companions}
          testID="companion-products-carousel"
          title="Companion products"
        />
      )}
    </>
  );

  return (
    <>
      <Container disableGutters={isTablet} maxWidth="md" sx={styles.container}>
        {backButton}
        <VSpacer size="3" />
        {isFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : (
          <>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={3}>
              <MobileOnly>
                {productTitle}
              </MobileOnly>
              {productImage}
              {productInfo}
            </Stack>
            {!isMobile && <VSpacer size="5" />}
            <VSpacer size="7" />
            <Divider />
            <VSpacer size="7" />
            {relatedProductCarousels}
          </>
        )}
      </Container>
      {showProductAddedModal && !!selectedProduct && !!product && (
        <ProductModal
          isAlternate={selectedProductIsAlternate}
          isCompanion={selectedProductIsCompanion}
          onClose={() => {
            setShowProductAddedModal(false);
            setSelectedProduct(null);
          }}
          open={!isCartLoading}
          parentProductName={(selectedProductIsAlternate || selectedProductIsCompanion)
            ? product.name ?? undefined
            : undefined
          }
          productId={selectedProduct.id}
        />
      )}
      {showCalculateQuantityModal && (
        <CalculateQuantityModal
          allowedUoms={getAllowedUoms()}
          onApplyAndClose={(quantity, uom) => {
            setOrderedProduct({ ...orderedProduct, quantity, uom });
            setShowCalculateQuantityModal(false);
          }}
          onClose={() => setShowCalculateQuantityModal(false)}
          open
          productUom={orderedProduct.uom ?? undefined}
        />
      )}
    </>
  );
}
