import { Button, HSpacer, IconButton } from '@/components/DesignSystem';
import { ProductCard, ProductCardProps } from '@/components/DesignSystem/ProductCard/ProductCard';
import { DemoBlock } from '@/pages/UXSandbox/components/DemoBlock';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';
import { LiquidFormulationUnitOfMeasure } from '@shared/enums';
import { ReviewAction } from '@/components/shared/SharedCardComponents/ReviewAction';
import { DemoContainer } from '../../components/DemoContainer';
import {
  CartProductCard,
  CartProduct,
} from '@/components/DesignSystem/ProductCard/CartProductCard';

type Variant = Omit<ProductCardProps, 'testID' | 'variant'>;

const AddPriceButton = (
  <Button
    onClick={() => alert("Add Price")}
    startIcon={<Add />}
    testID="add-price-button"
    variant="text"
  >
    Price
  </Button>
);
const AddButton = (
  <ReviewAction
    onClick={() => alert("Add")}
    testID="add-product"
    variant="add-product"
  />
);
const EditButton = (
  <IconButton
    size="small"
    testID={`product-card-demo-more-edit-button`}
  >
    <Edit fontSize="inherit" />
  </IconButton>
);
const RemoveButton = (
  <ReviewAction
    onClick={() => alert("Remove")}
    testID="remove-product"
    variant="remove-product"
  />
);
const CompareButton = (
  <ReviewAction
    onClick={() => alert("Compare")}
    testID="compare-button"
    variant="compare"
  />
);
const ProductAlreadyInOrder = (
  <ReviewAction
    onClick={() => alert("Compare")}
    testID="compare-button"
    variant="product-already-in-order"
  />
);

const variants: Variant[] = [
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
  },
  {
    product: {
      allowSubstitutions: true,
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
  },
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    rightAccessory: AddPriceButton,
  },
  {
    product: {
      allowSubstitutions: true,
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    rightAccessory: AddPriceButton,
  },
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
  },
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    substituteProduct: '[product name]',
  },
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    rightAccessory: EditButton,
  },
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    substituteProduct: '[product name]',
    rightAccessory: EditButton,
  },
  {
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    total: 100,
  },
  {
    bottomLeftAccessory: CompareButton,
    bottomRightAccessory: AddButton,
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    total: 100,
  },
  {
    bottomLeftAccessory: CompareButton,
    bottomRightAccessory: ProductAlreadyInOrder,
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    total: 100,
  },
  {
    bottomLeftAccessory: CompareButton,
    bottomRightAccessory: (
      <Stack alignItems="center" direction="row">
        {RemoveButton}
        <HSpacer size="5" />
        <CheckCircle color="success" />
      </Stack>
    ),
    product: {
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    total: 100,
  },
  {
    isAccepted: true,
    product: {
      companionToProductId: "1234",
      initialQuantity: 500,
      initialUom: LiquidFormulationUnitOfMeasure.QUART,
      name: 'Product Name',
      price: 100,
      pricingRequestProductId: '',
      quantity: 500,
      uom: LiquidFormulationUnitOfMeasure.QUART,
    },
    substituteProduct: '[product name]',
    total: 100,
    variant: 'companion',
  } as Variant,
];

const cartProduct: CartProduct = {
  id: '1',
  isAlternate: false,
  isCompanion: false,
  name: 'Product 1',
  package: null,
  parentProductName: null,
  productId: 'test',
  quantity: 5,
  uom: 'LB',
  userId: '123',
}

export const ProductCardDemo = () => {
  return (
    <Stack spacing={2}>
      {variants.map((props, index) => (
        <DemoBlock key={index} label={`Variant ${index + 1}`}>
          <Stack direction="row" spacing={3}>
            <DemoContainer
              backgroundColor="background.default"
              padding={2}
            >
              <ProductCard {...props} testID={`product-card-demo-variant-${index}`} />
            </DemoContainer>
            <DemoContainer
              backgroundColor="background.default"
              padding={2}
            >
              <ProductCard
                {...props}
                product={{ ...props.product, package: 'Carton' }}
                testID={`product-card-demo-more-variant-${index}`}
              />
            </DemoContainer>
          </Stack>
        </DemoBlock>
      ))}
      <DemoBlock label="Variant 14 - Shopping Cart product card">
        <Stack direction="row" spacing={3}>
          <DemoContainer
            backgroundColor="background.paper"
            padding={2}
          >
            <CartProductCard
              cartProduct={cartProduct}
              onEdit={(id) => alert(`Editing productId: ${id}`)}
              onRemove={(id) => alert(`Removing productId: ${id}`)}
            />
          </DemoContainer>
          <DemoContainer
            backgroundColor="background.default"
            padding={2}
          >
          </DemoContainer>
        </Stack>
      </DemoBlock>
    </Stack>
  );
}
