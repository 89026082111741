import { IconButton, Logo, Text, TopAppBar } from "@/components/DesignSystem";
import Square from "@mui/icons-material/Square";
import Menu from "@mui/icons-material/Menu";
import { DemoBlock } from "../../components/DemoBlock";

export const TopAppBarDemo = () => {
  return (
    <>
      <DemoBlock label="Leading Icon">
        <TopAppBar isStatic leftAccessory={<Square />} testID="demo1">
          <Text category="title-large">Title</Text>
        </TopAppBar>
      </DemoBlock>
      <DemoBlock label="Trailing Icon">
        <TopAppBar isStatic rightAccessory={<Square />} testID="demo2">
          <Text category="title-large">Title</Text>
        </TopAppBar>
      </DemoBlock>
      <DemoBlock label="Leading and trailing icon">
        <TopAppBar
          isStatic
          leftAccessory={<Square />}
          rightAccessory={<Square />}
          testID="demo3">
          <Text category="title-large">Title</Text>
        </TopAppBar>
      </DemoBlock>
      <DemoBlock label="No title">
        <TopAppBar
          isStatic
          leftAccessory={<Square />}
          rightAccessory={<Square />}
          testID="demo4">
        </TopAppBar>
      </DemoBlock>
      <DemoBlock label="Growers App">
        <TopAppBar
          isStatic
          leftAccessory={
            <IconButton
              sx={{ margin: "0 10px 0 4px" }}
              testID="top-app-bar-demo-left-accessory"
            >
              <Menu />
            </IconButton>
          }
          testID="demo4">
          <Logo size="medium" />
        </TopAppBar>
      </DemoBlock>
    </>
  );
}
