import { Button, Modal, VSpacer } from "@/components/DesignSystem";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { PricingRequestsApi } from "@/utilities/api/PricingRequestsApi";
import { ApiPricingRequest } from "@api/interfaces";
import { Alert, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

interface ExtendPricingRequestModalProps {
  onClose: () => void,
  pricingRequest: ApiPricingRequest,
}
const ExtendPricingRequestModal = (
  { onClose, pricingRequest }: ExtendPricingRequestModalProps,
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newExpiration, setNewExpiration] = useState<string>('');
  const { openSnackbar } = useSnackbar();
  const isOpen = !pricingRequest.isExpired && !pricingRequest.reviewExpiration;

  useEffect(
    () => {
      let expirationDate;
      if (isOpen) {
        expirationDate = DateTime.fromISO(pricingRequest.expiration! as unknown as string);
      } else {
        expirationDate = DateTime.fromISO(pricingRequest.reviewExpiration! as unknown as string);
      }

      if (DateTime.fromISO(newExpiration) <= expirationDate) {
        setErrorMessage(
          `Please pick a date and time after original expiration date and time (${expirationDate.toLocaleString(DateTime.DATETIME_SHORT)
          })`,
        );
      } else {
        setErrorMessage('');
      }
    },
    [newExpiration, isOpen],
  );

  const openDescription = (
    'Please pick a new date and time when this product request will be closed to further offers. '
    + 'Any invited retailers will have until the new date and time to place their offers.'
  );
  const inReviewDescription = (
    'Please pick a new date and time when this product request review period will be closed. '
    + 'The farmer will have until the new date and time to select received offers.'
  );

  const getUpdateBody = () => {
    const expirationDate = new Date(newExpiration);
    if (isOpen) {
      return { expiration: expirationDate };
    }
    return { reviewExpiration: expirationDate };
  }

  const { mutate: extendExpiration } = useMutation(
    () => PricingRequestsApi.extendExpiration(
      pricingRequest.id,
      getUpdateBody(),
    ),
    {
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || 'An error has occurred');
      },
      onSuccess: () => {
        openSnackbar(
          'Expiration extended!',
          5000000,
          true,
        );
        onClose();
      },
    },
  );

  const getSubLine = () => {
    if (isOpen) {
      return openDescription;
    }
    return inReviewDescription;
  }

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          disabled={!newExpiration || !!errorMessage}
          onClick={() => extendExpiration()} {...props}
        >
          Save changes
        </Button>
      )}
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Close
        </Button>
      )}
      onClose={onClose}
      open
      subline={getSubLine()}
      testID="extend-pricing-request-modal"
      title="Extend window"
    >
      <Stack direction="row" justifyContent="center">
        <TextField
          onChange={(e) => { setNewExpiration(e.target.value); }}
          type="datetime-local"
        />
      </Stack>
      {!!errorMessage && (
        <>
          <VSpacer size="3" />
          <Alert color="error" icon={false}>
            {errorMessage}
          </Alert>
        </>
      )}
    </Modal>
  );
};

export default ExtendPricingRequestModal;
