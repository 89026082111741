import { Text } from '@/components/DesignSystem';
import { FullPageLayout } from '@/components/layouts/FullPageLayout';
import { Routes } from '@/constants/Routes';
import { CreateAccountPage } from '@/pages/Auth/CreateAccountPage';
import { LoginHeader } from '@/pages/Auth/LoginHeader';
import { LoginPage } from '@/pages/Auth/LoginPage';
import { OauthVerify } from '@/pages/Auth/OauthVerify';
import { PortalLogin } from '@/pages/Auth/PortalLogin';
import StorefrontSignInPage from '@/pages/Auth/StorefrontSignInPage';
import { ProductPage } from '@/pages/Shop/ProductPage';
import Shop from '@/pages/Shop/Shop';
import { GlobalHeaderLayout } from '@/views/Layouts';
import { Stack } from '@mui/material';
import React from 'react';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";

const ErrorPage = () => (
  <FullPageLayout header={<LoginHeader />}>
    <Stack textAlign="center">
      <Text category="h1">Error</Text>
    </Stack>
  </FullPageLayout>
);

export const StorefrontLoginView = () => {
  return (
    <AppRoutes>
      <Route element={<LoginPage />} path={Routes.LOGIN} />
      <Route element={<StorefrontSignInPage />} path={Routes.STOREFRONT_LOGIN} />
      <Route element={<CreateAccountPage />} path={Routes.CREATE_ACCOUNT} />
      <Route element={<OauthVerify />} path={Routes.OAUTH_VERIFY} />
      <Route element={<PortalLogin />} path={Routes.PORTAL_LOGIN} />
      <Route element={<ErrorPage />} path={Routes.ERROR} />
      <Route
        element={<GlobalHeaderLayout><Shop /></GlobalHeaderLayout>}
        path={Routes.SHOP}
      />
      <Route
        element={<GlobalHeaderLayout><ProductPage /></GlobalHeaderLayout>}
        path={Routes.PRODUCT_PAGE}
      />
      <Route element={<Navigate to={Routes.SHOP} />} path={"*"} />
    </AppRoutes>
  );
}
