import {
  OfferEndpoint,
  PricingRequestEndpoint,
  PricingRequestRetailerEndpoint,
} from "@api/endpoints";
import { ApiOfferProduct } from "@api/interfaces";
import {
  DiscountType,
  FulfillmentMethod,
  PaymentMethodPreference,
  ProductUom,
} from "@shared/enums";

export interface Product extends Partial<OfferEndpoint.Create.Product> {
  allowSubstitutions: boolean,
  isAccepted?: ApiOfferProduct['isAccepted'],
  name: string,
  package?: string | null,
  quantity?: number | null,
  uom?: ProductUom | null,
}
export interface Offer extends Partial<Omit<OfferEndpoint.Create.Request, "products">> {
  discountAmount?: number,
  discountName?: string,
  discountType?: DiscountType,
  paymentMethodPreference?: PaymentMethodPreference,
  products?: Product[],
  publicId?: string,
}

export type PricingRequestRetailer = Partial<PricingRequestRetailerEndpoint.Update.Request>;

export type LastLookData = PricingRequestEndpoint.GetLastLook.Response;

export const defaultOffer: Offer = {
  fulfillmentMethod: FulfillmentMethod.NO_PREFERENCE,
  products: [],
};
