import { Checkbox, Radio } from '@/components/DesignSystem';
import { Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';

export const DataTableDemo = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell variant="head">Header</TableCell>
          <TableCell variant="head">Header</TableCell>
          <TableCell variant="head">Header</TableCell>
          <TableCell variant="head">Header</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left" variant="body">
            Left Aligned
          </TableCell>
          <TableCell align="right" variant="body">
            Right Aligned
          </TableCell>
          <TableCell align="center" variant="body">
            Center Aligned
          </TableCell>
          <TableCell variant="body">
            Test
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="body">
            <Checkbox testID="data-table-demo-body" />
          </TableCell>
          <TableCell variant="body">
            <EditOutlined />
          </TableCell>
          <TableCell variant="body">
            <DeleteOutlined />
          </TableCell>
          <TableCell variant="body">
            <Radio testID="data-table-demo-radio" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
