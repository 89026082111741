import { AppConfig } from '@/constants/AppConfig';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { ApiUtils } from './ApiUtils';

export type ClientOptions = {
  body?: object | FormData,
  isImage?: boolean
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH',
};

export const Client = async <T = unknown>(
  endpoint: string,
  options: ClientOptions = {},
): Promise<T> => {
  const { body, isImage: image = false, method = body ? 'POST' : 'GET' } = options;
  const headers: HeadersInit = {
    Accept: 'application/json',
  };

  if (body) {
    headers['Content-Type'] = 'application/json';
  }

  const subdomain = getSubdomain();
  if (subdomain) {
    headers['storefront'] = subdomain;
  }

  const config: RequestInit = {
    credentials: 'include',
    headers,
    method,
  };

  if (body && !(body instanceof FormData)) {
    config.body = JSON.stringify(body);
  }

  let baseUrl = AppConfig.biddingApiUrl;
  if (getSubdomain()) {
    // Temporary until we get both storefront and marketplace onto growers.ag
    baseUrl = baseUrl.replace('.mygrowers.io', '.growers.ag');
  }

  const response = await fetch(`${baseUrl}/${endpoint}`, config);

  if (
    endpoint !== "users/me" &&
    endpoint !== "login" &&
    response.status === 401
  ) {
    document.location.reload();
  }

  if (!response.ok) {
    await ApiUtils.handleError(response);
  }

  if (image) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.blob() as any;
  }

  if (response.status !== 204) {
    return response.json();
  }

  return {} as T;
};
