import qs from 'qs';

export class QueryUtil {
  static stringify = (obj: object) => {
    return qs.stringify(obj, { arrayFormat: 'repeat', skipNulls: true });
  }

  static getSearchParams = (location: Location) => {
    let query;
    const hashIndex = location.href.indexOf('#');
    const queryIndex = location.href.indexOf('?');
    if (hashIndex > queryIndex) {
      query = location.href.slice(queryIndex + 1, hashIndex);
    } else {
      query = location.href.slice(queryIndex + 1, location.href.length);
    }
    return new URLSearchParams(query);
  }
}
