import { CheckCircle, Email, ExpandMore, LocalOffer, Verified } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { OfferProductCard } from './OfferProductCard';
import { formatCurrency, pluralize } from "@shared/utilities";
import {
  FullscreenDialog,
  HSpacer,
  VSpacer,
  Heading,
  TextLink,
  InlineMessage,
  Button,
  Text,
  IconButton,
  Modal,
  EmptyState,
} from "@/components/DesignSystem";
import { ReviewAction } from "@/components/shared/SharedCardComponents/ReviewAction";
import { ProductOffers } from "../CompareAcceptOffers/interfaces";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { Box, Divider } from "@mui/material";
import { ApiOffer, ApiOfferProduct, ApiPricingRequest } from "@api/interfaces";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { Fragment, useMemo, useState } from "react";
import { OfferDetails } from "./OfferDetails";
import { OfferProduct } from "../CreateSendPriceRequest/interfaces";
import { OfferRetailersDialog } from "@/components/DesignSystem/ProductCard/OfferRetailersDialog";
import { MyOrderButton } from "./MyOrderButton";

export interface RetailerOfferProps {
  acceptedOffers?: ProductOffers[],
  isClosed?: boolean,
  onViewOrder: () => void,
  productRequest: ApiPricingRequest,
  readonly?: boolean,
  selectedOffer?: ApiOffer,
  setSelectedOffer: (offer?: ApiOffer) => void,
  setSubmittedProducts: (productOffers: ProductOffers[]) => void,
  submittedProducts: ProductOffers[],
}

export const RetailerOffer = ({
  acceptedOffers,
  isClosed,
  onViewOrder,
  productRequest,
  readonly = false,
  selectedOffer,
  setSelectedOffer,
  setSubmittedProducts,
  submittedProducts,
}: RetailerOfferProps) => {
  const { openSnackbar } = useSnackbar();
  const [showOfferDetails, setShowOfferDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ApiOfferProduct>();

  const numProductsInOffer = selectedOffer?.products?.length ?? 0;

  const retailerOffers = useMemo(() => {
    if (!selectedProduct) {
      return [];
    }

    const offer = submittedProducts.find(
      ({product}) => product.id === selectedProduct.pricingRequestProductId,
    );

    if (!offer) {
      return [];
    }
    return offer.retailers.filter(x => !!x.offerProduct);
  }, [selectedProduct, submittedProducts]);

  const totalOffer = selectedOffer?.products?.reduce((acc, product) => {
    return acc + product.price;
  }, 0) ?? 0;

  const PreferredRetailerBadge = () => (
    <InlineMessage
      icon={<Verified color="success" fontSize="small" />}
      testID="retailer-list-preferred-badge"
      title="PREFERRED"
      titleCategory="label-small"
      titleColor="success"
      titleSpacing="2"
      />
  );

  const formatNewSubmittedProducts = (newProducts: ProductOffers[]) => {
    return submittedProducts.map((product) => {
      const newProduct = newProducts?.find((newProduct) => {
        return newProduct.product?.id === product.product?.id;
      });
      return newProduct ?? product;
    });
  };

  const addAllRetailerProducts = () => {
    const newProducts = selectedOffer?.products?.map((product) => {
      const productOffer = submittedProducts.find((offer) => {
        return offer.product?.id === product.pricingRequestProductId;
      })!;
      return {
        ...productOffer,
        product: {
          ...productOffer.product,
          acceptedOfferProductId: product.id,
        },
      };
    });

    const newSubmittedProducts = formatNewSubmittedProducts(newProducts ?? []);

    setSubmittedProducts(newSubmittedProducts);
    openSnackbar('All products added to order');
  }

  const addRemoveProduct = (product: ProductOffers, id?: string) => {
    const newSubmittedProducts = formatNewSubmittedProducts([{
      ...product,
      product: {
        ...product.product,
        acceptedOfferProductId: id ?? null,
      },
    }]);
    setSubmittedProducts(newSubmittedProducts);
  }

  const AddRemoveRetailerProductButton = ({
    product: offerProduct,
  }: { product: ApiOfferProduct}) => {
    const productOffer = submittedProducts.find((offer) => {
      return offer.product?.id === offerProduct.pricingRequestProductId;
    });
    const isAddedToOffer = !!productOffer?.product?.acceptedOfferProductId;
    const ownProduct = productOffer?.product?.acceptedOfferProductId === offerProduct?.id;

    if (readonly) {
      return <></>;
    }

    return isAddedToOffer ? (
      <>
        <ReviewAction
          onClick={() => addRemoveProduct(productOffer!, ownProduct ? undefined : offerProduct.id )}
          testID={`retailer-offer-remove-product`}
          variant={isAddedToOffer && !ownProduct ? 'replace-product' : 'remove-product'}
        />
        <CheckCircle color="success"/>
      </>
    ) : (
      <ReviewAction
        onClick={() => {
          if (offerProduct?.id) {
            addRemoveProduct(productOffer!, offerProduct.id)
            openSnackbar('Product added to order');
          }
        }}
        testID={`retailer-product-add-product`}
        variant="add-product"
      />
    );
  }

  const Header = (
    <>
      <VSpacer size="3"/>
      <Box alignItems="center" display="flex" flexDirection="column" px="24px">
        <Heading
          breakWord
          level="1"
          testID="review-and-select-offers-my-order-modal-title"
          title={selectedOffer?.pricingRequestRetailer?.name ?? ""}
        />
        {selectedOffer?.pricingRequestRetailer?.preferred && (
          <>
            <VSpacer size="3" />
            <PreferredRetailerBadge />
          </>
        )}
      </Box>
      <VSpacer size="7" />
      <VSpacer size="2" />
      {!!numProductsInOffer && (
        <>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mx="24px"
            onClick={() => setShowOfferDetails(true)}
            sx={{
              cursor: "pointer",
              border: "1px solid",
              borderRadius: '4px',
              p:"16px",
            }}
          >
            <Text category="title-medium">
              {pluralize(numProductsInOffer, "Product")}
            </Text>
            <Box alignItems="center" display="flex">
              {!!selectedOffer!.discountName && (
                <>
                  <LocalOffer color="success" fontSize="small"/>
                  <HSpacer size="3" />
                </>
              )}
              {!!selectedOffer!.note && (
                <>
                  <Email color="info" fontSize="small" />
                  <HSpacer size="4" />
                </>
              )}
              {formatCurrency(totalOffer)}
              <HSpacer size="2" />
              <IconButton
                size="small"
                testID="review-and-select-offers-my-order-modal-close-button"
              >
                <ExpandMore />
              </IconButton>
            </Box>
          </Box>
          {!readonly && (
            <>
              <VSpacer size="7"/>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" mx="24px">
                <Button
                  onClick={addAllRetailerProducts}
                  startIcon={<Add />}
                  testID="review-and-select-offers-add-all-button"
                  variant="text"
                >
                  Add all
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {!numProductsInOffer && (
        <VSpacer size="6"/>
      )}
      <VSpacer size="3"/>
    </>
  )

  const Modals = (
    <>
      {showOfferDetails && (
        <OfferDetails
          offer={selectedOffer!}
          onClose={() => setShowOfferDetails(false)}
          priceRequest={productRequest}
          retailer={selectedOffer!.pricingRequestRetailer!}
          testID="offer-details"
        />
      )}

      {!!selectedProduct && (
        <OfferRetailersDialog
          acceptButton={(
            <>
              <DesktopOnly>
                {!readonly && (
                  <>
                    <Button
                      onClick={onViewOrder}
                      testID={`retailer-offer-view-details-view-order-button`}
                      variant="text"
                    >
                      View order
                    </Button>
                    <HSpacer size="3" />
                  </>
                )}

                <Button
                  onClick={() => setSelectedProduct(undefined)}
                  testID={`retailer-offer-view-details-done-button`}
                >
                  Done
                </Button>
              </DesktopOnly>
              <MobileOnly>
                {!readonly && (
                  <MyOrderButton
                    onViewOrder={onViewOrder}
                    orders={acceptedOffers?.length || 0}
                  />
                )}
              </MobileOnly>
            </>
          )}
          bottomRightAccessory={() => (
            <AddRemoveRetailerProductButton
              product={selectedProduct as unknown as ApiOfferProduct}
            />
          )}
          onClose={() => setSelectedProduct(undefined)}
          product={selectedProduct!.pricingRequestProduct! as unknown as OfferProduct}
          productOfferRetailers={retailerOffers}
          showAcreLabel={false}
          testID="retailer-offer-offers"
          title={selectedProduct?.pricingRequestProduct?.name ?? ''}
        />
      )}
    </>
  )

  const Body = (
    <>
      {Header}
      <Divider />
      {!numProductsInOffer && (
        <>
          <VSpacer size="12" />
          <VSpacer size="5" />
          <EmptyState
            headingMessage="Products submitted by this retailer will appear here"
            testID="review-and-select-offers-empty-state"
          />
          <VSpacer size="12" />
        </>
      )}
      {selectedOffer?.products?.map((product, index) => {
        return (
          <Fragment key={product.id}>
            <Box mx="24px">
              <VSpacer size="6" />
              <OfferProductCard
                bottomLeftAccessory={!isClosed && (
                  <TextLink
                    category="body-medium"
                    onClick={() => {
                      setSelectedProduct(product)
                    }}
                    testID={`review-and-select-offers-product-${index}-compare--link`}
                  >
                    Compare
                  </TextLink>
                )}
                bottomRightAccessory={(
                  <AddRemoveRetailerProductButton product={product}/>
                )}
                isAccepted={!!product.isAccepted}
                key={product.id}
                product={product}
                testID={`review-and-select-offers-product-${index}`}
              />
              <VSpacer size="6" />
            </Box>
            <Divider />
          </Fragment>
        )
      })}
      {Modals}
    </>
  );

  return (
    <>
      <MobileOnly>
        <FullscreenDialog
          actionButton={(
            <>
              {!!numProductsInOffer && (
                <MyOrderButton
                  onViewOrder={onViewOrder}
                  orders={acceptedOffers?.length || 0}
                  readonly
                />
              )}
            </>
          )}
          contentStyle={{
            p:0,
          }}
          onClose={() => {
            setSelectedOffer(undefined);
          }}
          open={!!selectedOffer}
          testID="selected-retailer-modal"
        >
          {Body}
        </FullscreenDialog>
      </MobileOnly>
      <DesktopOnly>
        <Modal
          acceptButton={() => (
            !!numProductsInOffer && (
              <Button
                onClick={() => {
                  setSelectedOffer(undefined);
                }}
                testID="review-and-select-offers-done-button"
              >
                Done
              </Button>
            )
          )}
          cancelButton={() => numProductsInOffer ? (
            <Button
              onClick={onViewOrder}
              testID="review-and-select-offers-view-order-button"
              variant="text"
            >
              View order
            </Button>
          ) : (
            <Button
              onClick={() => setSelectedOffer(undefined)}
              testID="review-and-select-offers-close-button"
              variant="text"
            >
              Close
            </Button>
          )}
          footerStyle={{
            px: "24px",
            pb: "24px",
          }}
          onClose={() => {
            setSelectedOffer(undefined);
          }}
          open={!!selectedOffer}
          padding={0}
          testID="selected-retailer-modal"
        >
          {Body}
        </Modal>
      </DesktopOnly>
    </>
  )
}
