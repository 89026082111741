import { Button, HSpacer, NumericInput, Text, VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { useStorefront } from '@/hooks/useStorefront';
import { CreateFarmerAccountEventType, logEvent } from '@/utilities/Analytics';
import { AuthApi } from '@/utilities/api/AuthApi';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { UserApi } from '@/utilities/api/UserApi';
import { ShoppingCartUtils } from '@/utilities/ShoppingCartUtils';
import { UserEndpoint } from '@api/endpoints';
import { ApiUser } from '@api/interfaces';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Alert, Stack } from '@mui/material';
import { ApiErrors } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities';
import React, { KeyboardEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface OtpFormProps {
  isRegister?: boolean,
  onCancel?: () => void,
  onError?: (error: DetailedApiError) => void,
  userToCreate?: Omit<UserEndpoint.Create.FarmerUserCreate, 'code'>,
  telephone: string,
}

const OtpForm = ({
  isRegister = false,
  onCancel,
  onError,
  telephone,
  userToCreate,
}: OtpFormProps) => {
  const [otpValues, setOtpValues] = useState<(number | undefined)[]>([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const otpFieldsRef: RefObject<(HTMLDivElement | null)[]> = useRef([]);
  const [errorMessage, setErrorMessage] = useState<{ title?: string, message: string }>();
  const { refreshUser, validateOtp } = useAuthentication();
  const navigate = useNavigate();
  const [otpResendCounter, setOtpResendCounter] = useState(0);
  const [hideResend, setHideResend] = useState(false);
  const { storefront } = useStorefront();
  const { saveGuestShoppingCart } = useShoppingCart();

  useEffect(() => {
    sendOtpCode();
    resetForm();
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (otpResendCounter > 0) {
      timer = setInterval(() => setOtpResendCounter(otpResendCounter - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [otpResendCounter]);

  const resetForm = () => {
    otpFieldsRef?.current?.[0]?.focus();
    setOtpValues([undefined, undefined, undefined, undefined, undefined, undefined]);
  }

  const { mutate: authenticateUser, isLoading: isAuthenticating } = useMutation(
    ({ otpValue }: { otpValue: string }) => (
      validateOtp(telephone, otpValue, storefront?.id)
    ),
    {
      onError: (err: { message: string }) => {
        if (err.message === ApiErrors.inactiveAccount) {
          setErrorMessage({ title: 'Inactive account',  message: err.message });
        } else {
          setErrorMessage({ message: "Incorrect OTP" });
        }
        resetForm();
      },
      onSuccess: async () => {
        await refreshUser();
        navigate(Routes.HOMEPAGE, { replace: true });
      },
      retry: false,
    },
  );

  const { mutate: createUser, isLoading: isCreatingUser } = useMutation(
    async (code: string) => {
      if (!userToCreate) {
        throw new DetailedApiError('', 'Nothing to Create', {});
      }
      return UserApi.create({
        businessName: userToCreate.businessName,
        code,
        countyId: userToCreate.countyId,
        email: userToCreate.email,
        firstName: userToCreate.firstName,
        lastName: userToCreate.lastName,
        // preferredRetailerId: userToCreate.preferredRetailerId,
        state: userToCreate.state,
        storefrontId: storefront?.id,
        telephone: userToCreate.telephone,
      });
    },
    {
      onError: (error: DetailedApiError) => {
        if (error.message === ApiErrors.inactiveAccount) {
          setErrorMessage({ message: "Incorrect OTP" });
        } else {
          onError?.(error);
          setErrorMessage({ message: error.message || "An error has occurred" });
        }
      },
      onSuccess: async (user: ApiUser) => {
        logEvent(CreateFarmerAccountEventType.CompleteCreateAccount);
        await refreshUser();
        await saveGuestShoppingCart(user, ShoppingCartUtils.getShoppingCart());
        navigate(Routes.HOMEPAGE, { replace: true });
      },
    },
  );

  const { mutate: sendOtpCode, isLoading: isSendingOtp } = useMutation(
    () => AuthApi.sendOtpCode({
      storefrontId: storefront?.id,
      telephone,
      type: isRegister ? 'Register' : 'Login',
    }),
    {
      onError: (err: DetailedApiError) => {
        setErrorMessage({ message: err.message });
        if (
          err.message === ApiErrors.userWithPhoneExists
          || err.message === ApiErrors.userWithPhoneDoesNotExist
        ) {
          setHideResend(true);
        }
        onError?.(err);
      },
      onSuccess: () => {
        setErrorMessage(undefined);
        setOtpResendCounter(30);
      },
      retry: false,
    },
  );

  const handleKeyboardEvent = async (
    event: KeyboardEvent,
    index: number,
  ) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      const isFirstInput = index === 0;
      if (!isFirstInput && otpFieldsRef?.current) {
        otpFieldsRef.current[index - 1]?.focus();
      }
    } else if (isFinite(Number(event.key))) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = Number(event.key);
      setOtpValues(newOtpValues);
      const isLastInput = (index + 1) === newOtpValues.length;
      if (isLastInput) {
        const otpValue = newOtpValues.join('');
        await isRegister ? createUser(otpValue) : authenticateUser({ otpValue });
      } else {
        if (otpFieldsRef?.current) {
          otpFieldsRef.current[index + 1]?.focus();
        }
      }
    }
  }

  const Error = () => (
    <>
      <Alert
        color="error"
        icon={<ErrorOutline fontSize="small" />}
        sx={{
          display: 'flex',
          px: '16px',
          py: '14px',
          textAlign: 'left',
        }}
      >
        {errorMessage?.message}
      </Alert>
      <VSpacer size="9" />
    </>
  )

  return (
    <Stack flexDirection="column">
      <Text category="h3">Verification Code</Text>
      {!hideResend &&
        <Text category="p2">
          We have sent a verification code to +1-{formatPhoneNumber(telephone, 'none')}
        </Text>
      }
      {errorMessage ? (
        <>
          <VSpacer size="4" />
          <Error />
        </>
      ) : (
        <VSpacer mobileSize="7" size="10" />
      )}
      {!hideResend &&
        <>
          <Stack flexDirection="row">
            {otpValues.map((value, index) => (
              <Stack flexDirection="row" key={index}>
                <NumericInput
                  decimals={0}
                  disabled={isAuthenticating || isSendingOtp}
                  inputRef={(ref) => {
                    if (otpFieldsRef.current) {
                      otpFieldsRef.current[index] = ref;
                    }
                  }}
                  maxLength={1}
                  maxValue={9}
                  minValue={0}
                  onKeyUp={(event) => handleKeyboardEvent(event, index)}
                  showFixedDecimals={false}
                  sx={{ textAlign: 'center' }}
                  testID={`${index}-otp-input`}
                  value={value}
                />
                <HSpacer size="3" />
              </Stack>
            ))}
          </Stack>
          <VSpacer size="4" />
        </>
      }
      {!isSendingOtp && otpResendCounter > 0 && !hideResend &&
        <Text category="p2">
          Resend SMS in {otpResendCounter}s
        </Text>
      }
      <VSpacer size="4" />
      <Button
        onClick={onCancel}
        testID="otp-form-cancel-button"
      >
        Cancel
      </Button>
      {!isSendingOtp && otpResendCounter === 0 && !hideResend &&
        <>
          <VSpacer size="4" />
          <Button
            disabled={isAuthenticating || isCreatingUser}
            onClick={() => sendOtpCode()}
            testID="resend-otp-counter"
          >
            Resend
          </Button>
        </>
      }
    </Stack>
  );
}

export default OtpForm;
