import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { Box } from '@mui/material';
import { SpacerProps, getSize } from './HSpacer';

export const VSpacer = ({ mobileSize, size }: SpacerProps) => {
  const height = getResponsiveValue(getSize(mobileSize ?? size), getSize(size));

  return (
    <Box sx={{ height, minHeight: height }} />
  );
}
