import { Chip, HSpacer, Text } from "@/components/DesignSystem";
import { FilterChip } from "@/components/DesignSystem/Toolbar/FilterChip";
import { FilterMenu } from "@/components/DesignSystem/Toolbar/FilterMenu";
import { FilterSheet } from "@/components/DesignSystem/Toolbar/FilterSheet";
import {
  Filter,
  FilterSelection,
  FilterSelections,
  NonBooleanFilter,
} from "@/components/DesignSystem/Toolbar/interfaces";
import { getResponsiveValue, useMediaQuery } from "@/hooks/useMediaQuery";
import Tune from "@mui/icons-material/Tune";
import { Stack } from "@mui/material";
import _ from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";

interface FilterBarProps {
  filters: Filter[],
  onChange?: (selections: FilterSelections) => void,
  selections: FilterSelections,
  testID: string,
  hideAllFilter?:boolean
}

export const FilterBar = ({
  filters = [],
  onChange,
  selections,
  testID,
  hideAllFilter=false,
}: FilterBarProps) => {
  const [showFilterSheet, setShowFilterSheet] = useState(false);
  const [selectedFilterId, setSelectedFilterId] = useState<string | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const { isMobile } = useMediaQuery();

  const selectedFilter = useMemo(() => (
    selectedFilterId
      ? filters.find((filter) => (
        filter.id === selectedFilterId && filter.selectionMethod !== 'boolean'
      ))
      : undefined
  ), [filters, selectedFilterId]);

  const handleSelectionChange = useCallback((filterId: string, selection: FilterSelection) => {
    const newSelections = _.cloneDeep(selections);
    newSelections.set(filterId, selection);
    onChange?.(newSelections);
  }, [onChange, selections]);

  return (
    <>
      <Stack
        className="scrollable-container"
        direction="row"
        flexWrap={getResponsiveValue('nowrap', 'wrap')}
        rowGap="8px"
      >
        {!hideAllFilter &&
          <Chip
            icon={<Tune color="primary" />}
            label={
              !isMobile ? (
                <Text category="label-large">
                  All Filters
                </Text>
              ) : undefined
            }
            onClick={() => setShowFilterSheet(!showFilterSheet)}
            size="medium"
            testID={`${testID}-chip-all-filters`}
            variant="outlined"
          />
        }
        {filters.map((filter) => (
          <Fragment key={filter.id}>
            <HSpacer size="3" />
            <FilterChip
              filter={filter}
              onClick={(event) => {
                if (filter.selectionMethod === 'boolean') {
                  handleSelectionChange(
                    filter.id,
                    selections.get(filter.id)?.has(filter.id)
                      ? new Set()
                      : new Set([filter.options[0].id]),
                  );
                } else {
                  setSelectedFilterId(filter.id);
                  setMenuAnchorEl(event.currentTarget);
                }
              }}
              selection={selections.get(filter.id)}
              testID={`${testID}-chip-${filter.id}`}
            />
          </Fragment>
        ))}
      </Stack>
      {selectedFilter && (
        <FilterMenu
          anchorEl={menuAnchorEl}
          filter={selectedFilter as NonBooleanFilter}
          onChange={(selection) => {
            if (selectedFilter.selectionMethod === 'single-select') {
              setSelectedFilterId(null);
            }
            handleSelectionChange(selectedFilter.id, selection);
          }}
          onClose={() => setSelectedFilterId(null)}
          open
          selection={selections.get(selectedFilter.id)}
          testID={`${testID}-menu`}
        />
      )}
      <FilterSheet
        filters={filters}
        onApply={onChange}
        onClose={() => setShowFilterSheet(false)}
        open={showFilterSheet}
        selections={selections}
        testID={`${testID}-sheet`}
      />
    </>
  );
}

