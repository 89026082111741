import { QueryKeys } from '@/constants/QueryKeys';
import { StorefrontApi } from '@/utilities/api/StorefrontApi';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { useQuery } from 'react-query';

export const useStorefront = (suspense = false) => {
  const subdomain = getSubdomain();

  const { data: storefront, isLoading } = useQuery(
    [QueryKeys.GET_STOREFRONT, subdomain],
    () => StorefrontApi.getBySubdomain(subdomain!),
    {
      enabled: !!subdomain,
      suspense,
    },
  );

  return {
    isLoading,
    storefront,
  };
}
