import {
  alpha,
  darken,
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { HSpacer } from '..';

interface SwitchProps extends Partial<FormControlLabelProps> {
  children?: ReactNode,
  disabled?: boolean,
  onChangeChecked?: (checked: boolean) => void,
  showIcon?: boolean,
  testID: string,
}

const buildIcon = (color: string, path: string) => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 ` +
    `0 24 24"><path fill="${encodeURIComponent(color)}" d="${path}"/></svg>')`
}

const checkedIcon = (showIcon: boolean, theme: Theme) => ({
  backgroundImage: showIcon ? buildIcon(
    theme.palette.primary.main,
    "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z",
  ) : "",
});

const disabledCheckedIcon = (showIcon: boolean, theme: Theme) => ({
  backgroundImage: showIcon ? buildIcon(
    alpha(theme.palette.background.paper, .5),
    "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z",
  ) : "",
});

const uncheckedIcon = (showIcon: boolean, theme: Theme) => ({
  backgroundImage: showIcon ? buildIcon(
    theme.palette.background.paper,
    "M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 "
    + "6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z",
  ) : "",
});

const CustomSwitch = styled((props: MuiSwitchProps & { showIcon: boolean }) => (
  <MuiSwitch
    focusRipple={false}
    focusVisibleClassName=".Mui-focusVisible"
    sx={{ overflow: 'initial !important' }}
    {...props}
  />
))(({ showIcon, theme }) => ({
  width: 52,
  padding: 0,
  marginLeft: 10,
  boxShadow: 'none',
  '& .MuiTouchRipple-root': {
    width: "40px",
    height: "40px",
    top: "-6px",
    left: "-6px",
  },
  '& .MuiTouchRipple-ripple': {
    backgroundColor: alpha(theme.palette.background.paper, .5),
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    paddingTop: 2,
    paddingLeft: 2,
    transitionDuration: '300ms',
    margin: 2,
    color: theme.palette.grey['500'],
    //Checked
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: darken(theme.palette.primary.main, .5),
      // Track, checked
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        height: 32,
        opacity: 1,
        border: 0,
      },
      // Thumb, checked
      '& .MuiSwitch-thumb': {
        ...checkedIcon(showIcon, theme),
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "4px",
        backgroundPositionY: "4px",
        boxShadow: 'none',
        boxSizing: 'border-box',
        marginTop: 0,
        marginLeft: 0,
        width: 24,
        height: 24,
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: darken(theme.palette.primary.main, .4),
      },
      ':hover': {
        color: darken(theme.palette.primary.main, .4),
      },
      // Track, disabled, checked
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.12,
        backgroundColor: theme.palette.grey['500'],
      },
      // Thumb, disabled, checked
      '&.Mui-disabled .MuiSwitch-thumb': {
        ...disabledCheckedIcon(showIcon, theme),
        boxSizing: 'border-box',
        color: theme.palette.background.paper,
        opacity: 1,
      },
    },
    // Thumb, not checked
    '.MuiSwitch-thumb': {
      ...uncheckedIcon(showIcon, theme),
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "4px",
      backgroundPositionY: "4px",
      boxSizing: 'border-box',
      marginTop: showIcon ? 0 : 4,
      marginLeft: showIcon ? 0 : 4,
      width: showIcon ? 24 : 16,
      height: showIcon ? 24 : 16,
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.text,
    },
    ':hover': {
      color: theme.palette.text,
    },
    // Thumb, disabled, not checked
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.text,
      opacity: 0.38,
    },
    // Track, disabled, not checked
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: "transparent",
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 100,
    backgroundColor: "transparent",
    height: 32,
    border: `2px solid ${theme.palette.grey['500']}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const Switch = ({
  children,
  disabled = false,
  onChangeChecked,
  showIcon = false,
  testID,
  ...rest
}: SwitchProps) => {
  return (
    <FormControlLabel
      control={
        <CustomSwitch
          disabled={disabled}
          onChange={rest.onChange
            ? rest.onChange
            : ((e) => onChangeChecked?.(e.target.checked))}
          showIcon={showIcon}
        />
      }
      data-testid={testID}
      label={
        <Box pb={1}>
          <HSpacer size="4" />
          {children}
        </Box>
      }
      {...rest}
    />
  );
};
