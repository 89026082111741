import { RetailerLocationEndpoint, UserEndpoint } from "@api/endpoints";
import { RetailerEndpoint } from "@api/endpoints/RetailerEndpoint";
import { ApiCounty, ApiRetailer } from "@api/interfaces";
import { UserType } from "@shared/enums";
import { SharedConfig } from "@shared/SharedConfig";
import { QueryUtil } from "../Query";
import { Client } from "./Client";

export class HierarchyOfRetailersApi {
  static addHierarchyOfRetailers (request: RetailerEndpoint.Create.Request): Promise<ApiRetailer> {
    return Client('retailer', {
      body: request,
      method: 'POST',
    })
  }

  static getHierarchyOfRetailers (queries: RetailerEndpoint.List.Query):
    Promise<RetailerEndpoint.List.Response> {
    const query = QueryUtil.stringify(queries)
    return Client(`retailer?${query}`)
  }

  static updateRetailer
  (id: string, request: RetailerEndpoint.Update.Request): Promise<ApiRetailer> {
    return Client(`retailer/${id}`, {
      body: request,
      method: 'PATCH',
    })
  }

  static getRetailerById (id: string): Promise<ApiRetailer> {
    return Client(`retailer/${id}`)
  }

  static getRetailerLocations (id: string, queries: RetailerEndpoint.List.Query):
    Promise<RetailerEndpoint.LocationList.Response> {
    const query = QueryUtil.stringify(queries)
    return Client(`retailer/${id}/location?${query}`)
  }

  static addRetailerLocation (request: RetailerLocationEndpoint.Create.Request):
    Promise<RetailerLocationEndpoint.Create.Request> {
    return Client(`location`, {
      body: request,
      method: 'POST',
    })
  }

  static addRetailerMember (request: UserEndpoint.Create.Request):
    Promise<UserEndpoint.Create.Response> {

    const updatedRequest = { ...request };

    if (updatedRequest?.userType === UserType.RetailerAdmin) {
      delete updatedRequest.locationId;
    }

    return Client(`users`, {
      body: updatedRequest,
      method: 'POST',
    })
  }

  static getRetailerMembers (id: string, search?: string):
    Promise<RetailerEndpoint.MemberList.Response> {
    if (search) {
      return Client(`retailer/${id}/retailer-admin?search=${search}&limit=${SharedConfig.maxPageLimit}`)
    }
    return Client(`retailer/${id}/retailer-admin?limit=${SharedConfig.maxPageLimit}`)
  }

  static getLocationMembers (id: string, search?: string):
    Promise<RetailerLocationEndpoint.SalespersonList.Response> {
    if (search) {
      return Client(`location/${id}/salesperson?search=${search}&limit=${SharedConfig.maxPageLimit}`)
    }
    return Client(`location/${id}/salesperson?limit=${SharedConfig.maxPageLimit}`)
  }

  static getRetailerLocationById (id: string):
    Promise<RetailerLocationEndpoint.LocationById.Response> {
    return Client(`location/${id}`)
  }

  static getAreRetailerLocationsPreferred (id: string):
    Promise<RetailerEndpoint.GetLocationsPreferred.Response> {
    return Client(`retailer/${id}/locations-preferred`, {
      method: 'GET',
    });
  }

  static setRetailerLocationsPreferred (id: string, isPreferred: boolean):
    Promise<RetailerEndpoint.LocationsPreferred.Response> {
    return Client(`retailer/${id}/locations-preferred`, {
      body: { isPreferred },
      method: 'POST',
    });
  }

  static updateRetailerLocation (id: string, request: RetailerLocationEndpoint.Update.Request):
    Promise<void> {
    return Client(`location/${id}`, {
      body: request,
      method: 'PATCH',
    })
  }

  static updateMember
  (id: string, request: UserEndpoint.Update.Request, isLocation?: boolean): Promise<void> {

    if (!isLocation) {
      delete request.locationId;
    }

    return Client(`users/${id}`, {
      body: request,
      method: 'PATCH',
    })
  }

  static deleteMember (id: string): Promise<void> {
    return Client(`users/${id}`, {
      method: 'DELETE',
    })
  }

  static getActiveRetailerLists (): Promise<RetailerEndpoint.RetailerList.Response> {
    return Client('retailer/active/list')
  }

  static getCounties (id: string): Promise<ApiCounty[]> {
    return Client(`location/${id}/allowed-counties`)
  }

  static getCountiesByState (state: string):
    Promise<UserEndpoint.ListCounties.Response> {
    return Client(`counties/?state=${state}`)
  }

  static addCounties (id: string, request: RetailerLocationEndpoint.UpdateAllowedCounties.Request):
    Promise<void> {
    return Client(`location/${id}/allowed-counties?isUpdateCounties=true`, {
      body: request,
      method: 'POST',
    })
  }

  static updateLocation (id: string, request: RetailerLocationEndpoint.Update.Request):
    Promise<void> {
    return Client(`location/${id}`, {
      body: request,
      method: 'PATCH',
    })
  }

  static deleteLocationCounty (id: string, county: string): Promise<void> {
    return Client(`location/${id}/allowed-counties?countyId=${county}`, {
      method: 'DELETE',
    })
  }
}
