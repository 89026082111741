import { Button, Modal, Search, Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { debounce } from '@/utilities/debounce';
import { ApiPricingRequest, ApiUser } from '@api/interfaces';
import { Alert, Divider, Stack } from "@mui/material";
import { getExpirationStatus, getFarmerName } from '@shared/utilities';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import AdminViewPricingRequest from "./PricingRequestList/AdminViewPricingRequest";

interface PricingRequestListModalProps {
  farmer: ApiUser,
  onClose: () => void,
  pricingRequests: ApiPricingRequest[],
}

const PricingRequestListModal = ({
  farmer,
  onClose,
  pricingRequests,
}: PricingRequestListModalProps) => {

  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  const [
    filteredPricingRequests,
    setFilteredPricingRequests,
  ] = useState<ApiPricingRequest[]>();
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedPricingRequestId, setSelectedPricingRequestId] = useState('');
  const [error, setError] = useState('');

  const refetch = async () => {
    setSearch("");
    await queryClient.invalidateQueries([
      QueryKeys.GET_PRICING_REQUESTS,
      farmer.id,
    ]);
  }

  useQuery(
    [QueryKeys.GET_PRICING_REQUESTS, farmer.id, search],
    () => PricingRequestsApi.getPricingRequests({
      userId: farmer.id,
      sortDesc: true,
      sort: "updatedAt",
      search,
    }),
    {
      onSuccess: (data) => {
        setFilteredPricingRequests(data?.data);
      },
      onError: (error: { message: string }) => {
        setError(error.message || "An error has occurred");
      },
      enabled: !!debouncedSearch,
    },
  );

  useEffect(() => debounce(() => setDebouncedSearch(search), 600), [search]);

  const farmerName = getFarmerName(farmer, true);
  const farmerCityState = [farmer.city, farmer.state].filter(Boolean).join(', ');
  const sublineText = [farmerName, farmerCityState].filter(Boolean).join(' | ');

  const PricingRequestRow = ({ pricingRequest }: { pricingRequest: ApiPricingRequest }) => {
    const approvedText = (pricingRequest.approvedDate)
      ? 'Approved: ' + new Date(pricingRequest.approvedDate).toLocaleDateString()
      : '';
    const expirationStatus = pricingRequest.expiration
      ? getExpirationStatus(new Date(pricingRequest.expiration))
      : "Pending approval";

    return (
      <Stack direction="row" justifyContent="space-between" sx={{ padding: '12px' }}>
        <Stack direction="row">
          <Text category="p1">{pricingRequest.publicId}</Text>
          <Text category="p1">
            &nbsp;{approvedText}
          </Text>
          <Text category="p1">
            &nbsp;| {expirationStatus}
          </Text>
        </Stack>
        <Button
          onClick={() => setSelectedPricingRequestId(pricingRequest.id)}
          size="small"
          testID="pricing-request-list-modal-view-offer-button"
          variant="text"
        >
          View product request
        </Button>
      </Stack>
    )
  };

  return (
    <>
      <Modal
        cancelButton={(props) => (
          <Button onClick={onClose} {...props}>
            Close
          </Button>
        )}
        onClose={onClose}
        open
        subline={sublineText}
        testID="pricing-request-list-modal"
        title="Product Requests"
      >
        <Search
          onChangeText={setSearch}
          testID="pricing-request-list-modal-search"
          value={search}
          width={289}
        />
        <VSpacer size="8" />
        <Text category="p2">
          {pricingRequests.length} Product Requests
        </Text>
        {!!error && (
          <Alert color="error" icon={false}>{error}</Alert>
        )}
        <Stack
          divider={<Divider />}
          maxHeight={320}
          overflow="hidden"
          sx={{ overflowY: 'scroll' }}
        >
          {(filteredPricingRequests
            ? filteredPricingRequests
            : pricingRequests).map((r) => (
              <PricingRequestRow key={r.id} pricingRequest={r} />
          ))}
        </Stack>
      </Modal>
      {!!selectedPricingRequestId && (
        <AdminViewPricingRequest
          onClose={() => {
            setSelectedPricingRequestId('');
            void refetch();
          }}
          pricingRequestId={selectedPricingRequestId}
        />
      )}
    </>
  );
}

export default PricingRequestListModal;
