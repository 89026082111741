import { useState } from 'react';
import { Button, VSpacer } from '@/components/DesignSystem';
import { Container, Divider, CircularProgress, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import MembersCard from '../Members/MemberOverviewSection';
import LocationDetailsInfo from './LocationDetailsCard';
import CountiesCard from './Counties/CountiesCard';
import PreferredRetailerCard from './PreferredRetailerFarmers/PreferredRetailerCard';
import { useGetLocationById, useGetLocationMembers } from '@/hooks/useRetailerLocations';
import { useSearch } from '@/hooks/useSearch';
import { ApiMember } from '@api/interfaces';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';

const Loader = () => (
  <Container>
    <Stack alignItems='center'>
      <VSpacer size='14' />
      <CircularProgress />
    </Stack>
  </Container>
);

const LocationDetailsOverviewPage = () => {
  const [showAddMember, setShowAddMember] = useState(false);
  const [showViewMember, setShowViewMember] = useState(false);

  const { locationId = '' } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const onAddMember = () => setShowAddMember(!showAddMember);
  const onViewMember = () => setShowViewMember(!showViewMember);

  const { debouncedSearch, search, setSearch } = useSearch();

  const { getLocationMembers } = useGetLocationMembers(locationId, debouncedSearch);

  const { getLocationById, isLoading } = useGetLocationById(locationId);

  const defaultSalesPerson =
  getLocationMembers?.data?.find((member: ApiMember) => member.defaultSalesperson);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container maxWidth='lg'>
      <Button
        onClick={onBack}
        startIcon={<KeyboardBackspaceIcon />}
        sx={{
          color: 'white',
          margin: '25px 0px',
        }}
        testID='back-to-retailer-details'
        variant='text'
      >
        Back to all locations
      </Button>
      <LocationDetailsInfo
        defaultSalesPerson={defaultSalesPerson?.businessName ?? ''}
      />
      <MembersCard
        getMembersCountText={stringifyCount(getLocationMembers?.total, 'member', 'members')}
        isLocation={true}
        members={getLocationMembers}
        onAddMember={onAddMember}
        onViewMember={onViewMember}
        search={search}
        setSearch={setSearch}
        showAddMember={showAddMember}
        showViewMember={showViewMember}
      />
      <Divider />
      <CountiesCard serveAllCounties={getLocationById?.serveAllCounties ?? false} />
      <Divider />
      <PreferredRetailerCard
        isPreferredEligible={getLocationById?.isPreferred ?? false}
      />
      <Divider />
    </Container>
  );
};

export default LocationDetailsOverviewPage;
