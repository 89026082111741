import { Button, VSpacer, ProgressLoader } from '@/components/DesignSystem';
import React, { Fragment } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import ManufacturerCard from './ManufacturerCard';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { useQuery } from 'react-query';
import { Divider } from '@mui/material';
import UploadImage from './UploadImage';

const ManufacturerDetails = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { data: manufacturer = [], isLoading: isManufacturerLoading } =
    useQuery([QueryKeys.GET_MANUFACTURER_BY_ID, id], () =>
      ManufacturerApi.getManufacturer(id),
    );

  if (isManufacturerLoading) {
    return (
      <div
        style={{
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <ProgressLoader type='circular' />
      </div>
    );
  }

  return (
    <Fragment>
      <Container maxWidth='lg'>
        <VSpacer size='5' />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => navigate(-1)}
            sx={{ display: 'flex', align: 'center' }}
            testID='back-to-all-retailers-button'
            variant='text'
          >
            <KeyboardBackspaceIcon sx={{ marginRight: '5px' }} />
            Back to all manufacturers
          </Button>
        </div>
        <VSpacer size='5' />
        <ManufacturerCard
          isRedirectable={false}
          manufacturer={manufacturer[0]}
        />
        <VSpacer size='5' />
        <div style={{ padding: '0px 20px' }}>
          <UploadImage id={id} manufacturerById={manufacturer[0]} />
        </div>
        <Divider />
      </Container>
    </Fragment>
  );
};

export default ManufacturerDetails;
