import { GlobalBanner } from '@/components/DesignSystem/Banner/GlobalBanner'
import { DemoBlock } from '../../components/DemoBlock'
import { BannerStatus, BannerStatuses } from '@/themes/variant-interfaces/Status'
import { useState } from 'react';
import { Button } from '@/components/DesignSystem';

const initialState = Array(BannerStatuses.length).fill(true);

const buildMessage = (status: BannerStatus) => (
  `This is a${status === 'info' ? 'n' : ''} ${status} message`
);

export const GlobalBannerDemo = () => {
  const [openStates, setOpenStates] = useState<boolean[]>(initialState);

  const handleBannerClose = (index: number) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = false;
    setOpenStates(newOpenStates);
  };

  return (
    <>
      <DemoBlock label="Title only" spacing={2}>
        {BannerStatuses.map((status) => (
          <GlobalBanner
            hideIcon
            key={status}
            message={buildMessage(status)}
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <DemoBlock label="Title with close" spacing={2}>
        {BannerStatuses.map((status) => (
          <GlobalBanner
            hideIcon
            key={status}
            message={buildMessage(status)}
            onClose={() => null}
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <DemoBlock label="Title with action" spacing={2}>
        {BannerStatuses.map((status) => (
          <GlobalBanner
            action={(
              <Button
                size="small"
                testID="test-button"
                variant="text"
              >
                Action
              </Button>
            )}
            hideIcon
            key={status}
            message={buildMessage(status)}
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <DemoBlock label="Title with icon" spacing={2}>
        {BannerStatuses.map((status) => (
          <GlobalBanner
            key={status}
            message={buildMessage(status)}
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <DemoBlock label="Title with icon and close" spacing={2}>
        {BannerStatuses.map((status) => (
          <GlobalBanner
            key={status}
            message={buildMessage(status)}
            onClose={() => null}
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <DemoBlock label="Title with icon and action" spacing={2}>
        {BannerStatuses.map((status) => (
          <GlobalBanner
            action={(
              <Button
                size="small"
                testID="test-button"
                variant="text"
              >
                Action
              </Button>
            )}
            key={status}
            message={buildMessage(status)}
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <DemoBlock label="All options" spacing={2}>
        {BannerStatuses.map((status, idx) => (
          <GlobalBanner
            action={(
              <Button
                size="small"
                testID="test-button"
                variant="text"
              >
                Action
              </Button>
            )}
            bottomSpacerSize="7"
            key={status}
            message={buildMessage(status)}
            onClose={() => handleBannerClose(idx)}
            open={openStates[idx]}
            position="static"
            status={status}
            testID={`${status}-demo`}
          />
        ))}
      </DemoBlock>
      <Button
        disabled={!openStates.some((openState) => openState === false)}
        onClick={() => setOpenStates(initialState)}
        testID="button"
      >
        Reset Banners
      </Button>
    </>
  );
}
