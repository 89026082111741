import { Client } from '@/utilities/api/Client';
import { SponsoredBannerEndPoint } from '@api/endpoints';

export class SponseredBannerApi {
  static create (body: SponsoredBannerEndPoint.Create.Request) {
    return Client('sponsored-banner', { body, method: 'POST' });
  }
  static list (
    search?: string,
  ): Promise<SponsoredBannerEndPoint.ListSponsoredBanner.Response[]> {
    if (search) {
      return Client(`sponsored-banner?search=${search}`);
    }
    return Client('sponsored-banner');
  }

  static deleteSponsoredBanner (id: string): Promise<void> {
    return Client(`sponsored-banner/${id}`, { method: 'DELETE' });
  }

  static reOrderedSponsoredBanner (
    ids: string[],
  ): Promise<SponsoredBannerEndPoint.ListSponsoredBanner.Response[]> {
    return Client(`sponsored-banner/`, { method: 'PATCH', body: ids });
  }
}
