import {
  Button,
  Card,
  Chip,
  DataPoint,
  HSpacer,
  Radio,
  Text,
  TextLink,
  VSpacer,
} from "@/components/DesignSystem";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from "@/constants/Routes";
import { getResponsiveValue } from '@/hooks/useMediaQuery';
// import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ReviewAndSelectOffersEventType, useLogEvent } from '@/utilities/Analytics';
import { OffersApi } from '@/utilities/api/OffersApi';
// import { UserApi } from '@/utilities/api/UserApi';
import { getTotalProductCount } from "@/utilities/PricingRequest";
import { ApiOffer, ApiOfferProduct, ApiPricingRequest } from "@api/interfaces";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Check from "@mui/icons-material/Check";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import StickyNote2Outlined from "@mui/icons-material/StickyNote2Outlined";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import {
  DiscountType,
  OfferStatus,
  PaymentMethodPreference,
  PaymentStatus,
  PricingRequestStatus,
} from "@shared/enums";
import { formatCurrency, formatPhoneNumber, localizeNumber } from '@shared/utilities';
import { useEffect, useMemo, useState } from "react";
import { useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
import OfferFeatureChip from "./OfferFeatureChip";

interface OfferDetailsProps {
  index: number,
  offer: ApiOffer,
  onPay?: (
    offer: ApiOffer,
    productRequest: ApiPricingRequest,
    connectAccountId: string | null,
  ) => void,
  onShowNote: (offer: ApiOffer) => void,
  paymentStatus?: PaymentStatus | null,
  pricingRequest: ApiPricingRequest,
  readonly?: boolean,
  selectMode?: boolean,
  showPrice?: boolean,
}

const OfferDetails = ({
  index,
  offer,
  onShowNote,
  onPay,
  pricingRequest,
  readonly = false,
  selectMode = true,
  showPrice = true,
}: OfferDetailsProps) => {
  // const { openSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [verifyingDeposit, setVerifyingDeposit] = useState(false);
  const [connectAccountId, _] = useState<string | null>(null);

  const navigate = useNavigate();
  const logEvent = useLogEvent();

  const { data: payment } = useQuery(
    [QueryKeys.GET_STRIPE_PAYMENT, offer.id],
    () => OffersApi.getFarmerStripePayment(offer.id),
    {
      enabled: offer.paymentMethodPreference === PaymentMethodPreference.INSIDE,
    },
  )

  const partnerRetailerId = pricingRequest.salespersons?.find((r) => (
    r.salespersonId === offer.pricingRequestRetailer?.salespersonId
    && offer.paymentMethodPreference === PaymentMethodPreference.INSIDE
  ))?.salespersonId;

  useEffect(() => {
    if (payment?.status === PaymentStatus.PAID) {
      setVerifyingDeposit(false);
    }
  }, [payment?.status]);

  // useQuery([QueryKeys.GET_CONNECT_ACCOUNT_ID], () => (
  //   partnerRetailerId && UserApi.getRetailerConnectAccountId(partnerRetailerId)
  // ), {
  //   enabled: !!partnerRetailerId
  //     && offer?.paymentMethodPreference === PaymentMethodPreference.INSIDE,
  //   onError: () => {
  //     openSnackbar('Payment could not be completed. Please try again');
  //   },
  //   onSuccess: (data) => {
  //     if (partnerRetailerId && typeof data === 'string') {
  //       setConnectAccountId(data);
  //     }
  //   },
  // });

  const offerHasBeenAccepted = offer.status === OfferStatus.Accepted
    || offer.status === OfferStatus.Partial;

  const hasAcceptedAllProductsInOffer = offer.products?.every((product) => product.isAccepted);

  const totalWithNoDiscounts = useMemo(() => {
    let totalAmount = 0;
    const partiallyAcceptedProducts = offer.products?.filter((product) => product.isAccepted);
    const products = offerHasBeenAccepted
      ? (hasAcceptedAllProductsInOffer ? offer.products : partiallyAcceptedProducts)
      : offer.products;
    products?.forEach((product) => {
      totalAmount += (product.price || 0);
    });
    return totalAmount;
  }, [offerHasBeenAccepted, hasAcceptedAllProductsInOffer, offer.products]);

  const discountsTotal = useMemo(() => {
    if (!offer.discountAmount) {
      return 0;
    }
    if (offer.discountType === DiscountType.Dollar) {
      return offer.discountAmount;
    } else {
      return totalWithNoDiscounts * (offer.discountAmount / 100);
    }
  }, [
    offer.discountAmount,
    offer.discountType,
    totalWithNoDiscounts,
  ]);

  const isInReview = pricingRequest.status === PricingRequestStatus.Review;

  const CompanionOrAlternateChip = (product: ApiOfferProduct) => {
    return (
      <>
        {!!product.pricingRequestProduct?.companionToProductId && (
          <Chip
            color="warning"
            label="Companion"
            testID={`offer-details-companion-chip-${index}`}
          />
        )}
        {!!product.pricingRequestProduct?.alternativeTo && (
          <Chip
            label="Alternate"
            testID={`offer-details-alternative-chip-${index}`}
          />
        )}
      </>
    )
  }

  const showDiscountAmount = !!offer.discountAmount && offer.status !== OfferStatus.Partial;
  const changeTotalStyle = !!offer.discountAmount && offer.status === OfferStatus.Accepted;

  const disablePayButton = payment?.status === PaymentStatus.UNPAID
    || payment?.status === PaymentStatus.PROCESSING
    || verifyingDeposit;
  const paymentRequiresAction = payment?.status === PaymentStatus.REQUIRES_ACTION
    && !!payment.trialDepositUrl;
  const showPayButton = payment?.status !== PaymentStatus.PAID
    && !!offer.pricingRequestRetailer?.salespersonId;
  const payingInside = offer?.paymentMethodPreference === PaymentMethodPreference.INSIDE;

  const PaymentButton = !!partnerRetailerId && (
    <Button
      disabled={disablePayButton}
      onClick={() => {
        if (paymentRequiresAction && !!payment.trialDepositUrl) {
          setVerifyingDeposit(true);
          window.open(payment.trialDepositUrl);
        } else {
          onPay?.(offer, pricingRequest, connectAccountId);
        }
      }}
      sx={{ marginLeft: getResponsiveValue(1, undefined) }}
      testID="show-pay-button"
    >
      {paymentRequiresAction ? 'Verify Trial Deposit' : 'Pay'}
    </Button>
  );

  const PaymentStatusChip = payingInside
    && payment
    && (
      <>
        <HSpacer size="3" />
        <Chip
          color="warning"
          label={payment?.status as PaymentStatus}
          testID="payment-status"
        />
      </>
    );

  const PaidChip = payingInside ? (
    <Chip color="success" label="Paid" testID="paid-chip" />
  ) : null;

  const Header = () => (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Stack alignItems="center" direction="row">
          {selectMode ? (
            <Radio testID="offer-details-id" value={offer.id} />
          ) : (
            <>
              {
                (offer.products?.some((p) => p.isAccepted) ?? false) && (
                  <Stack alignItems="center" direction="row">
                    {!!onPay && (
                      <>
                        {showPayButton ? (
                          <>
                            {PaymentButton}
                            {PaymentStatusChip}
                          </>
                        ) : PaidChip}
                        <HSpacer size="4" />
                      </>
                    )}
                    <CheckCircleOutlined color="success" />
                    <HSpacer size="4" />
                  </Stack>
                )
              }
            </>
          )}
          <Stack direction="column">
            <Text
              fontSize="18px"
              fontWeight="500"
              testID="label-retailer-name"
            >
              {offer.pricingRequestRetailer?.name}
            </Text>
            <TextLink
              color="primary"
              fontSize="18px"
              fontWeight="500"
              href={
                offer.pricingRequestRetailer?.retailerSalesperson?.telephone ?
                  `tel:${offer.pricingRequestRetailer?.retailerSalesperson?.telephone}` :
                  `mailto:${offer.pricingRequestRetailer?.retailerSalesperson?.email}`
              }
              testID="link-retailer-contact-info"
            >
              {
                offer.pricingRequestRetailer?.retailerSalesperson?.telephone ?
                  formatPhoneNumber(offer.pricingRequestRetailer?.retailerSalesperson?.telephone) :
                  offer.pricingRequestRetailer?.retailerSalesperson?.email
              }
            </TextLink>
          </Stack>
        </Stack>
        <VSpacer size="4" />
        <MobileOnly>
          <ProductCount />
        </MobileOnly>
      </Stack>
      <MobileOnly>
        <Stack sx={{ textAlign: "right" }}>
          <Text
            fontSize="18px"
            fontWeight="500"
          >
            {formatCurrency(totalWithNoDiscounts - discountsTotal)}
          </Text>
          <Text>
            TOTAL PRICE
          </Text>
        </Stack>
      </MobileOnly>
      <DesktopOnly>
        <Stack alignItems="center" direction="row">
          <Stack sx={{ textAlign: "right" }}>
            <Text
              fontSize="18px"
              fontWeight="500"
              lineThrough={changeTotalStyle}>
              {formatCurrency(totalWithNoDiscounts)}
            </Text>
            <Text>
              TOTAL PRICE
            </Text>
          </Stack>
          {
            showDiscountAmount && (
              <>
                <HSpacer size="5" />
                <Stack sx={{ textAlign: "right" }}>
                  <Text fontSize="18px" fontWeight="500">
                    {formatCurrency(totalWithNoDiscounts - discountsTotal)}
                  </Text>
                  <Stack direction="row">
                    <Text
                      fontSize="12px"
                      fontWeight="500">
                      DISCOUNT:
                    </Text>
                    <HSpacer size="2" />
                    <Text
                      color="success"
                      fontSize="12px"
                      fontWeight="500"
                    >
                      {`-${offer.discountType === DiscountType.Dollar
                        ? formatCurrency(offer.discountAmount ?? 0)
                        : `${offer.discountAmount}%`}`}
                    </Text>
                  </Stack>
                </Stack>
              </>
            )
          }
        </Stack>
      </DesktopOnly>
    </Stack>
  );

  const ProductCount = () => {
    const totalProductsCount = getTotalProductCount(pricingRequest.products || []);

    return !selectMode ? (
      <Text category="p2">
        {`Selected products: ${offer.products?.filter((p) => p.isAccepted).length} of ${totalProductsCount}`}
      </Text>
    ) : (
      <Text category="p2">
        {`Available products: ${offer.products?.length} of ${totalProductsCount}`}
      </Text>
    );
  }

  const MobileDivider = () => (
    <>
      <VSpacer size="5" />
      <Divider />
      <VSpacer size="7" />
    </>
  )

  const showDiscountAppliedChip = !!offer.discountAmount && offer.status !== OfferStatus.Partial;
  const showAllProductsAvailableChip = (
    selectMode
    && pricingRequest.products?.length === offer.products?.length
  );
  const showSubstituteProductChip = offer.products?.some((p) => !!p.substituteProduct);
  const showNoteChip = !!offer.note;

  const showChips = showDiscountAppliedChip
    || showAllProductsAvailableChip
    || showSubstituteProductChip
    || showNoteChip;

  const Chips = () => (
    <>
      {showDiscountAppliedChip && (
        <>
          <DesktopOnly>
            <HSpacer size="6" />
          </DesktopOnly>
          <MobileOnly>
            <VSpacer size="2" />
          </MobileOnly>
          <OfferFeatureChip
            icon={(props) => <LocalOfferOutlined {...props} />}
            label="Discount applied"
            testID={`offer-feature-chip-discount-applied-${index}`} />
        </>
      )}
      {
        showAllProductsAvailableChip && (
          <>
            <DesktopOnly>
              <HSpacer size="6" />
            </DesktopOnly>
            <MobileOnly>
              <VSpacer size="2" />
            </MobileOnly>
            <OfferFeatureChip
              icon={(props) => <Check {...props} />}
              label="All products available"
              testID={`offer-feature-chip-all-products-available-${index}`} />
          </>
        )
      }
      {
        showSubstituteProductChip && (
          <>
            <DesktopOnly>
              <HSpacer size="6" />
            </DesktopOnly>
            <MobileOnly>
              <VSpacer size="2" />
            </MobileOnly>
            <OfferFeatureChip
              icon={(props) => <Check {...props} />}
              label="Substitute product"
              testID={`offer-feature-chip-substitute-product-${index}`} />
          </>
        )
      }
      {
        showNoteChip && (
          <>
            <DesktopOnly>
              <HSpacer size="6" />
            </DesktopOnly>
            <MobileOnly>
              <VSpacer size="2" />
            </MobileOnly>
            <OfferFeatureChip
              icon={(props) => <StickyNote2Outlined {...props} />}
              label="View Note"
              onClick={() => onShowNote(offer)}
              testID={`offer-feature-chip-view-note-${index}`} />
          </>
        )
      }
    </>
  );

  const formatQuantity = (product: ApiOfferProduct) => {
    const quantity = product.pricingRequestProduct?.recommendedRateNote
      ? product.quantity
      : product.pricingRequestProduct?.quantity;

    return localizeNumber(
      quantity || 0,
      2,
      true,
    );
  }

  const formatUom = (product: ApiOfferProduct) => {
    if (product.pricingRequestProduct?.recommendedRateNote) {
      return (product.uom ? FriendlyUoM[product.uom] : "");
    }

    return (product.pricingRequestProduct?.uom
      ? FriendlyUoM[product.pricingRequestProduct?.uom]
      : "");
  }

  const ProductsTable = () => (
    <>
      <MobileOnly>
        <Stack>
          {
            offer.products?.map((product) => {
              const isDisabled = !selectMode && !product.isAccepted;
              return (
                <Stack key={product.id}>
                  <Text
                    disabled={isDisabled}
                    fontSize="16px"
                    fontWeight="500"
                  >
                    {product.substituteProduct
                      ? product.substituteProduct
                      : product.pricingRequestProduct?.name
                    }
                  </Text>
                  <Stack>
                    <DataPoint
                      label="Package size:"
                      testID="package-size"
                    >
                      {product.pricingRequestProduct?.package}
                    </DataPoint>
                  </Stack>
                  <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Stack direction="row">
                      <Text
                        category="tiny"
                        disabled={isDisabled}
                      >
                        {formatQuantity(product)}
                      </Text>
                      <HSpacer mobileSize="2" />
                      <Text
                        category="tiny"
                        disabled={isDisabled}
                      >
                        {formatUom(product)}
                      </Text>
                    </Stack>
                    {showPrice && (
                      <Text category="p1" disabled={isDisabled}>
                        {formatCurrency(product.price)}
                      </Text>
                    )}
                  </Stack>
                  <VSpacer size="4" />
                  {CompanionOrAlternateChip(product)}
                  <VSpacer size="6" />
                </Stack>
              )
            },
            )
          }
          {!!offer.discountAmount && (
            <Stack>
              <Divider light />
              <VSpacer size="3" />
              <Text color="success" fontSize="16px" fontWeight="500">
                Discount
              </Text>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Text category="tiny">
                  {`Discount applied (-${offer.discountType === DiscountType.Dollar
                    ? formatCurrency(offer.discountAmount)
                    : `${offer.discountAmount}%`})`}
                </Text>
                <Text category="p1" color="success">
                  {`(-${formatCurrency(discountsTotal)})`}
                </Text>
              </Stack>
              <VSpacer size="7" />
            </Stack>
          )}
        </Stack>
      </MobileOnly>
      <DesktopOnly>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Products</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>UoM</TableCell>
              <TableCell>Package size</TableCell>
              <TableCell>Price</TableCell>
              {offer?.products?.some(
                (product) =>
                  product.pricingRequestProduct?.companionToProductId
                  || product.pricingRequestProduct?.alternativeTo,
              ) && <TableCell>Product Info</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {offer.products?.map((product) => {
              const isDisabled = !selectMode && !product.isAccepted;
              const overflowStyle: SxProps<Theme> = {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }
              return (
                <TableRow key={product.id}>
                  <TableCell sx={overflowStyle}>
                    <Stack direction="row">
                      <Text category="p2" disabled={isDisabled}>
                        {product.substituteProduct
                          ? product.substituteProduct
                          : product.pricingRequestProduct?.name
                        }
                      </Text>
                      {!!product.substituteProduct && (
                        <>
                          <HSpacer size="2" />
                          <Text
                            category="p2"
                            disabled={isDisabled}
                          >
                            {` (Substitute for ${product.pricingRequestProduct?.name})`}
                          </Text>
                        </>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Text category="p2" disabled={isDisabled}>
                      {formatQuantity(product)}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text category="p2" disabled={isDisabled}>
                      {formatUom(product)}
                    </Text>
                  </TableCell>
                  <TableCell sx={overflowStyle}>
                    <Text category="p2" disabled={isDisabled}>
                      {product.pricingRequestProduct?.package}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text category="p2" disabled={isDisabled}>
                      {showPrice ? formatCurrency(product.price) : '-'}
                    </Text>
                  </TableCell>
                  <TableCell>
                    {CompanionOrAlternateChip(product)}
                  </TableCell>
                </TableRow>
              )
            },
            )}
          </TableBody>
        </Table>
      </DesktopOnly>
    </>
  );

  return (
    <>
      <DesktopOnly>
        <Card testID="offer-details-card">
          <Stack>
            <Header />
            <Stack direction="row">
              <ProductCount />
              <Chips />
            </Stack>
            <ProductsTable />
            <VSpacer size="8" />
          </Stack>
        </Card>
      </DesktopOnly>
      <MobileOnly>
        {
          (selectMode || !readonly) ? (
            <Accordion disableGutters expanded={expanded}>
              <AccordionSummary>
                <Stack sx={{ width: "calc(100vw - 30px)" }}>
                  <Header />
                  <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Stack>
                      <VSpacer size="3" />
                      <Chips />
                    </Stack>
                    <Button
                      endIcon={expanded
                        ? <ExpandLess fontSize="small" />
                        : <ExpandMore fontSize="small" />
                      }
                      onClick={() => setExpanded(!expanded)}
                      testID="offer-details-expand-details-button"
                      variant="text"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="500">
                        DETAILS
                      </Text>
                    </Button>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <ProductsTable />
              </AccordionDetails>
            </Accordion>
          ) : (
            <Container maxWidth="md">
              <Stack>
                <MobileOnly>
                  {
                    isInReview && (
                      <>
                        <Stack direction="row" justifyContent="center">
                          <Button
                            endIcon={<ArrowForward fontSize="small" />}
                            onClick={() => {
                              logEvent(ReviewAndSelectOffersEventType.ClickReviewAndSelectOffers);
                              navigate(
                                Routes.REVIEW_SELECT_OFFERS.replace(
                                  ":id",
                                  pricingRequest.id,
                                ),
                              );
                            }}
                            size="small"
                            testID="offer-details-review-button"
                            variant="outlined"
                          >
                            {"Review & Select Offers"}
                          </Button>
                        </Stack>
                        <VSpacer size="8" />
                      </>
                    )
                  }
                </MobileOnly>
                <Header />
                {selectMode && (
                  <>
                    <MobileDivider />
                    <Stack>
                      <Text
                        fontSize="18px"
                        fontWeight="500">
                        {formatCurrency(totalWithNoDiscounts - discountsTotal)}
                      </Text>
                      <Text>
                        TOTAL PRICE
                      </Text>
                    </Stack>
                  </>
                )}
                {showChips && (
                  <>
                    <MobileDivider />
                    <Chips />
                  </>
                )}
                <MobileDivider />
                <ProductsTable />
              </Stack>
            </Container>
          )
        }

      </MobileOnly>
    </>
  );
}

export default OfferDetails;
