import {
  Search,
} from '@/components/DesignSystem';
import { useState } from 'react';

export const SearchDemo = () => {

  const [search, setSearch] = useState<string>();

  return (
    <Search 
      onChangeText={setSearch}
      testID="search-demo" 
      value={search} 
      width={400} />
  );
}
