import {
  Button,
  Dialog,
  IconButton,
  Input,
  NumericInput,
  VSpacer,
} from '@/components/DesignSystem';
import { CreateOfferEventType, useLogEvent } from '@/utilities/Analytics';
import Check from '@mui/icons-material/Check';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { ButtonGroup, Stack } from "@mui/material";
import { DiscountType } from '@shared/enums';
import { useState } from 'react';

export type OfferDiscount = {
  name?: string,
  amount?: number,
  type?: DiscountType,
}

interface AddDiscountModalProps {
  discount?: OfferDiscount,
  onClose: () => void,
  onRemoveDiscount?: () => void,
  onSave: (discount: OfferDiscount) => void,
}

const maxDollarValue = 99999999.99;
const minDollarValue = -99999999.99;
const maxPercentValue = 100.0;
const minPercentValue = -99999999.9;

export const AddDiscountModal = ({
  discount,
  onClose,
  onRemoveDiscount,
  onSave,
}: AddDiscountModalProps) => {
  const [discountName, setDiscountName] = useState(discount?.name || '');
  const [discountAmount, setDiscountAmount] = useState<number | undefined>(discount?.amount);
  const [
    discountType,
    setDiscountType] = useState<DiscountType>(discount?.type || DiscountType.Dollar);
  const [nameError, setNameError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const logEvent = useLogEvent();

  const isFormValid = !!discountName && !!discountAmount;

  return (
    <Dialog
      acceptButton={(props) => (
        <Button
          disabled={!isFormValid}
          onClick={() => {
            onSave({
              name: discountName,
              amount: discountAmount || 0,
              type: discountType,
            });
            onClose();
            logEvent(CreateOfferEventType.ClickApplyDiscount);
          }}
          {...props}
        >
          {discount ? 'Save' : 'Apply'}
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          onClick={() => {
            onClose();
            logEvent(CreateOfferEventType.ClickCancelDiscount);
          }}
          {...props}
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      secondaryActionButton={discount && onRemoveDiscount ? () => (
        <IconButton
          onClick={() => {
            onRemoveDiscount();
            onClose();
          }}
          testID="add-discount-modal-remove-discount"
        >
          <DeleteOutlined />
        </IconButton>
      ): undefined}
      testID="add-discount-modal-dialog"
      title={discount ? 'Edit Discount' : 'Add Discount'}
    >
      <VSpacer size="5" />
      <Stack>
        <Input
          error={nameError}
          label="Discount name"
          onBlur={() => setNameError(!discountName)}
          onBlurChanged={() => logEvent(CreateOfferEventType.ChangeDiscountName)}
          onChangeText={setDiscountName}
          testID="add-discount-modal-name"
          value={discountName}
        />
        <VSpacer size="9" />
        <ButtonGroup>
          <Button
            aria-selected={discountType === DiscountType.Dollar}
            fullWidth
            onClick={() => {
              setDiscountAmount(undefined);
              setDiscountType(DiscountType.Dollar);
              logEvent(CreateOfferEventType.ClickDiscountType);
            }}
            startIcon={discountType === DiscountType.Dollar && <Check />}
            testID="add-discount-modal-dollar-button"
          >
            {DiscountType.Dollar}
          </Button>
          <Button
            aria-selected={discountType === DiscountType.Percentage}
            fullWidth
            onClick={() => {
              setDiscountAmount(undefined);
              setDiscountType(DiscountType.Percentage);
              logEvent(CreateOfferEventType.ClickDiscountType);
            }}
            startIcon={discountType === DiscountType.Percentage && <Check />}
            testID="add-discount-modal-percentage-button"
          >
            {DiscountType.Percentage}
          </Button>
        </ButtonGroup>
        <VSpacer size="9" />
        <NumericInput
          decimals={discountType === DiscountType.Dollar ? 2 : 1}
          error={amountError}
          label="Discount amount"
          maxValue={discountType === DiscountType.Dollar ? maxDollarValue : maxPercentValue}
          minValue={discountType === DiscountType.Dollar ? minDollarValue : minPercentValue}
          onBlur={() => setAmountError(!discountAmount)}
          onBlurChanged={() => logEvent(CreateOfferEventType.ChangeDiscountAmount)}
          onChangeNumber={setDiscountAmount}
          prefix={discountType === DiscountType.Dollar ? '$' : undefined}
          showFixedDecimals={discountType === DiscountType.Dollar}
          suffix={discountType === DiscountType.Percentage ? '%' : undefined}
          testID="add-discount-modal-amount"
          value={discountAmount}
        />
        <VSpacer size="7" />
      </Stack>
    </Dialog>
  );
}
