import { Client } from '@/utilities/api/Client';
import { StorefrontEndpoint } from '@api/endpoints';

export class StorefrontApi {
  static async get (
    id: string,
  ): Promise<StorefrontEndpoint.Get.Response> {
    return Client(`storefronts/${id}`);
  }

  static async getBySubdomain (
    subdomain: string,
  ): Promise<StorefrontEndpoint.Get.Response> {
    return Client(`storefronts/subdomain/${subdomain}`);
  }

  static getStorefrontRetailer (
    subdomain: string,
  ): Promise<StorefrontEndpoint.GetRetailer.Response> {
    return Client(`storefronts/${subdomain}/retailer`);
  }
}
