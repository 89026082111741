import { Button, Modal, Search, Text, VSpacer } from "@/components/DesignSystem";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Box, Divider, Stack } from "@mui/material";
import { State } from "@shared/enums";
import { Fragment, useMemo, useState } from "react";

export interface CountiesModalProps {
  counties: Map<State, string[]>,
  isVisible: boolean,
  onClose: () => void,
  state: State,
}

const CountiesModal = ({
  counties,
  isVisible,
  onClose,
  state,
}: CountiesModalProps) => {

  const [search, setSearch] = useState("");
  const { isMobile } = useMediaQuery();

  const filteredCounties = useMemo(() => {
    if (!counties) {
      return [];
    }

    return (counties.get(state) || []).filter(
      (c) => c.toLowerCase().includes(search.toLowerCase()),
    );

  }, [counties, state, search]);

  return (
    <Modal
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Close
        </Button>
      )}
      onClose={onClose}
      open={isVisible}
      testID="counties-modal"
      title={`Counties in ${state}`}
    >
      <Stack>
        <Search
          onChangeText={setSearch}
          testID="counties-modal-search"
          value={search}
          width={!isMobile ? 289 : undefined} />
        <VSpacer size="8" />
        <Text category="p2">
          {filteredCounties.length} {filteredCounties.length === 1 ? 'County' : 'Counties'}
        </Text>
        <Box sx={
          !isMobile ? {
            minHeight: 470,
            maxHeight: 470,
            overflow: "scroll",
          } : undefined
        }>
          {
            filteredCounties.map((county) => (
              <Fragment key={county}>
                <VSpacer size="4" />
                <Text category="p1">{county}</Text>
                <VSpacer size="4" />
                <Divider light />
              </Fragment>
            ))
          }
        </Box>
      </Stack>
    </Modal>
  );
}

export default CountiesModal;

