import { MenuItem, Select, Text } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { navigateToStorefront } from '@/utilities/StorefrontUtilities';
import { getSubdomain } from '@/utilities/ThemeUtilities';

export const StorefrontSwitcher = () => {
  if (!AppConfig.env.test) {
    return null;
  }
  const onSelectStorefront = async (value: string) => {
    const nextRoute = value === 'marketplace' ? Routes.LOGIN : Routes.STOREFRONT_LOGIN;
    navigateToStorefront(value === 'marketplace' ? null : value, nextRoute);
  }

  return (
    <Select
      label="Storefront"
      onChangeValue={onSelectStorefront}
      size="small"
      sx={{ maxWidth: '200px' }}
      testID="storefront-switcher"
      value={getSubdomain() ?? 'marketplace'}
    >
      <MenuItem
        testID="user-switcher-marketplace"
        value="marketplace"
      >
        <Text color="primary">
          Marketplace
        </Text>
      </MenuItem>
      <MenuItem
        testID="user-switcher-landus"
        value="landus"
      >
        <Text color="info">
          Landus
        </Text>
      </MenuItem>
    </Select>
  );
}
