import { Button, Heading, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { InlineMessage } from '@/components/DesignSystem/InlineMessage/InlineMessage';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiError } from '@api/interfaces';
import { CheckCircleOutline } from '@mui/icons-material';
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const CheckEmailSignUpPage = () => {
  const { isMobile } = useMediaQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { refreshUser } = useAuthentication();
  const emailSuccessMsg = "Email successfully resent";
  const errorMsg = "An error has occurred";

  const { mutate: resendEmail } = useMutation(
    () => UserApi.resendCreatePasswordEmail(id!), {
      onError: (err: ApiError) => {
        setErrorMessage(err.message || errorMsg);
      },
      onSuccess: () => {
        openSnackbar(emailSuccessMsg);
      },
    });

  useEffect(() => {
    if (!id || !location.state.email) {
      navigate(Routes.LANDING);
    }
  }, [location, id, navigate]);

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      maxWidth='439px'
      padding={isMobile ? '8px' : '32px'}
    >
      <CheckCircleOutline color="success" fontSize="large" />
      <VSpacer size="5" />
      <Heading level="1" testID='check-email-heading' title="Success!" />
      <VSpacer size="8" />
      <Text category="body-medium" testID='email-sent-text' textAlign="center">
        We've sent an email to {location.state.email} with a
        link to activate your account and set a password.
      </Text>
      <VSpacer size="5" />
      <VSpacer size="9" />
      <Button
        onClick={refreshUser}
        testID='continue-to-growers-app'
        variant="outlined"
      >
        Continue to The GROWERS App
      </Button>
      <VSpacer size="7" />
      <TextLink
        category="body-small"
        onClick={() => resendEmail()}
        testID='resend-email'
      >
        Resend account activation email
      </TextLink>
      {!!errorMessage && (
        <>
          <VSpacer size="9" />
          <InlineMessage
            secondaryText={errorMessage}
            secondaryTextColor="error"
            testID={'error'}
            title="Error:"
            titleColor="error"
          />
          <VSpacer mobileSize="5" size="8" />
        </>
      )}
    </Stack>
  )
}
