import { useState } from 'react';
import { Button, FullscreenDialog, Text } from '@/components/DesignSystem';

export const FullscreenDialogDemo = () => {

  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Button 
        onClick={() => { 
          setShow(true);
        }} 
        testID="full-screen-dialog-demo-show-button"
      >
        Show dialog
      </Button>
      {
        show && (
          <FullscreenDialog
            actionButton={(
              <Button testID="full-screen-dialog-demo-save-button" variant="text">
                Save
              </Button>
            )}
            onClose={() => setShow(false)}
            open={show}
            testID="fullscreendialog-demo"
            title="Fullscreen dialog demo">
              <Text category="body-medium">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Text>
          </FullscreenDialog>
        )
      }
    </>
  );
}
