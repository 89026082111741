import { isNil } from 'lodash';

/**
 * Return a new list consisting of all entries in a that do not appear in b.
 */
export function arraySubtract <T> (a: T[], b: T[]) {
  return a.filter((i) => !b.includes(i));
}

export function compactJoin (arr: unknown[], separator: string) {
  return arr.filter((val) => val != null && val !== '').join(separator);
}

/** Filter out nil values. Returns a new array. */
export const filterNils = <T> (array: (T | undefined | null)[]): T[] => {
  return array.filter((i) => !isNil(i)) as T[];
}

export const getDuplicateValues = (array: unknown[]) => {
  return array.filter((val, i, iteratee) => iteratee.includes(val, i + 1))
}

/**
 * Split a list of items into two lists, based on whether the provided function is true or false
 * for each item in the list.
 * @returns The first list is all the items for which the function was true, the second list is all
 * the items for which the function was false.
 */
export function splitList<Input> (
  inputs: Input[],
  discriminator: (input: Input) => unknown,
): [Input[], Input[]] {
  const trues: Input[] = [];
  const falses: Input[] = [];
  inputs.forEach((input) => {
    (discriminator(input) ? trues : falses).push(input);
  });

  return [trues, falses]
}

/**
 * Move the specified item one position left in the array. Returns a copy.
 */
export function swapLeft<T> (array: T[], item: T) {
  const index = array.indexOf(item);
  if (index === -1) { throw new Error('Item not found in array'); }
  if (index === 0) { return array; }
  const temp = array[index-1];
  const result = [...array];
  result[index-1] = result[index];
  result[index] = temp;
  return result;
}

/**
 * Move the specified item one position right in the array. Returns a copy.
 */
export function swapRight<T> (array: T[], item: T) {
  const index = array.indexOf(item);
  if (index === -1) { throw new Error('Item not found in array'); }
  if (index === (array.length - 1)) { return array; }

  const temp = array[index+1];
  const result = [...array];
  result[index+1] = result[index];
  result[index] = temp;
  return result;
}

export function weightedRandom <T> (
  options: { item: T, weight: number }[],
): T | undefined {
  if (!options.length) { return undefined;}
  const maxWeight = options.reduce((total, value) => total + value.weight, 0);

  const targetWeight = Math.random() * maxWeight;
  let currentWeight = 0;
  let index = 0;
  while (currentWeight + options[index].weight < targetWeight) {
    currentWeight += options[index].weight;
    index += 1;
  }

  return options[index].item;
}
