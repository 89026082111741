import { Banner, BannerStatus, Text, VSpacer } from '@/components/DesignSystem';
import { Divider, Stack } from '@mui/material';

export const BannerDemo = () => {
  const statuses: BannerStatus[] = ['error', 'info', 'success', 'warning'];

  return (
    <>
      <Text>With Icon</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        {statuses.map((status) => (
          <Banner
            includeIcon
            key={status}
            message="Lorem ipsum dolor sit amet"
            status={status}
            title="Title"
          />
        ))}
      </Stack>
      <VSpacer size="5" />
      <Text>Without Icon</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        {statuses.map((status) => (
          <Banner
            key={status}
            message="Lorem ipsum dolor sit amet"
            status={status}
          />
        ))}
      </Stack>
    </>
  )
}
