import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactNode, useEffect, useState } from 'react';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ClickAwayListener, Divider, Stack } from '@mui/material';
import { Logo, TopAppBar, VSpacer } from '..';
import Menu from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

const DrawerWidth = 375;
const ClosedDrawerHeightWidth = 80;

const openedMixin = (theme: Theme, isMobile: boolean = false): CSSObject => ({
  width: isMobile ? "100%" : DrawerWidth,
  transition: theme.transitions.create(isMobile ? 'height' : 'width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
});

const closedMixin = (theme: Theme, isMobile: boolean = false): CSSObject => ({
  transition: theme.transitions.create(isMobile ? 'height' : 'width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: isMobile ? "100%" : `${ClosedDrawerHeightWidth}px`,
  ...(isMobile ? {
    height: ClosedDrawerHeightWidth,
    position: "absolute",
    bottom: 0,
    left: 0,
  } : {}),
});

const DrawerHeader = styled('div')(({ theme }) => ({
  padding: "12px 0px 0px 22px",
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: DrawerWidth,
    width: `calc(100% - ${DrawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: DrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerMobile = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: "100%",
    height: "100%",
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, true),
      '& .MuiDrawer-paper': openedMixin(theme, true),
    }),
    ...(!open && {
      ...closedMixin(theme, true),
      '& .MuiDrawer-paper': closedMixin(theme, true),
    }),
  }),
);

export interface ContentRenderingProps {
  isTransitioning: boolean,
  open: boolean,
  closeDrawer: () => void,
}

export interface NavigationRailProps {
  children?: ReactNode,
  content: (props: ContentRenderingProps) => ReactNode,
  open?: boolean,
  testID: string,
}

let expandedTimeout: any = null;

export const NavigationRail = ({
  children,
  content,
  open: isOpen = false,
  testID,
}: NavigationRailProps) => {

  const [open, setOpen] = useState(isOpen);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const GrowersTopAppBar = () => (
    <TopAppBar
      leftAccessory={
        <IconButton
          onClick={toggleDrawer}
          sx={{ margin: "0" }}>
          <Menu />
        </IconButton>
      }
      testID={`${testID}-appbar`}>
      <Logo size="medium" />
    </TopAppBar>
  );

  useEffect(() => {
    clearTimeout(expandedTimeout);
    setIsTransitioning(true);

    if (!open) {
      expandedTimeout = setTimeout(() => {
        setIsTransitioning(false);
      }, theme.transitions.duration.leavingScreen);
    } else {
      expandedTimeout = setTimeout(() => {
        setIsTransitioning(false);
      }, theme.transitions.duration.enteringScreen);
    }
  }, [open]);

  return (
    <>
      <DesktopOnly>
        <Box sx={{ display: 'flex' }}>
          { !open && (
            <AppBar position="fixed">
              <GrowersTopAppBar />
            </AppBar>
          )}
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={() => open && setOpen(false)}
            open={open}
            touchEvent="onTouchStart">
            <Drawer
              data-testid={`${testID}-drawer`}
              open={open}
              sx={{
                position: "fixed",
                zIndex: theme.zIndex.drawer,
              }}
              variant="permanent"
            >
              <DrawerHeader>
                {
                  open && !isTransitioning && (
                    <IconButton
                      data-testid={`${testID}-close-drawer-button`}
                      onClick={toggleDrawer}>
                      <CloseIcon />
                    </IconButton>
                  )
                }
              </DrawerHeader>
              <VSpacer size="6" />
              {content({ open, isTransitioning, closeDrawer })}
            </Drawer>
          </ClickAwayListener>
          { !!children && (
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                padding: "64px 30px 0px 85px",
              }}
            >
              {children}
            </Box>
          )}
        </Box>
      </DesktopOnly>
      <MobileOnly>
        {
          !open && (
            <AppBar position="fixed">
              <GrowersTopAppBar />
            </AppBar>
          )
        }
        { !!children && (
          <Box
            component="main"
            sx={{
              paddingTop: "64px",
              height: 'calc(100vh - 145px)',
              overflow: "auto",
            }}
          >
            {children}
          </Box>
        )}
        <DrawerMobile
          data-testid={`${testID}-drawer`}
          open={open}
          variant="permanent">
          {
            open ? (
              <>
                <DrawerHeader>
                  <IconButton
                    data-testid={`${testID}-close-drawer-button`}
                    onClick={toggleDrawer}>
                    <CloseIcon />
                  </IconButton>
                </DrawerHeader>
                {content({ open: true, isTransitioning, closeDrawer })}
              </>
            ) : (
              <>
                <Divider />
                <Stack
                  alignItems="center"
                  alignSelf="center"
                  direction="row"
                >
                  {content({ open: false, isTransitioning, closeDrawer })}
                  <IconButton
                    data-testid={`${testID}-open-drawer-button`}
                    onClick={toggleDrawer}
                    sx={{ margin: "8px 0px 0px 17px" }}>
                    <MoreHorizIcon />
                  </IconButton>
                </Stack>
              </>
            )
          }
        </DrawerMobile>
      </MobileOnly>
    </>
  );
}
