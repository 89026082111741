import {
  Button,
  Card,
  Checkbox,
  Modal,
  ProgressLoader,
  Text,
  TextLink,
  Toolbar,
} from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { ApiRetailerLocation } from '@api/interfaces';
import { useSearch } from '@/hooks/useSearch';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';

interface SelectLocationsModalProps {
  existingLocations?: ApiRetailerLocation[],
  onClose: () => void,
  onSave: (locations: ApiRetailerLocation[]) => void,
  testID: string,
}

export const SelectLocationsModal = ({
  existingLocations,
  onClose,
  onSave,
  testID,
}: SelectLocationsModalProps) => {
  const { debouncedSearch, setSearch } = useSearch(1);
  const [selectedLocations, setSelectedLocations]
    = useState<ApiRetailerLocation[]>(existingLocations ?? []);

  const { data: locations, isFetching } = useQuery(
    [QueryKeys.GET_RETAILER_LOCATION_LIST, debouncedSearch],
    async () => await RetailerLocationApi.list({
      isActive: true,
      search: debouncedSearch,
    }),
  );

  const LocationView = (
    <Card
      sx={{ borderRadius: '12px', maxHeight: '430px' }}
      testID={`${testID}-location-view`}
    >
      <Stack alignItems="end" height="25px">
        <TextLink
          category="label-medium"
          onClick={() => setSelectedLocations([])}
          testID={`${testID}-clear`}
        >
          Clear
        </TextLink>
      </Stack>
      <Stack maxHeight="405px" overflow="auto" pb="30px">
        {locations?.data.map((location) => (
          <Stack direction="row" height="60px" key={location.id} minHeight="60px">
            <Checkbox
              checked={selectedLocations.some(({ id }) => id === location.id)}
              onChangeChecked={(checked) => {
                if (checked) {
                  setSelectedLocations([...selectedLocations, location]);
                } else {
                  setSelectedLocations(selectedLocations.filter(({ id }) => id !== location.id));
                }
              }}
              testID={`${testID}-location-checkbox-${location.id}`}
            />
            <Stack justifyContent="center">
              {location.retailer?.name && (
                <Text
                  category="body-large"
                  maxWidth="340px"
                  noWrap
                  sx={{ color: '#B0B0B0'}}
                >
                  {location.retailer.name}
                </Text>
              )}
              <Text
                category="body-large"
                maxWidth="340px"
                noWrap
              >
                {location.name}
              </Text>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!selectedLocations.length}
          onClick={() => {
            onSave(selectedLocations);
            onClose();
          }}
          variant="contained"
        >
          {selectedLocations.length ? `Apply ${selectedLocations.length} Locations` : 'Apply'}
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID={testID}
      title="Select Retailer Locations"
      width={560}
    >
      <Stack gap="28px">
        <Toolbar
          onChange={({ search }) => {
            setSearch(search ?? '');
          }}
          testID={`${testID}-toolbar`}
        />
        {isFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : LocationView}
      </Stack>
    </Modal>
  );
}
