import defaultLandusProductImage from '@/assets/images/default-product-img-landus.png';
import defaultProductImage from '@/assets/images/default-product-img-growers.png';
import { AppConfig } from '@/constants/AppConfig';
import { getSubdomain } from '@/utilities/ThemeUtilities';

export const getProductImageUrl = (image?: string | null) => {
  const subdomain = getSubdomain();
  const defaultImageUrl = subdomain === 'landus'
    ? defaultLandusProductImage
    : defaultProductImage;
  const productImageUrl = image && `${AppConfig.staticImageHost}/${image}`;
  return productImageUrl || defaultImageUrl;
}
