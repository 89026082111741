import { Button, Chip, InlineMessage, Text, VSpacer } from "@/components/DesignSystem";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import DetailSection from '@/components/shared/DetailsSections';
import { FriendlyFulfillmentMethod } from "@/constants/FriendlyFulfillmentMethod";
import { Routes } from '@/constants/Routes';
import { useMediaQuery } from "@/hooks/useMediaQuery";
import ContactInformationModal from '@/pages/ReceivePlaceOffers/Modals/ContactInformationModal';
import { Color } from '@/themes/MUITheme/palette';
import { ApiPricingRequestPublic } from "@api/interfaces";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Warning from "@mui/icons-material/Warning";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { FulfillmentMethod, OfferStatus, OfferStatusNew } from '@shared/enums';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ViewOffersItemProps {
  index: number,
  pricingRequest: ApiPricingRequestPublic,
}

const ViewOffersItem = ({
  index,
  pricingRequest,
}: ViewOffersItemProps) => {

  const [expanded, setExpanded] = useState(false);
  const [showShippingDetailsModal, setShowShippingDetailsModal] = useState(false);
  const { isMobile } = useMediaQuery();

  const navigate = useNavigate();
  const { user: farmer } = pricingRequest;

  const showFarmerEmailAndTelephone = pricingRequest.offer
    && (pricingRequest.offer.status === OfferStatus.Accepted
    || pricingRequest.offer.status === OfferStatus.Partial);

  const renderStatusBadge = () => {
    if (!pricingRequest.retailerOfferStatus) {
      return null;
    }
    /**
 * Awaiting Offer for Retailers doesn't officially trigger until a Retailer has interacted with a
 * PR i.e. tapped the View Details button. At that point, the only way to avoid the Awaiting Offer
 * label is to a.) reject/ignore the PR or b.) create and submit an offer. If the Retailer navigates
 * away from the PR card and/or doesn't finish creating an Offer, the Awaiting Offer label will be
 * applied to the card
 */
    let color: Color = 'success';
    let text = '';

    switch(pricingRequest.retailerOfferStatus) {
      case OfferStatusNew.AwaitingOffer:
        color = 'warning';
        text = OfferStatusNew.AwaitingOffer;
        break;
      case OfferStatusNew.PaymentDue:
        color = 'error';
        text = OfferStatusNew.PaymentDue;
        break;
      case OfferStatusNew.OnAccountRequested:
        color = 'info';
        text = OfferStatusNew.OnAccountRequested;
        break;
      case OfferStatusNew.FinancingRequested:
        color = 'info';
        text = OfferStatusNew.FinancingRequested;
        break;
      case OfferStatusNew.AwaitingFulfillment:
        color = 'info';
        text = OfferStatusNew.AwaitingFulfillment;
        break;
      case OfferStatusNew.PickupReady:
        color = 'info';
        text = OfferStatusNew.PickupReady;
        break;
      case OfferStatusNew.AwaitingDelivery:
        color = 'info';
        text = OfferStatusNew.AwaitingDelivery
        break;
      case OfferStatusNew.OfferSent:
        color = 'info';
        text = OfferStatusNew.OfferSent;
        break;
      case OfferStatusNew.OfferClosed:
        text = OfferStatusNew.OfferClosed
        break;
      case OfferStatusNew.OfferExpired:
        text = OfferStatusNew.OfferExpired
        break;
    }

    return (
      <Chip
        color={color}
        label={text}
        testID={`status-chip-${index}-${text.replace(" ", "-")}`}
      />
    );
  }

  const navigateToPlaceOffer = () => {
    navigate(Routes.PLACE_OFFER.replace(':id', pricingRequest.id))
  };

  const Header = () => (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ width: "100%" }}>
      <Stack alignItems="flex-start">
        <Stack alignItems="center" direction="row">
          <Text category="h6">
            Product Request #{pricingRequest.publicId}
          </Text>

        </Stack>
        <VSpacer size="3" />
        {renderStatusBadge()}
      </Stack>
      { showShippingDetailsModal &&
        <ContactInformationModal
          contactInfo={{
            address1: pricingRequest.deliveryAddress1,
            address2: pricingRequest.deliveryAddress2,
            county: pricingRequest.user?.countyData?.county,
            city: pricingRequest.deliveryCity,
            postal: pricingRequest.deliveryPostalCode,
            state: pricingRequest.deliveryState,
          }}
          isVisible
          onClose={() => setShowShippingDetailsModal(false)}
        />
      }
    </Stack>
  );

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onClick={navigateToPlaceOffer}
      sx={{ cursor: 'pointer', padding: '28px' }}
    >
      <AccordionSummary
        expandIcon={isMobile
          ? <ExpandMore onClick={() => setExpanded(!expanded)} />
          : undefined
        }
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Header />
            <DesktopOnly>
              <Button
                endIcon={expanded
                  ? <ExpandLess fontSize="small" />
                  : <ExpandMore fontSize="small" />
                }
                onClick={(event) => {
                  setExpanded(!expanded);
                  event.stopPropagation();
                }}
                testID="view-offers-item-expand-details-button"
                variant="text"
              >
                <Text category="label">
                  Details
                </Text>
              </Button>
            </DesktopOnly>
          </Stack>
          {!!pricingRequest.isPaymentDue && (
            <Stack direction="row" justifyContent="flex-end">
              <InlineMessage
                icon={<Warning color="warning" fontSize="small" />}
                testID="awaiting-payment-badge"
                title="Awaiting payment"
                titleColor="warning"
              />
            </Stack>
          )}
        </Stack>
      </AccordionSummary>
      <VSpacer size="3" />
      <AccordionDetails>
        <Divider />
        <Grid
          alignItems="start"
          container
          direction="row"
          spacing={isMobile ? 0 : 4}
        >
          <Grid item md={4}>
            <Stack>
              {!!farmer?.firstName &&
                <DetailSection
                  details={`${farmer?.firstName} ${farmer?.lastName}`}
                  label="Name"
                  testID="farmer-name"
                />
              }
              {showFarmerEmailAndTelephone && !!farmer?.email &&
                <DetailSection
                  details={farmer?.email}
                  label="Email"
                  testID="farmer-email"
                />
              }
              {showFarmerEmailAndTelephone && !!farmer?.telephone &&
                <DetailSection
                  details={farmer?.telephone}
                  label="Phone"
                  testID="farmer-phone"
                />
              }
              <DetailSection
                details="View list"
                detailsOnClick={navigateToPlaceOffer}
                label="Products"
                testID="farmer-products"
              />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <DetailSection
                details={FriendlyFulfillmentMethod[pricingRequest.fulfillmentMethod]}
                label="Fulfillment method"
                testID="fulfillment-method"
              />
              {pricingRequest.fulfillmentMethod === FulfillmentMethod.DELIVERY &&
                <DetailSection
                  details="View contact info"
                  detailsOnClick={() => setShowShippingDetailsModal(true)}
                  label="Shipping contact"
                  testID="shipping-contact"
                />
              }
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ViewOffersItem;
