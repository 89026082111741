import { Text, VSpacer } from '@/components/DesignSystem';
import { StringUtility } from '@/utilities/String';
import { Divider, Stack } from '@mui/material';
import { State } from '@shared/enums';
import { DemoBlock } from '../../components/DemoBlock';

export const TextDemo = () => {
  return (
    <>
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Text fontWeight="400">Font weight: 400</Text>
        <Text fontWeight="500">Font weight: 500</Text>
        <Text category="overline">Overline</Text>
      </Stack>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Text category="label-small">Label Small</Text>
        <Text category="label-medium">Label Medium</Text>
        <Text category="label-large">Label Large</Text>
        <Text category="body-small">Body Small</Text>
        <Text category="body-medium">Body Medium</Text>
        <Text category="body-large">Body Large</Text>
        <Text category="body-xlarge">Body XLarge</Text>
        <Text category="title-small">Title Small</Text>
        <Text category="title-medium">Title Medium</Text>
        <Text category="title-large">Title Large</Text>
      </Stack>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Text category="headline-small">Headline Small</Text>
        <Text category="headline-medium">Headline Medium</Text>
        <Text category="headline-large">Headline Large</Text>
      </Stack>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Text category="display-small">Display Small</Text>
        <Text category="display-medium">Display Medium</Text>
        <Text category="display-large">Display Large</Text>
      </Stack>
      <Divider/>
      <DemoBlock label="Multiline with line breaks:">
        <Text multiline>
          {StringUtility.formatAddress(
            '1 Patriot Place',
            'Lot 1',
            'Foxboro',
            State.Massachusetts,
            '02035',
          )}
        </Text>
      </DemoBlock>
    </>
  );
}
