import { Divider, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Fab, HSpacer, Text, VSpacer } from '@/components/DesignSystem';

export const FabDemo = () => {
  return (
    <>
      <Text>Primary UI Kitten</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Fab testID="fab-demo-1" variant="extended">
          <AddIcon />
          <HSpacer size="4" />
          Enabled
        </Fab>
        <Fab testID="fab-demo-2" >
          <AddIcon />
        </Fab>
        <Fab size="small" testID="fab-demo-3" variant="extended">
          <AddIcon />
        </Fab>
      </Stack>
      <VSpacer size="5" />
      <Text>Primary</Text>
      <VSpacer size="5" />
      <Stack
        alignItems="top"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Fab color="primary" testID="fab-demo-4" variant="extended">
          <AddIcon />
          <HSpacer size="4" />
          Enabled
        </Fab>
        <Fab color="primary" testID="fab-demo-5">
          <AddIcon />
        </Fab>
        <Fab color="primary" size="small" testID="fab-demo-6" variant="extended">
          <AddIcon />
        </Fab>
      </Stack>
    </>
  );
}
