import { Stack } from '@mui/material';
import { Card, DataPoint, VSpacer, Text } from '@/components/DesignSystem';

export const DataPointDemo = () => {
  // eslint-disable-next-line max-len
  const text = 'Gumbo beet greens corn soko endive gumbo gourd. Parsley shallot courgette tatsoi pea sprouts fava bean collard greens dandelion okra wakame tomato. Dandelion cucumber earthnut pea peanut soko zucchini.';
  return (
    <Stack>
      <Text category="body-large">Large DataPoint. Flush</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-qty-uom-large">
        <DataPoint label="Qty/UoM" size="large" testID="test-datapoint">10 gal</DataPoint>
      </Card>
      <VSpacer size="5" />
      <Text category="body-large">Medium DataPoint. Flush</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-qty-uom-medium">
        <DataPoint label="Qty/UoM" size="medium" testID="test-datapoint">10 gal</DataPoint>
      </Card>
      <VSpacer size="5" />
      <Text category="body-large">Large DataPoint. Fixed</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-package-size-large">
        <DataPoint label="Package size" size="large" spacing="fixed" testID="test-datapoint">
          10 lbs
        </DataPoint>
      </Card>
      <VSpacer size="5" />
      <VSpacer size="5" />
      <Text category="body-large">Medium DataPoint. Fixed</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-package-size-medium">
        <DataPoint label="Package size" size="medium" spacing="fixed" testID="test-datapoint">
          10 lbs
        </DataPoint>
      </Card>
      <VSpacer size="5" />
      <Text category="body-large">Large DataPoint. Long text. Flush</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-note-large">
        <DataPoint label="Note:" size="large" testID="test-datapoint">
          {text}
        </DataPoint>
      </Card>
      <VSpacer size="5" />
      <Text category="body-large">Medium DataPoint. Long text. Flush</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-note-medium">
        <DataPoint label="Note:" size="medium" testID="test-datapoint">
          {text}
        </DataPoint>
      </Card>
      <VSpacer size="5" />
      <Text category="body-large">Large DataPoint. Stacked</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-crop-year-large">
        <DataPoint label="Crop year" size="large" stacked testID="test-datapoint">
          {new Date().getFullYear()}
        </DataPoint>
      </Card>
      <VSpacer size="5" />
      <Text category="body-large">Medium DataPoint. Stacked</Text>
      <VSpacer size="5" />
      <Card testID="data-point-demo-body-crop-year-medium">
        <DataPoint label="Crop year" size="large" stacked testID="test-datapoint">
          {new Date().getFullYear()}
        </DataPoint>
      </Card>
    </Stack>
  );
}
