import {
  Button,
  Card,
  HSpacer,
  Switch,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { Box, Stack } from '@mui/material';
import React, { Fragment, useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { SaveManufacturerModal } from './SaveEditManufacturer';
import { ApiManufacturer } from '@api/interfaces';
import ConfirmDialog from './ConfirmDialog';
import { useMutation, useQueryClient } from 'react-query';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { QueryKeys } from '@/constants/QueryKeys';

const ManufacturerCard = ({
  manufacturer,
  isRedirectable = true,
}: {
  manufacturer: ApiManufacturer;
  isRedirectable?: boolean;
}) => {
  const queryClient = useQueryClient();
  const [isEditManufacturer, setIsEditManufacturer] = useState(false);
  const [toggleInActive, setToggleInActive] = useState(false);
  const [isActive, setIsActive] = useState(manufacturer.isActive ?? false);
  const navigate = useNavigate();
  const handleOnCardClick = () => {
    navigate(`/admin/manufacturers/${manufacturer.id}`);
  };

  const { mutate: updateManufacturerActivation, isLoading } = useMutation(
    async (isActive: boolean) => {
      return ManufacturerApi.update(manufacturer.id, {
        isActive: isActive,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        setToggleInActive(false);
      },
    },
  );

  return (
    <Fragment key={manufacturer.id}>
      <Card
        onClick={() => isRedirectable && handleOnCardClick()}
        sx={isRedirectable ? { cursor: 'pointer' } : {}}
        testID='manufacturer-list-item'
      >
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Stack spacing={1}>
            <Text
              category='body-medium'
            >
              ID: {manufacturer.id}
            </Text>
            <Text category='h6'>{manufacturer?.name}</Text>
          </Stack>
          {isRedirectable && (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Stack style={{ marginTop: '7px' }}>
                <Switch
                  checked={!!manufacturer.isActive}
                  label={manufacturer.isActive ? 'Active' : 'Inactive'}
                  labelPlacement='start'
                  onClick={(e) => {
                    e.stopPropagation();
                    const target = e.target as HTMLInputElement;
                    !manufacturer.isActive &&
                      updateManufacturerActivation(target.checked);
                    setIsActive(target.checked);
                    setToggleInActive(true);
                  }}
                  testID='partner-retailer-list-item-is-inactive'
                />
              </Stack>
              <HSpacer size='7' />
              <Button
              disabled={!manufacturer.isActive}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditManufacturer(true);
                }}
                startIcon={<EditOutlined />}
                testID='Manufacturer-Edit-details-button'
                variant='outlined'
              >
                Edit
              </Button>
            </Box>
          )}
        </Stack>
      </Card>
      <VSpacer size='3' />
      {isEditManufacturer && (
        <SaveManufacturerModal
          close={() => setIsEditManufacturer(false)}
          manufacturer={manufacturer}
        />
      )}
      {!isActive && toggleInActive && (
        <ConfirmDialog
          isLoading={isLoading}
          isOpen={toggleInActive}
          message='Are you sure you want to deactivate this manufacturer?'
          onClose={() => setToggleInActive(false)}
          onConfirm={() => {
            updateManufacturerActivation(false);
          }}
          title='Inactivate Manufacturer?'
        />
      )}
    </Fragment>
  );
};

export default ManufacturerCard;
