import { Button } from "@/components/DesignSystem";
import Check from "@mui/icons-material/Check";
import Triangle from '@mui/icons-material/ChangeHistory';
import { ButtonGroup, ButtonGroupProps } from "@mui/material";
import { useState } from "react";
import { DemoBlock } from "../../components/DemoBlock";

export const SegmentedButtonDemo = () => {

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const buttons = ["One", "Two", "Three", "Four", "Five"];
  const buttons2 = ["Any", "Pickup", "Delivery"];

  const ScrollableButtonGroup = (props: ButtonGroupProps) => (
    <ButtonGroup className="scrollable-container" {...props} />
  );

  return (
    <>
      <DemoBlock label="No icons">
        <ScrollableButtonGroup>
          {
            buttons.map((button, index) => (
              <Button
                aria-selected={selectedIndex === index}
                key={button}
                onClick={() => setSelectedIndex(index)}
                startIcon={
                  selectedIndex === index && <Check sx={{ width: "17px", height: "17px" }} />
                }
                testID={`no-icons-${index}`}
              >
                {button}
              </Button>
            ))
          }
        </ScrollableButtonGroup>
      </DemoBlock>
      <DemoBlock label="Icons">
        <ScrollableButtonGroup>
          {
            buttons.map((button, index) => (
              <Button
                aria-selected={selectedIndex === index}
                key={button}
                onClick={() => setSelectedIndex(index)}
                startIcon={
                  selectedIndex === index && <Check sx={{ width: "17px", height: "17px" }} />
                }
                testID={`no-icons-${index}`}
              >
                <Triangle sx={{ width: "13px", height: "13px" }} />
              </Button>
            ))
          }
        </ScrollableButtonGroup>
      </DemoBlock>
      <DemoBlock label="Disabled">
        <ScrollableButtonGroup>
          {
            buttons.map((button, index) => (
              <Button
                aria-selected={selectedIndex === index}
                disabled
                key={button}
                onClick={() => setSelectedIndex(index)}
                startIcon={
                  selectedIndex === index && <Check sx={{ width: "17px", height: "17px" }} />
                }
                testID={`disabled-${index}`}
              >
                {button}
              </Button>
            ))
          }
        </ScrollableButtonGroup>
      </DemoBlock>
      <DemoBlock label="Real world example">
        <ScrollableButtonGroup >
          {
            buttons2.map((button, index) => (
              <Button
                aria-selected={selectedIndex === index}
                key={button}
                onClick={() => setSelectedIndex(index)}
                startIcon={
                  selectedIndex === index && <Check sx={{ width: "17px", height: "17px" }} />
                }
                testID={`rwe-${index}`}
              >
                {button}
              </Button>
            ))
          }
        </ScrollableButtonGroup>
      </DemoBlock>
    </>
  );
}
