export enum QueryKeys {
  ACTIVE_RETAILERS = 'active_retailers',
  GET_ACTIVE_INGREDIENTS = 'active-ingredients',
  GET_ADMINS = 'get_admins',
  GET_ALLOWED_COUNTIES = 'get_allowed_counties',
  GET_CATEGORY = 'category',
  GET_CONNECT_ACCOUNT_ID = 'get_connect_account_id',
  GET_COUNTIES = 'get_counties',
  GET_COUNTIES_BY_STATE = 'get_counties_by_state',
  GET_CROP = 'crop',
  GET_FARMERS = 'get_farmers',
  GET_FEATURE_FLAGS = 'get_feature_flags',
  GET_FEATURED_PRODUCTS = 'get_featuredProducts',
  GET_FEATURED_RETAILERS = 'featured-retailer',
  GET_FEATUREMANUFACTURERS = 'get_featuremanufacturers',
  GET_FEATURERETAILER = 'get_featureretailer',
  GET_LAST_LOOK = 'get_last_look',
  GET_LOCATIONS = 'get_locations',
  GET_LOCATION_BY_ID = 'get_location_by_id',
  GET_LOCATION_MEMBERS = 'get_location_members',
  GET_MANUFACTURER_BY_ID = 'get_manufacturer_by_id',
  GET_MANUFACTURERS = 'get_manufacturers',
  GET_MANUFACTURERS_LIST = 'manufacturers',
  GET_OFFER = 'get_offer',
  GET_OFFERS = 'get_offers',
  GET_ORGANIZATIONS = 'get_organizations',
  GET_PACKAGE_SIZE = 'package',
  GET_PARTNER_RETAIER_BY_ID = 'get_partner_retailer_by_id',
  GET_PARTNER_RETAILERS = 'get_partner_retailers',
  GET_PRACTICE = 'practice',
  GET_PREFERRED_RETAILER = 'get_preferred_retailer',
  GET_PREPAYMENTS = 'get_prepayments',
  GET_PRICING_REQUEST = 'get_pricing_request',
  GET_PRICING_REQUEST_OFFER = 'get offer',
  GET_PRICING_REQUEST_PUBLIC = 'get_pricing_request_public',
  GET_PRICING_REQUESTS = 'get_pricing_requests',
  GET_PRICING_REQUESTS_FOR_ADMIN = 'get_pricing_requests_for_admin',
  GET_PRICING_REQUESTS_FOR_RETAILER = 'get_pricing_requests_retailer',
  GET_PRIMARY_NUTRIENT = 'primary-nutrient',
  GET_PRODUCT_ALTERNATIVES_COMPANIONS = 'get_alternative_companions',
  GET_PRODUCT_ALTERNATIVES = 'get_product_alternatives',
  GET_PRODUCT_COMPANIONS = 'get_product_companions',
  GET_PRODUCT_RECOMMENDATIONS = 'get_recommendations',
  GET_PRODUCT = 'product',
  GET_PRODUCTS = 'get_products',
  GET_PRODUCT_DETAILS = 'get_product_details',
  GET_PROMOTION = 'get_promotion',
  GET_PROMOTIONS = 'get_promotions',
  GET_RECOMMENDATIONS = 'get_product_recommendations',
  GET_RECOMMENDATION_BY_ID = 'get_product_recommendation_by_id',
  GET_RETAILER_FARMERS_BY_ID = 'get_retailer_farmers_by_id',
  GET_RETAILER_NAMES = 'get_retailer_names',
  GET_RETAILERS = 'get_hierarchy_of_retailers',
  GET_RETAILER_BY_ID = 'get_hierarchy_of_retailers_by_id',
  GET_RETAILER_LOCATION_LIST = 'get_retailer_location_list',
  GET_RETAILER_LOCATIONS_PREFERRED = 'get_retailer_locations_preferred',
  GET_RETAILER_MEMBERS = 'get_retailer_members',
  GET_SALES_PERSONS = 'get_sales_persons',
  GET_SALES_PERSON_LISTS = 'get_sales_person_lists',
  GET_SAVED_RETAILER = 'get_saved_retailer',
  GET_SHOP_FILTERS = 'get_shop_filters',
  GET_SHOPPING_CART = 'get_shopping_cart',
  GET_SPONSOREDBANNER = 'get_sponseredbanner',
  GET_STOREFRONT = 'get_storefront',
  GET_STRIPE_FEES = 'get_stripe_fees',
  GET_STRIPE_PAYMENT = 'get_stripe_payment',
  GET_STRIPE_PROMISE = 'get_stripe_promise',
  GET_UOM = 'UoM',
  GET_USER_LOCATIONS = 'get_user_locations',
  GET_USER_REWARDS = 'get_user_rewards',
  SAVED_RETAILERS = 'saved_retailers',
  SCHEDULER = 'scheduler',
  VALIDATE_TOKEN = 'validate_token',
}
