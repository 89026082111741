import { ChipProps } from "@/components/DesignSystem";
import {
  FriendlyPricingRequestStatus,
  FriendlyPricingRequestStatusNew,
} from "@/constants/FriendlyPricingRequestStatus";
import { CompanionProduct, Product } from '@/pages/CreateSendPriceRequest/interfaces';
import { Color } from '@/themes/MUITheme/palette';
import { ApiPricingRequestPublic } from '@api/interfaces';
import {
  OfferStatusNew,
  PricingRequestStatus,
  PricingRequestStatusNew,
  ProductUom,
} from "@shared/enums";
import { ConversionUtility } from "@shared/utilities";
import { DateTime } from "luxon";

export const calculateCompanionProductQuantity = (
  rate: number,
  quantity: number,
  productUom: ProductUom,
  companionUom: ProductUom,
): (number | undefined) => {

  if (productUom === companionUom) {
    return rate * quantity;
  }

  if (ConversionUtility.isSameCategory(productUom, companionUom)) {
    return rate * ConversionUtility.convertProductUoM(quantity, productUom, companionUom);
  }

  return undefined;
}

export const calculateCompanionProductQuantityRange = (
  companion: CompanionProduct,
  source: Product,
) => {

  if (!source.uom || !companion.companionUom) {
    return undefined;
  }

  if (!ConversionUtility.isSameCategory(source.uom, companion.companionUom)) {
    return undefined;
  }

  if (!companion.rateMin && !companion.rateMax) {
    return undefined;
  }

  const convertedQty = ConversionUtility.convertProductUoM(
    source.quantity ?? 0,
    source.uom,
    companion.companionUom,
  );

  return {
    min: convertedQty * (companion.rateMin ?? 0),
    max: convertedQty * (companion.rateMax ?? 0),
  };
}

export const formatLicense = (
  licenseNumber?: string | null,
  expirationDate?: Date | null,
) => {
  if (!licenseNumber) {
    return "Not provided -- please coordinate with the farmer directly";
  } else if (!expirationDate) {
    return licenseNumber;
  }
  const formattedDate = DateTime.fromISO(expirationDate.toString()).toFormat("MM/dd/yyyy");
  return `${licenseNumber} -- expires ${formattedDate}`;
}

export const getChipStatusForOffers = (
  pricingRequest: ApiPricingRequestPublic,
): ChipProps => {
  let color: Color = 'success';
  let label: string = '';

  switch(pricingRequest.retailerOfferStatus) {
    case OfferStatusNew.AwaitingOffer:
      color = 'warning';
      label = OfferStatusNew.AwaitingOffer;
      break;
    case OfferStatusNew.PaymentDue:
      color = 'error';
      label = OfferStatusNew.PaymentDue;
      break;
    case OfferStatusNew.OnAccountRequested:
      color = 'info';
      label = OfferStatusNew.OnAccountRequested;
      break;
    case OfferStatusNew.FinancingRequested:
      color = 'info';
      label = OfferStatusNew.FinancingRequested;
      break;
    case OfferStatusNew.AwaitingFulfillment:
      color = 'info';
      label = OfferStatusNew.AwaitingFulfillment;
      break;
    case OfferStatusNew.PickupReady:
      color = 'info';
      label = OfferStatusNew.PickupReady;
      break;
    case OfferStatusNew.AwaitingDelivery:
      color = 'info';
      label = OfferStatusNew.AwaitingDelivery
      break;
    case OfferStatusNew.OfferSent:
      color = 'info';
      label = OfferStatusNew.OfferSent;
      break;
    case OfferStatusNew.OfferClosed:
      label = OfferStatusNew.OfferClosed
      break;
    case OfferStatusNew.OfferExpired:
      label = OfferStatusNew.OfferExpired
      break;
  }

  return {
    color,
    label,
    testID: 'view-price-request-status-badge',
  }
}

export const getStatusChipPropsFromPRStatusNew = (
  farmerStatus: PricingRequestStatusNew,
): ChipProps => {
  const label = FriendlyPricingRequestStatusNew[farmerStatus];
  let color: Color = 'info';
  const testID = 'pricing-request-chip-status';
  switch (farmerStatus) {
    case PricingRequestStatusNew.AwaitingFulfillment:
    case PricingRequestStatusNew.FinancingRequested:
    case PricingRequestStatusNew.OnAccountRequested:
    case PricingRequestStatusNew.OrderShipped:
    case PricingRequestStatusNew.PickupReady:
    case PricingRequestStatusNew.ReviewOffer:
      color = 'info';
      break;
    case PricingRequestStatusNew.PaymentDue:
      color = 'error';
      break;
    case PricingRequestStatusNew.OfferClosed:
    case PricingRequestStatusNew.OfferExpired:
      break;
    case PricingRequestStatusNew.PendingApproval:
      color = 'warning';
      break;
    default:
      break;
  }
  return { color, label, testID };
}

export const getStatusChipPropsFromPRStatus = (status: PricingRequestStatus): ChipProps => {
  const message = FriendlyPricingRequestStatus[status];

  let color: Color = 'info';
  const testID="pricing-request-badge-status"

  switch (status) {
    case PricingRequestStatus.Accepted:
    case PricingRequestStatus.Open:
      color = 'success';
      break;
    case PricingRequestStatus.Draft:
    case PricingRequestStatus.AwaitingApproval:
      color = 'warning'
      break;
    case PricingRequestStatus.Review:
      color = 'info';
      break;
    case PricingRequestStatus.Closed:
      break;
  }

  const badgeProps = {
    color,
    label: message,
    testID,
  }

  return badgeProps;
};

export const getTotalProductCount = (products: Product[]) => {
  return products?.reduce(
    (sum, product) => sum + 1 + (product.companionProducts?.length ?? 0), 0,
  ) ?? 0;
}

export const scrubQtyAndUom = (product: Product) => {
  const isMissingUomOrQty = (product.quantity && !product.uom)
  || (product.uom && !product.quantity);
  let payload = { ...product };
  if (isMissingUomOrQty && !!product.recommendedRateNote) {
    payload = { ...payload, uom: null, quantity: null };
  }
  return payload;
}
