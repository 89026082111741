import { toUpper } from 'lodash';

export * from './AllowedPaymentMethodType';
export * from './DiscountType';
export * from './Errors';
export * from './FeatureFlag';
export * from './FulfillmentMethod';
export * from './GrowersContactInfo';
export * from './LedgerType';
export * from './NotificationType';
export * from './OfferStatus';
export * from './PackageType';
export * from './PaymentMethodPreference';
export * from './PaymentStatus';
export * from './PaymentType';
export * from './PrepaymentType';
export * from './PriceOfferStatusFilter';
export * from './PricingRequestApproval';
export * from './PricingRequestStatus';
export * from './ProductEventType';
export * from './RecommendationStatus';
export * from './State';
export * from './TalonStatusType';
export * from './UnitOfMeasures';
export * from './UserType';
export * from './NotificationType';
export * from './TalonStatusType';
export * from './TalonWalletTransactionType';
export * from './RecommendationFarmerStatus';

export function parseEnum (dataEnum: object, input: string | number | undefined) {
  if (!input) {
    return undefined;
  }

  const match = Object.values(dataEnum).find(
    (value) => value === (typeof input === 'string' ? toUpper(input) : input),
  );
  return (match || undefined);
}

export function parseEnums (dataEnums: object[], input: string | number | undefined) {
  for (const dataEnum of dataEnums) {
    const parsedValue = parseEnum(dataEnum, input);
    if (parsedValue) { return parsedValue; }
  }

  return undefined;
}

/**
 * Throw error if value received does not match a known enum
 * @param dataEnums
 * @param errMessage
 */
export function validateEnum (dataEnums: object | object[], errMessage: string) {
  return {
    fn: (value: unknown) => {
      const validValues = Array.isArray(dataEnums)
        ? dataEnums.map((dataEnum) => Object.values(dataEnum)).flat()
        : Object.values(dataEnums);
      if (!validValues.includes(value)) {
        throw new Error(`${errMessage}: ${value}`);
      }
    },
  };
}
