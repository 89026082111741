import {
  BottomBar,
  Button,
  Input,
  Modal,
  PhoneInput,
  ProgressLoader,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { DateInput } from '@/components/DesignSystem/Input/DateInput';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { InnerContainer } from '@/components/shared/InnerContainer';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { CountySelect } from '@/components/shared/Select/CountySelect';
import { StateSelect } from '@/components/shared/Select/StateSelect';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useCountyList } from '@/hooks/useCountyList';
import { useStorefront } from '@/hooks/useStorefront';
import OtpForm from '@/pages/Auth/OtpForm';
import { VerifyYourEmail } from '@/pages/Auth/VerifyYourEmailModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { Alert } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { State } from '@shared/enums';
import { validateEmail, validatePhoneNumber } from '@shared/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const FarmerProfileForm = () => {
  const navigate = useNavigate();
  const { refreshUser, updateUser: authUpdateUser, user } = useAuthentication();
  const { openSnackbar } = useSnackbar();
  const { counties } = useCountyList();
  const { isLoading: isStorefrontLoading, storefront } = useStorefront();
  const isStorefront = !!storefront;
  const isReadOnly = isStorefront;

  const [businessName, setBusinessName] = useState(user?.businessName ?? '');
  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [state, setState] = useState(user?.state || undefined);
  const [countyId, setCountyId] = useState(user?.countyId ?? '');
  const [city, setCity] = useState(user?.city ?? '');
  const [address1, setAddress1] = useState(user?.address1 ?? '');
  const [address2, setAddress2] = useState(user?.address2 ?? '');
  const [postal, setPostal] = useState(user?.postal ?? '');
  const [telephone, setTelephone] = useState(user?.telephone ?? '');
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [email, setEmail] = useState(user?.email ?? '');
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isDuplicatePhone, setIsDuplicatePhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);

  const [
    restrictedUseLicense,
    setRestrictedUseLicense,
  ] = useState(user?.restrictedUseLicense ?? '');
  const [
    taxExemptLicense,
    setTaxExemptLicense,
  ] = useState(user?.taxExemptLicense ?? '');
  const [
    technologyLicense,
    setTechnologyLicense,
  ] = useState(user?.technologyLicense ?? '');
  const [
    restrictedUseLicenseExpirationDate,
    setRestrictedUseLicenseExpirationDate,
  ] = useState<Date | undefined>(
    user?.restrictedUseLicenseExpirationDate ?? undefined,
  );
  const [
    taxExemptLicenseExpirationDate,
    setTaxExemptLicenseExpirationDate,
  ] = useState<Date | undefined>(
    user?.taxExemptLicenseExpirationDate ?? undefined,
  );
  const [
    technologyLicenseExpirationDate,
    setTechnologyLicenseExpirationDate,
  ] = useState<Date | undefined>(
    user?.technologyLicenseExpirationDate ?? undefined,
  );

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!counties || counties.length === 0) {
      return;
    }

    if (state && !counties.some(
      (c) => c.state === state && c.id === countyId)
    ) {
      setCountyId("");
    }
    if (!state) {
      setCountyId("");
    }
  }, [countyId, counties, state]);

  const isProfileComplete = !!(
    user
    && user.otpVerified
    && user.firstName
    && user.lastName
    && user.state
    && user.countyId
    && user.email
  );

  const canLeaveForm = isProfileComplete || isStorefront;

  const isFormValid = useCallback(() => {
    if (!validatePhoneNumber(telephone, AppConfig.env.test)) {
      return false;
    }

    if (!validateEmail(email)) {
      return false;
    }

    return !!countyId && !!firstName && !!lastName && !!state;
  }, [countyId, email, firstName, lastName, state, telephone]);

  const getEmailHelperText = () => {
    if (isDuplicateEmail) {
      return 'This email is already in use, please verify the email you typed in.';
    }
    if (!isValidEmail) {
      return 'A valid email address is required';
    }
    return '';
  }

  const getPhoneHelperText = () => {
    if (isDuplicatePhone) {
      return 'This number is already in use, please verify the number you typed in.';
    }
    if (!isValidPhone) {
      return 'A valid phone number is required';
    }
    return '';
  }

  const { mutate: updateUser, isLoading: isUserLoading } = useMutation(
    () =>
      authUpdateUser({
        address1: address1 || null,
        address2: address2 || null,
        businessName: businessName || null,
        city: city || null,
        countyId,
        email,
        firstName: firstName || null,
        lastName: lastName || null,
        postal: postal || null,
        restrictedUseLicense: restrictedUseLicense || null,
        state: state as State,
        telephone,
        taxExemptLicense: taxExemptLicense || null,
        technologyLicense: technologyLicense || null,
        restrictedUseLicenseExpirationDate:
          restrictedUseLicenseExpirationDate || null,
        taxExemptLicenseExpirationDate: taxExemptLicenseExpirationDate || null,
        technologyLicenseExpirationDate: technologyLicenseExpirationDate || null,
      }),
    {
      onError: (error: {
        code: string,
        details: { details: { email?: string, telephone?: string } },
        message: string,
      }) => {
        setIsDuplicateEmail(error.code === '409' && !!error.details.details.email);
        setIsDuplicatePhone(error.code === '409' && !!error.details.details.telephone);
        setErrorMessage(
          error.message || 'An error has occurred',
        );
      },
      onSuccess: async (response) => {
        setErrorMessage('');
        if (response) {
          openSnackbar('Account updated');
          if (response.otpVerified) {
            if (response.emailVerified) {
              navigate(Routes.LANDING, { replace: true });
            } else {
              await refreshUser();
              setShowVerifyEmailModal(true);
            }
          } else {
            setShowOtpForm(true);
          }
        }
      },
    },
  );

  const isLoading = isStorefrontLoading || isUserLoading;

  const CloseButton = () => {
    return (
      <Button
        onClick={() => isStorefront ? navigate(Routes.SHOP) : navigate(Routes.HOMEPAGE)}
        size="giant"
        testID="farmer-profile-form-logged-in-button"
        variant="outlined"
      >
        Close
      </Button>
    );
  };

  const SaveButton = () => {
    return (
      <Button
        disabled={!isFormValid()}
        loading={isLoading}
        onClick={() => {
          updateUser();
        }}
        size="giant"
        testID="farmer-profile-form-update-user-button"
      >
        Save
      </Button>
    );
  };

  return (
    <>
      <VSpacer mobileSize="11" size="12" />
      <Container maxWidth="md">
        <Stack>
          <Text category="h3">
            {storefront ? 'Shop Account' : 'My account'}
          </Text>
          <VSpacer size="5" />
          <Text category="p2">
            {storefront
              ? `Any changes to your account information must be made on the ${storefront.name} Portal`
              : 'Please enter the city, county, and state of your farm(s).'
            }
          </Text>
          <VSpacer size="9" />
          <InnerContainer maxWidth="xs">
            {isLoading ? (
              <Stack alignItems="center">
                <ProgressLoader type="circular" />
              </Stack>
            ) : (
              <Stack>
                {!!errorMessage && (
                  <>
                    <Alert color="error" icon={false}>{errorMessage}</Alert>
                    <VSpacer mobileSize="5" size="8" />
                  </>
                )}
                {!isProfileComplete && !isStorefront && (
                  <>
                    <Alert color="info" icon={false}>
                      Please confirm your profile information before continuing
                    </Alert>
                    <VSpacer mobileSize="5" size="8" />
                  </>
                )}
                <Input
                  customInputComponentProps={{ readOnly: isReadOnly }}
                  label="First name"
                  onChangeText={setFirstName}
                  required={!isReadOnly}
                  testID="farmer-profile-form-first-name"
                  value={firstName}
                />
                <VSpacer size="8" />
                <Input
                  customInputComponentProps={{ readOnly: isReadOnly }}
                  label="Last name"
                  onChangeText={setLastName}
                  required={!isReadOnly}
                  testID="farmer-profile-form-last-name"
                  value={lastName}
                />
                <VSpacer size="8" />
                <Input
                  customInputComponentProps={{ readOnly: isReadOnly }}
                  label="Business name"
                  onChangeText={setBusinessName}
                  testID="farmer-profile-form-business-name"
                  value={businessName}
                />
                {!storefront &&
                  <>
                    <VSpacer size="8" />
                    <StateSelect
                      onChangeState={setState}
                      required
                      testID="farmer-profile-form-state"
                      value={state}
                    />
                    <VSpacer size="8" />
                    <CountySelect
                      onChangeCounty={setCountyId}
                      required
                      state={state}
                      testID="farmer-profile-form-county"
                      value={countyId}
                    />
                    <VSpacer size="8" />
                    <Input
                      label="City"
                      onChangeText={setCity}
                      testID="farmer-profile-form-city"
                      value={city}
                    />
                    <VSpacer size="8" />
                    <Input
                      label="Address 1"
                      onChangeText={setAddress1}
                      testID="farmer-profile-form-address-1"
                      value={address1}
                    />
                    <VSpacer size="8" />
                    <Input
                      label="Address 2"
                      onChangeText={setAddress2}
                      testID="farmer-profile-form-address-2"
                      value={address2}
                    />
                    <VSpacer size="8" />
                    <Input
                      inputMode="numeric"
                      label="Postal"
                      onChangeText={setPostal}
                      pattern="[0-9]*"
                      testID="farmer-profile-form-postal"
                      value={postal}
                    />
                  </>
                }
                <VSpacer size="8" />
                <PhoneInput
                  customInputComponentProps={{ readOnly: isReadOnly }}
                  error={(isDuplicatePhone || !isValidPhone) && !isReadOnly}
                  helperText={getPhoneHelperText()}
                  label="Phone"
                  onBlur={() => !isReadOnly
                    && setIsValidPhone(validatePhoneNumber(telephone, AppConfig.env.test))}
                  onChangeText={setTelephone}
                  required={!isReadOnly}
                  testID="farmer-profile-form-phone"
                  value={telephone}
                />
                <VSpacer size="8" />
                <Input
                  customInputComponentProps={{ readOnly: isReadOnly }}
                  error={(isDuplicateEmail || !isValidEmail) && !isReadOnly}
                  helperText={getEmailHelperText()}
                  inputMode="email"
                  label="Email"
                  onBlur={() => !isReadOnly && setIsValidEmail(validateEmail(email))}
                  onChangeText={setEmail}
                  required={!isReadOnly}
                  testID="farmer-profile-form-email"
                  type="email"
                  value={email}
                />
                {isStorefront && user?.externalId &&
                  <>
                    <VSpacer size="8" />
                    <Input
                      customInputComponentProps={{ readOnly: true }}
                      label="Account ID"
                      testID="farmer-profile-form-external-id"
                      value={user.externalId}
                    />
                  </>
                }
                {!isStorefront &&
                  <>
                    <VSpacer size="11" />
                    <Text category="c2">LICENSES</Text>
                    <VSpacer size="9" />
                    <Input
                      label="Restricted Use License"
                      onChangeText={(value) => {
                        if (!value) {
                          setRestrictedUseLicenseExpirationDate(undefined);
                        }
                        setRestrictedUseLicense(value);
                      }}
                      testID="farm-profile-form-restricted-license"
                      value={restrictedUseLicense}
                    />
                    <VSpacer size="8" />
                    <DateInput
                      disabled={!restrictedUseLicense}
                      label="Expiration date"
                      onChangeDate={setRestrictedUseLicenseExpirationDate}
                      testID="farm-profile-form-restricted-license-date"
                      value={restrictedUseLicenseExpirationDate}
                    />
                    <VSpacer size="8" />
                    <Input
                      label="Tax-Exempt License"
                      onChangeText={(value) => {
                        if (!value) {
                          setTaxExemptLicenseExpirationDate(undefined);
                        }
                        setTaxExemptLicense(value);
                      }}
                      testID="farm-profile-form-tax-exempt-license"
                      value={taxExemptLicense}
                    />
                    <VSpacer size="8" />
                    <DateInput
                      disabled={!taxExemptLicense}
                      label="Expiration date"
                      onChangeDate={setTaxExemptLicenseExpirationDate}
                      testID="farm-profile-form-tax-exempt-license-date"
                      value={taxExemptLicenseExpirationDate}
                    />
                    <VSpacer size="8" />
                    <Input
                      label="Technology License"
                      onChangeText={(value) => {
                        if (!value) {
                          setTechnologyLicenseExpirationDate(undefined);
                        }
                        setTechnologyLicense(value);
                      }}
                      testID="farm-profile-form-tech-license"
                      value={technologyLicense}
                    />
                    <VSpacer size="9" />
                    <DateInput
                      disabled={!technologyLicense}
                      label="Expiration date"
                      onChangeDate={setTechnologyLicenseExpirationDate}
                      testID="farm-profile-form-tech-license-date"
                      value={technologyLicenseExpirationDate}
                    />
                    <VSpacer mobileSize="10" size="11" />
                  </>
                }
                <VSpacer mobileSize="9" size="14" />
                <MobileOnly>
                  <Stack alignItems="center">
                    {!isStorefront &&
                      <>
                        <SaveButton />
                        <VSpacer size="9" />
                      </>
                    }
                    {canLeaveForm && <CloseButton />}
                    <VSpacer size="8" />
                  </Stack>
                </MobileOnly>
              </Stack>
            )}
          </InnerContainer>
        </Stack>
      </Container>
      {showOtpForm && user?.telephone &&
        <Modal onClose={() => setShowOtpForm(false)} open testID="profile-otp-form-modal">
          <OtpForm onCancel={() => setShowOtpForm(false)} telephone={user?.telephone} />
        </Modal>
      }
      <DesktopOnly>
        <BottomBar
          justifyContent={canLeaveForm ? 'space-between' : 'flex-end'}
        >
          {canLeaveForm && <CloseButton /> }
          {!isStorefront &&
            <SaveButton />
          }
        </BottomBar>
      </DesktopOnly>
      {showVerifyEmailModal && user && user.email && (
        <VerifyYourEmail
          email={user.email}
          flow="profile"
          onClose={() => setShowVerifyEmailModal(false)}
          userId={user.id}
        />
      )}
    </>
  );
};

export default FarmerProfileForm;
