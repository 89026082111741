import { Button, Chip, HSpacer, Text } from '@/components/DesignSystem';
import ViewRewardsDialog from '@/pages/Admin/FarmerList/ViewRewardsDialog';
import { getUserDisplayName } from '@/utilities/Farmer';
import { ApiUser } from '@api/interfaces';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import { useState } from 'react';
import AddRewardsDialog from './AddRewardsDialog';
import FarmerDetails from './FarmerDetails';
import './styles.css';

type FarmerListItemProps = {
  farmer: ApiUser;
};
const FarmerListItem = ({ farmer }: FarmerListItemProps) => {

  const [addDialog, setAddDialog] = useState(false);
  const [showRewardsDialog, setShowRewardsDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion disableGutters expanded={expanded} sx={{ padding: '28px' }}>
      <AccordionSummary
      className='accordion'
      expandIcon={<ExpandMore onClick={() => setExpanded(!expanded)} />}
      sx={{ padding: '0px' }}
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between' width='100%'>
          <Stack direction="row">
            <Text category='h6'>{getUserDisplayName(farmer)}</Text>
            {farmer.isTest &&
              <>
                <HSpacer size="5" />
                <Chip color="primary" label="Test User" testID="test-user-chip" />
              </>
            }
          </Stack>
          <Stack alignItems={'center'} direction='row' mr={'16px'} spacing={2}>
            <Button
              onClick={() => {
                setShowRewardsDialog(true);
              }}
              testID='view-rewards-button'
              variant='outlined'
            >
              View Rewards
            </Button>
            <Button
              onClick={() => {
                setAddDialog(true);
              }}
              testID='add-rewards-button'
              variant='outlined'
            >
              Add Rewards
            </Button>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{expanded && <FarmerDetails farmer={farmer} />}</AccordionDetails>
      {addDialog && (
        <AddRewardsDialog
          farmer={farmer}
          setShow={setAddDialog}
          show={addDialog}
        />
      )}
      {showRewardsDialog &&
        <ViewRewardsDialog
          setShow={setShowRewardsDialog}
          show={showRewardsDialog}
          userId={farmer.id}
        />
      }
    </Accordion>
  );
};

export default FarmerListItem;
