import { Button, Modal, Text } from '@/components/DesignSystem';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface ConfirmModalProps {
  cancelButtonText?: string,
  confirmButtonText?: string,
  hideHeaderOnMobile?: boolean,
  message: string | ReactNode,
  onCancel: () => void,
  onConfirm?: () => void,
  open: boolean,
  overline?: string,
  subline?: string,
  testID: string,
  title?: string,
}

const ConfirmModal = ({
  cancelButtonText = "Cancel",
  confirmButtonText = "Accept",
  hideHeaderOnMobile = false,
  message,
  onCancel,
  onConfirm,
  open,
  overline,
  subline,
  testID,
  title,
}: ConfirmModalProps) => {
  const renderBody = () => {
    if (typeof message === 'string') {
      return (
        <Text category="body-large">
          {message}
        </Text>
      )
    }
    return (
      <Box>
        {message}
      </Box>
    );
  }
  return (
    <Modal
      acceptButton={!onConfirm ? undefined : (props) => (
        <Button onClick={onConfirm} {...props}>
          {confirmButtonText}
        </Button>
      )}
      cancelButton={(props) => (
        <Button onClick={onCancel} {...props}>
          {cancelButtonText}
        </Button>
      )}
      hideHeaderOnMobile={hideHeaderOnMobile}
      onClose={onCancel}
      open={open}
      overline={overline}
      subline={subline}
      testID={testID}
      title={title}
      titleSpacerSize="5">
      {renderBody()}
    </Modal>
  );
}

export default ConfirmModal;
