import { QueryKeys } from '@/constants/QueryKeys';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ProductApi } from '@/utilities/api/ProductApi';
import { useQuery } from 'react-query';

export const useProductList = () => {

  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PRODUCT],
    ProductApi.list,
  );
    
  const productList = response?.data;
  return {
    isLoading,
    productList,
  }
}

export const UseCategoryList=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_CATEGORY],
    ProductApi.getCategories,
  );
    
  const categoryList = response?.data;
  return {
    isLoading,
    categoryList,
  }
}

export const UsePrimaryNutrient=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PRIMARY_NUTRIENT],
    ProductApi.getPrimaryNutrient,
  );
    
  const primaryNutrientList = response?.data;
  return {
    isLoading,
    primaryNutrientList,
  }
}

export const UseActiveIngredients=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_ACTIVE_INGREDIENTS],
    ProductApi.getActiveIngredients,
  );
    
  const activeIngredientsDataList = response;
  return {
    isLoading,
    activeIngredientsDataList,
  }
}

export const UseManufacturerList=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_MANUFACTURERS_LIST],
    () => ProductApi.getManufacturers({}),
  );
    
  const manufacturerList = response;
  return {
    isLoading,
    manufacturerList,
  }
}


export const UseCropList=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_CROP],
    ProductApi.getCrop,
  );
    
  const cropsList = response?.data;
  return {
    isLoading,
    cropsList,
  }
}

export const UsePracticeList=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PRACTICE],
    ProductApi.getPractice,
  );
    
  const PracticeList = response?.data;
  return {
    isLoading,
    PracticeList,
  }
}


export const UseUoMList=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_UOM],
    ProductApi.getUoM,
  );
    
  const UoMList = response?.data;
  return {
    isLoading,
    UoMList,
  }
}

export const UsePackageSizeList=()=>{
  const { data: response, isLoading } = useQuery(
    [QueryKeys.GET_PACKAGE_SIZE],
    ProductApi.getPackageSize,
  );
    
  const PackageSizeList = response?.data;
  return {
    isLoading,
    PackageSizeList,
  }
}

export const useGetRetailerList = () => {
  const { data: retailerList, isLoading } = useQuery(
    [QueryKeys.ACTIVE_RETAILERS],
    HierarchyOfRetailersApi.getActiveRetailerLists,
  );

  return {
    isLoading,
    retailerList,
  }
};
