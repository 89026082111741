import { Button, Modal, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import Check from '@mui/icons-material/Check';
import { Divider, Stack } from '@mui/material';
import { GrowersContactInfo } from '@shared/enums';
import React from "react";

interface PaymentSubmittedModalProps {
  offerPublicId: string,
  onClose: () => void,
}

export const PaymentSubmittedModal = ({
  offerPublicId,
  onClose,
}: PaymentSubmittedModalProps) => {
  return (
    <Modal
      acceptButton={(props) => (
        <Button onClick={onClose} {...props}>
          Dismiss
        </Button>
      )}
      heroIcon={<Check color="success" />}
      onClose={onClose}
      open
      testID="payment-submitted-modal"
    >
      <Stack alignItems="center" sx={{ width: getResponsiveValue('auto', '380px') }}>
        <Text category="headline-small">Payment submitted</Text>
        <VSpacer size="7" />
        <Text category="body-medium">Thank you for using The GROWERS App!</Text>
        <VSpacer size="7" />
        <Text category="body-medium" textAlign="center">
          You'll receive an email from GROWERS acknowledging receipt of your payment for
          Offer number {offerPublicId}
        </Text>
        <VSpacer size="8" />
        <Divider flexItem />
        <VSpacer size="8" />
        <Text category="body-small" textAlign="center">
          The GROWERS App connects retailers with farmers throughout your region to support
          your business growth.&nbsp;
          <TextLink
            category="body-small"
            href={GrowersContactInfo.growersWebsite}
            onClick={() => window.open(GrowersContactInfo.growersWebsite)}
            testID="payment-submit-modal-info-link"
          >
            Learn more
          </TextLink>
        </Text>
      </Stack>
    </Modal>
  );
}
