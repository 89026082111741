import { Button, Dialog,Text, VSpacer } from "@/components/DesignSystem";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { deleteProductConstant } from "@/constants/productConstant";

interface DeleteDialogProps {
  setShowDeleteConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: (id: string) => void;
  productFieldId: string;
  onClose: () => void;
  showDeleteConfirmationDialog: boolean;
}

const DeleteConfirmation = ({
  setShowDeleteConfirmationDialog,
  handleDelete,
  onClose,
  productFieldId,
  showDeleteConfirmationDialog,
}: DeleteDialogProps) => {
  return (
    <Dialog
      acceptButton={() => (
        <Button
          onClick={() => {
            setShowDeleteConfirmationDialog(false);
            handleDelete(productFieldId);
            onClose();
          }}
          sx={{marginLeft:'10px'}}
          testID="button-demo-enable"
          variant='contained'
        >
          Yes, delete
        </Button>
      )}
      cancelButton={() => (
        <Button
          onClick={() => setShowDeleteConfirmationDialog(false)}
          testID="button-demo-enable"
          variant='outlined'
        >
          Go back
        </Button>
      )}
      heroIcon={<WarningAmberIcon color="warning" />}
      onClose={() => setShowDeleteConfirmationDialog(false)}
      open={showDeleteConfirmationDialog}
      testID="test"
      title={deleteProductConstant.deleteConfirmation}
    >
      <Text sx={{textAlign:'center', width:'calc(100% - 20px)'}}>
        {deleteProductConstant.deleteMessage}</Text>
        <VSpacer size="3"/>
    </Dialog>
  );
};
export default DeleteConfirmation;
