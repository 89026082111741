import { Button, Chip, HSpacer, InlineMessage, Text, VSpacer } from "@/components/DesignSystem";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import PricingRequestStatusChip from '@/components/shared/PricingRequestStatusChip';
import { AppConfig } from "@/constants/AppConfig";
import { QueryKeys } from "@/constants/QueryKeys";
import { Routes } from "@/constants/Routes";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ReviewAndSelectOffersEventType, useLogEvent } from '@/utilities/Analytics';
import { PricingRequestsApi } from "@/utilities/api/PricingRequestsApi";
import { ApiOffer, ApiPricingRequest } from "@api/interfaces";
import { CurrencyExchange, Warning } from "@mui/icons-material";
import ArrowForward from '@mui/icons-material/ArrowForward';
import Check from '@mui/icons-material/Check';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Stack } from "@mui/material";
import {
  OfferStatus,
  PaymentMethodPreference,
  PricingRequestPaymentStatus,
  PricingRequestStatus,
} from '@shared/enums';
import { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import OfferDetails from "../Offers/OfferDetails";

interface PastPricingRequestsItemProps {
  onShowNote: (offer: ApiOffer) => void,
  pricingRequest: ApiPricingRequest,
}

const PricingRequestListItem = ({
  onShowNote,
  pricingRequest,
}: PastPricingRequestsItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const { isMobile } = useMediaQuery();

  const isDraft = pricingRequest.status === PricingRequestStatus.Draft;
  const isOpen = pricingRequest.status === PricingRequestStatus.Open;
  const isInReview = pricingRequest.status === PricingRequestStatus.Review;
  const queryClient = useQueryClient();

  const { isLoading, data: offers } = useQuery(
    [QueryKeys.GET_OFFERS, pricingRequest.id],
    () => PricingRequestsApi.getOffers(pricingRequest.id),
    {
      enabled: expanded,
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || "An error has occurred");
      },
    },
  );

  const offerAcceptedStatuses = [
    OfferStatus.Accepted,
    OfferStatus.AwaitingDelivery,
    OfferStatus.AwaitingFulfillment,
    OfferStatus.AwaitingPickup,
    OfferStatus.OrderShipped,
    OfferStatus.Partial,
    OfferStatus.PickupReady,
  ];
  const hasSomeOffersInside = pricingRequest.offers?.some((offer) => (
    offer.paymentMethodPreference === PaymentMethodPreference.INSIDE
      && offerAcceptedStatuses.includes(offer.status)
  ));

  const isAcceptedButNotPaid = pricingRequest.status === PricingRequestStatus.Accepted
    && pricingRequest.isPaymentDue === true
    && hasSomeOffersInside;

  const actionButtons = (): JSX.Element | undefined => {
    if (isInReview) {
      const navigateToReviewOffers = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        logEvent(ReviewAndSelectOffersEventType.ClickReviewAndSelectOffers);
        navigate(Routes.REVIEW_SELECT_OFFERS.replace(":id", pricingRequest.id));
      };
      return (
        <Button
          endIcon={<ArrowForward fontSize="small" />}
          onClick={navigateToReviewOffers}
          size="small"
          testID="pricing-request-list-item-navigate-review-button"
          variant="outlined"
        >
          Review & Select Offers
        </Button>
      );
    } else if (isAcceptedButNotPaid) {
      const navigateToPayment = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        navigate(Routes.REVIEW_SELECT_OFFERS.replace(':id', pricingRequest.id));
      }
      return (
        <Button
          endIcon={<CurrencyExchange fontSize="small" />}
          onClick={navigateToPayment}
          size="small"
          testID="pricing-request-list-item-navigate-review-button"
          variant="outlined"
        >
          Complete Payment of Selected Offers
        </Button>
      );
    } else if (isOpen) {
      const navigateToReviewOffers = () => {
        navigate(Routes.REVIEW_SELECT_OFFERS.replace(':id', pricingRequest.id));
      };
      return (
        <Button
          endIcon={<ArrowForward fontSize="small" />}
          onClick={navigateToReviewOffers}
          size="small"
          testID="pricing-request-list-item-navigate-open-button"
          variant="outlined"
        >
          Review Request
        </Button>
      );
    }
  }

  const Header = () => {
    const offersReceivedText = (
      <Text category="p1">
        {`${pricingRequest.offers?.length ?? 0} Offers Received`}
      </Text>
    );

    const draftChip = <Chip
      color="warning"
      label="Draft"
      testID="pricing-request-list-item"
    />;

    const paymentBadge = (
      (hasSomeOffersInside
        && pricingRequest.paymentStatus === PricingRequestPaymentStatus.PAYMENT_RECEIVED ? (
        <Chip
          color="success"
          label="Paid"
          testID="payment-status"
        />
        ) : null
      )
    );

    return (
      <Stack sx={{ width: "100%" }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          p={1}
          sx={{ width: "100%" }}
        >
          <Stack>
            <Text category="h6">
              Product Request #{pricingRequest.publicId}
            </Text>
            <VSpacer size="4" />
            {paymentBadge}
            <VSpacer size="4" />
            <Stack alignItems="center" direction="row">
              {isDraft ? (
                draftChip
              ) : (
                <>
                  {pricingRequest.farmerPricingRequestStatus && (
                    <PricingRequestStatusChip
                      paymentStatus={pricingRequest.paymentStatus}
                      status={pricingRequest.farmerPricingRequestStatus}
                    />
                  )}
                  <HSpacer size="5" />
                  {offersReceivedText}
                </>
              )}
              <HSpacer size="3" />
              {!!pricingRequest.acceptedDate && (
                <Check color="success" />
              )}
            </Stack>
          </Stack>
          <DesktopOnly>
            <Stack alignItems="center" direction="row">
              {actionButtons() ?? null}
              <HSpacer size="8" />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
                size="tiny"
                testID="pricing-request-list-item-details-button"
                uppercase
                variant="text"
              >
                Details
              </Button>
              {expanded ? (
                <ExpandLess
                  onClick={() => setExpanded(!expanded)}
                />
              ) : (
                <ExpandMore
                  onClick={() => setExpanded(!expanded)}
                />
              )}
            </Stack>
          </DesktopOnly>
        </Stack>
        {!!pricingRequest.isPaymentDue && (
          <Stack direction="row" justifyContent="flex-end">
            <InlineMessage
              icon={<Warning color="warning" fontSize="small" />}
              testID="payment-due-badge"
              title="Payment due"
              titleColor="warning"
            />
          </Stack>
        )}
      </Stack>
    );
  };

  const earlyExpirationButtons = AppConfig.env.test && (
    <>
      <VSpacer size="7" />
      <Stack direction="row">
        {isOpen && (
          <Button
            onClick={async () => {
              await PricingRequestsApi.expirePricingRequest(pricingRequest.id);
              await queryClient.invalidateQueries(QueryKeys.GET_PRICING_REQUESTS);
              logEvent(ReviewAndSelectOffersEventType.ClickEndOfferPeriod);
            }}
            sx={{ backgroundColor: "red" }}
            testID="pricing-request-list-item-end-offer-period-button"
          >
            End Offer Period
          </Button>
        )}
        {isInReview && (
          <Button
            onClick={async () => {
              await PricingRequestsApi.expirePricingRequestReview(pricingRequest.id);
              await queryClient.invalidateQueries(QueryKeys.GET_PRICING_REQUESTS);
              logEvent(ReviewAndSelectOffersEventType.ClickEndReviewPeriod);
            }}
            sx={{ backgroundColor: "red" }}
            testID="pricing-request-list-item-end-review-period-button"
          >
            End Review Period
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={isMobile && <ExpandMore
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          />}
          onClick={() => {
            navigate(Routes.REVIEW_SELECT_OFFERS.replace(':id', pricingRequest.id));
          }}
        >
          <Header />
        </AccordionSummary>
        <AccordionDetails>
          {!!errorMessage && (
            <>
              <Alert color="error" icon={false}>{errorMessage}</Alert>
              <VSpacer mobileSize="5" size="8" />
            </>
          )}
          <MobileOnly>
            {
              isDraft
              && (actionButtons() ?? null)
            }
            {isOpen && (
              <>
                <Stack direction="row" justifyContent="center">
                  <Button
                    endIcon={<ArrowForward fontSize="small" />}
                    onClick={() => {
                      logEvent(ReviewAndSelectOffersEventType.ClickReviewAndSelectOffers);
                      navigate(
                        Routes.REVIEW_SELECT_OFFERS.replace(
                          ":id",
                          pricingRequest.id,
                        ),
                      );
                    }}
                    size="small"
                    testID="offer-details-review-button"
                    variant="outlined"
                  >
                      Review Request
                  </Button>
                </Stack>
                <VSpacer size="8" />
              </>
            )}
            {(isAcceptedButNotPaid) && (
              <>
                <Stack direction="row" justifyContent="center">
                  {actionButtons()}
                </Stack>
                <VSpacer size="8" />
              </>
            )}
          </MobileOnly>
          {
            isLoading && (
              <Text category="p2">
                Loading offers...
              </Text>
            )
          }
          {
            offers?.data.map((offer, index) => (
              <Fragment key={offer.id}>
                <OfferDetails
                  index={index}
                  offer={offer}
                  onShowNote={onShowNote}
                  pricingRequest={pricingRequest}
                  readonly={true}
                  selectMode={false} />
                <VSpacer mobileSize="4" size="3" />
              </Fragment>
            ))
          }
          {
            !isDraft && offers && offers.data.length === 0 && (
              <Text category="p2">
                No offers have been created for this product request yet
              </Text>
            )
          }
          {earlyExpirationButtons}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default PricingRequestListItem;
