import { Card, Text, VSpacer } from "@/components/DesignSystem";

export const CardDemo = () => {
  return (
    <>
      <Text>Elevated card</Text>
      <VSpacer size="5" />
      <Card testID="card-demo" variant="elevation">
        <Text category="body-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Text>
      </Card>
      <VSpacer size="5" />
      <Text>Outlined card</Text>
      <VSpacer size="5" />
      <Card testID="card-demo" variant="outlined">
        <Text category="body-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Text>
      </Card>
    </>
  );
}
