import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { ProductEventLogger } from '@/hooks/useProductEventLogging';
import { Divider, Stack } from '@mui/material';
import { ProductListDesktop, ProductListMobile } from '@/pages/ViewPriceRequest/ProductList';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { AddPriceModal } from './AddPriceModal';
import { useState } from 'react';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { CreateOfferEventType, useLogEvent } from '@/utilities/Analytics';

interface AddPricesProps {
  onChange: (products: OfferProduct[]) => void,
  productEventLogger?: ProductEventLogger,
  products: OfferProduct[],
}

export const AddPrices = ({
  onChange,
  productEventLogger,
  products,
}: AddPricesProps) => {
  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<OfferProduct | undefined>();
  
  const logEvent = useLogEvent();

  return (
    <>
      <Stack px={getResponsiveValue(0, 2)}>
        <Divider />
        <DesktopOnly>
          <ProductListDesktop
            onAddEditPrice={setSelectedProduct}
            products={products}
            variant="createOffer"
          />
        </DesktopOnly>
        <MobileOnly>
          <ProductListMobile
            onAddEditPrice={setSelectedProduct}
            products={products}
            variant="createOffer"
          />
        </MobileOnly>
        <Divider />
      </Stack>
      {!!selectedProduct && (
        <AddPriceModal
          onClose={() => setSelectedProduct(undefined)}
          onSave={(data) => {
            setSelectedProduct(data);

            const sourceProduct = products.find((product) => (
              product.companionProducts?.some((c) => c.id === data.id)
            ));
            if (data.price) {
              productEventLogger?.logPriceAdded({
                alternativeTo: data.alternativeTo,
                companionTo: sourceProduct?.name,
                productName: data.name!,
                substitute: data.substituteProduct,
              });
            }

            const newProducts = products.map((product) => {
              return product.id === data.id ? data : {
                ...product,
                companionProducts: product.companionProducts?.map((companionProduct)=> {
                  return companionProduct.id === data.id ? data : companionProduct;
                }),
              };
            });

            onChange(newProducts);
            logEvent(CreateOfferEventType.ClickSavePrice);
          }}
          product={selectedProduct}
        />
      )}
    </>
  );
}
