import { Button, Card, HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import { FriendlyOfferStatus } from "@/constants/FriendlyOfferStatus";
import ContactInformation from '@/pages/ReceivePlaceOffers/OfferOverview/ContactInformation';
import { PaymentDetails } from '@/pages/ReceivePlaceOffers/OfferOverview/PaymentDetails';
import { ApiPayment, ApiPricingRequestPublic } from "@api/interfaces";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import StickyNote2Outlined from "@mui/icons-material/StickyNote2Outlined";
import { Grid, Stack } from "@mui/material";
import { DiscountType, OfferStatus } from "@shared/enums";
import { formatCurrency } from '@shared/utilities';
import { formatDate } from '@shared/utilities/DateUtils';
import { Fragment } from "react";
import { LastLookData, Offer, Product } from "../interfaces";
import { LastLookSameOrganizationBanner } from "./LastLookSameOrganizationBanner";
import OfferCostSummary from "./OfferCostSummary";
import OfferFarmerNote from "./OfferFarmerNote";
import OfferFulfillmentMethod from "./OfferFulfillmentMethod";
import OfferHeader from "./OfferHeader";
import OfferLicenses from "./OfferLicenses";
import OfferNote from "./OfferNote";
import ProductCard from "./ProductCard";

type OfferOverviewDesktopProps = {
  expired?: boolean,
  lastLookData?: LastLookData,
  offer: Offer,
  onApplyDiscount: () => void,
  onChangeOffer: (offer: Offer) => void,
  onEditNote: () => void,
  payment?: ApiPayment | null,
  pricingRequest: ApiPricingRequestPublic,
  readonly?: boolean,
  reviewMode?: boolean,
}

export const OfferOverviewDesktop = ({
  expired = false,
  lastLookData,
  offer,
  onApplyDiscount,
  onChangeOffer,
  onEditNote,
  payment,
  pricingRequest,
  readonly = false,
  reviewMode = false,
}: OfferOverviewDesktopProps) => {
  const offerStatus = pricingRequest.offer?.status;
  const isAcceptedOffer = offerStatus === OfferStatus.Accepted
    || offerStatus === OfferStatus.Partial;
  const hasPlacedOffer = !!pricingRequest.offer;

  const getPageTitle = () => {
    if (readonly) {
      if (expired && !hasPlacedOffer) {
        return `Product Request #${pricingRequest.publicId}`;
      } else {
        return `Offer ${offer.publicId}`;
      }
    } else {
      if (reviewMode) {
        return 'Review and Submit Offer';
      } else {
        return 'Create Offer';
      }
    }
  }

  return (
    <Stack>
      <VSpacer size="8" />
      <Grid container direction="row" justifyContent="space-between" spacing={4}>
        <Grid item md={payment ? 7 : 12} xs={payment ? 6 : 12}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Text category="h3">
              {getPageTitle()}
            </Text>
            {(!reviewMode && !readonly) && (
              <Button
                onClick={onEditNote}
                startIcon={<StickyNote2Outlined />}
                testID="offer-overview-desktop-outline-edit-button"
                variant="text"
              >
                {!offer.note ? 'Add note' : 'Edit note'}
              </Button>
            )}
          </Stack>
          <Text category="p2">
            {`Sent on ${formatDate(pricingRequest.approvedDate as Date)}. `}
          </Text>
          <LastLookSameOrganizationBanner
            bottomSpacing="4"
            lastLookData={lastLookData}
            topSpacing="6"
          />
          {(readonly && hasPlacedOffer) && (
            <>
              {!!offerStatus &&
                <Text category="p2">
                  {FriendlyOfferStatus[offerStatus]}
                </Text>
              }
              <Text category="p2">
                This offer has already been placed and cannot be changed.
              </Text>
            </>
          )}
          {(expired && !hasPlacedOffer) && (
            <Text category="p2">
              This product request has expired and no new offers can be placed
            </Text>
          )}
        </Grid>
        {(!!payment && !!offer.publicId && !!payment.feePercent) && (
          <Grid item md={5} xs={6}>
            <PaymentDetails offerPublicId={offer.publicId} payment={payment} />
          </Grid>
        )}
      </Grid>
      {(isAcceptedOffer && pricingRequest.user) && (
        <>
          <VSpacer size="7" />
          <ContactInformation user={pricingRequest.user} />
        </>
      )}
      <VSpacer size="7" />
      <Card testID="offer-overview-desktop-view">
        <OfferHeader
          pricingRequest={pricingRequest}
          user={!isAcceptedOffer ? pricingRequest.user : undefined}
        />
        <VSpacer size="7" />
        {offer.products?.map((product, index) => (
          <Fragment key={product.pricingRequestProductId}>
            <ProductCard
              disabled={reviewMode}
              lastLookData={lastLookData}
              onChange={(updatedProduct: Product) => {
                if (offer.products) {
                  offer.products[index] = updatedProduct;
                }
                onChangeOffer(offer);
              }}
              product={product}
              readonly={readonly} />
            <VSpacer size="3" />
          </Fragment>
        ))}
      </Card>
      {(!reviewMode && !readonly) && (
        <>
          <VSpacer size="7" />
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Button
              onClick={onApplyDiscount}
              startIcon={<LocalOfferOutlined />}
              testID="offer-overview-desktop-apply-discount-button"
              variant="text"
            >
              Add Discount to Offer
            </Button>
            {offer.discountAmount && (
              <Stack direction="row">
                <LocalOfferOutlined color="success" />
                <HSpacer size="3" />
                <Text category="p2" color="success">
                  Discount Added: {offer.discountType === DiscountType.Dollar
                  ? formatCurrency(offer.discountAmount)
                  : `${offer.discountAmount}%`}
                </Text>
              </Stack>
            )}
          </Stack>
          { pricingRequest.note && (
            <>
              <VSpacer size="6" />
              <VSpacer size="3" />
              <OfferFarmerNote note={pricingRequest.note} />
            </>
          )}
        </>
      )}
      {(reviewMode && !expired) && (
        <>
          <VSpacer size="10" />
          <OfferCostSummary offer={offer} />
          <VSpacer size="10" />
          <OfferFulfillmentMethod
            offerMethod={offer.fulfillmentMethod}
            onEditFulfillmentMethod={(fulfillmentMethod) => {
              onChangeOffer({ ...offer, fulfillmentMethod });
            }}
            pricingRequest={pricingRequest}
            readonly={readonly}
          />
          {(!!pricingRequest.user && isAcceptedOffer) && (
            <>
              <VSpacer size="10" />
              <Stack direction="row">
                <Text category="h3">Licenses</Text>
              </Stack>
              <VSpacer size="7" />
              <OfferLicenses user={pricingRequest.user} />
            </>
          )}
          {!!offer.note && (
            <>
              <VSpacer size="10" />
              <OfferNote offer={offer} onEditNote={onEditNote} readonly={readonly} />
            </>
          )}
        </>
      )}
      <VSpacer size="14" />
    </Stack>
  )
}

