import { Filter, Text } from '@/components/DesignSystem';
import { PricingRequestEndpoint } from '@api/endpoints';
import { Stack } from '@mui/material';
import { PriceOfferStatusFilter } from '@shared/enums';
import { useState } from 'react';
export const FilterDemo = () => {
  const [selectedFilters, setSelectedFilters] = (
    useState<PricingRequestEndpoint.ListForRetailer.Query>({})
  );
  return (
    <Stack spacing={2}>
      <Text>
        Selected filters: {JSON.stringify(selectedFilters)}
      </Text>
      <Filter<PricingRequestEndpoint.ListForRetailer.Query>
        filters={[{
          id: 'offerStatus',
          label: 'Status',
          options: Object.values(
            PriceOfferStatusFilter,
          ).map(
            (value) => ({ id: value.toString(), label: value.toString() }),
          ),
        }]}
        onChange={(filters) => setSelectedFilters(filters)}
        recordName="Product request"
        testID="filter-demo"
        totalResults={10}
      />
    </Stack>
  );
}