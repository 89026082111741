import { PricingRequestStatus, PricingRequestStatusNew } from "@shared/enums";

export const FriendlyPricingRequestStatus = {
  [PricingRequestStatus.Accepted]: 'Payment Due',
  [PricingRequestStatus.AwaitingApproval]: 'Pending Approval',
  [PricingRequestStatus.Closed]: 'Offer Closed',
  [PricingRequestStatus.Draft]: 'Draft',
  [PricingRequestStatus.Open]: 'Awaiting Offer',
  [PricingRequestStatus.Rejected]: 'Rejected',
  [PricingRequestStatus.Review]: 'Farmer Review',
  [PricingRequestStatus.AwaitingFulfillment]: 'Awaiting Fulfillment',
  [PricingRequestStatus.OrderShipped]: 'Order Shipped',
  [PricingRequestStatus.PickupReady]: 'Pickup Ready',
  [PricingRequestStatus.OnAccountRequested]: 'On account',
  [PricingRequestStatus.FinancingRequested]: 'Financing requested',
  [PricingRequestStatus.AwaitingDelivery]: 'Awaiting Delivery',
  [PricingRequestStatus.AwaitingPickup]: 'Awaiting Pickup',
};

export const FriendlyPricingRequestStatusNew = {
  [PricingRequestStatusNew.AwaitingDelivery]: 'Awaiting Delivery',
  [PricingRequestStatusNew.AwaitingFulfillment]: 'Awaiting Fulfillment',
  [PricingRequestStatusNew.AwaitingOffer]: 'Awaiting Offer',
  [PricingRequestStatusNew.AwaitingPickup]: 'Awaiting Pickup',
  [PricingRequestStatusNew.Draft]: 'Draft',
  [PricingRequestStatusNew.FinancingRequested]: 'Finance Requested',
  [PricingRequestStatusNew.OfferClosed]: 'Offer Closed',
  [PricingRequestStatusNew.OfferExpired]: 'Offer Expired',
  [PricingRequestStatusNew.OnAccountRequested]: 'On Account',
  [PricingRequestStatusNew.OrderShipped]: 'Order Shipped',
  [PricingRequestStatusNew.PaymentDue]: 'Payment Due',
  [PricingRequestStatusNew.PendingApproval]: 'Pending Approval',
  [PricingRequestStatusNew.PickupReady]: 'Pickup Ready',
  [PricingRequestStatusNew.ReviewOffer]: 'Review Offer',
}
