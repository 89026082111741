import { Button, Modal, Text } from '@/components/DesignSystem';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ApiOffer } from '@api/interfaces';
import { Container } from "@mui/material";

interface NoteModalProps {
  isVisible: boolean,
  offer: ApiOffer,
  onClose: () => void,
}

const NoteModal = ({
  isVisible,
  offer,
  onClose,
}: NoteModalProps) => {

  const { isMobile } = useMediaQuery();

  return (
    <Modal
      cancelButton={(props) => (
        <Button
          onClick={onClose}
          {...props}
        >
          Close
        </Button>
      )}
      hideHeaderOnMobile={isMobile}
      largeModal
      onClose={onClose}
      open={isVisible}
      overline="Note"
      testID="note-modal"
      title={`From ${offer.pricingRequestRetailer?.name} - Offer #${offer.publicId}`}
      titleSpacerSize="11">
      <Container maxWidth="sm" sx={{ marginLeft: 0 }}>
        <Text category="p1" multiline>
          {offer.note}
        </Text>
      </Container>
    </Modal>
  );
}

export default NoteModal;
