import { Select, VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { StripePaymentDemo } from '@/pages/UXSandbox/demos/complex/StripePaymentDemo';
import { Container, ListSubheader, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BadgeDemo,
  BannerDemo,
  BottomBarDemo,
  BottomSheetDemo,
  ButtonDemo,
  CalculateQuantityModalDemo,
  CardDemo,
  CheckboxDemo,
  ChipDemo,
  CreateOfferDemo,
  DataPointDemo,
  DataSetDemo,
  DataTableDemo,
  DateInputDemo,
  DatePickerDemo,
  DialogDemo,
  DividerDemo,
  EmptyStateDemo,
  FabDemo,
  FilterDemo,
  FullscreenDialogDemo,
  GlobalBannerDemo,
  GlobalHeaderDemo,
  HeadingDemo,
  IconButtonDemo,
  IconDemo,
  InlineMessageDemo,
  InlineTextListDemo,
  InputDemo,
  ListDemo,
  LogoDemo,
  MenuDemo,
  ModalBottomSheetDemo,
  ModalDemo,
  NavigationBarDemo,
  NavigationDrawerDemo,
  NavigationRailDemo,
  NumericInputDemo,
  OfferDetailsDemo,
  OfferWindowPickerDemo,
  OtherDemo,
  PaymentTypePickerDemo,
  PreferredRetailerCardDemo,
  PriceRequestDetailsModalDemo,
  PriceRequestOrderDemo,
  PricingRequestCardDemo,
  ProductCardDemo,
  ProductListItemDemo,
  ProgressIndicatorDemo,
  ProgressLoaderDemo,
  ProgressStepperDemo,
  RadioDemo,
  ResponsiveDialogDemo,
  ResponsiveSideSheetDemo,
  RetailerCardDemo,
  SearchDemo,
  SegmentedButtonDemo,
  SelectDemo,
  SideSheetDemo,
  SnackbarDemo,
  SplitTabsDemo,
  SwitchDemo,
  TabsDemo,
  TextAreaInputDemo,
  TextDemo,
  TextElementDemo,
  TextLinkDemo,
  TextListDemo,
  ToolbarDemo,
  TopAppBarDemo,
  UIShellDemo,
} from '.';

interface RenderComponent {
  component: () => JSX.Element,
  name: string,
}

type SortedComponent = { name: string };

const styles = {
  container: {
    padding: 60,
  },
  demoContainer: {
    padding: '32px',
    borderRadius: '10px',
  },
  mobileSelect: {
    width: '100%',
  },
  select: {
    width: '30%',
  },
};

interface Group {
  category: string,
  components: RenderComponent[],
}

const groups: Group[] = [
  {
    category: 'simple',
    components: [
      {
        component: BadgeDemo,
        name: 'Badge',
      },
      {
        component: BannerDemo,
        name: 'Banner',
      },
      {
        component: ButtonDemo,
        name: 'Button',
      },
      {
        component: CardDemo,
        name: 'Card',
      },
      {
        component: CheckboxDemo,
        name: 'Checkbox',
      },
      {
        component: ChipDemo,
        name: 'Chip',
      },
      {
        component: DataPointDemo,
        name: 'DataPoint',
      },
      {
        component: DataTableDemo,
        name: 'DataTable',
      },
      {
        component: DateInputDemo,
        name: 'DateInput',
      },
      {
        component: DatePickerDemo,
        name: 'DatePicker',
      },
      {
        component: DialogDemo,
        name: 'Dialog',
      },
      {
        component: DividerDemo,
        name: 'Divider',
      },
      {
        component: EmptyStateDemo,
        name: 'EmptyState',
      },
      {
        component: FabDemo,
        name: 'Fab',
      },
      {
        component: FullscreenDialogDemo,
        name: 'FullscreenDialog',
      },
      {
        component: GlobalBannerDemo,
        name: 'GlobalBanner',
      },
      {
        component: IconButtonDemo,
        name: 'IconButton',
      },
      {
        component: IconDemo,
        name: 'Icon',
      },
      {
        component: InputDemo,
        name: 'Input',
      },
      {
        component: ListDemo,
        name: 'List',
      },
      {
        component: LogoDemo,
        name: 'Logo',
      },
      {
        component: MenuDemo,
        name: 'Menu',
      },
      {
        component: ModalDemo,
        name: 'Modal',
      },
      {
        component: ModalBottomSheetDemo,
        name: 'ModalBottomSheet',
      },
      {
        component: NavigationBarDemo,
        name: 'NavigationBar',
      },
      {
        component: NavigationDrawerDemo,
        name: 'NavigationDrawer',
      },
      {
        component: NavigationRailDemo,
        name: 'NavigationRail',
      },
      {
        component: NumericInputDemo,
        name: 'NumericInput',
      },
      {
        component: OtherDemo,
        name: 'Other',
      },
      {
        component: ProgressIndicatorDemo,
        name: 'ProgressIndicator',
      },
      {
        component: ProgressLoaderDemo,
        name: 'ProgressLoader',
      },
      {
        component: ProgressStepperDemo,
        name: 'ProgressStepper',
      },
      {
        component: RadioDemo,
        name: 'Radio',
      },
      {
        component: SearchDemo,
        name: 'Search',
      },
      {
        component: SegmentedButtonDemo,
        name: 'SegmentedButton',
      },
      {
        component: SelectDemo,
        name: 'Select',
      },
      {
        component: SnackbarDemo,
        name: 'Snackbar',
      },
      {
        component: SwitchDemo,
        name: 'Switch',
      },
      {
        component: TabsDemo,
        name: 'Tabs',
      },
      {
        component: TextDemo,
        name: 'Text',
      },
      {
        component: TextAreaInputDemo,
        name: 'TextAreaInput',
      },
      {
        component: TextElementDemo,
        name: 'TextElement',
      },
      {
        component: TextLinkDemo,
        name: 'TextLink',
      },
      {
        component: TopAppBarDemo,
        name: 'TopAppBar',
      },
      {
        component: () => <UIShellDemo />,
        name: 'UIShell',
      },
    ],
  },
  {
    category: 'complex',
    components: [
      {
        component: BottomBarDemo,
        name: 'BottomBar',
      },
      {
        component: BottomSheetDemo,
        name: 'BottomSheet',
      },
      {
        component: CalculateQuantityModalDemo,
        name: 'CalculateQuantityModal',
      },
      {
        component: CreateOfferDemo,
        name: 'CreateOffer',
      },
      {
        component: DataSetDemo,
        name: 'DataSet',
      },
      {
        component: FilterDemo,
        name: 'Filter',
      },
      {
        component: GlobalHeaderDemo,
        name: 'Global Header',
      },
      {
        component: HeadingDemo,
        name: 'Heading',
      },
      {
        component: InlineMessageDemo,
        name: 'InlineMessage',
      },
      {
        component: OfferDetailsDemo,
        name: 'OfferDetails',
      },
      {
        component: OfferWindowPickerDemo,
        name: 'OfferWindowPicker',
      },
      {
        component: PaymentTypePickerDemo,
        name: 'PaymentTypePicker',
      },
      {
        component: PreferredRetailerCardDemo,
        name: 'PreferredRetailerCard',
      },
      {
        component: PriceRequestDetailsModalDemo,
        name: 'PriceRequestDetailsModal',
      },
      {
        component: PricingRequestCardDemo,
        name: 'PricingRequestCard',
      },
      {
        component: ProductCardDemo,
        name: 'ProductCard',
      },
      {
        component: ProductListItemDemo,
        name: 'ProductListItem',
      },
      {
        component: RetailerCardDemo,
        name: 'RetailerCard',
      },
      {
        component: SideSheetDemo,
        name: 'SideSheet',
      },
      {
        component: SplitTabsDemo,
        name: 'SplitTabs',
      },
      {
        component: StripePaymentDemo,
        name: 'StripePayment',
      },
      {
        component: ToolbarDemo,
        name: 'Toolbar',
      },
    ],
  },
  {
    category: 'shared',
    components: [
      {
        component: InlineTextListDemo,
        name: 'InlineTextList',
      },
      {
        component: PriceRequestOrderDemo,
        name: 'PriceRequestOrder',
      },
      {
        component: ResponsiveDialogDemo,
        name: 'ResponsiveDialog',
      },
      {
        component: ResponsiveSideSheetDemo,
        name: 'ResponsiveSideSheet',
      },
      {
        component: TextListDemo,
        name: 'TextList',
      },
    ],
  },
];

export const UXSandbox = () => {
  const { isMobile } = useMediaQuery();
  const { componentName } = useParams();
  const navigate = useNavigate();

  const RenderComponent = groups
    .reduce((acc, { components }) => {
      acc.push(...components);
      return acc;
    }, [] as RenderComponent[])
    .find(({ name }) => name === componentName)?.component;

  const sortedByName = (a: SortedComponent, b: SortedComponent) =>
    a.name.localeCompare(b.name);


  return (
    <div style={isMobile ? {} : styles.container}>
      <Select
        id="component-select"
        label="Select component"
        onChangeValue={(name) => navigate(`${Routes.UX_SANDBOX}/${name}`)}
        style={isMobile ? styles.mobileSelect : styles.select}
        testID="ux-sandbox-selector"
        value={componentName}
      >
        {groups.map(({ category, components }) => ([
          <ListSubheader key={category}>
            {category}
          </ListSubheader>,
          components.sort(sortedByName).map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          )),
        ]))}
      </Select>
      <VSpacer size="6" />
      <Container sx={isMobile ? {} : styles.demoContainer}>
        {RenderComponent && <RenderComponent />}
      </Container>
    </div>
  );
};
