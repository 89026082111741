import { ApiExtendedProduct, ApiManufacturer, ApiPricingRequestCart } from '@api/interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { Box, Stack } from '@mui/material';
import { Text } from '../Text/Text';
import { HSpacer, VSpacer } from '../Spacer';
import { Button } from '../Button/Button';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ProductUom, ProductUomEnums, parseEnums } from '@shared/enums';
import { getProductImageUrl } from '@/utilities/Product';

export type CartProduct = ApiPricingRequestCart;

export interface CartProductProps {
  cartProduct: CartProduct,
  onEdit: (cartProductId: string) => void,
  onRemove: (cartProductId: string) => void,
}

const styles: SXStyles = {
  card: {
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: 'background.paper',
  },
  image: {
    borderRadius: '4px',
    height: '52px',
    width: '52px',
  },
} as const;

export const CartProductCard = ({
  cartProduct,
  onEdit,
  onRemove,
}: CartProductProps) => {
  const imageUrl = getProductImageUrl(cartProduct.productDetails?.image);

  const uom = FriendlyUoM[parseEnums(ProductUomEnums, cartProduct.uom) as ProductUom];
  const productDetails = cartProduct.productDetails as ApiExtendedProduct;
  const manufacturerProduct =
    productDetails?.manufacturerProduct as ApiManufacturer & { manufacturer: ApiManufacturer };
  const manufacturerName = manufacturerProduct?.manufacturer.name;

  return (
    <Box
      data-testid={`cart-card-${cartProduct.id}`}
      sx={styles.card}
    >
      <Stack direction="row" spacing={2}>
        <Box
          alt={`Image of ${cartProduct?.name}`}
          component="img"
          src={imageUrl}
          sx={styles.image}
        />
        <Stack flex={1} spacing={1}>
          <Text category="title-medium">{cartProduct.name}</Text>
            {!!manufacturerName && (
              <Text category="body-small">
                {manufacturerName}
              </Text>
            )}
          <Text category="title-medium">
            {cartProduct.quantity}&nbsp;{uom}
          </Text>
          <VSpacer size="2" />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              onClick={() => onEdit(cartProduct.id)}
              testID="edit-cart-item-button"
              variant="outlined"
            >
              Edit
            </Button>
            <HSpacer size="3" />
            <Button
              onClick={() => onRemove(cartProduct.id)}
              testID="remove-cart-item-button"
              variant="outlined"
            >
              Remove
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
