export enum RecommendationFarmerStatus {
  Expired = 'EXPIRED',
  Ignored = 'IGNORED',
  New = 'NEW',
  Ordered = 'ORDERED',
  Viewed = 'VIEWED',
}

export enum RecommendationFarmerStatusFilterList {
  Expired = 'Expired',
  Ignored = 'Ignored',
  New = 'New',
  Ordered = 'Ordered',  
}