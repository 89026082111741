import { Routes } from '@/constants/Routes';
import { CheckEmailSignUpPage } from '@/pages/Auth/CheckEmailPage';
import { CheckYourEmail } from '@/pages/Auth/CheckYourEmail';
import { CreateAccountPage } from '@/pages/Auth/CreateAccountPage';
import FarmerForgotPasswordForm from '@/pages/Auth/FarmerForgotPasswordForm';
import { LandingPage } from '@/pages/Auth/LandingPage';
import { LoginPage } from '@/pages/Auth/LoginPage';
import { GetAppPage } from '@/views/GetAppPage';
import { CheckEmailLayout, LoginLayout, PasswordLayout } from '@/views/Layouts';
import { UnderConstruction } from '@/views/UnderConstruction';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";
import SetPasswordForm from '../pages/Auth/SetPasswordForm';

export const LoginView = () => {
  return (
    <AppRoutes>
      <Route element={<LandingPage />} path={Routes.LANDING} />
      <Route element={<LoginPage />} path={Routes.LOGIN} />
      <Route
        element={<CheckEmailLayout><CheckEmailSignUpPage /></CheckEmailLayout>}
        path={Routes.CHECK_SIGN_UP_EMAIL}
      />
      <Route
        element={<CheckEmailLayout><CheckYourEmail /></CheckEmailLayout>}
        path={Routes.CHECK_EMAIL}
      />
      <Route
        element={<CreateAccountPage />}
        path={Routes.CREATE_ACCOUNT}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm /></PasswordLayout>}
        path={Routes.CREATE_PASSWORD}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm termsAndConditions /></PasswordLayout>}
        path={Routes.SET_RETAILER_PASSWORD}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm isResetPasswordForm /></PasswordLayout>}
        path={Routes.RESET_PASSWORD}
      />
      <Route
        element={<LoginLayout><FarmerForgotPasswordForm /></LoginLayout>}
        path={Routes.FORGOT_PASSWORD}
      />
      <Route
        element={<FarmerForgotPasswordForm />}
        path={Routes.FORGOT_PASSWORD}
      />
      <Route element={<UnderConstruction />} path={Routes.UNDER_CONSTRUCTION} />
      <Route element={<GetAppPage />} path={Routes.GET_APP} />
      <Route element={<Navigate to={Routes.LANDING} />} path={"*"} />
    </AppRoutes>
  );
}
