import { Button, ProgressLoader, Text, Toolbar, VSpacer } from "@/components/DesignSystem";
import { Container, Pagination, Stack } from "@mui/material";
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { PromotionCard } from '@/pages/Admin/Promotions/PromotionCard';
import { useEffect, useMemo, useState } from 'react';
import { ApiProductList } from '@api/interfaces';
import { Modify } from '@shared/utilities/UtilityTypes';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import Add from '@mui/icons-material/Add';
import { Mode, PromotionInputs, PromotionModal } from '@/pages/Admin/Promotions/PromotionModal';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { useSearch } from '@/hooks/useSearch';
import { formatDateOnly } from '@shared/utilities';
import { ToastMessages } from '@/constants/constant';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { PromotionEndpoint } from '@api/endpoints/PromotionEndpoint';

export const Promotions = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { setSearch, debouncedSearch } = useSearch(1);

  const [page, setPage] = useState(0);
  const [showPromotionModal, setShowPromotionModal] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [promotionModalMode, setPromotionModalMode] = useState<Mode | undefined>();
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    setPage(0);
  }, [selectionsString, debouncedSearch]);

  async function handleDelete (id: string) {
    await PromotionApi.delete(id);
    await queryClient.invalidateQueries(QueryKeys.GET_PROMOTIONS);
  }

  async function handleSave (promotion: PromotionInputs) {
    if (promotion.id) {
      await updatePromotion(promotion as Required<PromotionInputs>);
    } else {
      await createPromotion(promotion as Omit<Required<PromotionInputs>, 'id'>);
    }
  }

  const { mutateAsync: createPromotion } = useMutation(
    async (promotion: Omit<Required<PromotionInputs>, 'id'>) => {
      const requestBody = {
        ...promotion,
        productIds: promotion.products.map(({ id }) => id),
        locationIds: promotion.locations.map(({ id }) => id),
        startDate: formatDateOnly(promotion.startDate),
        endDate: formatDateOnly(promotion.endDate),
      };
      return PromotionApi.create(requestBody as PromotionEndpoint.Create.Request);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_PROMOTIONS]);
        openSnackbar(ToastMessages.promotionCreated);
      },
      onError: (error: { message: string }) => {
        openSnackbar(error.message);
      },
    },
  );

  const { mutateAsync: updatePromotion } = useMutation(
    async (promotion: Required<PromotionInputs>) => {
      const requestBody = {
        ...promotion,
        productIds: promotion.products.map(({ id }) => id),
        locationIds: promotion.locations.map(({ id }) => id),
        startDate: formatDateOnly(promotion.startDate),
        endDate: formatDateOnly(promotion.endDate),
      };
      return PromotionApi.update(promotion.id, requestBody);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_PROMOTIONS]);
        openSnackbar(ToastMessages.promotionUpdated);
      },
      onError: (error: { message: string }) => {
        openSnackbar(error.message);
      },
    },
  );


  const { data: locations } = useQuery(
    [QueryKeys.GET_LOCATIONS],
    async () => RetailerLocationApi.list({ isActive: true, limit: 1000 }),
  );

  const { data: products } = useQuery(
    [QueryKeys.GET_PRODUCTS],
    async () => ProductApi.getProductList({ limit: 1000 }),
  );
  const productsWithNames = useMemo(() => (
    products?.data.filter(({ name }) => name) ?? []
  ) as Modify<ApiProductList, { name: string }>[], [products]);

  const filters: Filter[] = [
    {
      id: 'show-expired',
      label: 'Show Expired',
      options: [
        { id: 'show-expired', label: 'Show Expired' },
      ],
      selectionMethod: 'boolean',
    },
    {
      id: 'locations',
      label: 'Retailers',
      options: locations?.data.map(({ id, name }) => ({
        id,
        label: name,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
    {
      id: 'products',
      label: 'Products',
      options: productsWithNames.map(({ id, name }) => ({
        id,
        label: name,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
  ];

  const isActive = !filterSelections?.get('show-expired')?.has('show-expired') || undefined;
  const productIds = Array.from(filterSelections?.get('products') ?? []);
  const locationIds = Array.from(filterSelections?.get('locations') ?? []);
  const { data: promotions, isFetching: arePromotionsFetching } = useQuery(
    [QueryKeys.GET_PROMOTIONS, debouncedSearch, selectionsString, page],
    async () => PromotionApi.list({
      isActive,
      productIds,
      locationIds,
      page,
      limit: 20,
      search: debouncedSearch,
      sort: 'endDate',
    }),
  );

  const Header = (
    <>
      <Text category="title-large">
        Promotions
      </Text>
      <VSpacer size="5" />
      <Stack alignItems="end" direction="row" justifyContent="space-between">
        <Toolbar
          filters={filters}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          testID="promotions-toolbar"
          totalItems={promotions?.total}
          totalUnit="promotion"
        />
        <VSpacer size="6" />
        <Button
          onClick={() => {
            setPromotionModalMode('add');
            setShowPromotionModal(true);
          }}
          startIcon={<Add />}
          testID="create-promotion-button"
        >
          Create Promotion
        </Button>
      </Stack>
    </>
  );

  const PromotionCards = !!promotions?.data.length && (
    <Stack gap="16px">
      {promotions.data.map((promotion) => (
        <PromotionCard
          key={promotion.id}
          onDelete={() => {
            setSelectedId(promotion.id);
            setShowDeleteDialog(true);
          }}
          onEdit={() => {
            setPromotionModalMode('edit');
            setSelectedId(promotion.id);
            setShowPromotionModal(true);
          }}
          onView={() => {
            setPromotionModalMode('view');
            setSelectedId(promotion.id);
            setShowPromotionModal(true);
          }}
          promotion={promotion}
          testID={`promotion-${promotion.name}`}
        />
      ))}
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <VSpacer size="10" />
        {Header}
        <VSpacer size="6" />
        {arePromotionsFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : PromotionCards}
        {!!promotions?.total && promotions?.lastPage > 0 && (
          <Stack alignItems="center" py="20px">
            <Pagination
              count={promotions?.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={promotions?.page + 1}
            />
          </Stack>
        )}
      </Container>
      {showPromotionModal && promotionModalMode && (
        <PromotionModal
          mode={promotionModalMode}
          onClose={() => {
            setShowPromotionModal(false);
            setPromotionModalMode(undefined);
            setSelectedId(undefined);
          }}
          onSave={handleSave}
          promotionIdToEdit={selectedId}
          testID={'create-promotion-modal'}
        />
      )}
      {showDeleteDialog && !!selectedId && (
        <ConfirmDialog
          cancelText="Go back"
          confirmText="Yes, delete"
          onCancel={() => {
            setShowDeleteDialog(false);
            setSelectedId(undefined);
          }}
          onConfirm={async () => {
            setShowDeleteDialog(false);
            await handleDelete(selectedId);
            setSelectedId(undefined);
          }}
          open
          testID={'delete-promotion-dialog'}
          title="Delete Promotion"
        >
          Are you sure you want to delete this promotion?
        </ConfirmDialog>
      )}
    </>
  );
}
