import {
  DataPoint,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { Divider, Stack } from '@mui/material';
import { FulfillmentMethod } from '@shared/enums';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { ApiPricingRequestPublic } from '@api/interfaces';
import { Offer } from '@/pages/ReceivePlaceOffers/interfaces';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { useFarmerOfferInfo } from '@/pages/ViewPriceRequest/helpers';

export interface ReviewAndSubmitProps {
  handleShippingChange: () => void,
  offer: Offer,
  priceRequest: ApiPricingRequestPublic,
  products: OfferProduct[],
}

export const ReviewAndSubmit = ({
  handleShippingChange,
  offer,
  priceRequest,
  products,
}: ReviewAndSubmitProps) => {
  const {
    deliveryAddress,
    offerSubtotal,
    offerShipping,
    offerTotal,
  } = useFarmerOfferInfo(priceRequest, offer, products);

  return (
    <>
      <DataPoint
        label="Subtotal"
        size="large"
        spacing="fixed"
        testID="review-and-submit-subtotal"
      >
        {offerSubtotal}
      </DataPoint>
      {offer.fulfillmentMethod !== FulfillmentMethod.PICKUP && (
        <DataPoint
          label="Shipping"
          size="large"
          spacing="fixed"
          testID="review-and-submit-shipping"
        >
          <Stack>
            {offerShipping}
            <TextLink
              category="body-small"
              color="primary"
              onClick={handleShippingChange}
              testID="review-and-submit-shipping-change"
            >
              Change
            </TextLink>
          </Stack>
        </DataPoint>
      )}
      <DataPoint
        label="Order Total"
        size="large"
        spacing="fixed"
        testID="review-and-submit-total"
      >
        {offerTotal}
      </DataPoint>
      <DataPoint
        label="Fulfillment"
        size="large"
        spacing="fixed"
        testID="review-and-submit-fulfillment"
      >
        {offer.fulfillmentMethod === FulfillmentMethod.DELIVERY
          ? `Delivery to ${deliveryAddress}`
          : FriendlyFulfillmentMethod.Pickup
        }
      </DataPoint>
      <VSpacer size="10" />
      <Divider />
    </>
  );
}
