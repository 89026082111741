import {
  Box,
  Stack,
  ListItem,
  ListItemButton,
  Divider,
} from '@mui/material';
import { Fragment, MouseEvent, TouchEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { Button, Text, VSpacer } from '@/components/DesignSystem';
import './DatePickerStyles.css';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { DayNames, MonthNames } from '@/constants/Dates';
import { Typography } from '@/themes/MUITheme/typography';
import Check from "@mui/icons-material/Check";
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

const styles: SXStyles = {
  mainContainer: {
    padding: "8px",
    minWidth: 340,
  },
  dateRangeContainer: {
    maxHeight: 456,
    overflowY: "scroll",
  },
  dropdownMonthPickerContainer: {
    maxHeight: 336,
    overflowY: "scroll",
  },
  dropdownYearPickerContainer: {
    maxHeight: 336,
    overflowY: "scroll",
  },
  listItem: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
  },
  listItemButton: {
    height: 48,
    padding: 0,
    ...Typography["body-large"],
  },
  monthItemIcon: {
    textAlign:"center",
    width: 56,
  },
  yearPickerContainer: {
    maxHeight: 280,
    overflowY: "scroll",
  },
} as const;

export interface DatePickerCalendarProps {
  internalEndDate?: Date,
  internalStartDate?: Date,
  endDate?: Date,
  onChange: (startDate: Date, endDate?: Date) => void,
  onShowMonthPicker?: (show: boolean) => void,
  onShowYearPicker?: (show: boolean) => void,
  startDate?: Date,
  testID: string,
  variant: 'modal' | 'dropdown' | 'modal-range',
}

interface CalendarItemProps {
  highlight?: 'middle' | 'start' | 'end',
  isSelected: boolean,
  isToday: boolean,
  onChange: (value: DateTime) => void,
  testID: string,
  value?: DateTime,
}

interface YearPickerItemProps {
  isSelected: boolean,
  onChange: (value: number) => void,
  value: number,
}

interface CalendarProps {
  endDate?: DateTime,
  monthAndYear: DateTime,
  onChange: (startDate: DateTime, endDate?: DateTime) => void,
  showDateRange?: boolean,
  startDate?: DateTime,
  testID: string,
}

interface CalendarRangeProps {
  luxonEndDate?: DateTime,
  luxonStartDate?: DateTime,
  months: DateTime[],
  onChange: (startDate: DateTime, endDate?: DateTime) => void,
  testID: string,
}

const Calendar = ({
  endDate,
  monthAndYear,
  onChange,
  showDateRange = false,
  startDate,
  testID,
}: CalendarProps) => {

  const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const handleOnChange = (selectedDate: DateTime) => {

    if (!showDateRange) {
      onChange(selectedDate);
      return;
    }

    if (!startDate) {
      onChange(selectedDate, undefined);
    } else if (!endDate) {
      if (selectedDate > startDate) {
        onChange(startDate, selectedDate);
      } else {
        onChange(selectedDate, undefined);
      }
    } else {
      onChange(selectedDate, undefined);
    }
  }

  const daysArray: (DateTime | undefined)[][] = useMemo(() => {
    let currentDay = DateTime.fromISO(monthAndYear.toFormat("yyyy-MM-01"));
    const days: (DateTime | undefined)[][] = [];

    while (currentDay.month === monthAndYear.month) {
      const row: (DateTime | undefined)[] = [];
      for (let weekday = 0; weekday < DayNames.length; weekday += 1) {
        const luxonWeekday = weekday === 0 ? 7 : weekday;
        if (currentDay.day === 1) {
          if (currentDay.weekday === luxonWeekday) {
            row.push(currentDay);
            currentDay = currentDay.plus({ days: 1 });
          } else {
            row.push(undefined);
          }
        } else if (currentDay.month === monthAndYear.month) {
          row.push(currentDay);
          currentDay = currentDay.plus({ days: 1 });
          if (currentDay.day === 1) {
            break;
          }
        }
      }
      days.push(row);
    }

    const lastRow = days[days.length - 1];
    for (let i = lastRow.length; i < days[days.length - 2].length; i += 1) {
      lastRow.push(undefined);
    }

    return days;
  }, [monthAndYear]);

  const getHighlightType = (
    row: (DateTime | undefined)[],
    startSelected: boolean,
    endSelected: boolean,
    itemDate?: DateTime,
  ) => {
    if (!showDateRange) {
      return undefined;
    }

    const datesAreEqual = !!startDate && !!endDate && startDate.equals(endDate);

    if (!itemDate && !!startDate && !!endDate && startDate.month !== endDate.month) {
      const dates = [...row.filter(x => !!x)];
      const firstOrLastCell = (
        row[0]
          ? dates.pop()
          : dates.shift()
      ) as DateTime;

      if (
        (firstOrLastCell < endDate && firstOrLastCell > startDate) ||
        (!row[0] && firstOrLastCell.equals(endDate)) ||
        (row[0] && firstOrLastCell.equals(startDate))
      ) {
        return 'middle';
      }
    }

    if (startSelected && !datesAreEqual && !!endDate) {
      return 'start';
    }
    else if (endSelected && !datesAreEqual) {
      return 'end';
    }
    else if (
      itemDate && startDate && endDate
      && itemDate > startDate && itemDate < endDate
    ) {
      return 'middle';
    }
  }

  const renderItem =
  (
    row: (DateTime | undefined)[],
    colIndex: number,
    itemDate?: DateTime,
  ) => {

    const startSelected = itemDate
      ? startDate?.equals(itemDate)
      : false;
    const endSelected = itemDate
      ? endDate?.equals(itemDate)
      : false;

    const highlightType = getHighlightType(
      row,
      startSelected || false,
      endSelected || false,
      itemDate,
    );
    return (
      <CalendarItem
        highlight={highlightType}
        isSelected={!!startSelected || !!endSelected}
        isToday={itemDate ? today.equals(itemDate) : false}
        key={`${itemDate?.toISO()}-${colIndex}`}
        onChange={handleOnChange}
        testID={testID}
        value={itemDate}
      />
    );
  }

  return (
    <Box sx={styles.mainContainer}>
      <table
        className="calendar-table-container"
        id={`calendar-month-picker-${monthAndYear.toFormat('M-yyyy')}`}
      >
        <tbody>
          {daysArray.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {
                row.map((col, colIndex) => {
                  return renderItem(
                    row,
                    colIndex,
                    col,
                  );
                })
              }
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

const CalendarItem = ({
  highlight,
  isSelected,
  isToday,
  onChange,
  testID,
  value,
}: CalendarItemProps) => {
  const rippleRef = useRef<{
    start: (event: MouseEvent | TouchEvent) => void,
    stop: (event: MouseEvent | TouchEvent) => void,
  }>(null);
  const onRippleStart = (e: MouseEvent | TouchEvent) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: MouseEvent | TouchEvent) => {
    rippleRef?.current?.stop(e);
  };

  const getClassName = () => {
    if (isSelected) {
      return "calendar-day-selected";
    } else if (isToday) {
      return "calendar-day-today";
    }
    return "";
  };

  return (
    <td
      className="calendar-day-container"
    >
      <div className={highlight ? `calendar-day-range-highlight-${highlight}` : ''}>
      </div>
      {value && (
        <div
          className={['calendar-day', getClassName()].join(' ')}
          data-testid={`${testID}-day-${value.toFormat('M-d')}`}
          onClick={() => {
            onChange(value);
          }}
          onMouseDown={onRippleStart}
          onMouseUp={onRippleStop}
          onTouchEnd={onRippleStop}
          onTouchStart={onRippleStart}
        >
          <Text category="body-large">
            {value.toFormat('d')}
          </Text>
          <TouchRipple center ref={rippleRef} />
        </div>
      )}
    </td>
  );
}

const YearPickerItem = ({
  isSelected,
  onChange,
  value,
}: YearPickerItemProps) => {
  const rippleRef = useRef<{
    start: (event: MouseEvent | TouchEvent) => void,
    stop: (event: MouseEvent | TouchEvent) => void,
  }>(null);
  const onRippleStart = (e: MouseEvent | TouchEvent) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: MouseEvent | TouchEvent) => {
    rippleRef?.current?.stop(e);
  };

  return (
    <td className='calendar-year-item-container'>
      <div
        className={[
          'calendar-year-item',
          isSelected ? 'calendar-year-item-selected' : '',
        ].join(' ')}
        onClick={() => onChange(value)}
        onMouseDown={onRippleStart}
        onMouseUp={onRippleStop}
        onTouchEnd={onRippleStop}
        onTouchStart={onRippleStart}
      >
        <Text
          category="body-large"
          id={`calendar-year-picker-${value}`}
        >
          {value}
        </Text>
      </div>
    </td>
  );
}

export const CalendarRange = ({
  luxonEndDate,
  luxonStartDate,
  months,
  onChange,
  testID,
}: CalendarRangeProps) => {
  return (
    <Box
      className="scrollable-container"
      sx={styles.dateRangeContainer}
    >
    {months.map((month) => (
      <Fragment key={month.toFormat('LLLL yyyy')}>
        <Box px={4.5} py={1.8}>
          <Text category="title-small">{month.toFormat('LLLL yyyy')}</Text>
        </Box>
        <Calendar
          endDate={luxonEndDate}
          monthAndYear={month}
          onChange={onChange}
          showDateRange
          startDate={luxonStartDate}
          testID={testID}
        />
      </Fragment>
    ))}
    </Box>
  );
};

export const DatePickerCalendar = ({
  internalEndDate,
  internalStartDate,
  onChange,
  onShowMonthPicker,
  onShowYearPicker,
  testID,
  variant,
}: DatePickerCalendarProps) => {

  const calendarContainerRef = useRef<HTMLElement>(null);
  const [monthAndYear, setMonthAndYear] = useState(
    internalStartDate ? DateTime.fromJSDate(internalStartDate) : DateTime.now(),
  );
  const [luxonStartDate, setLuxonStartDate] = useState<DateTime | undefined>();
  const [luxonEndDate, setLuxonEndDate] = useState<DateTime | undefined>();
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [showMonthPicker, setShowMonthPicker] = useState(false);

  useEffect(() => {
    if (internalStartDate) {
      document.getElementById(
        `calendar-month-picker-${internalStartDate.getMonth()+1}-${internalStartDate.getFullYear()}`,
      )?.scrollIntoView?.();
    }
  }, []);

  useEffect(() => {
    onShowMonthPicker?.(showMonthPicker);
  }, [showMonthPicker]);

  useEffect(() => {
    onShowYearPicker?.(showYearPicker);
  }, [showYearPicker]);

  useEffect(() => {
    setLuxonStartDate(
      internalStartDate
        ? DateTime.fromISO(internalStartDate.toISOString().split('T')[0])
        : undefined,
    );
  }, [internalStartDate]);

  useEffect(() => {
    setLuxonEndDate(
      internalEndDate
        ? DateTime.fromISO(internalEndDate.toISOString().split('T')[0])
        : undefined,
    );
  }, [internalEndDate]);

  const handleMonthChange = (month: string) => {
    setMonthAndYear(
      DateTime.fromISO(
        `${monthAndYear.toFormat(`yyyy-${month}-dd`)}`,
      ),
    );
    setShowMonthPicker(false);
  }

  const handleYearChange = (year: number) => {
    setMonthAndYear(
      DateTime.fromISO(`${year}-${monthAndYear.toFormat('MM-dd')}`),
    );
    setShowYearPicker(false);
  }

  const handleOnChange = (startDate: DateTime, endDate?: DateTime) => {
    onChange(startDate.toJSDate(), endDate?.toJSDate());
  }

  const scrollIntoView = (id: string) => {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView?.();
    }, 0);
  }

  const toggleMonthPicker = () => {
    setShowMonthPicker(!showMonthPicker);
    if (!showMonthPicker) {
      scrollIntoView(`calendar-month-picker-${Math.max(monthAndYear?.month - 3, 0)}`);
    }
  }

  const toggleYearPicker = () => {
    setShowYearPicker(!showYearPicker);
    if (!showYearPicker) {
      scrollIntoView(`calendar-year-picker-${Math.max(monthAndYear?.year - 3, 1900)}`);
    }
  }

  const DropdownHeader = () => (
    <Stack
      alignItems="center"
      direction="row"
      height={64}
      justifyContent="space-between"
      px={3}
    >
      <Stack alignItems="center" direction="row">
        <Text
          category="label-large"
        >
          {monthAndYear.toFormat('MMMM')}
        </Text>
        {!showYearPicker && (
          <Button
            onClick={() => toggleMonthPicker()}
            size="small"
            testID="date-picker-calendar-show-month-picker"
            variant="text"
          >
            {showMonthPicker
              ? <ArrowDropUp fontSize="small" />
              : <ArrowDropDown fontSize="small"/>
            }
          </Button>
        )}
      </Stack>
      <Stack alignItems="center" direction="row">
        <Text
          category="label-large"
        >
          {monthAndYear.toFormat('yyyy')}
        </Text>
        {!showMonthPicker ? (
          <Button
            onClick={() => toggleYearPicker()}
            size="small"
            testID={`${testID}-show-year-picker`}
            variant="text"
          >
            {showYearPicker
              ? <ArrowDropUp fontSize="small" />
              : <ArrowDropDown fontSize="small"/>
            }
          </Button>
        ) : (
          <Box width={44} />
        )}

      </Stack>
    </Stack>
  );

  const ModalHeader = () => (
    <Box px={3}>
      <Stack
        alignItems="center"
        direction="row"
        height={56}
        justifyContent="space-between"
      >
        <Stack alignItems="center" direction="row">
          <Text category="label-large">
            {monthAndYear.toFormat('MMMM yyyy')}
          </Text>
          <Button
            onClick={() => toggleYearPicker()}
            size="small"
            testID={`${testID}-show-year-picker`}
            variant="text"
          >
            {showYearPicker
              ? <ArrowDropUp fontSize="small" />
              : <ArrowDropDown fontSize="small" />
            }
          </Button>
        </Stack>
        {!showYearPicker && (
          <Stack direction="row">
            <Button
              onClick={() => setMonthAndYear(monthAndYear.plus({ months: -1 }))}
              testID={`${testID}-month-prev`}
              variant="text"
            >
              <NavigateBefore />
            </Button>
            <Button
              onClick={() => setMonthAndYear(monthAndYear.plus({ months: 1 }))}
              testID={`${testID}-month-next`}
              variant="text"
            >
              <NavigateNext />
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );

  const DropdownMonthPicker = () => {
    return (
      <>
        <Divider />
        <VSpacer size="3" />
        <Stack
          className="scrollable-container"
          sx={styles.dropdownMonthPickerContainer}
        >
          {MonthNames.map((month, monthIndex) => (
            <ListItem
              id={`calendar-month-picker-${monthIndex}`}
              key={month}
              sx={styles.listItem}
            >
              <ListItemButton onClick={() =>
                handleMonthChange(String(monthIndex + 1).padStart(2, '0'))
              } sx={styles.listItemButton}>
                <Stack alignContent="center" direction="row">
                  <Box sx={styles.monthItemIcon}>
                    {monthIndex === monthAndYear.month - 1 && (
                      <Check />
                    )}
                  </Box>
                  {month}
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}
        </Stack>
        <VSpacer size="6" />
      </>
    );
  }

  const yearsList = useMemo(() => {
    let currentYear = 1900;
    const years = [];

    while (currentYear < 2099) {
      currentYear += 1;
      years.push(currentYear);
    }

    return years;
  }, []);

  const DropdownYearPicker = () => {
    return (
      <>
        <Divider />
        <VSpacer size="3" />
        <Stack
          className="scrollable-container"
          sx={styles.dropdownYearPickerContainer}
        >
          {yearsList.map((year, index) => (
            <ListItem
              id={`calendar-year-picker-${year}`}
              key={index}
              sx={styles.listItem}
            >
              <ListItemButton onClick={() =>
                handleYearChange(year)}
                sx={styles.listItemButton}
              >
                <Stack alignContent="center" direction="row">
                  <Box sx={styles.monthItemIcon}>
                    {year === monthAndYear.year && (
                      <Check />
                    )}
                  </Box>
                  {year}
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}
        </Stack>
        <VSpacer size="6" />
      </>
    );
  };

  const yearsTable = useMemo(() => {
    let currentYear = 1900;
    const years = [];

    while (currentYear < 2099) {
      const row: number[] = [];
      for (let i=0; i < 3; i+=1) {
        row.push(currentYear);
        currentYear += 1;
      }
      years.push(row);
    }

    return years;
  }, []);

  const YearPicker = () => {
    return (
      <>
        <VSpacer size="3" />
        <Box
          className="scrollable-container"
          px={3}
          sx={styles.yearPickerContainer}
        >
          <table>
            <tbody>
              {yearsTable.map((row, index) => (
                <tr key={index}>
                  {
                    row.map(col => (
                      <YearPickerItem
                        isSelected={col === monthAndYear.year}
                        key={col}
                        onChange={handleYearChange}
                        value={col}
                      />
                    ))
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <VSpacer size="3" />
        <Divider />
      </>
    );
  };

  const CalendarHeader = () => {
    return (
      <Box sx={styles.mainContainer}>
        <table className="calendar-table-container">
          <tbody>
            <tr>
              {DayNames.map((day, dayIndex) => (
                <td
                  className="calendar-day-container"
                  key={`${day}-${dayIndex}`}
                >
                  <Text category="body-large">
                    {day}
                  </Text>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Box>
    );
  };

  const rangeMonths = useMemo(() => {
    const maxMonths = 5;
    const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const startingMonth = internalStartDate ? DateTime.fromJSDate(internalStartDate) : today;
    const endingMonth = internalEndDate ? DateTime.fromJSDate(internalEndDate) : today;

    let mounthCounter = maxMonths;
    const months = [];
    let currentMonth = startingMonth;

    while (
      !(currentMonth.month === endingMonth.month &&
      currentMonth.year === endingMonth.year)
    ) {
      months.push(currentMonth);
      currentMonth = currentMonth.plus({ months: 1 });
      mounthCounter -= 1;

      if (mounthCounter < 0) {
        break;
      }
    }

    months.push(endingMonth);

    if (months.length >= maxMonths) {
      return months;
    }

    for (let i = 1; i <= 2; i++) {
      months.splice(0, 0, startingMonth.plus({ months: -i }));
      months.push(endingMonth.plus({ months: i }));
    }
    return months;
  }, []);

  return (
    <>
      {variant === "modal" && <ModalHeader />}
      {variant === "dropdown" && <DropdownHeader />}
      {showYearPicker && variant !== "dropdown" && <YearPicker />}
      {showYearPicker && variant == "dropdown" && <DropdownYearPicker />}
      {showMonthPicker && <DropdownMonthPicker />}
      {!showYearPicker && !showMonthPicker && variant !== "modal-range" && (
        <>
          <CalendarHeader />
          <Calendar
            monthAndYear={monthAndYear}
            onChange={(startDate, endDate) => {
              const scrollTop = calendarContainerRef.current?.scrollTop || 0;
              setTimeout(() => {
                if (calendarContainerRef.current) {
                  calendarContainerRef.current.scrollTop = scrollTop;
                }
              }, 0);
              handleOnChange(startDate, endDate);
            }}
            startDate={luxonStartDate}
            testID={testID}
          />
        </>
      )}
      {!showYearPicker && !showMonthPicker && variant === "modal-range" && (
        <>
          <CalendarHeader />
          <CalendarRange
            luxonEndDate={luxonEndDate}
            luxonStartDate={luxonStartDate}
            months={rangeMonths}
            onChange={handleOnChange}
            testID={testID}
          />
        </>
      )}
    </>
  );
};
