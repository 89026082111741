import { Text, Toolbar, VSpacer } from "@/components/DesignSystem";
import { QueryKeys } from "@/constants/QueryKeys";
import { ReviewAndSelectOffersEventType, useLogEvent } from '@/utilities/Analytics';
import { PricingRequestsApi } from "@/utilities/api/PricingRequestsApi";
import { debounce } from "@/utilities/debounce";
import { ApiOffer, ApiPricingRequest } from "@api/interfaces";
import { Alert, Container, Skeleton, Stack } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import NoteModal from "../CompareAcceptOffers/NoteModal";
import PricingRequestListItem from "./PricingRequestListItem";

const PricingRequestList = () => {
  const logEvent = useLogEvent();

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [results, setResults] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<ApiOffer>();

  const { data: pricingRequests, isLoading } = useQuery(
    [QueryKeys.GET_PRICING_REQUESTS, debouncedSearch],
    () => PricingRequestsApi.getPricingRequests(
      {
        sort: 'updatedAt',
        sortDesc: true,
        search: debouncedSearch,
      }),
    {
      onSuccess: (data) => {
        setResults(data?.data?.length);
      },
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || "An error has occurred");
      },
    },
  );

  useEffect(() => {
    logEvent(ReviewAndSelectOffersEventType.ViewMyPriceRequests);
  }, []);

  useEffect(() => debounce(() => setDebouncedSearch(search), 600), [search]);

  const pricingRequestCards = pricingRequests?.data?.map((item: ApiPricingRequest) => (
    <Fragment key={item.id}>
      <PricingRequestListItem
        onShowNote={(offer) => {
          setSelectedOffer(offer);
          setShowNoteModal(true);
        }}
        pricingRequest={item}
      />
      <VSpacer mobileSize="5" size="3" />
    </Fragment>
  ));

  return (
    <>
      <Container maxWidth="md">
        <Stack>
          <VSpacer size="8" />
          <Text category="h3">Product Requests</Text>
          <VSpacer size="9" />
          <Stack>
            <Toolbar
              onChange={async ({ search }) => {
                setSearch(search ?? '');
              }}
              searchPlaceholder="Search Requests"
              testID="product-requests-toolbar"
              totalItems={results}
              totalUnit="result"
            />
          </Stack>
          <VSpacer size="5" />
          {
            !!errorMessage && (
              <Alert color="error" icon={false}>{errorMessage}</Alert>
            )
          }
          {
            isLoading && [1, 2, 3, 4, 5, 6, 7].map((index) => (
              <Fragment key={index}>
                <Skeleton height={76} variant="rectangular" />
                <VSpacer mobileSize="5" size="3" />
              </Fragment>
            ))
          }
          {pricingRequestCards}
          <VSpacer size="14" />
        </Stack>
      </Container>
      {
        selectedOffer && (
          <NoteModal
            isVisible={showNoteModal}
            offer={selectedOffer}
            onClose={() => setShowNoteModal(false)}
          />
        )
      }
    </>
  );
}

export default PricingRequestList;
