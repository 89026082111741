import { InlineMessage, Text, VSpacer } from '@/components/DesignSystem';
import { SwapHorizontalCircle } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';

interface SubstituteProductMessageProps {
  name: string,
  substituteProduct?: string,
  testID: string,
}

export const SubstituteProductMessage = ({
  name,
  substituteProduct,
  testID,
}: SubstituteProductMessageProps) => {
  return !substituteProduct ? (
    <InlineMessage
      icon={<Check color="info" fontSize="inherit" />}
      testID={`product-card-substitutes-allowed-${testID}`}
      title="SUBSTITUTES ALLOWED"
      titleCategory="label-small"
      titleColor="info"
      titleSpacing="3"
    />
  ) : (
    <>
      <Text
        category="title-large"
        testID={`substitute-product-name-${testID}`}>
        {substituteProduct}
      </Text>
      <VSpacer size="3" />
      <InlineMessage
        icon={<SwapHorizontalCircle color="info" fontSize="small" />}
        testID={`product-card-substitute-product-${testID}`}
        title={`Substitute for ${name}`}
        titleColor="primary"
        titleSpacing="3"
      />
    </>
  );
}
