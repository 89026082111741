import { useRef, useState } from 'react';
import LocationForm from './LocationForm';
import { useParams } from 'react-router-dom';
import { useAddRetailerLocation } from '@/hooks/useRetailerLocations';
import { errorMessages } from '@/constants/constant';
import { sanitizedFileName, trimValue } from '@shared/utilities';
import { SalesPerson } from './Types';
import { UserType } from '@shared/enums';
import MemberFormModal from '../Members/MemberFormModal';
import { RetailerLocationEndpoint } from '@api/endpoints';

export const validateImage = (image: HTMLImageElement, imageFile: File) => {
  switch (true) {
    case !imageFile?.type || imageFile?.type !== 'image/png':
      return errorMessages.invalidImageType;
    case imageFile.size > 1024 * 1024:
      return errorMessages.imageTooLarge;
    case image?.width !== 220 || image?.height !== 220:
      return errorMessages.invalidImageSize;
  }
};

const location: RetailerLocationEndpoint.Create.Request = {
  address1: '',
  address2: '',
  city: '',
  connectAccountId: '',
  image: '',
  name: '',
  postal: '',
  retailerId: '',
  salesperson: {
    businessName: '',
    email: '',
    telephone: '',
    userType: UserType.SalesPerson,
  },
};

const AddLocation = ({
  onClose,
  show,
}: {
  onClose: () => void;
  show: boolean;
}) => {
  const [addOrEditLocation, setAddOrEditLocation] = useState(location);
  const [showMemberForm, setShowMemberForm] = useState(false);
  const [error, setError] = useState({
    imageError: '',
    serverError: '',
  });
  const [selectedImage, setSelectedImage] = useState<Blob | MediaSource>();
  const [image, setImage] = useState({
    name: '',
    base64: '',
  });

  const { id } = useParams<{ id: string }>();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onUploadAreaClick = () => {
    if (fileUploadRef.current) {
      (fileUploadRef.current as HTMLInputElement).click();
    }
  };

  const areAnyInputsEmpty = Object.entries(addOrEditLocation).some(
    ([key, value]: [
      key: string,
      value: string | boolean | null | SalesPerson
    ]) => {
      if (
        key === 'address2' ||
        key === 'connectAccountId' ||
        key === 'image' ||
        key === 'retailerId'
      )
        return false;
      if (addOrEditLocation?.postal && addOrEditLocation?.postal?.length < 5) return true;
      if (typeof value === 'string') {
        return value?.trim() === '';
      }
    },
  );

  const onInputChange = (value: string, name: string) => {
    if (name === 'name') {
      setError({
        ...error,
        serverError: '',
      });
    }
    setAddOrEditLocation({
      ...addOrEditLocation,
      [name]: value?.trimStart(),
    });
  };

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError({
      ...error,
      imageError: '',
    });
    setImage({
      name: '',
      base64: '',
    });
    const imageFile = event.target.files?.[0];

    if (imageFile) {
      if (!imageFile?.type || imageFile?.type !== 'image/png') {
        return setError({
          ...error,
          imageError: errorMessages?.invalidImageType ?? '',
        }); 
      }

      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const selectedImageError = validateImage(image, imageFile);
          if (selectedImageError) {
            return setError({
              ...error,
              imageError: selectedImageError ?? '',
            });
          }
          setError({
            ...error,
            imageError: '',
          });
          setImage({
            name: sanitizedFileName(imageFile.name),
            base64: reader.result as string,
          });
          setSelectedImage(imageFile as Blob);
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const { addRetailerLocation, isAddLocationLoading } =
    useAddRetailerLocation(onClose, setError);

  const onSave = () => {
    if (!addOrEditLocation.connectAccountId) {
      delete addOrEditLocation.connectAccountId;
    }
    if (!addOrEditLocation.address2) {
      delete addOrEditLocation.address2;
    }

    if(!addOrEditLocation?.salesperson?.businessName) {
      delete addOrEditLocation.salesperson
    }

    const trimmedLocationData = Object.fromEntries(
      Object.entries(addOrEditLocation).map(([key, nestedValue]) => 
        [key, trimValue(nestedValue)]),
    );

    addRetailerLocation({
      ...trimmedLocationData,
      image: image.name
        ? `${sanitizedFileName(image.name)}+${image.base64}`
        : '',
      isActive: true,
      name: trimmedLocationData.name?.toString() ?? '',
      retailerId: id ?? '',
    });
  };

  const onAddMember = () => {
    setShowMemberForm(true);
  };

  const onRemove = () => {
    setSelectedImage(undefined);
    setImage({
      name: '',
      base64: '',
    });
    if (fileUploadRef.current) {
      (fileUploadRef.current as HTMLInputElement).value = '';
    }
  };

  return (
    <>
      <LocationForm
        addOrEditLocation={{
          address1: addOrEditLocation.address1,
          address2: addOrEditLocation.address2,
          city: addOrEditLocation.city,
          connectAccountId: addOrEditLocation.connectAccountId,
          image: addOrEditLocation.image,
          name: addOrEditLocation.name,
          postal: addOrEditLocation.postal,
        }}
        areAnyInputsEmpty={areAnyInputsEmpty}
        error={error}
        fileUploadRef={fileUploadRef}
        image={image}
        isAddLocationLoading={isAddLocationLoading}
        isEdit={false}
        isEditDisabled={false}
        onAddMember={onAddMember}
        onClose={onClose}
        onImageChange={onImageChange}
        onInputChange={onInputChange}
        onRemove={onRemove}
        onSave={onSave}
        onUploadAreaClick={onUploadAreaClick}
        selectedImage={selectedImage}
        show={show}
        showMemberForm={showMemberForm}
      />
      {showMemberForm && (
        <MemberFormModal
          isAddLocation={true}
          isLoading={isAddLocationLoading}
          onClose={() => setShowMemberForm(false)}
          onSaveMember={onSave}
          serverError={error?.serverError}
          setAddOrEditLocation={setAddOrEditLocation}
          setErrorMessage={setError}
          show={showMemberForm}
        />
      )}
    </>
  );
};

export default AddLocation;
