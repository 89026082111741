import { Button, Heading, HSpacer, Input, VSpacer } from "@/components/DesignSystem";
import { ZipInput } from "@/components/DesignSystem/Input";
import { StateSelect } from "@/components/shared/Select/StateSelect";
import { FriendlyFulfillmentMethod } from "@/constants/FriendlyFulfillmentMethod";
import Check from "@mui/icons-material/Check";
import { ButtonGroup, Stack } from "@mui/material";
import { FulfillmentMethod, State } from "@shared/enums";
import { validatePostalCode } from "@shared/utilities";
import { useState } from "react";

export interface FulfillmentMethodData {
  deliveryAddress1: string | null,
  deliveryAddress2: string | null,
  deliveryCity: string | null,
  deliveryPostalCode: string | null,
  deliveryState: State | null,
  fulfillmentMethod?: FulfillmentMethod,
}

interface FulfillmentMethodFormProps<T> {
  fulfillmentMethodData: T,
  handleChange: <K extends keyof T>(prop: K, value: T[K]) => void,
  hideOptionalText?: boolean,
  readonlyDeliveryAddress?: boolean,
  testID: string,
}

export const FulfillmentMethodForm = ({
  fulfillmentMethodData,
  handleChange,
  hideOptionalText = false,
  readonlyDeliveryAddress = false,
  testID,
}: FulfillmentMethodFormProps<FulfillmentMethodData>) => {
  const [zipError, setZipError] = useState(false);
  const fulfillmentMethodOptions = [
    FulfillmentMethod.PICKUP,
    FulfillmentMethod.DELIVERY,
  ];

  return (
    <>
      <Stack data-testid={`${testID}-fulfillment-method-form`}>
        <ButtonGroup className="scrollable-container">
          {fulfillmentMethodOptions.map((option, index) => (
            <Button
              aria-selected={fulfillmentMethodData.fulfillmentMethod === option}
              fullWidth
              key={option}
              onClick={() => handleChange('fulfillmentMethod', option)}
              startIcon={
                fulfillmentMethodData.fulfillmentMethod === option &&
                <Check sx={{ width: "17px", height: "17px" }}/>
              }
              testID={`fulfillment-method-button-${index}`}
              variant={
                fulfillmentMethodData.fulfillmentMethod === option ? 'contained' : 'outlined'
              }
            >
              {FriendlyFulfillmentMethod[option]}
            </Button>
          ))}
        </ButtonGroup>
      </Stack>
      {fulfillmentMethodData.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
        <>
          <VSpacer size="7" />
          <VSpacer size="2" />
          <Heading
            level="4"
            testID="fulfillment-method-delivery-address-heading"
            title="DELIVERY ADDRESS"
          />
          <VSpacer size="6" />
          <Input
            disabled={readonlyDeliveryAddress}
            label="Address line 1"
            onChangeText={(value) => handleChange('deliveryAddress1', value)}
            testID="fulfillment-method-address-1"
            value={fulfillmentMethodData.deliveryAddress1}
          />
          <VSpacer size="7" />
          <VSpacer size="2" />
          <Input
            disabled={readonlyDeliveryAddress}
            label={`Address line 2${hideOptionalText ? '' : ' (optional)'}`}
            onChangeText={(value) => handleChange('deliveryAddress2', value)}
            testID="fulfillment-method-address-2"
            value={fulfillmentMethodData.deliveryAddress2}
          />
          <VSpacer size="7" />
          <VSpacer size="2" />
          <Input
            disabled={readonlyDeliveryAddress}
            label="City"
            onChangeText={(value) => handleChange('deliveryCity', value)}
            testID="fulfillment-method-city"
            value={fulfillmentMethodData.deliveryCity}
          />
          <VSpacer size="7" />
          <VSpacer size="2" />
          <Stack direction="row" justifyContent="center">
            <Stack flex={1}>
              <StateSelect
                disabled={readonlyDeliveryAddress}
                onChangeState={(value) => handleChange('deliveryState', value)}
                testID="fulfillment-method-state-select"
                value={fulfillmentMethodData.deliveryState}
              />
            </Stack>
            <HSpacer size="7" />
            <Stack flex={1}>
              <ZipInput
                disabled={readonlyDeliveryAddress}
                error={zipError}
                label="Zip"
                onBlur={() => (
                  setZipError(!!fulfillmentMethodData?.deliveryPostalCode &&
                    !validatePostalCode(fulfillmentMethodData?.deliveryPostalCode),
                  )
                )}
                onChangeText={(value) => handleChange('deliveryPostalCode', value)}
                testID="fulfillment-method-zip"
                value={fulfillmentMethodData.deliveryPostalCode}
              />
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
