import { Button } from '@/components/DesignSystem';
import { ResponsiveSideSheet } from '@/components/shared/ResponsiveSideSheet/ResponsiveSideSheet';
import { DemoBlock } from '@/pages/UXSandbox/components/DemoBlock';
import { Box } from '@mui/material';
import React, { useState } from 'react';

export const ResponsiveSideSheetDemo = () => {
  const [showResponsiveSideSheet, setShowResponsiveSideSheet] = useState(false);
  return (
    <>
      <DemoBlock>
        <Button onClick={() => setShowResponsiveSideSheet(true)} testID="demo-button">
          Open Responsive Side Sheet
        </Button>
      </DemoBlock>
      <ResponsiveSideSheet
        actionButton={(props) => (
          <Button
            {...props}
            onClick={() => setShowResponsiveSideSheet(false)}
            testID="demo-action-button"
          >
            Action
          </Button>
        )}
        onClose={() => setShowResponsiveSideSheet(false)}
        onOpen={() => null}
        open={showResponsiveSideSheet}
        secondaryActionButton={(props) => (
          <Button
            {...props}
            onClick={() => setShowResponsiveSideSheet(false)}
            testID="demo-secondary-action-button"
          >
            Secondary Action
          </Button>
        )}
        testID="demo-side-sheet"
        title="Title"
      >
        <Box px="24px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </Box>
      </ResponsiveSideSheet>
    </>
  )
}
