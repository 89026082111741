import { Button, Dialog, Modal, Text } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import Check from '@mui/icons-material/Check';
import { Divider, Stack } from '@mui/material';
import { useState } from 'react';

export const ModalDemo = () => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [largeModal, setLargeModal] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button
          onClick={() => {
            setLargeModal(false);
            setShowModal(true)
          }}
          testID="modal-demo-show-button"
        >
          Show modal
        </Button>
        <Button
          onClick={() => {
            setLargeModal(true);
            setShowModal(true);
          }}
          testID="modal-demo-show-large-button"
        >
          Show large modal
        </Button>
      </Stack>
      {/*<MuiModal open>*/}
      {/*  <Text>Test</Text>*/}
      {/*</MuiModal>*/}
      <Modal
        acceptButton={(props) => (
          <Button
            onClick={() => setShowModal(false)}
            {...props}
          >
            Accept
          </Button>
        )}
        cancelButton={(props) => (
          <Button
            onClick={() => setShowModal(false)}
            {...props}
          >
            Close
          </Button>
        )}
        heroIcon={<Check color="success" />}
        largeModal={largeModal}
        onClose={() => setShowModal(false)}
        open={showModal}
        overline="This is the overline"
        secondaryActionButton={(props) => (
          <Button
            onClick={() => setShowModal(false)}
            {...props}
          >
            Secondary
          </Button>
        )}
        subline="This is the subline"
        testID="demo-modal"
        title="Test modal"
      >
        <DesktopOnly>
          <Stack alignItems="center" spacing={1}>
            <Text category="p2">
              Make your screen smaller to see the mobile view
            </Text>
            <Button onClick={() => setOpenDialog(true)} testID="test">Open Dialog in Modal</Button>
          </Stack>
        </DesktopOnly>
        <MobileOnly>
          <Stack alignItems="center" spacing={1}>
            <Text category="p2">
              Make your screen bigger to see the desktop view
            </Text>
            <Button onClick={() => setOpenDialog(true)} testID="test">Open Dialog in Modal</Button>
          </Stack>
        </MobileOnly>
      </Modal>
      <Dialog
        cancelButton={(props) => (
          <Button
            onClick={() => setOpenDialog(false)}
            {...props}
          >
            Close
          </Button>
        )}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        testID="dialog"
        title="Dialog"
      >
        Here is a dialog
      </Dialog>
    </>
  );
}
