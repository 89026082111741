import { StripePayment } from '@/components/DesignSystem';
import { DemoBlock } from '@/pages/UXSandbox/components/DemoBlock';
import { PaymentStatus, PaymentType } from '@shared/enums';

export const StripePaymentDemo = () => {
  const feeAmountInCents = 12.80 * 100;

  return (
    <>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.UNPAID}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.PAID}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.PROCESSING}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.REFUNDED}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.FAILED}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.REQUIRES_ACTION}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.FAILED}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
      <DemoBlock>
        <StripePayment
          feeAmountInCents={feeAmountInCents}
          feePercent={1.5}
          paymentStatus={PaymentStatus.REQUIRES_ACTION}
          paymentType={PaymentType.ACH}
          testID="demo-stripe-payment"
        />
      </DemoBlock>
    </>
  );
}
