import { Chip, HSpacer, Text } from '@/components/DesignSystem';
import { Filter, FilterSelection } from '@/components/DesignSystem/Toolbar/interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Check from '@mui/icons-material/Check';
import { Stack, useTheme } from '@mui/material';
import React from 'react';

const styles: SXStyles = {
  dropdownIcon: {
    height: '18px',
    marginRight: '-4px',
    width: '18px',
  },
} as const;

interface FilterChipProps {
  filter: Filter,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  selection?: FilterSelection,
  testID: string,
}

export const FilterChip = ({
  filter,
  onClick,
  selection,
  testID,
}: FilterChipProps) => {
  const isBoolean = filter.selectionMethod === 'boolean';
  const showCount = ['chips', 'multi-select'].includes(filter.selectionMethod);
  const selectionCount = selection?.size ?? 0;
  const labelText = selectionCount && showCount
    ? `${selectionCount} ${filter.label}`
    : filter.label;
  const isMandatorySelection = (
    filter.selectionMethod === 'single-select'
    && filter.hideClearButton
    && filter.options.some((option) => option.default)
  );
  const showSelectedState = !!selection?.size;
  const theme = useTheme();

  return (
    <Chip
      icon={showSelectedState && !isMandatorySelection ? <Check color="primary" /> : undefined}
      key={filter.id}
      label={
        <Stack direction="row">
          <Text
            category="label-large"
            color={showSelectedState ? 'primary' : undefined}
            testID={`${testID}-label`}
          >
            {labelText}
          </Text>
          {!isBoolean && (
            <>
              <HSpacer size="3"/>
              <ArrowDropDown sx={styles.dropdownIcon}/>
            </>
          )}
        </Stack>
      }
      onClick={onClick}
      size="medium"
      sx={{
        backgroundColor: showSelectedState && theme.palette.mode === 'light'
          ? theme.palette.background.paper : undefined,
      }}
      testID={testID}
      variant={showSelectedState ? 'filled' : 'outlined'}
    />
  );
}
