import { createContext, useContext } from 'react';

export const createPropsContext = <Props,>() => {
  const Context = createContext<Props | null>(null);
  const useProps = (): Props => {
    const context = useContext(Context);
    if (context == null) {
      throw new Error('Context is null');
    }

    return context;
  }

  return {
    Provider: Context.Provider,
    useProps,
  }
}
