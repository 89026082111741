import { HSpacer, IconButton, Text } from '@/components/DesignSystem';
import ChatIcon from '@mui/icons-material/Chat';
import { Card, Stack } from '@mui/material';
import ChatLogs from '../PricingRequestList/ChatLog';
import { useState } from 'react';
import { ChatCardProps } from './Interfaces';

const ChatCard = ({ farmer, retailerId, publicId }: ChatCardProps) => {

  const [openChat, setOpenChat] = useState<boolean>(false);

  return (
    <Card sx={{ padding: '1rem', marginBottom: '0.5rem', borderRadius: '8px' }}>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
      >
        <Stack direction='column' spacing={1}>
          <Text 
          category='h6'
          sx={{textTransform: 'capitalize'}}>
            {`${farmer?.firstName ?? ''} ${farmer?.lastName ?? ''}`}
          </Text>
          <Text
          category='body-medium'
          sx={{textTransform: 'capitalize'}}>
            Farm Name : {farmer?.businessName?.length ? 
            farmer?.businessName : '-'}
          </Text>
          <Text
            category='label-medium'
            style={{
              color: 'rgba(255, 255, 255, 0.8)',
              fontWeight: '100',
            }}
          >
            <>
              State : <span style={{ color: 'white' }}>{farmer?.countyData?.state ?? '-'}</span>
              <HSpacer size='4' />
              County : <span style={{ color: '#fff' }}>{farmer?.countyData?.county ?? '-'}</span>
            </>
          </Text>
        </Stack>
        <Stack>
          <IconButton
            onClick={() => setOpenChat(true)}
            testID='chat-id'
            variant='outlined'
          >
            <ChatIcon />
          </IconButton>
        </Stack>
      </Stack>
      {openChat && (
        <ChatLogs
          chatCredentials={{
            chatId: `${farmer?.id}|${retailerId}`,
            publicId: `PR-${JSON.stringify(publicId)}`,
          }}
          openChat={openChat}
          setOpenChat={setOpenChat}
          title={`${farmer?.firstName ?? ''} ${farmer?.lastName ?? ''} - Chat Details`}
        />
      )}
    </Card>
  );
};

export default ChatCard;
