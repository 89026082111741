import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Pixels } from '@/themes/variant-interfaces/Pixels';
import {
  Box,
  CircularProgress,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  StepperProps,
  styled,
} from '@mui/material'
import { ReactNode, useEffect, useRef, useState } from 'react';

interface ProgressStepperProps extends StepperProps {
  activeStep: number,
  steps: number,
  testID: string,
  type: 'secondary' | 'primary' | 'circle'
}

interface SecondaryStepperProps extends StepperProps {
  activeStep: number,
  stepsToRender: (width: number) => ReactNode[],
  testID: string,
}

const baseColor = (color: string, width: Pixels) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: width,
  },
});

const PrimaryConnector = styled(StepConnector)(({ theme }) => (
  baseColor(theme.palette.primary.main, '4px')),
);

const SecondaryConnector = styled(StepConnector)(( { theme }) => (
  baseColor(theme.palette.info.main, '2px')),
);

const calculateGapWidth = (totalWidth: number, steps: number) => {
  const totalGaps = steps - 1;
  const totalGapWidth = totalWidth * (totalGaps * 0.01);
  return totalGapWidth / totalGaps;
}

const calculateExtraUnfilledWidth = (totalWidth: number, steps: number) => {
  const gapWidth = calculateGapWidth(totalWidth, steps);
  const totalGapWidth = gapWidth * (steps - 1);
  // The extra width should be 40.1% of connector width (0.401), but doesn't display as such in the container.
  // A factor of 0.495 results in the correct rendered width.
  const percentOfConnectorWidth = 0.495
  const connectorWidth = (totalWidth - totalGapWidth) / (steps + percentOfConnectorWidth)
  return connectorWidth * percentOfConnectorWidth;
}

export const SecondaryProgressStepper = ({
  activeStep,
  stepsToRender,
  testID,
}: SecondaryStepperProps) => {
  const { windowWidth } = useMediaQuery();
  const boxRef = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState(0);
  const stepCount = stepsToRender(0).length - 1;
  const gapWidth = calculateGapWidth(boxWidth, stepCount);
  const extraUnfilledWidth = calculateExtraUnfilledWidth(boxWidth, stepCount)

  useEffect(() => {
    if (boxRef.current) {
      setBoxWidth(boxRef.current.offsetWidth);
    }
  }, [boxRef, windowWidth]);

  const ExtraUnfilledSpace = () => (
    <Box
      sx={{
        width: extraUnfilledWidth,
        height: '2px',
      }}
    />
  )

  return (
    <Stack direction="row">
      <Box ref={boxRef} sx={{ width: '100%' }}>
          <Stepper
            activeStep={activeStep}
            connector={<SecondaryConnector />}
            data-testid={testID}
          >
            {stepsToRender(gapWidth)}
          </Stepper>
      </Box>
      <ExtraUnfilledSpace />
    </Stack>
  );
}

export const ProgressIndicator = ({
  activeStep,
  steps = 4,
  testID,
  type,
}: ProgressStepperProps) => {
  steps = steps + 1;

  const percent = (100 * activeStep) / steps;

  const renderPrimarySteps = () => (
    Array.from({ length: steps }, (v, step) => (
      <Step key={step.toString()} sx={{ padding: 0 }} />
    ))
  );

  const renderSecondarySteps = (gapWidth: number) => (
    Array.from({ length: steps }, (v, step) => (
      <Step
        key={step.toString()}
        sx={{
          padding: 0,
          width: step !== 0 && steps !== step + 1 ? gapWidth : 0,
          height: 2,
          backgroundColor: 'transparent',
        }}
      />
    ))
  );


  if (type === 'secondary') {
    return (
      <SecondaryProgressStepper
        activeStep={activeStep}
        stepsToRender={(width) => renderSecondarySteps(width)}
        testID={testID}
      />
    );
  }

  if (type === 'circle') {
    return (
      <CircularProgress
        data-testid={testID}
        size={48}
        thickness={4.5}
        value={percent}
        variant="determinate"
      />
    )
  }

  return (
    <Box sx={{ maxWidth: '100%', height: '6px' }}>
      <Stepper
        activeStep={activeStep}
        connector={<PrimaryConnector sx={{ lineHorizontal: '6px' }} />}
        data-testid={testID}
      >
        {renderPrimarySteps()}
      </Stepper>
    </Box>
  );
}
