import {
  BottomBar,
  Button,
  Input,
  Logo,
  Modal,
  PhoneInput,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { InnerContainer } from '@/components/shared/InnerContainer';
import { AppConfig } from '@/constants/AppConfig';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import OtpForm from '@/pages/Auth/OtpForm';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { Alert } from '@mui/material';
import { Container, Stack } from "@mui/system";
import { validateEmail, validatePhoneNumber } from '@shared/utilities';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const PartnerRetailerProfileForm = () => {

  const navigate = useNavigate();
  const { updateUser: authUpdateUser, user } = useAuthentication();

  const [businessName, setBusinessName] = useState(user?.businessName ?? '');
  const [email, setEmail] = useState(user?.email ?? '')
  const [telephone, setTelephone] = useState(user?.telephone ?? '');
  const [errorMessage, setErrorMessage] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);

  const isFormValid = useCallback(() => {
    return !!businessName && validateEmail(email) && !isInvalidPhone;
  }, [businessName, email, isInvalidPhone]);

  const isProfileComplete = !!(
    user && user.otpVerified && user.businessName && user.email
  );

  const isStorefront = !!getSubdomain();
  const canLeaveForm = isProfileComplete || isStorefront;

  const { mutate: updateUser, isLoading } = useMutation(
    () => authUpdateUser({
      businessName,
      email,
      telephone,
    }),
    {
      onError: (error: { message: string }) => {
        setErrorMessage(
          error.message || "An error has occurred",
        );
      },
      onSuccess: (response) => {
        if (response) {
          if (response.otpVerified) {
            navigate(Routes.LANDING, { replace: true });
          } else {
            setShowOtpForm(true);
          }
        }
      },
    },
  );

  const CloseButton = () => {
    return (
      <Button
        onClick={() => navigate(Routes.HOMEPAGE)}
        size="giant"
        testID="partner-retailer-profile-form-logged-in-button"
        variant="outlined"
      >
        Close
      </Button>
    );
  }

  const SaveButton = () => {
    return (
      <Button
        disabled={!isFormValid()}
        loading={isLoading}
        onClick={() => {
          updateUser();
        }}
        size="giant"
        testID="partner-retailer-profile-form-update-user-button"
      >
        Save Changes
      </Button>
    );
  }

  return (
    <>
      <Logo size="small" />
      <VSpacer mobileSize="6" size="8" />
      <Container maxWidth="md">
        <Stack>
          <Text category="h3">
            My Account
          </Text>
          <VSpacer size="9" />
          <InnerContainer maxWidth="xs">
            <Stack>
              {!!errorMessage && (
                <>
                  <Alert color="error" icon={false}>{errorMessage}</Alert>
                  <VSpacer mobileSize="5" size="8" />
                </>
              )}
              {!isProfileComplete && (
                <>
                  <Alert color="info" icon={false}>
                    Please confirm your profile information before continuing
                  </Alert>
                  <VSpacer mobileSize="5" size="8" />
                </>
              )}
              <Input
                label="Business name"
                onChangeText={setBusinessName}
                required
                testID="partner-retailer-profile-form-business"
                value={businessName} />
              <VSpacer size="8" />
              <Input
                inputMode="email"
                label="Email"
                onChangeText={setEmail}
                required
                testID="partner-retailer-profile-form-email"
                type="email"
                value={email}
              />
              <VSpacer size="8" />
              <PhoneInput
                error={isInvalidPhone}
                label="Phone"
                onChangeText={(phone) => {
                  setIsInvalidPhone(!validatePhoneNumber(phone, AppConfig.env.test));
                  setTelephone(phone);
                }}
                required
                testID="partner-retailer-profile-form-phone"
                value={telephone}
              />
            </Stack>
          </InnerContainer>
        </Stack>
      </Container>
      {showOtpForm && user?.telephone &&
        <Modal onClose={() => setShowOtpForm(false)} open testID="retailer-profile-otp-form-modal">
          <OtpForm onCancel={() => setShowOtpForm(false)} telephone={user?.telephone} />
        </Modal>
      }
      <DesktopOnly>
        <BottomBar justifyContent={canLeaveForm ? 'space-between' : 'flex-end'}>
          {canLeaveForm && <CloseButton />}
          <SaveButton />
        </BottomBar>
      </DesktopOnly>
    </>
  );
}
