import {
  Button,
  EmptyState,
  Text,
  VSpacer,
} from "@/components/DesignSystem";
import { ResponsiveSideSheet } from '@/components/shared/ResponsiveSideSheet/ResponsiveSideSheet';
import { OfferProductsRetailer } from '@/pages/CompareAcceptOffers/interfaces';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { ApiPricingRequest } from '@api/interfaces';
import { DateTime } from "luxon";
import { Fragment } from 'react';
import { PriceRequestOrderCard } from "./PriceRequestOrderCard";

interface PriceRequestOrderModalProps {
  offerProductsRetailers?: OfferProductsRetailer[];
  onClose: () => void,
  onOfferProductDelete?: (offerProduct: OfferProduct) => void,
  onSubmit: () => void,
  open: boolean,
  priceRequest: ApiPricingRequest,
  readonly?: boolean,
  testID: string,
}

export const PriceRequestOrderModal = ({
  offerProductsRetailers,
  onClose,
  onOfferProductDelete,
  onSubmit,
  open,
  priceRequest,
  readonly = false,
  testID,
}: PriceRequestOrderModalProps) => {

  const orderSubmittedOnDate = priceRequest.submittedDate
    ? DateTime.fromISO(priceRequest.submittedDate.toString()).toLocaleString(DateTime.DATE_FULL)
    : '';

  return (
    <ResponsiveSideSheet
      actionButton={!readonly ? (props) => (
        <Button
          {...props}
          disabled={!offerProductsRetailers?.length}
          onClick={onSubmit}
          testID={`${testID}-dialog-submit`}
        >
          Submit
        </Button>
      ) : undefined}
      onClose={onClose}
      onOpen={() => null}
      open={open}
      testID={testID}
      title={readonly ? "Order Details" : "My Order"}
    >
      {!!orderSubmittedOnDate && readonly && (
        <Text
          category="body-medium"
          testID={`${testID}-order-submitted-n`}
        >
          {`Order submitted on ${orderSubmittedOnDate}`}
        </Text>
      )}
      <VSpacer size="3" />
      {!offerProductsRetailers?.length ? (
        <>
          <VSpacer size="12" />
          <VSpacer size="9" />
          <EmptyState
            headingMessage="No products in order"
            subline="Select a product or retailer to find the best offers!"
            testID={`${testID}-empty-state`}
          />
        </>
      ) : offerProductsRetailers.map((offerProductsRetailer, index) => (
        <Fragment key={offerProductsRetailer.retailer.id}>
          <VSpacer size="5" />
          <PriceRequestOrderCard
            offerProductsRetailer={offerProductsRetailer}
            onOfferProductDelete={onOfferProductDelete}
            priceRequest={priceRequest}
            readonly={readonly}
            testID={`${testID}-card-${index}`}
          />
        </Fragment>
      ))}
    </ResponsiveSideSheet>
  );
};
