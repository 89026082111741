import { ToastMessages } from "@/constants/constant";
import { QueryKeys } from "@/constants/QueryKeys";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { UserApi } from "@/utilities/api/UserApi";
import { useMutation, useQueryClient } from "react-query";

type AddRewardsByIdArgs = {
  farmerId: string,
  manufacturerId?: string | null,
  pointsToAdd: number,
  pricingRequestId?: string,
  retailerId?: string | null,
}
const useAddRewardsByFarmerId = (closeDialog: () => void) => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading: isAddRewardsLoading, mutate: addRewardsById  } = useMutation(
    (args: AddRewardsByIdArgs) =>
      UserApi.addFarmerRewardsById(args.farmerId, args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_FARMERS);
        openSnackbar(ToastMessages.addFarmerRewards);
        closeDialog();
      },
      onError: () => {
        openSnackbar('Failed to save');
      },
    },
  );
  return { addRewardsById, isAddRewardsLoading }
};

export default useAddRewardsByFarmerId;
