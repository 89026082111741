import {
  Button,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { MobileOnly } from '@/components/shared/MobileOnly';

export const ModalBottomSheetDemo = () => {
  const [open, setOpen] = useState(false);
  const title = "L-CBF 7-21-3";

  return (
    <>
      <ModalBottomSheet
        acceptButton={(props) => (
          <Button {...props} testID="add-companion-product">
            Add companion product
          </Button>
        )}
        containerStyle={{
          px: getResponsiveValue(2, 0),
        }}
        headerStyle={{
          padding: "20px 8px 0px 0px",
        }}
        hideScrollbarOnDesktop
        mobileTitle=""
        onClose={() => setOpen(false)}
        open={open}
        showTopButton
        testID="modal-bottom-sheet"
        title={title}
        width={343}
      >
        <MobileOnly>
          <Text category="body-xlarge">{title}</Text>
          <VSpacer size="3" />
        </MobileOnly>
        <Text category="body-medium">
          10 gal per 32 lbs of Monoammonium Phosphate
        </Text>
        <VSpacer mobileSize="5" size="8" />
        <Text category="body-medium">
          L-CBF 7-21-3 is a liquid carbon-based fertilizer derived from sugar cane
          molasses and quality plant nutrients. This formulation helps stimulate
          soil biology in early spring and cycle nutrients in the soil.
          It delivers the soil-health benefits of the L-CBF product line
          while providing high amounts of plant-available phosphorus to
          fit today’s liquid starter programs.
        </Text>
      </ModalBottomSheet>
      <DemoBlock>
        <Button
          onClick={() => setOpen(!open)}
          testID="bottom-sheet-demo-button"
        >
          Open Modal Bottom Sheet
        </Button>
      </DemoBlock>
    </>
  );
}
