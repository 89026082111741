import FarmerDesktopImage from '@/assets/images/farmer-desktop.png';
import { Logo, Text, VSpacer } from '@/components/DesignSystem';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Box, Link, Stack } from '@mui/material';
import { Container } from '@mui/system';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

const urls = {
  androidDownloadBadge: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
  iosDownloadBadge: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1698364800',
  growersAppIcon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/02/3c/f1/023cf176-564d-9791-4291-813adba49545/AppIcon-1x_U007emarketing-1-85-220.png/540x540bb.jpg',
  retailAppIcon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/d5/65/4a/d5654a47-2b0c-17ea-93a4-b2a9c04ce6af/AppIcon-1x_U007emarketing-0-7-0-85-220-0.png/540x540bb.jpg',
  iosGrowersAppLink: 'https://apps.apple.com/us/app/growers/id6462903105?itscg=30200&amp;itsct=apps_box_appicon',
  iosRetailAppLink: 'https://apps.apple.com/us/app/growers-retail/id6463931594?itscg=30200&amp;itsct=apps_box_appicon',
  androidGrowersAppLink: 'https://play.google.com/store/apps/details?id=com.growers_farmer',
  androidRetailAppLink: 'https://play.google.com/store/apps/details?id=com.growers_retailer',
} as const;

const styles: SXStyles = {
  appIcon: {
    borderRadius: '22%',
    display: 'inline-block',
    height: '100px',
    overflow: 'hidden',
    verticalAlign: 'middle',
    width: '100px',
  },
  iosDownloadButton: {
    borderRadius: '13px',
    height: '60px',
  },
  androidDownloadButton: {
    borderRadius: '13px',
    height: '80px',
  },
} as const;

export const UnderConstruction = () => {
  const { isMobile } = useMediaQuery();

  const iosAppDownloadButton = (href: string) => (
    <Link
      href={href}
      sx={styles.iosDownloadButton}
    >
      <Box
        alt="Download on the App Store"
        component="img"
        src={urls.iosDownloadBadge}
        sx={styles.iosDownloadButton}
      />
    </Link>
  );

  const androidAppDownloadButton = (href: string) => (
    <Link
      href={href}
      sx={styles.androidDownloadButton}
    >
      <Box
        alt="Get it on Google Play"
        component="img"
        src={urls.androidDownloadBadge}
        sx={styles.androidDownloadButton}
      />
    </Link>
  );

  return (
    <Box
      minHeight='100vh'
      sx={{ backgroundColor: 'black' }}
    >
      <Stack
        display='flex'
        flexDirection='column'
        minHeight='75vh'
        paddingTop='78px'
        sx={{
          backgroundImage: `url(${FarmerDesktopImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <>
          <Container maxWidth={false} sx={{ maxWidth: 600 + 48, textAlign: 'center' }}>
            <Logo />
            <VSpacer mobileSize="10" size="12" />
            <Text category="h2" fontWeight={600}>
              The web application is being updated and new functionality is coming soon!
            </Text>
            <VSpacer size="10" />
            <Text category="h5">
              Please download the new GROWERS app for farmers or GROWERS Retail app for retailers by
              searching “GROWERS” in the Apple App Store or Google Play Store on your mobile device,
              or click one of the buttons below.
            </Text>
            <VSpacer size="10" />
            <Stack
              alignItems={isMobile ? 'center' : 'flex-start'}
              direction={isMobile ? 'column' : 'row'}
              justifyContent="center"
            >
              <Stack alignItems="center" width="40%">
                <Box
                  alt="GROWERS"
                  component="img"
                  src={urls.growersAppIcon}
                  sx={styles.appIcon}
                />
                <VSpacer size="4" />
                <Text category="h4">GROWERS</Text>
                <Stack alignItems="center">
                  <VSpacer size="5" />
                  {iosAppDownloadButton(urls.iosGrowersAppLink)}
                  <VSpacer size="2" />
                  {androidAppDownloadButton(urls.androidGrowersAppLink)}
                  <VSpacer size="6" />
                </Stack>
              </Stack>
              <Stack alignItems="center" width="40%">
                <Box
                  alt="GROWERS Retail"
                  component="img"
                  src={urls.retailAppIcon}
                  sx={styles.appIcon}
                />
                <VSpacer size="4" />
                <Text category="h4">GROWERS Retail</Text>
                <Stack alignItems="center">
                  <VSpacer size="5" />
                  {iosAppDownloadButton(urls.iosRetailAppLink)}
                  <VSpacer size="2" />
                  {androidAppDownloadButton(urls.androidRetailAppLink)}
                  <VSpacer size="6" />
                </Stack>
                <VSpacer size="12" />
              </Stack>
            </Stack>
          </Container>
        </>
      </Stack>
    </Box>
  )
}
