import { HSpacer, Text } from '@/components/DesignSystem';
import { Category } from '@/themes/variant-interfaces/Category';
import { ColorCategory } from '@/themes/variant-interfaces/ColorCategory';
import { SpacerSize } from '@/themes/variant-interfaces/Size';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export interface InlineMessageProps {
  icon?: ReactNode,
  secondaryText?: string,
  secondaryTextColor?: ColorCategory,
  testID: string,
  textWidth?: number,
  title: string,
  titleCategory?: Category,
  titleColor?: ColorCategory,
  titleSpacing?: SpacerSize,
}

export const InlineMessage = ({
  icon,
  secondaryText,
  secondaryTextColor,
  testID,
  textWidth,
  title,
  titleCategory = "body-medium",
  titleColor,
  titleSpacing = "4",
}: InlineMessageProps) => {
  return (
    <Stack alignItems="center" data-testid={testID} direction="row">
      {!!icon && (
        <>
          {icon}
          <HSpacer size={titleSpacing} />
        </>
      )}
      <Text
        category={titleCategory}
        color={titleColor}
        width={textWidth ? textWidth : undefined}
      >
        {title}
      </Text>
      {secondaryText != null && (
        <>
          <HSpacer size="3" />
          <Text color={secondaryTextColor}>
            {secondaryText}
          </Text>
        </>
      )}
    </Stack>
  );
}
