import {
  CircularProgress,
  CircularProgressProps,
  LinearProgress,
  LinearProgressProps,
} from '@mui/material'

interface CircularProps extends CircularProgressProps {
  type: 'circular'
}

interface LinearProps extends LinearProgressProps {
  type: 'linear'
}

type ProgressProps = CircularProps | LinearProps;

const CircularProgressWrapper = (props: CircularProps) => (
  <CircularProgress {...props} />
)

const LinearProgressWrapper = (props: LinearProps) => (
  <LinearProgress {...props} />
)

export const ProgressLoader = (props: ProgressProps) => {
  const { type } = props;

  if (type === 'circular') {
    return (
      <CircularProgressWrapper {...props} />
    )
  } 
  return (
    <LinearProgressWrapper {...props} />
  )
}
