import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { Container, Stack } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from '@/constants/Routes';
import { SuccessCheckmark } from '@/components/shared/SuccessCheckmark';
import { useLogEvent, ReviewAndSelectOffersEventType } from '@/utilities/Analytics';

const AcceptOfferSuccess = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const logEvent = useLogEvent();

  return (
    <Container maxWidth="sm">
      <Stack alignItems="center" textAlign="center">
        <VSpacer mobileSize="1" size="11" />
        <SuccessCheckmark />
        {
          searchParams.get("retailers") ? (
            <Text category="h5">
              You have accepted {searchParams.get("products")} products
              from {searchParams.get("retailers")} retailers.
            </Text>
          ) : (
            <Text category="h5">
              You have selected {searchParams.get("retailerName")}'s
              offer #{searchParams.get("offerId")}.
            </Text>
          )
        }
        <VSpacer size="8" />
        <Text category="p1">
          They have been notified via email, and will soon get in touch
          with you at: {searchParams.get("email")}
        </Text>
        <VSpacer size="8" />
        <Text category="h5">
          Congrats and thank you for using The GROWERS App!
        </Text>
        <VSpacer size="10" />
        <VSpacer size="12" />
        <Stack direction="row" textAlign="center">
          <Button
            onClick={() => {
              logEvent(ReviewAndSelectOffersEventType.ClickDone);
              navigate(Routes.HOMEPAGE);
            }}
            size="giant"
            testID="accept-offer-success-logged-in-button"
            variant="outlined"
          >
            Done
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default AcceptOfferSuccess;
