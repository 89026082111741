import { Routes } from "@/constants/Routes";
import { PartnerRetailerProfileForm } from '@/pages/Auth/PartnerRetailerProfileForm';
import { VerifyEmail } from '@/pages/Auth/VerifyEmail';
import { PlaceOffer } from '@/pages/ReceivePlaceOffers/PlaceOffer';
import { PlaceOfferSuccess } from '@/pages/ReceivePlaceOffers/PlaceOfferSuccess';
import { ViewOffers } from '@/pages/ViewOffers/ViewOffers';
import { ReactNode } from 'react';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";
import { RetailerHomePage } from '@/pages/HomePage/RetailerHomePage';
import { AppHeader } from '@/components/shared/AppHeader';

export const RetailerView = () => {
  const createLayout = (element: ReactNode) => {
    return (
      <>
        <AppHeader/>
        {element}
      </>
    );
  }

  return (
    <AppRoutes>
      <Route element={createLayout(<RetailerHomePage/>)} path={Routes.HOMEPAGE} />
      <Route element={createLayout(<ViewOffers />)} path={Routes.VIEW_OFFERS} />
      <Route element={<PartnerRetailerProfileForm /> } path={Routes.MY_PROFILE} />
      <Route element={createLayout(<PlaceOffer/>)} path={Routes.PLACE_OFFER} />
      <Route element={createLayout(<PlaceOfferSuccess />)} path={Routes.PLACE_OFFER_SUCCESS} />
      <Route element={createLayout(<VerifyEmail />)} path={Routes.VERIFY_EMAIL} />
      <Route element={<Navigate to={Routes.HOMEPAGE}/>} path={"*"}/>
    </AppRoutes>
  );
}
