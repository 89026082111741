import { Banner, VSpacer } from '@/components/DesignSystem';
import { SpacerSize } from '@/themes/variant-interfaces/Size';
import { LastLookData } from '../interfaces';

interface LastLookSameOrganizationBannerProps {
  bottomSpacing: SpacerSize,
  lastLookData?: LastLookData,
  topSpacing: SpacerSize,
}

const message = 'Another salesperson within your organization was invited to place an offer on '
  + 'this product request. If they do so, we’ll show you their lowest product price in addition to '
  + 'the lowest product price overall when you place your "last look" offer.';

export const LastLookSameOrganizationBanner = ({
  bottomSpacing,
  lastLookData,
  topSpacing,
}: LastLookSameOrganizationBannerProps): (JSX.Element | null) => {
  if (!lastLookData?.sameOrganization?.multipleOffers) {
    return null;
  }

  return (
    <>
      <VSpacer size={topSpacing} />
      <Banner message={message} status={'info'} />
      <VSpacer size={bottomSpacing} />
    </>
  );
}