import { DateTime } from 'luxon';

const StartOfBusinessHour = 9; // 9am
const BusinessHoursRequired = 6;
const EndOfBusinessHour = 17; // 5pm

export const adjustForWeekends = (date: DateTime) => {
  if (isSaturday(date)) {
    return date.plus({ day: 2 }).set({ hour: 14, minute: 0 });
  } else if (isSunday(date)) {
    return date.plus ({ day: 1 }).set({ hour: 14, minute: 0 });
  }
  return date;
}

export const isSaturday = (date: DateTime) => {
  return date.weekday === 6;
}

export const isSunday = (date: DateTime) => {
  return date.weekday === 7;
}

export const isAfterBusinessHours = (date: DateTime) => {
  return date.hour >= EndOfBusinessHour;
}

export const isBeforeBusinessHours = (date: DateTime) => {
  return date.hour < StartOfBusinessHour;
}

export const wouldRequireNextDay = (date: DateTime) => {
  if (isAfterBusinessHours(date)) {
    return true;
  }
  const withBusinessHoursAdded = date.plus({ hour: BusinessHoursRequired });
  return isAfterBusinessHours(withBusinessHoursAdded);
}

export const formatExpirationDate = (date: DateTime, isDateOnly?: boolean) => {
  const formattedDate = date.setZone('local').toLocaleString({
    dateStyle: "medium",
  });
  const formattedTime = date
    .setZone('local')
    .toLocaleString(DateTime.TIME_SIMPLE)
    .toLowerCase();

  return isDateOnly
    ? formattedDate
    : `${formattedDate} at ${formattedTime}`;
}

export const formatEmailExpirationDate = (date: DateTime, isDateOnly?: boolean) => {
  const formattedDate = date.setZone('US/Central').toLocaleString({
    dateStyle: "medium",
  });
  const formattedTime = date
    .setZone('US/Central')
    .toLocaleString({
      timeZone: 'US/Central',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    })
    .replace('AM', 'am')
    .replace('PM', 'pm');

  return isDateOnly
    ? formattedDate
    : `${formattedDate} at ${formattedTime}`;
}

export const calculateExpirationDate = (
  expirationDays: number | null | undefined,
  isEmail?: boolean,
  isDateOnly?: boolean,
) => {
  if (!expirationDays) {
    return null;
  }
  const expirationDate = DateTime.now().plus({ day: expirationDays });
  return isEmail
    ? formatEmailExpirationDate(expirationDate, isDateOnly)
    : formatExpirationDate(expirationDate, isDateOnly);
}
