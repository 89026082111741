import { Button, Input, Modal, VSpacer } from "@/components/DesignSystem";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { UserApi } from "@/utilities/api/UserApi";
import { Alert } from "@mui/material";
import { UserType } from "@shared/enums";
import { validateEmail } from "@shared/utilities";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

interface CreateAdminModalProps {
  onClose: () => void,
}
const CreateAdminModal = ({ onClose }: CreateAdminModalProps) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const { openSnackbar } = useSnackbar();


  const isFormValid = useCallback(
    () => (!!firstName && !!lastName && validateEmail(email)),
    [firstName, email, lastName],
  );

  const { mutate: createUser, isLoading } = useMutation(
    () => UserApi.create({
      email,
      firstName,
      lastName,
      userType: UserType.Internal,
    }),
    {
      onError: (error: { code: string, message: string }) => {
        setIsDuplicateEmail(error.code === '409');
        setErrorMessage(error.message || 'An error has occurred');
      },
      onSuccess: () => {
        openSnackbar(
          'Account created!',
          5000000,
          true,
        );
        onClose();
      },
    },
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isFormValid()}
          loading={isLoading}
          onClick={() => { createUser() }}
        >
          Create
        </Button>
      )}
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Close
        </Button>
      )}
      onClose={onClose}
      open
      testID="create-admin-modal"
      title="Create admin"
    >
      {!!errorMessage && (
        <Alert color="error" icon={false}>{errorMessage}</Alert>
      )}
      <Input
        label="First name"
        onChangeText={setFirstName}
        required
        testID="create-admin-modal-first-name"
        value={firstName}
      />
      <VSpacer size="5" />
      <Input
        label="Last name"
        onChangeText={setLastName}
        required
        testID="create-admin-modal-last-name"
        value={lastName}
      />
      <VSpacer size="5" />
      <Input
        error={isDuplicateEmail}
        helperText={isDuplicateEmail
          ? "This email is already in use, please verify the email you typed in."
          : ""
        }
        label="Email"
        onChangeText={setEmail}
        required
        testID="create-admin-modal-email"
        value={email}
      />
    </Modal>
  )
}

export default CreateAdminModal;
