import { Input, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { InnerContainer } from '@/components/shared/InnerContainer';
import { Container, Stack } from "@mui/system";
import { PricingRequestRetailer } from './interfaces';

interface RetailerUpdateInfoFormProps {
  onChange: (pricingRequestRetailer: PricingRequestRetailer) => void,
  pricingRequestRetailer: PricingRequestRetailer,
}

const RetailerUpdateInfoForm = ({
  onChange,
  pricingRequestRetailer,
}: RetailerUpdateInfoFormProps) => {

  return (
    <>
      <VSpacer mobileSize="6" size="8" />
      <Container maxWidth="md">
        <Stack>
          <Text category="h3">
            Confirm your info
          </Text>
          <VSpacer size="5" />
          <Text category="p2">
            Check and update your information
          </Text>
          <VSpacer size="9" />
          <DesktopOnly>
            <VSpacer size="5" />
          </DesktopOnly>
          <InnerContainer maxWidth="xs">
            <Stack>
              <Input
                label="Business name"
                onChangeText={(name) => onChange({ ...pricingRequestRetailer, name })}
                required
                testID="retailer-update-info-form-business-name"
                value={pricingRequestRetailer.name} />
              <VSpacer size="14" />
            </Stack>
          </InnerContainer>
        </Stack>
      </Container>
    </>
  );
}

export default RetailerUpdateInfoForm;
