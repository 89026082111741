import { Chip, HSpacer, IconButton, Text, TextElement, VSpacer } from '@/components/DesignSystem';
import { ProductCard } from '@/components/DesignSystem/ProductCard/ProductCard';
import { OfferCompanionProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { LastLookData } from '@/pages/ReceivePlaceOffers/interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { ApiOfferProduct } from '@api/interfaces';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Divider, Stack } from '@mui/material';
import { formatCurrency } from '@shared/utilities';
import { Fragment, ReactNode } from 'react';

const styles: SXStyles = {
  border: {
    border: '1px solid',
    borderRadius: 2,
    paddingLeft: '12px',
  },
  transparentBorder: {
    border: '1px solid transparent',
    paddingLeft: '12px',
  },
  header: {
    padding: '2px 12px 2px 0px',
  },
  product: {
    padding: '2px 0px',
  },
} as const;

interface CompanionProductCardProps {
  companionProduct: OfferCompanionProduct,
  lastLookData?: LastLookData,
  offerProducts?: ApiOfferProduct[],
  rightAccessory?: (product: OfferCompanionProduct) => ReactNode,
  testID: string,
}

const CompanionProductCard = ({
  companionProduct,
  lastLookData,
  offerProducts,
  rightAccessory,
  testID,
}: CompanionProductCardProps) => {
  return (
    <>
      <Divider />
      <Stack sx={styles.product}>
        <VSpacer size="6" />
        <ProductCard
          lastLookData={lastLookData}
          offerProducts={offerProducts}
          product={companionProduct}
          rightAccessory={rightAccessory?.(companionProduct)}
          substituteProduct={companionProduct.substituteProduct}
          testID={`${testID}-companion-product-${companionProduct.id}`}
          variant="companion"
        />
        <VSpacer size="6" />
      </Stack>
    </>
  );
}

export interface ExpandButtonProps {
  onExpand: () => void,
  showPriceList: boolean,
  testID: string,
}

interface CompanionProductListProps {
  companionProducts: OfferCompanionProduct[],
  expand?: boolean,
  expandButton?: (props: ExpandButtonProps) => ReactNode,
  lastLookData?: LastLookData,
  offerProducts?: ApiOfferProduct[],
  onExpand?: () => void,
  rightAccessory?: (product: OfferCompanionProduct) => ReactNode,
  testID: string,
}

export const CompanionProductList = ({
  companionProducts,
  expand,
  expandButton,
  lastLookData,
  offerProducts,
  onExpand,
  rightAccessory,
  testID,
}: CompanionProductListProps) => {
  const productsWithPrices = companionProducts.filter(({ price }) => !!price);
  const showPriceList = productsWithPrices.length > 0;
  const subline = showPriceList
    ? `${productsWithPrices.length} product${productsWithPrices.length !== 1 ? 's' : ''} added`
    : undefined;

  return (
    <Stack
      data-testid={`${testID}-companion-products`}
      sx={expand ? styles.transparentBorder : styles.border}
    >
      {showPriceList && (
        <VSpacer size="4" />
      )}
      <Stack direction="row" spacing={1} sx={styles.header}>
        <Stack>
          <VSpacer size={showPriceList ? "1" : "4"} />
          <Chip
            color="warning"
            label={companionProducts.length}
            testID={`${testID}-companion-products-count`}
          />
        </Stack>
        <Stack flex={1}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <TextElement
              category="14"
              subline={subline}
              testID={`${testID}-companion-products-title`}
              title={`Companion Product${companionProducts.length !== 1 ? "s" : ""}`}
            />
            {!!onExpand && ((!expand && expandButton?.({
              onExpand,
              showPriceList,
              testID: `${testID}-companion-products-expand-button`,
            })) || (
              <IconButton
                onClick={onExpand}
                testID={`${testID}-companion-products-expand-button`}
              >
                {expand ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            ))}
          </Stack>

          {showPriceList && !expand && productsWithPrices.map((companionProduct, index) => (
            <Fragment key={companionProduct.id}>
              <VSpacer size="4" />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                mr={1}
                spacing={1}
              >
                <Text testID={`${testID}-companion-product-name-${index}`}>
                  {companionProduct.name}
                </Text>
                <Stack direction="row">
                  <Text
                    category="body-large"
                    testID={`${testID}-companion-product-price-${index}`}
                  >
                    {formatCurrency(companionProduct.price)}
                  </Text>
                  {!!offerProducts?.find(x => x.id === companionProduct.id)?.isAccepted && (
                    <>
                      <HSpacer size="5" />
                      <CheckCircle color="success" />
                    </>
                  )}
                </Stack>
              </Stack>
            </Fragment>
          ))}
        </Stack>
      </Stack>
      {showPriceList && (
        <VSpacer size="4" />
      )}
      {expand && (
        <>
          {companionProducts.map((companionProduct) => (
            <CompanionProductCard
              companionProduct={companionProduct}
              key={companionProduct.id}
              lastLookData={lastLookData}
              offerProducts={offerProducts}
              rightAccessory={rightAccessory}
              testID={testID}
            />
          ))}
        </>
      )}
    </Stack>
  );
}
