import { Box, Stack, Tab } from '@mui/material';
import { useState } from 'react';
import { DemoBlock } from '../../components/DemoBlock';
import { SplitTabs } from '@/components/DesignSystem';

export const SplitTabsDemo = () => {

  const [tabsValue, setTabsValue] = useState<number>(0);

  return (
    <Box p={2}>
      <DemoBlock label="">
        <Stack>
          <SplitTabs
            onChange={(_, value) => setTabsValue(value)}
            testID="demo1"
            value={tabsValue}>
            <Tab label="Products" />
            <Tab label="Retailers" />
          </SplitTabs>
        </Stack>
      </DemoBlock>
    </Box>
  );
}
