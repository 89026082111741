import { NavigationDrawer, NavigationDrawerItem } from "@/components/DesignSystem";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import Map from "@mui/icons-material/Map";
import { Box, Divider, ListSubheader } from "@mui/material";
import { DemoBlock } from "../../components/DemoBlock";

export const NavigationDrawerDemo = () => {

  const actions = (collapsed: boolean, isTransitioning: boolean) => [
    !collapsed && (
      <ListSubheader key="title">
        Title
      </ListSubheader>
    ),
    !collapsed && (
      <ListSubheader key="section-header">
        Section Header
      </ListSubheader>
    ),
    <NavigationDrawerItem
      collapsed={collapsed} 
      icon={(props) => <Delete {...props} /> }
      isTransitioning={isTransitioning}
      key="option-1"
      secondaryText="100+"
      selected={true}
      testID={"option-1"} 
      text="Option 1" />,
    <NavigationDrawerItem 
      collapsed={collapsed} 
      icon={(props) => <Edit {...props} /> }
      isTransitioning={isTransitioning}
      key="option-2"
      secondaryText="100+"
      selected={false}
      testID={"option-2"} 
      text="Option 2" />,
    <NavigationDrawerItem 
      collapsed={collapsed} 
      icon={(props) => <Add {...props} /> }
      isTransitioning={isTransitioning}
      key="option-3"
      secondaryText="100+"
      selected={false}
      testID={"option-3"}
      text="Option 3" />,
    <NavigationDrawerItem 
      collapsed={collapsed} 
      icon={(props) => <Map {...props} /> }
      isTransitioning={isTransitioning}
      key="option-4"
      secondaryText="100+"
      selected={false}
      testID={"option-4"} 
      text="Option 4" />,
    !collapsed && (<Divider key="divider" sx={{ margin: "2px 12px 2px 12px" }} />),
    !collapsed && (
      <ListSubheader key="section-header-2">
        Section Header 2
      </ListSubheader>
    ),
    <NavigationDrawerItem
      collapsed={collapsed} 
      icon={(props) => <Delete {...props} /> }
      isTransitioning={isTransitioning}
      key="option-5"
      selected={false}
      testID={"option-5"} 
      text="Option 5" />,
  ];

  return (
    <>
      <DemoBlock label="Expanded">
        <Box sx={{ width: "375px" }}>
          <NavigationDrawer
            collapsed={false}
            isTransitioning={false}
            testID="navigation-drawer" 
          >
            {actions(false, false)}
          </NavigationDrawer>
        </Box>
      </DemoBlock>
      <DemoBlock label="Collapsed">
        <Box sx={{ width: "80px" }}>
          <NavigationDrawer 
            collapsed
            isTransitioning={false}
            testID="navigation-drawer" 
          >
            {actions(true, false)}
          </NavigationDrawer>
        </Box>
      </DemoBlock>
    </>
  );
}
