import { useState } from 'react';
import { Card, Box, Typography, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, HSpacer } from '@/components/DesignSystem';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ConfirmModal from '@/components/shared/ConfirmModal';
import { SaveSponseredBannerModal } from './SaveSponseredBanner';
import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';

interface ICardDetails {
  id: string;
  rank: number;
  name: string;
  productId?: string;
  manufacturerId?: string;
  retailerId?: string;
  bannerImage?: string;
}

const CardListItem = ({
  currentCardName,
  position,
  name,
  deleteCard,
  cardId,
  isClickable = true,
  cardDetails,
  provided,
  snapshot,
}: {
  currentCardName: string;
  position: string;
  name: string;
  deleteCard: (id: string) => void;
  cardId: string;
  isClickable?: boolean;
  cardDetails?: ICardDetails;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}) => {
  const [isDelete, setIsDelete] = useState(false);
  const [isViewSponsoredBanner, setIsViewSponsoredBanner] = useState(false);
  const getCardName = () => {
    if (currentCardName?.toLowerCase().includes('sponsored')) {
      return currentCardName;
    }
    return `featured ${currentCardName?.toLowerCase()}`;
  };

  return (
    <Card
      key={cardId}
      ref={provided.innerRef}
      sx={{
        backgroundColor: snapshot.isDragging ? '#101010ff' : '#262625ff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 15px',
        margin: '10px 0px',
      }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Box alignItems='center' display='flex'>
        <DragIndicatorIcon sx={{ padding: '0px 5px' }} />
        <HSpacer size='5' />
        <Box
          sx={{
            padding: '20px 42px',
            backgroundColor: '#2d2b28ff',
            borderRadius: '5px',
          }}
        >
          {position}
        </Box>
        <HSpacer size='7' />
        <Typography
          component="div"
          sx={{ flex: 1, wordBreak: 'break-word' }}
          variant="h6"
        >
          {name}
        </Typography>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {isClickable && (
          <Button
          onClick={() => setIsViewSponsoredBanner(true)}
          testID='view'
          variant='outlined'
        >
          View
        </Button>
        )}
        <HSpacer size='2' />
        <IconButton
          aria-label='delete'
          onClick={(e) => {
            e.stopPropagation();
            setIsDelete(true);
          }}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </Box>
      {isDelete && (
        <ConfirmModal
          cancelButtonText='Cancel'
          confirmButtonText='Confirm'
          message={`Are you sure you want to delete the ${getCardName()} from the list`}
          onCancel={() => setIsDelete(false)}
          onConfirm={() => deleteCard(cardId)}
          open={isDelete}
          testID='delete-modal'
          title={`Delete ${getCardName()}?`}
        />
      )}
      {isViewSponsoredBanner && (
        <SaveSponseredBannerModal
          cardDetails={cardDetails}
          close={() => setIsViewSponsoredBanner(false)}
        />
      )}
    </Card>
  );
};

export default CardListItem;
