import { RetailerLocationEndpoint, UserEndpoint } from "@api/endpoints"
import { SharedConfig } from '@shared/SharedConfig';
import { Client } from "./Client";
import { QueryUtil } from '@/utilities/Query';

export class RetailerLocationApi {
  static getAllowedCounties (
    id: string,
  ): Promise<RetailerLocationEndpoint.GetAllowedCounties.Response> {
    return Client(`location/${id}/allowed-counties`);
  }

  static getFarmersForLocation (
    locationId: string,
    search?: string,
    onlyPreferred?: boolean,
  ): Promise<UserEndpoint.List.Response> {
    const query = QueryUtil.stringify({
      locationId,
      limit: SharedConfig.maxPageLimit,
      onlyPreferred,
      search,
    });
    return Client(`location/${locationId}/farmers?${query}`);
  }

  static list (
    query: RetailerLocationEndpoint.List.Query,
  ): Promise<RetailerLocationEndpoint.List.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`location?${queryString}`);
  }

  static updatePreferredFarmers (
    id: string,
    userIds: string[],
  ): Promise<void> {
    return Client(`location/${id}/preferred-farmers`, {
      body: { userIds },
      method: 'POST',
    });
  }
}
