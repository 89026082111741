import { DataSet, VSpacer, Text, IconButton, ChipProps } from '@/components/DesignSystem';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { DataPointGroupProps } from '@/components/DesignSystem/DataSet/DataSet';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { StringUtility } from '@/utilities/String';

export const DataSetDemo = () => {
  // eslint-disable-next-line max-len
  const text = 'Gumbo beet greens corn soko endive gumbo gourd. Parsley shallot courgette tatsoi pea sprouts fava bean collard greens dandelion okra wakame tomato. Dandelion cucumber earthnut pea peanut soko zucchini.';
  const badges: ChipProps[] = [
    {
      color: 'success',
      label: 'Badge',
      testID:"data-set-demo-success",
    },
    {
      color: 'warning',
      label: 'Badge',
      testID:"data-set-demo-warning",
    },
  ];
  const metadata: DataPointGroupProps[] = [
    {
      children: 'Some text here',
      label: 'Label:',
    },
    {
      children: 'Some more text here',
      label: 'Label:',
    },
    {
      children: 'Some text that will overflow to next line',
      label: 'Label:',
    },
    {
      children: 'Some more text here',
      label: 'Label:',
    },
    {
      children: 'Some text here',
      label: 'Label:',
    },
    {
      children: 'Some text here',
      label: 'Label:',
    },
    {
      children: 'Some text here',
      label: 'Label:',
    },
    {
      children: 'Some text here',
      label: 'Label:',
    },
  ];

  const actions: ReactNode[] = [
    (
      <IconButton
        key="action-1"
        onClick={() => { null }}
        size="small"
        testID="test-dataset-delete-button"
      >
        <Delete />
      </IconButton>
    ),
    (
      <IconButton
        key="action-2"
        onClick={() => { null }}
        size="small"
        testID="test-dataset-edit-button"
      >
        <Edit />
      </IconButton>
    ),
  ];

  return (
    <Stack>
      <Text category="body-large">All Props, with drawer, default opened</Text>
      <VSpacer size="5" />
      <DataSet
        actions={actions}
        badges={badges}
        hasDrawer
        isOpen
        metadata={metadata}
        overline="overline"
        rightContent={StringUtility.formatCurrencyAccounting(1)}
        subtitle="subtitle"
        testID="test-dataset"
        title="Title"
      >
        <Text category="body-medium" testID="test-data">{text}</Text>
      </DataSet>
      <VSpacer size="5" />
      <Text category="body-large">All Props, with drawer, default closed</Text>
      <VSpacer size="5" />
      <DataSet
        actions={actions}
        badges={badges}
        hasDrawer
        metadata={metadata}
        overline="overline"
        subtitle="subtitle"
        testID="test-dataset"
        title="Title"
      >
        <Text category="body-medium">{text}</Text>
      </DataSet>
      <VSpacer size="5" />
      <Text category="body-large">All props, no drawer</Text>
      <VSpacer size="5" />
      <DataSet
        actions={actions}
        badges={badges}
        overline="overline"
        subtitle="subtitle"
        testID="test-dataset"
        title="title"
      />
      <VSpacer size="5" />
      <Text category="body-large">Title only</Text>
      <VSpacer size="5" />
      <DataSet
        rightContent={StringUtility.formatCurrencyAccounting(1)}
        testID="test-dataset"
        title="Title"
      />
      <VSpacer size="5" />
      <Text category="body-large">Title and subtitle</Text>
      <VSpacer size="5" />
      <DataSet
        subtitle="subtitle"
        testID="test-dataset"
        title="Title"
      />
      <VSpacer size="5" />
      <Text category="body-large">Title and overline</Text>
      <VSpacer size="5" />
      <DataSet
        overline="overline"
        testID="test-dataset"
        title="Title"
      />
    </Stack>
  )
}
