import { Chip, HSpacer, IconButton, Select, Switch, Text } from '@/components/DesignSystem';
import { ConfirmationDialog } from '@/constants/constant';
import { useDeleteMember, useUpdateMember } from '@/hooks/useHierarchyOfRetailers';
import { ApiMember } from '@api/interfaces';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Divider, MenuItem, Stack } from '@mui/material';
import { formatPhoneNumber } from '@shared/utilities';
import { useState } from 'react';
import ConfirmDialog from '../ConfirmDialog';
import AddMember from './MemberFormModal';

interface MemberProfileCardProps {
  isLocation?: boolean;
  length?: number;
  member: ApiMember;
}
const MemberProfileCard = ({ isLocation, member }: MemberProfileCardProps) => {

  const [showEditMember, setShowEditMember] = useState(false);
  const [showDeleteMember, setShowDeleteMember] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [error, setError] = useState('');

  const onClose = () => {
    setShowConfirmModal(false);
    setShowDeleteMember(false);
  };

  const { updateMember: updateSalesPerson, isLoading: isUpdateLoading } =
    useUpdateMember(member?.id ?? '', isLocation, onClose);

  const { deleteMember, isLoading } = useDeleteMember(member?.id ?? '', onClose, setError);

  const onSwitch = () => {
    !member?.defaultSalesperson ? setShowConfirmModal(true) : () => null;
  };

  const isDeleteDisabled = () => {
    return member?.defaultSalesperson;
  }

  return (
    <>
      <Divider />
      <Stack
        sx={{
          padding: '20px',
        }}
      >
        <Stack>
          <Stack direction='row' justifyContent={'space-between'}>
          <Stack direction='column'>
          <Stack direction="row">
            <Text category='h6' sx={{textTransform: 'capitalize'}}>
              {member?.businessName ?? ''}
            </Text>
            {member?.isTest &&
              <>
                <HSpacer size="5" />
                <Chip color="primary" label="Test User" testID="test-user-chip" />
              </>
            }
          </Stack>
          <Text mb='10px' mt='5px'>
            +1 {formatPhoneNumber(member.telephone ?? '', 'paren')} •{' '}
            {member?.email ?? ''}
          </Text>
          </Stack>
          {!member?.isActive && (
            <Chip
            label='Inactive'
            testID='active-inactive-member' />
          )}
          </Stack>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent={'space-between'}
          >
            <Select
              disabled
              label='Role'
              testID='role'
              value={isLocation ? 'sales-person' : 'role-admin'}
            >
              <MenuItem value='role-admin'>Retailer Admin</MenuItem>
              <MenuItem value='sales-person'>Salesperson</MenuItem>
            </Select>
            <Stack alignItems='center' direction='row'>
              {isLocation && (
                <Switch
                  checked={member?.defaultSalesperson ?? false}
                  label='Default'
                  labelPlacement='start'
                  onClick={onSwitch}
                  sx={{ marginTop: '5px', marginRight: '5px' }}
                  testID='active-toggle'
                />
              )}
              <IconButton
                onClick={() => setShowEditMember(true)}
                testID='edit-member'
              >
                <EditOutlined />
              </IconButton>
              <IconButton
                disabled={isDeleteDisabled()}
                onClick={() => setShowDeleteMember(true)}
                testID='delete-member'
              >
                <DeleteOutlined />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {showEditMember && (
        <AddMember
          isEdit={true}
          isLocation={isLocation}
          member={{
            ...member,
            businessName: member?.businessName ?? '',
            email: member?.email ?? '',
            telephone: member?.telephone ?? '',
            role: isLocation ? 'sales-person' : 'retailer-admin',
          }}
          onClose={() => setShowEditMember(false)}
          show={showEditMember}
        />
      )}
      {showDeleteMember && (
        <ConfirmDialog
          isLoading={isLoading}
          isOpen={showDeleteMember}
          message={ConfirmationDialog.deleteMemberMessage}
          onClose={() => setShowDeleteMember(false)}
          onConfirm={() => deleteMember()}
          title={ConfirmationDialog.deleteMemberTitle}
        />
      )}
      {showConfirmModal && (
        <ConfirmDialog
          isLoading={isUpdateLoading}
          isOpen={showConfirmModal}
          message={ConfirmationDialog.changeDefaultSalesPersonMessage}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => {
            updateSalesPerson({
              defaultSalesperson: true,
            });
          }}
          title={ConfirmationDialog.changeDefaultSalesPersonTitle}
        />
      )}
      {
        !!error && (
          <ConfirmDialog
          cancelButtonText='Close'
          isOpen={!!error}
          message={error}
          onClose={() => {
            onClose();
            setError('');
          }}
        />
        )
      }
    </>
  );
};

export default MemberProfileCard;
