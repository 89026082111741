// More info about statuses:
// https://growersnc.atlassian.net/wiki/spaces/PM/pages/71598081/Pricing+Request+and+Offer+statuses

export enum OfferStatus {
  Accepted = 'Accepted',
  Open = 'Open',
  Partial = 'Partial',
  Rejected = 'Rejected',
  Draft = 'Draft',
  Closed = 'Closed',
  AwaitingFulfillment= 'Awaiting Fulfillment',
  OrderShipped= 'Order Shipped',
  PickupReady = 'Pickup Ready',
  OnAccountRequested= 'On Account Requested',
  FinancingRequested= 'Financing Requested',
  AwaitingDelivery= 'Awaiting Delivery',
  AwaitingPickup= 'Awaiting Pickup'
}

export enum OfferStatusNew {
  AwaitingDelivery = 'Awaiting Delivery',
  AwaitingFulfillment = 'Awaiting Fulfillment',
  AwaitingOffer = 'Awaiting Offer',
  FinancingRequested= 'Financing Requested',
  OfferClosed = 'Offer Closed',
  OfferExpired = 'Offer Expired',
  OfferSent = 'Offer Sent',
  OnAccountRequested= 'On Account Requested',
  OrderShipped = 'Order Shipped',
  Partial = 'Partial',
  PaymentDue = 'Payment Due',
  PickupReady = 'Awaiting Pickup',
}

export enum RetailerRequestType {
  Active = 'active',
  Order = 'my-orders',
  PastOrder = 'past-order',
  Pending = 'pending',
}

export const AcceptedStatuses: OfferStatus[] = [
  OfferStatus.Accepted,
  OfferStatus.AwaitingFulfillment,
  OfferStatus.AwaitingDelivery,
  OfferStatus.FinancingRequested,
  OfferStatus.OrderShipped,
  OfferStatus.OnAccountRequested,
  OfferStatus.Partial,
  OfferStatus.PickupReady,  
];