import { Button, Logo, Text, VSpacer } from '@/components/DesignSystem';
import { FullPageLayout } from '@/components/layouts/FullPageLayout';
import { ContactFooter } from '@/components/shared/ContactFooter';
import { Routes } from "@/constants/Routes";
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useStorefront } from '@/hooks/useStorefront';
import { LoginHeader } from '@/pages/Auth/LoginHeader';
import { getPortalRedirectUrl } from '@/utilities/StorefrontUtilities';
import { ApiStorefront } from '@api/interfaces';
import { CircularProgress, Divider, Stack, useTheme } from "@mui/material";
import { Suspense } from 'react';
import { useNavigate } from "react-router-dom";

const StorefrontSignInPage = () => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const { storefront } = useStorefront(true);
  const theme = useTheme();
  const Form = ({ storefront }: { storefront: ApiStorefront }) => (
    <>
      <Stack direction='row' justifyContent='center'>
        <Logo size="giant" subdomain={storefront.subdomain}/>
      </Stack>
      <VSpacer size="9" />
      <Text category="h3" textAlign="center">
        Sign in to the {storefront.name} Portal
      </Text>
      <VSpacer size="3" />
      <Text textAlign="center">
        Once signed in, select 'Purchase Inputs' to submit your Product Request
      </Text>
      <VSpacer size="5" />
      <Stack alignSelf="center" width="fit-content">
        <Button
          onClick={
            () => window.location.href = getPortalRedirectUrl(storefront.subdomain)
          }
          testID="portal-sign-in-button"
        >
          Sign in
        </Button>
      </Stack>
      <VSpacer size="7" />
    </>
  )

  return (
    <FullPageLayout footer={<ContactFooter />} header={<LoginHeader />}>
      <Stack
        direction="row"
        justifyContent="center"
        paddingTop={isMobile ? '0px' : '100px'}
      >
        <Stack
          display="flex"
          flex={1}
          maxWidth="512px"
        >
          <Stack
            alignItems="center"
            borderRadius="12px"
            justifyContent="center"
            padding={isMobile ? '16px' : '48px'}
            sx={{ backgroundColor: isMobile ? undefined : theme.palette.background.paper }}
          >
            <Suspense fallback={<CircularProgress />}>
              <Form storefront={storefront!} />
            </Suspense>
            <Divider sx={{ height: '1px', width: '100%' }}/>
            <VSpacer size="7" />
            <Text category="title-medium">Don't have an account?</Text>
            <VSpacer size="3" />
            <Text>Click 'Continue' to sign in or sign up.</Text>
            <VSpacer size="5" />
            <Button
              onClick={() => navigate(Routes.LOGIN)}
              testID="storefront-sigin-continue-button"
              variant="outlined"
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </FullPageLayout>
  );
}

export default StorefrontSignInPage;
