export enum Routes {
  ACCEPT_OFFER_SUCCESS = '/acceptOfferSuccess',
  ACCESS_DENIED = '/access-denied',
  ADMIN_HOME_PAGE = '/admin/homepage',
  ADMIN_HIERARCHY_OF_RETAILERS = '/admin/hierarchy-of-retailers',
  ADMIN_LIST_ADMINS = '/admin/admins',
  ADMIN_LIST_FARMERS = '/admin/farmers',
  ADMIN_LIST_MANUFACTURERS = '/admin/manufacturers',
  ADMIN_LIST_PARTNERS = '/admin/partner-retailers',
  ADMIN_LIST_PROMOTIONS = '/admin/promotions',
  ADMIN_LIST_REQUESTS = '/admin/pricing-requests',
  ADMIN_LOGIN = '/admin-login',
  ADMIN_PRODUCT_EVENTS = '/admin/product-events',
  ADMIN_PRODUCT_RECOMMENDATION = '/admin/product-recommendation',
  ADMIN_PRODUCT_RECOMMENDATION_VIEW = '/admin/product-recommendation/:id',
  CHECK_EMAIL = '/auth/check-email/:id',
  CHECK_SIGN_UP_EMAIL = '/check-email/:id',
  CREATE_ACCOUNT = '/auth/createAccount',
  CREATE_PASSWORD = '/createPassword',
  CREATE_PRICING_REQUEST = '/pricing-request/create',
  CREATE_PRICING_REQUEST_SUCCESS = '/createPricingRequestSuccess',
  ERROR = '/error',
  FEATURE_FLAGS = '/admin/feature-flags',
  FORGOT_PASSWORD = '/auth/forgotPassword',
  GET_APP = '/get-app',
  HOMEPAGE = '/',
  INDIVIDUAL_PRICING_REQUEST = '/admin/individual-Pricing-Request',
  LANDING = '/auth/login',
  LOGIN = '/login',
  MY_PROFILE = '/auth/profile',
  OAUTH_VERIFY = '/oauth/verify',
  PAST_PRICING_REQUESTS = '/pricing-request/list',
  PAYMENT = '/offer/:offerId/payment',
  PAYMENT_SUBMITTED = '/offer/:offerId/payment-success',
  PLACE_OFFER = '/pricing-request/:id/offer',
  PLACE_OFFER_SUCCESS = '/placeOfferSuccess',
  PORTAL_LOGIN = '/portal-login',
  PRODUCT = '/product',
  PRODUCT_PAGE = '/product/:id',
  REDIRECT = '/redirect/:id',
  RESET_PASSWORD = '/resetPassword',
  RETAILER_HOME = '/retailerHome',
  REVIEW_SELECT_OFFERS = '/pricing-request/:id/offers',
  SAVED_RETAILERS = '/saved-retailers',
  SET_PASSWORD = '/auth/setPassword',
  SET_RETAILER_PASSWORD = '/setRetailerPassword',
  SHOP = '/shop',
  STOREFRONT_LOGIN = '/sign-in',
  UI_SHELL_DEMO = '/ui-shelldemo',
  UNDER_CONSTRUCTION = '/under-construction',
  UPDATE_PRICING_REQUEST = '/pricing-request/:id',
  UX_SANDBOX = '/ux-sandbox',
  VERIFY_EMAIL = '/verify-email',
  VERSION = '/version',
  VIEW_DETAIL = '/admin/view-detail',
  VIEW_OFFERS = '/viewOffers',
}

export const PublicRoutes = [
  Routes.PAYMENT,
  Routes.PAYMENT_SUBMITTED,
  Routes.PLACE_OFFER,
  Routes.PLACE_OFFER_SUCCESS,
  Routes.REDIRECT,
  Routes.RETAILER_HOME,
  Routes.VERIFY_EMAIL,
  Routes.VERSION,
];
