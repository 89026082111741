import { Text, VSpacer } from '@/components/DesignSystem';
import { ApiProductRecommendation } from '@api/interfaces';
import { Card } from '@mui/material';
import moment from 'moment/moment';

const ProductRecommendationInfo = ({
  info,
}: {
  info: ApiProductRecommendation | undefined;
}) => {
  return (
    <>
      <Card sx={{ padding: '20px' }}>
        <Text
          category='overline'
          testID='card-text'
        >
          Product Recommendation
        </Text>
        <VSpacer size='5' />
        <Text fontSize='28px' testID='card-text'>
          {`#${info?.publicId}`}
        </Text>
      </Card>
      <VSpacer size='5' />
      <Card sx={{ padding: '20px' }}>
        <Text testID='salesperson-name-label-text'>
          Salesperson Name
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="salesperson-name-text">
          {info?.salespersonDetails?.businessName ?? '-'}
        </Text>
        <VSpacer size='8' />
        <Text testID='products-count-label-text'>
          Products
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="products-count-text">
          {info?.productRecommendationData?.length ?? 0}
        </Text>
        <VSpacer size='8' />
        <Text testID='farmers-count-label-text'>
          Farmers
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="products-count-text">
          {info?.recommendationFarmer?.length ?? 0}
        </Text>
        <VSpacer size='8' />
        <Text testID='created-on-label-text'>
          Created on
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="created-on-text">
          {moment(info?.createdAt).format('MMM DD, YYYY')}
        </Text>
        <VSpacer size='8' />
        <Text testID='expires-on-label-text'>
          Expires on
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="expires-on-text">
          {moment(info?.expiration).format('MMM DD, YYYY')}
        </Text>
        <VSpacer size='8' />
        <Text testID='description-label-text'>
          Description
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="description-text">
          {info?.note}
        </Text>
      </Card>
    </>
  );
};

export default ProductRecommendationInfo;
