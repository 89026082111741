import { PriceRequestOrderCard } from "@/components/shared/PriceRequestOrder/PriceRequestOrderCard";
import { OfferProductsRetailer } from '@/pages/CompareAcceptOffers/interfaces';
import { ApiPricingRequest } from '@api/interfaces';
import { DemoBlock } from "../../components/DemoBlock";
import {
  DryFormulationUnitOfMeasure,
  FulfillmentMethod,
  PricingRequestStatus,
  State,
  TalonStatus,
} from "@shared/enums";
import {
  PriceRequestOrderModal,
} from "@/components/shared/PriceRequestOrder/PriceRequestOrderModal";
import { useState } from "react";
import { Button } from "@/components/DesignSystem";
export const PriceRequestOrderDemo = () => {
  const [openMyOrder, setOpenMyOrder] = useState(false);
  const [openMyOrderEmpty, setOpenMyOrderEmpty] = useState(false);

  const priceRequest: ApiPricingRequest = {
    acceptedDate: null,
    acceptedRetailerIds: [],
    approvedDate: null,
    createdAt: new Date(),
    createdById: '',
    deletedAt: null,
    deliveryAddress1: "Test Address 1",
    deliveryAddress2: "Test Address 2",
    deliveryCity: "Test City",
    deliveryPostalCode: "12345",
    deliveryState: State.NorthCarolina,
    expiration: null,
    paymentStatus: null,
    retailerPaymentStatus: '',
    isPaymentSubmitted: false,
    fulfillmentMethod: FulfillmentMethod.DELIVERY,
    id: '',
    isExpired: false,
    isRead: true,
    isReviewExpired: false,
    note: null,
    paymentType: null,
    publicId: 0,
    redeemedPoints: 0,
    referralAffiliate: null,
    referralNote: null,
    referralSender: null,
    rejectedDate: null,
    requestedExpirationDays: null,
    reviewExpiration: null,
    status: PricingRequestStatus.Open,
    storefrontId: null,
    submittedDate: null,
    systemDiscount: 0,
    talonStatus: TalonStatus.DEFAULT,
    updatedAt: new Date(),
    userId: '1234',
  };

  const offerProductsRetailer: OfferProductsRetailer = {
    offerProducts: [
      {
        allowSubstitutions: true,
        companionToProductId: null,
        initialQuantity: 5,
        initialUom: DryFormulationUnitOfMeasure.OUNCE,
        name: 'Product 1',
        price: 20,
        pricingRequestProductId: '1',
        quantity: 5,
        uom: DryFormulationUnitOfMeasure.OUNCE,
      },
      {
        allowSubstitutions: true,
        companionToProductId: null,
        initialQuantity: 5,
        initialUom: DryFormulationUnitOfMeasure.OUNCE,
        name: 'Product 2',
        price: 20,
        pricingRequestProductId: '1',
        quantity: 5,
        uom: DryFormulationUnitOfMeasure.OUNCE,
      },
    ],
    retailer: {
      createdAt: new Date(),
      id: '',
      lastLook: false,
      name: 'Salesperson name',
      salespersonId: 'salesperson-id',
      preferred: true,
      pricingRequestId: '',
      updatedAt: new Date(),
    },
  };

  return (
    <>
      <DemoBlock label="Product request order card">
        <PriceRequestOrderCard
          offerProductsRetailer={offerProductsRetailer}
          priceRequest={priceRequest}
          testID="order-card"
        />
      </DemoBlock>
      <DemoBlock label="Product request My Order modal">
        <Button
          onClick={() => setOpenMyOrder(true)}
          testID="open-my-order-button"
        >
          Open My Order
        </Button>
        <PriceRequestOrderModal
          offerProductsRetailers={[offerProductsRetailer]}
          onClose={() => setOpenMyOrder(false)}
          onSubmit={() => null}
          open={openMyOrder}
          priceRequest={priceRequest}
          testID="order-modal"
        />
      </DemoBlock>
      <DemoBlock label="Product request My Order modal (Empty)">
        <Button
          onClick={() => setOpenMyOrderEmpty(true)}
          testID="open-my-order-empty-button"
        >
          Open My Order (Empty)
        </Button>
        <PriceRequestOrderModal
          offerProductsRetailers={[]}
          onClose={() => setOpenMyOrderEmpty(false)}
          onSubmit={() => null}
          open={openMyOrderEmpty}
          priceRequest={priceRequest}
          testID="order-modal"
        />
      </DemoBlock>
    </>
  );
};
