import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { ApiPricingRequestProduct, RecommendationProduct } from '@api/interfaces';
import { alpha, Chip, Divider, Stack } from '@mui/material';
import { PromotionType } from '@shared/enums/PromotionType';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { PromotionsSideSheet } from '@/pages/Admin/PromotionsSideSheet';
import { formatCurrency, formatDateOnly } from '@shared/utilities';

const ProductListTab = ({
  products,
  promotionSnapshotDate,
  showPrices = false,
}: {
  products: ApiPricingRequestProduct[] | RecommendationProduct[],
  promotionSnapshotDate?: Date,
  showPrices?: boolean,
}) => {
  const [showFarmerPromotions, setShowFarmerPromotions] = useState(false);
  const [showRetailerPromotions, setShowRetailerPromotions] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>();

  const productIds = products.map(
    (product) => product.productId,
  ).filter(Boolean) as string[] ?? [];

  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds, promotionSnapshotDate],
    async () => {
      const promotions = await PromotionApi.list({
        isActive: true,
        productIds: productIds,
        snapshotDate: promotionSnapshotDate,
      });
      const currentDate = formatDateOnly(promotionSnapshotDate
        ? new Date(promotionSnapshotDate)
        : new Date(),
      );
      return promotions.data.filter((promotion) => (
        promotion.startDate <= currentDate
      ));
    },
    {
      enabled: !!productIds.length,
    },
  );

  const getFarmerPromotions = (productId?: string | null) => {
    if (!productId) {
      return [];
    }
    return promotions?.filter(
      (promotion) => promotion.type === PromotionType.Farmer
        && promotion.productIds.includes(productId),
    ) ?? [];
  }

  const getRetailerPromotions = (productId?: string | null) => {
    if (!productId) {
      return [];
    }
    return promotions?.filter(
      (promotion) => promotion.type === PromotionType.Retailer
        && promotion.productIds.includes(productId!),
    ) ?? [];
  }
  return (
    <>
      {products.map(
        (item) => {
          const farmerPromotions = getFarmerPromotions(item.productId);
          const retailerPromotions = getRetailerPromotions(item.productId);
          return (
            <React.Fragment key={item.id}>
              <Stack sx={{ padding: '20px 0' }}>
                <Stack direction="row" justifyContent="space-between" pr="12px">
                  <Text fontSize='20px' testID={`${item.id} name`}>
                    {item.name}
                  </Text>
                  {showPrices && !!item.quantity && ('price' in item) && !!item.price && (
                    <Text fontSize='20px' testID={`${item.id} price`}>
                      Total: {formatCurrency(item.quantity * item.price)}
                    </Text>
                  )}
                </Stack>
                <VSpacer size='5' />
                <Text category='overline' testID={`${item.id} quantity`}>
                  {item.quantity} {item.uom}{item.package ? ` • ${item.package}` : ''}
                </Text>
                {(!!farmerPromotions.length || !!retailerPromotions.length) && !!item.productId && (
                  <>
                    <VSpacer size="3" />
                    <Stack direction="row">
                      {!!farmerPromotions.length && (
                        <>
                          <Chip
                            label="Farmer Rewards"
                            onClick={() => {
                              setSelectedProductId(item.productId!);
                              setShowFarmerPromotions(true);
                            }}
                            sx={{
                              backgroundColor: '#FFD600',
                              color: '#141414',
                              '&&:hover': {
                                backgroundColor: alpha('#FFD600', .9),
                              },
                            }}
                          />
                          <HSpacer size="4" />
                        </>
                      )}
                      {!!retailerPromotions.length && (
                        <Chip
                          label="Active Sales Incentives"
                          onClick={() => {
                            setSelectedProductId(item.productId!);
                            setShowRetailerPromotions(true);
                          }}
                          sx={{
                            backgroundColor: '#A7FFEB',
                            color: '#141414',
                            '&&:hover': {
                              backgroundColor: alpha('#A7FFEB', .9),
                            },
                          }}
                        />
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
              <Divider />
            </React.Fragment>
          );
        },
      )}
      {(showFarmerPromotions || showRetailerPromotions) && !!selectedProductId && (
        <PromotionsSideSheet
          onClose={() => {
            setShowFarmerPromotions(false);
            setShowRetailerPromotions(false);
            setSelectedProductId(undefined);
          }}
          promotionType={showFarmerPromotions ? PromotionType.Farmer : PromotionType.Retailer}
          promotions={showFarmerPromotions
            ? getFarmerPromotions(selectedProductId)
            : getRetailerPromotions(selectedProductId)
          }
        />
      )}
    </>
  );
};

export default ProductListTab;
